import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import LockIcon from '@material-ui/icons/Lock';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Tabs, Label } from './userStyles';

export function UserSettingsTabs({ selectedTab, setSelectedTab }) {
  const { t } = useTranslation();

  return (
    <Tabs
      variant="fullWidth"
      textColor="primary"
      indicatorColor="primary"
      value={selectedTab}
      onChange={(_e, value) => setSelectedTab(value)} 
    >
      <Tab 
        value="notifications" 
        label={
          <Box display="flex" alignItems="center">
            <NotificationsIcon />
            <Label variant="subtitle2">{t('notificacoes').toLocaleLowerCase()}</Label>
          </Box>
        } 
      />
      <Tab 
        value="security" 
        label={
          <Box display="flex" alignItems="center">
            <LockIcon />
            <Label variant="subtitle2">{t('segurança')}</Label>
          </Box>
        } 
      />
    </Tabs>
  );
}