import './styles.css';

import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import React, { useEffect, useState } from 'react';

import { getMonth } from '../../utils/month';
import { useTheme } from '@material-ui/core';
import config from '../../config';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const LineWithArea = ({ lineColors, types }) =>
  types?.map((type) => [
    <Line
      type="monotone"
      dataKey={type}
      stroke={lineColors[type]}
      strokeWidth="2"
    />,
    <Tooltip />
  ]);

function LineChartImagine({ data }) {
  const [types, setTypes] = useState([]);
  const [legends, setLegends] = useState([]);
  const [lines, setLines] = useState([]);
  const theme = useTheme();

  const { ideasStepsFlux } = useSelector(state => state.domains);

  const isIdeaFlux = config.SHOW_IDEAS_BY_STEP_FLUX;

  const compareMonthYear = (a, b) => {
    const dateA = new Date(`1/${a.monthYear}`);
    const dateB = new Date(`1/${b.monthYear}`);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  const formatedData = isIdeaFlux ? data.map(({ charts, step }) => {
    return charts.map(({ monthYear, total }) => ({ monthYear, [step.name]: total }));
  }).reduce((acc, curr) => acc.concat(curr), []).reduce((acc, obj) => {
    const monthYear = obj.monthYear;
    const existingIndex = acc.findIndex((item) => item.monthYear === monthYear);
    if (existingIndex !== -1) {
      Object.keys(obj).forEach((key) => {
        if (key !== "monthYear") {
          acc[existingIndex][key] = obj[key];
        }
      });
    } else {
      const newObj = { monthYear: obj.monthYear };
      Object.keys(obj).forEach((key) => {
        if (key !== "monthYear") {
          newObj[key] = obj[key];
        }
      });
      acc.push(newObj);
    }
    return acc;

  }, []) : data;

  data = formatedData.sort(compareMonthYear);

const lineColorStepFlux = useMemo(() => {
  const result = {};
  ideasStepsFlux.forEach(({ name, labelColor }) => (result[name] = labelColor));
  return result;
}, [ideasStepsFlux]);

  const lineColors = isIdeaFlux ? lineColorStepFlux : {
    Aprovadas: theme.palette.auxiliary.green.main,
    Criadas: theme.palette.auxiliary.blue.main,
    Implementadas: theme.palette.auxiliary.grape.main,
    Rejeitadas: theme.palette.auxiliary.red.main
  };

  const dataFormated = data.map((item) => {
    return {
      monthYear: item.monthYear,
      Aprovadas: item.ideiasAprovadas,
      Criadas: item.ideiasCriadas,
      Implementadas: item.ideiasImplementadas,
      Rejeitadas: item.ideiasRejeitadas
    }
  });

  useEffect(() => {
    const lines = isIdeaFlux ? data.map(({ monthYear, ...rest }) => {
      const dateSplit = `${monthYear}`.split('/');
      return {
        monthYear: `${getMonth(Number(dateSplit[0]))}/${dateSplit[1]}`,
        ...rest,
      }
    }) : dataFormated.map(({ monthYear, ...rest }) => {
      const dateSplit = `${monthYear}`.split('/');
      return {
        monthYear: `${getMonth(Number(dateSplit[0]))}/${dateSplit[1]}`,
        ...rest,
      }
    });
    setLines(lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const types = Object.keys(isIdeaFlux ? lineColorStepFlux : dataFormated[0]);
    types.shift();
    setTypes(types);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const legends = types.map((t) => ({
      id: t,
      value: t,
      type: 'circle',
      color: lineColors[t],
    }));

    setLegends(legends);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  const textColor = theme.palette.neutrals.low.light;

  const fill = {
    fill: textColor,
  };

  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: textColor }}>{value}</span>;
  };

  return (
    <ResponsiveContainer height={400} width="100%">
      <ComposedChart
        data={lines}
        margin={{ top: 50, right: 10, left: 0, bottom: 5 }}
      >

        <defs>
          {types.map((type, index) => (
            <linearGradient
              key={index}
              id={String(index)}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={lineColors[type]}
                stopOpacity={0.1}
              />
              <stop offset="50%" stopColor={lineColors[type]} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <XAxis
          dataKey="monthYear"
          color={theme.palette.neutrals.high.lightest}
          axisLine={false}
          tickLine={false}
          tick={fill}
          dy={10}
        />
        <YAxis stroke={theme.palette.neutrals.high.lightest} tick={fill} tickLine={false} dx={-20} />
        <CartesianGrid strokeWidth="1" stroke={theme.palette.neutrals.high.lightest} />
        {legends && (
          <Legend
            formatter={renderColorfulLegendText}
            payload={legends}
          />
        )}
        {LineWithArea({ lineColors, types })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export { LineChartImagine };
