import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import EditIcon from '@material-ui/icons/Edit';

import { generalEvaluationActions } from '../../actions';
import { Evaluations } from '../../components/GeneralEvaluation';
import { BackNavAdmin } from '../../components/Shared';
import { SkeletonGeneralEvaluation } from '../../components/Skeletons';

export function GeneralEvaluationDetailsPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: groupId } = useParams();
  const { loading } = useSelector(state => state.generalEvaluation);

  useEffect(() => {
    dispatch(generalEvaluationActions.getEvaluationGroupById(groupId));
    dispatch(generalEvaluationActions.getAllEvaluationsByGroup(groupId));
  }, [groupId]);

  function handleDirectToEditEvaluation() {
    history.push(`/preferences/general-evaluation/${groupId}/edit`);
  }

  return (<>
    <BackNavAdmin title="Detalhes da avaliação">
      <Button
        color="primary"
        variant="outlined"
        disabled={loading}
        startIcon={<EditIcon/>}
        onClick={handleDirectToEditEvaluation}
      >
        {t('editar_avaliacao')}
      </Button>
    </BackNavAdmin>

    <Container maxWidth="lg" style={{ padding: 32 }}>
      <Grid container>
        <Grid item xs={12}>
          {loading 
            ? <SkeletonGeneralEvaluation /> 
            : <Evaluations />
          }
        </Grid>
      </Grid>
    </Container>
  </>);
}