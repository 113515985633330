import React from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    DialogActions,
    makeStyles,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogTitle: {
        fontSize: 20,
        color: theme.palette.neutrals.low.main,
        fontWeight: 'bold',
        padding: theme.spacing(2, 0),
    },
    dialogDescription: {
        fontSize: 16,
        color: theme.palette.neutrals.low.medium,
    },
    dialogActions: {
        padding: theme.spacing(4, 0),
        justifyContent: 'end',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    closeIcon: {
        color: theme.palette.neutrals.low.medium
    },
    iconButton: {
        width: 40,
        height: 40
    }
}));

function DialogExecutionFlow({ open, onClose, title, subtitle, description, children }) {
    const classes = useStyles();

    function handleCloseDialog() {
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogTitle}>{title}</div>
                    <IconButton className={classes.iconButton} onClick={handleCloseDialog}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </div>
                <Divider />
                <div className={classes.dialogContent}>
                    <div className={classes.dialogTitle}>{subtitle}</div>
                    <div className={classes.dialogDescription}>{description}</div>
                </div>
                <DialogActions className={classes.dialogActions}>
                    {children}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export { DialogExecutionFlow };
