import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Divider, Card as MuiCard, styled } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import { AvatarIconWithText } from '../Shared';

const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.neutrals.high.main,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4)
}));

export function ListClassificationPersonalized() {
  const { t } = useTranslation();
  const { personalizedClassification } = useSelector(state => state.personalizedClassification);

  return (
    <Card elevation={0}>
      <AvatarIconWithText
        icon={<CategoryIcon />}
        variant="h3"
        text={t('classificacao_das_ideias')}
      />
      <Box margin="2rem 0rem 1rem">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t('categoria')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{t('descricao')}</Typography>
          </Grid>
        </Grid>
      </Box>
      {personalizedClassification?.map(({ name, description, _id }, index) => (
        <Grid container spacing={3} key={_id}>
          <Grid item xs={4}>
            <Typography variant="body2">{name}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{description}</Typography>
          </Grid>
          {personalizedClassification?.length !== index + 1 && (
            <Grid item xs={12}>
              <Divider style={{ marginBottom: 10 }} />
            </Grid>
          )}
        </Grid>
      ))}
    </Card>
  );
}
