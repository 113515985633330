import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Button, Card } from "@material-ui/core"
import { ideaEditStyles } from "./IdeaEditStyles"
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux'
import { ideaUpdateActions } from "./../../actions"
import { useParams } from 'react-router-dom';
import { DialogDeleteDraft } from '../../components';
import { Skeleton } from '@material-ui/lab';

export const IdeaEditActions = () => {
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { deleteLoading } = useSelector(state => state.idea)
  const { filesToRemove, saveLoading } = useSelector(state => state.ideaUpdate)
  const { fields } = useSelector(state => state.multiAreaCascade)
  const { ideaUpdate } = useSelector(state => state)
  const { id } = useParams();
  const [ deleteDialog, setDeleteDialog ] = useState(false);
  const buls = fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit)[0];

  function convertStringInNumber(value) {
    let cleanValue = value.replace('R$', ' ').trim();

    cleanValue = cleanValue.replace(/\./g, '').replace(',', '.');

    let number = parseFloat(cleanValue);

    return number;
  }

  async function handleSave() {
    if (filesToRemove.length > 0) {
      dispatch(ideaUpdateActions.sendRemoveFiles(id, filesToRemove));
    }

    let data = {
      ...ideaUpdate,
      contributors: ideaUpdate.contributors.map(item => item.value),
      estimatedFinancialReturn: convertStringInNumber(ideaUpdate.estimatedFinancialReturn),
      estimatedExecutionCost: convertStringInNumber(ideaUpdate.estimatedExecutionCost),
      challenge: ideaUpdate.challenge?.value || null,
      businessUnitLocal: fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit),
      personalizedClassifications: ideaUpdate.personalizedClassifications.filter(item => item.checked === true).map(item => {
        delete item.checked;
        return item;
      })
    }

    delete data.attachments;
    delete data.filesToRemove;
    delete data.loadingParticipants;
    delete data.loadParticipants;
    delete data.loading;
    delete data.saveLoading;
    delete data.allParticipants;
    delete data.classificationsList;
    delete data.challengesList;
    delete data.ideaFailed;
    delete data.currentStepFlux;

    await dispatch(ideaUpdateActions.updateIdea(id, data, t('sua_ideia_foi_salva')));
  }

  function handleSaveAndSendQualification() {
    if (filesToRemove.length > 0) {
      dispatch(ideaUpdateActions.sendRemoveFiles(id, filesToRemove));
    };
    
    dispatch(ideaUpdateActions.updateIdea(id, {
      ...ideaUpdate,
      sendToQualification: true,
      businessUnitLocal: fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit),
    }, t('sua_ideia_foi_salva_qualificacao')));
  }

  function toggleDeleteDialog() {
    setDeleteDialog(!deleteDialog)
  }

  function SkeletonCard() {
    return (
      <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 50 }}>
        <Skeleton width='90%' height={80} />
        <Skeleton width='90%' height={80} />
      </Card>
    );
  }

  return (
    <>
      <DialogDeleteDraft open={deleteDialog} idea={ideaUpdate} onClose={toggleDeleteDialog} />
      {ideaUpdate?._id ? (
        <Grid item container spacing={2} className={`${classes.root} ${classes.sticky}`}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              className={classes.actionButton}
              onClick={handleSave}
              disabled={deleteLoading || saveLoading || !buls?.local.name || !buls?.businessUnit.name || !buls?.occupationArea.name || buls === undefined}
            >
              {t('salvar_ideia')}
            </Button>
          </Grid>
          {(ideaUpdate?.statusIdea?.code === 'PENDING_REVIEW') && (<Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              className={classes.actionButton}
              onClick={handleSaveAndSendQualification}
              disabled={deleteLoading || saveLoading}
            >
              {t('enviar_para_qualificacao')}
            </Button>
          </Grid>
          )}
          {(ideaUpdate?.statusIdea?.code === 'AWAITING_QUALIFICATION') && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<DeleteIcon />}
                className={classes.actionButton}
                onClick={toggleDeleteDialog}
                disabled={deleteLoading || saveLoading}
              >
                {t('excluir_ideia')}
              </Button>
            </Grid>
          )}
        </Grid>
      ) : <SkeletonCard />}
    </>
  )
}
