import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(2)
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonContentsFeed() {
  const classes = useStyles();
  const arrayItems = Array.from(Array(2));

  return (
    <Box>
      <Card elevation={0} className={classes.card} style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton variant="circle" width={22} height={22} />
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" marginLeft="7px">
          <Skeleton variant="rect" width={60} />
          <Skeleton variant="rect" width={60} />
        </Box>
      </Card>
      
      <Card elevation={0} className={classes.card} style={{ margin: '16px 0' }}>
        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
          {arrayItems.map((item, index) => (
            <Fragment key={index}>
              <Grid item xs={8}>
                <Skeleton variant="rect" width="100%" height={12} />
                <Skeleton variant="rect" width="100%" height={12} style={{ margin: '8px 0' }} />
                <Skeleton variant="rect" width="100%" height={12} style={{ marginBottom: 8 }} />
                <Skeleton variant="rect" width="100%" height={12} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="rect" width={"100%"} height={72} />
              </Grid>
              {index !== (arrayItems.length -1) && (
                <Grid item xs={12}>
                  <Divider style={{ margin: '0.5rem 0' }} />
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      </Card>
    </Box>
  );
}