import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  hidden: {
    backgroundColor: 'unset',
    border: 'unset',
  },
  root: {
    cursor: 'default',
    marginRight: 15,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  titleContainer: {
    padding: theme.spacing(2, 2, 0),
    borderTop: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderLeft: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRight: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  tableHeader: {
    borderLeft: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRight: `1px solid ${theme.palette.neutrals.high.medium}`,
  }
}));