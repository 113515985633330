import React, { useState } from 'react'
import Box from "@material-ui/core/Box";
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  characters: {
    position: 'absolute',
    right: 18,
    fontSize: 12,
    padding: theme.spacing(1, 0),
  },
}))

export const TextArea = ({ limitChars=256, label, onBlur, value="" }) => {
  const classes = useStyles();
  const [descriptionChar, setDescriptionChar] = useState(0);

  function handleSetProjectDescription(e) {
    setDescriptionChar(e.target.value.length) 
  }

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.characters}>
          <span>{descriptionChar}/{ limitChars } caracteres</span>
        </Box>
        <TextField
          minRows={5}
          fullWidth
          multiline
          variant="filled"
          label={label}
          onChange={handleSetProjectDescription}
          onBlur={onBlur}
          defaultValue={value}
          inputProps={{ maxLength: limitChars }}
        />
      </Box>
    </Box>
  )
}
