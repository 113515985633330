import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import { 
  DialogTitle,
  HeaderTitle,
  DialogContent,
  DialogActions
} from './styles';
import { evaluationTeamsActions } from '../../actions';

export function DialogDeleteEvaluationTeam({ open, onClose, team }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleDeleteEvaluationTeam() {
    dispatch(evaluationTeamsActions.deleteEvaluationTeam(team._id, t('equipe_excluida')));
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <HeaderTitle>{t('excluir_equipe')}</HeaderTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">{t('deseja_excluir_a_equipe')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('as_informacoes_incluidas_sobre_a_equipe')}
              {' '} <strong>{team.name}</strong> {' '}
              {t('serao_excluidas_permanentemente')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: 16 }}
            onClick={onClose}
          >
            {t('voltar')}
          </Button>
          <Button
            color="primary"
            variant="contained"           
            startIcon={<DeleteIcon />}
            onClick={handleDeleteEvaluationTeam}
          >
            {t('excluir_equipe')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}