import { tutorialsConstants } from "../constants";
import { uploadsService } from "../services";
import { helpCenterService } from "../services/help-center.service";
import { alertActions } from "./alert.actions";

export const tutorialsActions = {
  getTutorials,
  putTutorialFile,
  handleDialog,
  deleteFileList,
  setTutorialUrl,
  deleteTutorial
};

function setTutorialUrl(url) {
  return dispatch => {
    dispatch({ type: tutorialsConstants.SET_TUTORIAL_URL, payload: { url } })
  }
}

function getTutorials() {
  return dispatch => {
    dispatch({ type: "REQUEST_GET_TUTORIALS" });
    helpCenterService.getTutorials()
      .then(response => {
        dispatch({ type: "SUCCESS_GET_TUTORIALS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FAILURE_GET_TUTORIALS" });
        console.error(error);
      });
  }
}

function deleteFileList(id) {
  return dispatch => {
    dispatch({ type: tutorialsConstants.DELETE_TUTORIAL_FILE, payload: { id } })
  }
}

function putTutorialFile(title, url, id, fileToDeleteId, file) {
  return dispatch => {
    dispatch({ type: "REQUEST_PUT_TUTORIALS" });
    if (!!url.length) {
      uploadsService.putTutorialFile(title, url, id)
        .then(() => {
          dispatch({ type: "SUCCESS_PUT_TUTORIALS" });
          dispatch(handleDialog({ open: false, isEdit: true }));
          dispatch(getTutorials());
          dispatch(alertActions.success('success'));
        })
        .catch((error) => {
          dispatch({ type: "FAILURE_PUT_TUTORIALS" });
          console.error(error);
          dispatch(alertActions.error('Tente novamente'));
        });
    } else {
      uploadsService.postTutorialFile(file).then(({ data }) => {
        uploadsService.putTutorialFile(title, data, id, file.name)
          .then(() => {
            dispatch({ type: "SUCCESS_PUT_TUTORIALS" });
            dispatch(handleDialog({ open: false, isEdit: true }));
            dispatch(getTutorials());
            dispatch(alertActions.success('success'));
          }).catch(err => console.error(err))
      }).catch((error) => {
        dispatch({ type: "FAILURE_PUT_TUTORIALS" });
        console.error(error);
        dispatch(alertActions.error('Tente novamente'));
      });
    }
  }
}

function handleDialog({ open = false, isEdit = false, tutorialId="" }) {
  return dispatch => {
    dispatch({ type: tutorialsConstants.HANDLE_DIALOG_TUTORIAL, payload: { open, isEdit, tutorialId } });
  }
}

function deleteTutorial(id, message='') {
  return dispatch => {
    helpCenterService.deleteTutorial(id).then(() => {
      dispatch(getTutorials());
      dispatch(alertActions.success(message));
    }).catch(error => console.error(error))
  }
}