import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from "@material-ui/core"
import { ideaEditStyles } from './IdeaEditStyles'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import { MultiAreaCascade } from './../../components/Shared'
import { useDispatch, useSelector } from 'react-redux'
import { multiAreaCascadeActions } from './../../actions'

export const IdeaEditGeneralCoverage = () => {
  const classes = ideaEditStyles();
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { businessUnitLocal } = useSelector(state => state.ideaUpdate)
  const { imagineLocals } = useSelector(state => state.domains)
  
  function handleAddMoreBusiness() {
    dispatch(multiAreaCascadeActions.addField(imagineLocals))
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatarIcon}>
              <BusinessOutlinedIcon color="primary" />
            </Avatar>
            <Typography variant="h3">{t('abrangencia')}</Typography>
          </Box>
          <Button 
            variant="text"
            color="primary"
            startIcon={<AddCircleOutlinedIcon />}
            onClick={handleAddMoreBusiness}
          >
            {t('incluir_area')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MultiAreaCascade hasDeleteButton loadBusinessUnitLocals={businessUnitLocal} />
        </Grid>
      </Grid>
    </Grid>
  )
}
