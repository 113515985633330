import axios from 'axios';
import config from '../config';
import { requestHeaders } from '../helpers';

export const ideaStepFluxService = {
    createIdeaStepFlux,
    removeIdeaStepFlux,
    editIdeaStepFlux
}

async function createIdeaStepFlux(data) {
  const response = await axios.post(`${config.URL_BASE}/idea-step-flux/`, data, requestHeaders());
  return response.data;
}

async function editIdeaStepFlux(data) {
  const response = await axios.put(`${config.URL_BASE}/idea-step-flux/${data._id}`, data, requestHeaders());
  return response.data;
}

async function removeIdeaStepFlux(_id) {
  const response = await axios.delete(`${config.URL_BASE}/idea-step-flux/${_id}`, requestHeaders());
  return response;
}
