import { styled } from '@material-ui/core/styles';
import MuiGrid from '@material-ui/core/Grid';
import MuiAvatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

export const Grid = styled(MuiGrid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  background: theme.palette.primary.lightest,
  color: theme.palette.primary.main,
  fill: theme.palette.primary.main,
  marginRight: 16
}));

export const HeaderTitle = styled(Typography)({
  paddingRight: 10
});