import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import {
  Card,
  Header,
  Title,
  Value,
  SubtitleContainer,
  Content,
  ValuesContainer,
  Circle,
  LinearProgressGray,
  LinearProgressGreen,
  LinearProgressRed
 } from './styles';
import { dynamicMask } from 'simple-currency-mask';
import { masker } from '../../../../helpers';

export function FinancialReturnCard({ loading=false, financialReturnTotal }) {
  const { t } = useTranslation();
  const { palette } = useTheme();


  return (
    <Card elevation={0}>
      <Header>
        <Title variant="subtitle1">{t('retorno_financeiro')}</Title>
        <Value>{masker((financialReturnTotal?.retornoRealizadoTotal || 0).toString())}</Value>
      </Header>
      <Content>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">{t('retorno_previsto')} x {t('realizado')}</Typography>
            <SubtitleContainer>
              <Circle style={{ backgroundColor: palette.neutrals.high.dark }} />
              <Typography variant="caption">{t('previsto')}</Typography>
            </SubtitleContainer>
            <ValuesContainer>
       
              <Typography variant="caption">{masker((financialReturnTotal?.retornoPrevistoTotal || 0).toString())}</Typography>
            </ValuesContainer>
            <SubtitleContainer>
              <Circle style={{ backgroundColor: palette.auxiliary.green.main }} />
              <Typography variant="caption">{t('realizado')}</Typography>
            </SubtitleContainer>
            <ValuesContainer>
   
              <Typography variant="caption">{masker((financialReturnTotal?.retornoRealizadoTotal || 0).toString())}</Typography>
            </ValuesContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">custo previsto x realizado</Typography>
            <SubtitleContainer>
              <Circle style={{ backgroundColor: palette.neutrals.high.dark }} />
              <Typography variant="caption">{t('previsto')}</Typography>
            </SubtitleContainer>
            <ValuesContainer>
              <Typography variant="caption">{masker((financialReturnTotal?.custoPrevistoTotal || 0).toString())}</Typography>
            </ValuesContainer>
            <SubtitleContainer>
              <Circle style={{ backgroundColor: palette.auxiliary.red.main }} />
              <Typography variant="caption">{t('realizado')}</Typography>
            </SubtitleContainer>
            <ValuesContainer>   
            <Typography variant="caption">{masker((financialReturnTotal?.custoRealizadoTotal || 0).toString())}</Typography>
            </ValuesContainer>
          </Grid>
        </Grid>
      </Content>
    </Card>
  );
}