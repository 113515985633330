import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, DialogActions, Grid, FormControl, MenuItem, InputLabel, Select } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { ReactComponent as OutBox } from '../../../assets/imgs/outbox.svg';
import { delegateStepIdeaActions } from "../../../actions";
import { AvatarIconWithText } from '../../Shared';

export function ForwardIdeaStepFluxForm({ handleBack, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ideasStepsFlux } = useSelector((state) => state.domains);
  const { selectedStepId } = useSelector(state => state.delegateStepIdea);

  const SelectProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  function handleSelectedStep(e) {
    dispatch(delegateStepIdeaActions.changeSelectedStep(e.target.value));
  }

  function handleSend() {
    dispatch(delegateStepIdeaActions.postForwardStepId(id, selectedStepId));
    onClose();
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AvatarIconWithText
            style={{ width: 32, height: 32 }}
            icon={<OutBox />}
            variant="subtitle1"
            text={t('encaminhar_ideia')}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 30 }}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Selecione a próxima etapa se tomada decisão de voltar</InputLabel>
            <Select
              MenuProps={SelectProps}
              defaultValue={selectedStepId}
              onChange={handleSelectedStep}
            >
              {ideasStepsFlux.map((value, index) => (
                <MenuItem value={value._id} key={index}>{value.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent={'space-between'}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleBack('index')}
            startIcon={<ArrowBack />}
          >
            {t('voltar')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            disabled={!selectedStepId}
          >
            {t('encaminhar')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}