import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Note from '@material-ui/icons/Note';

export const ContentsContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  boxSizing: 'border-box',
  height: 'calc(100vh - 70px)',
  top: '70px',
  paddingTop: theme.spacing(0),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
  },
}));

export const ContentsCardTitle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
}));

export const TextSeeAll = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  cursor: 'pointer',
  color: theme.palette.neutrals.low.light,
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));

export const ContentsCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  backgroundColor: theme.palette.neutrals.high.main
}));

export const ContentDescription = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  paddingTop: theme.spacing(1),
  color: theme.palette.neutrals.low.light,    
}));

export const Image = styled('img')(({ theme }) => ({
  width: 72,
  height: 72,
  borderRadius: 4,
  objectFit: 'cover',
  marginLeft: theme.spacing(1)
}));

export const Link = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  wordWrap: 'break-word',
  color: theme.palette.neutrals.low.main,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));

export const NoteIcon = styled(Note)(({ theme }) => ({
  marginRight: 6,
  color: theme.palette.auxiliary.pink.main,
}));
