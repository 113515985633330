import { dashboardConstants } from '../constants';

const initialState = {
	locals: [],
	businessUnits: [],
	occupationArea: [],
	statusIdeaCode: [],
	statusChallengeCode: [],
	loading: false,
	csvBase64: "",
	loaders: {
		loadingTopUsers: false
	},
	topUsers: [],
	sortUsers: "points",
  sortPeriod: 30,
  periodType: "daily"
}

export function dashboard(state = initialState, action) {
	switch (action.type) {

		case dashboardConstants.GET_DASHBOARD_CSV_REQUEST: {
			return {
				...state,
				loading: true
			}
		}

		case dashboardConstants.GET_DASHBOARD_CSV_SUCCESS: {
			return {
				...state,
				loading: false,
				csvBase64: action.payload
			}
		}

		case dashboardConstants.GET_DASHBOARD_CSV_FAILURE: {
			return {
				...state,
				loading: false
			}
		}

		case dashboardConstants.REQUEST_GET_USERS: {
			return {
				...state,
				loaders: {
					loadingTopUsers: true
				}
			};
		}

		case dashboardConstants.FAILURE_GET_USERS: {
			return {
				...state,
				loaders: {
					loadingTopUsers: false
				}
			};
		}

		case dashboardConstants.SUCCESS_GET_USERS: {
			return {
				...state,
				topUsers: action.payload,
				loaders: {
					loadingTopUsers: false
				}
			};
		}

    case dashboardConstants.CLEAR_DASHBOARD_CSV_DOWNLOAD: {
      return {
        ...state,
        csvBase64: ""
      }
    }

		case dashboardConstants.SET_SORT_USERS: {
			return {
				...state,
				sortUsers: action.payload
			};
		}

	case dashboardConstants.SET_SORT_PERIOD: {
			return {
				...state,
				sortPeriod: action.payload
			};
		}
	case dashboardConstants.SET_PERIOD_TYPE: {
			return {
				...state,
				periodType: action.payload
			};
		}
		default:
			return state;

	}
}