import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: alpha(theme.palette.common.black, 0.9),
    textTransform: 'lowercase',
    fontSize: 12
  },
}));

function CustomTooltip(props) {
  const classes = useStyles();

  return <Tooltip classes={classes} {...props} />;
}

export { CustomTooltip };
