import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  TextField,
  FormControl,
  // FormLabel,
  // RadioGroup,
  FormControlLabel,
  // Radio,
  Button,
  Checkbox,
  FilledInput,
  InputLabel,
  FormHelperText,
  Hidden,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import registerImage from '../../assets/imgs/register.svg';
import imagineLogo from '../../assets/imgs/logo.svg';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { validation, history, PhoneMaskCustom, CPFMaskCustom } from '../../helpers';
import { MultiAreaCascade } from '../../components/Shared';
import {
  domainsActions,
  alertActions,
  authenticationActions,
} from '../../actions';
import Save from '@material-ui/icons/Save';
import { UploadImageUser } from '../../components/UserEdit/UploadImageUser';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.high.lightest,
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(0),
    margin: 0,
  },
  presentation: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',

    padding: theme.spacing(0, 12),
  },
  subtitle: {
    fontSize: 16,
  },
  registerContainer: {
    padding: theme.spacing(0, 10, 6, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2, 2, 2),
    },
  },
  spaceBottom: {
    paddingBottom: theme.spacing(3),
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
  navFixed: {
    position: 'fixed',
    backgroundColor: 'white',
    zIndex: 9999,
    paddingTop: theme.spacing(6),
  },
  fixed: {
    position: 'fixed',
    boxSizing: 'border-box',
    maxWidth: 480,
  },
  emailField: {
    maxWidth: 400,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  imagineLogo: {
    [theme.breakpoints.down('md')]: {
      width: 120,
    },
  },
  containerForm: {
    paddingTop: 240,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 290,
    },
  },
  containerButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
 error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}));

function RegisterPage() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const { fields } = useSelector(state => state.multiAreaCascade)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const { register, errors, control, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {},
  });

  useEffect(() => {
    dispatch(domainsActions.getDomains());
  }, [dispatch]);

  function onSubmit(data) {
    if (!data.terms) {
      dispatch(alertActions.error('É necessário aceitar o termos de uso.'));
      return;
    }

    // if (!fields[0].selectedBusinessUnit) {
    //   dispatch(alertActions.error('Ainda faltam campos obrigatórios.'));
    //   return;
    // }

    const body = {
      businessUnitLocal: null,
      email: user.email,
      emailNotification: false,
      enrolment: '',
      isFullRegister: true,
      name: user.name,
      dateOfBirth: user.dateOfBirth,
      document: user.document,
      phone: '',
      position: '',
      smsNotification: null,
      status: true,
      _id: user._id,
    };
    let registerUser = { 
      ...body, 
      ...data,
      // businessUnitLocal: fields[0].selectedBusinessUnit
    };

    if (data.password && data.password !== '') registerUser.password = data.password;

    dispatch(authenticationActions.registerOffice365(registerUser));
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container style={{ height: '100vh' }}>
        <Hidden smDown>
          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={6}
            className={classes.presentation}
          >
            <div className={classes.fixed}>
              <img
                style={{ width: '100%' }}
                src={registerImage}
                alt="register"
              ></img>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <div className={classes.registerContainer}>
            <div className={classes.navFixed}>
              <div className={classes.spaceBottom}>
                <img
                  src={imagineLogo}
                  alt="logo"
                  className={classes.imagineLogo}
                />
              </div>
              <div
                className={clsx(classes.subtitle, classes.spaceBottom)}
                style={{ fontWeight: 'bold' }}
              >
                {t('seja_bem_vindo_ao')}
              </div>
              <div className={clsx(classes.subtitle, classes.spaceBottom)}>
                {t('esse_e_o_seu_primeiro_acesso')}, {t('por_favor')},{' '}
                {t(
                  'preencha_os_campos_abaixo_para_acessar_a_sua_conta_na_plataforma'
                )}
                .
              </div>
            </div>
            <div className={classes.containerForm}>
              <div
                className={clsx(classes.subtitle, classes.spaceBottom)}
                style={{ fontWeight: 'bold', paddingTop: '20px' }}
              >
                {t('dados_pessoais').toUpperCase()}
              </div>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <UploadImageUser user={user} /> (opcional)

                <TextField
                  name="name"
                  id="name"
                  label={t('nome')}
                  autoComplete="name"
                  type="text"
                  variant="filled"
                  margin="normal"
                  fullWidth
                  required
                  inputRef={register({
                    required: t('o_campo_nome_e_obrigatorio'),
                  })}
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  defaultValue={user.name}
                  className={classes.spaceTop}
                />
                {/* <div>
                  <Controller
                    as={<KeyboardDatePicker />}
                    variant="inline"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    fullWidth
                    style={{ maxWidth: 320 }}
                    margin="normal"
                    id="date-picker-inline"
                    label="data de nascimento"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDateMessage="Date should not be in the past"
                    invalidDateMessage="Data de nascimento incorreta"
                    control={control}
                    name="dateOfBirth"
                    defaultValue={null}
                    className={classes.spaceTop}
                    required
                    inputRef={register}
                    rules={{required: true}}
                    error={!!errors.dateOfBirth}
                  />
                  <FormHelperText className={classes.error}>
                    {errors.dateOfBirth &&
                      errors.dateOfBirth.type === 'required' &&
                      'A data de nascimento é obrigatório'}
                  </FormHelperText>
                </div> */}
                <br />
                <TextField
                  name="email"
                  id="email"
                  label={t('email_corporativo_ou_pessoal')}
                  autoComplete="email"
                  type="email"
                  variant="filled"
                  margin="normal"
                  fullWidth
                  className={classes.emailField}
                  required
                  inputRef={register({
                    required: t('o_email_e_obrigatorio'),
                    pattern: {
                      value: validation.email,
                      message: t('o_email_digitado_nao_e_um_email_valido'),
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  defaultValue={user.email}
                />
                <br />
                {/* <FormControl
                  variant="filled"
                  style={{ width: '100%', maxWidth: 340 }}
                  error={!!errors.document}
                  className={classes.spaceTop}
                >
                  <InputLabel htmlFor="document">CPF *</InputLabel>
                  <FilledInput
                    name="document"
                    id="document"
                    type="text"
                    fullWidth
                    required
                    inputRef={register({
                      required: 'O campo CPF é obrigatório.',
                    })}
                    inputComponent={CPFMaskCustom}
                  />
                  <FormHelperText id="helper-document">
                    {errors.document && errors.document.message}
                  </FormHelperText>
                </FormControl> */}
                <br />
                  <FormControl
                    variant="filled"
                    error={!!errors.password}
                    margin="normal"
                    className={classes.spaceBottom}
                  >
                    <FilledInput
                      id="outlined-adornment-password"
                    placeholder={t('nova_senha')}
                    type="password"
                      name="password"
                      required
                      inputRef={register({
                      required: t('a_senha_e_obrigatoria'),
                      })}

                    />
                    <FormHelperText id="helper-password">
                      {errors.password && errors.password.message}
                    </FormHelperText>
                  </FormControl>
                <br />

                {/* <FormControl
                  variant="filled"
                  style={{ width: '100%', maxWidth: 340 }}
                  error={!!errors.phone}
                  className={classes.spaceTop}
                >
                  <InputLabel htmlFor="phone">telefone</InputLabel>
                  <FilledInput
                    name="phone"
                    id="phone"
                    type="tel"
                    inputRef={register({
                      // pattern: {
                      //   value: validation.phone,
                      //   message: 'O telefone digitado não é um telefone valido',
                      // },
                    })}
                    fullWidth
                    inputComponent={PhoneMaskCustom}
                  />
                  <FormHelperText id="helper-phone">
                    {errors.phone && errors.phone.message}
                  </FormHelperText>
                </FormControl> */}

                {/* <FormControl
                  component="fieldset"
                  style={{ width: '100%' }}
                  className={classes.spaceTop}
                >
                  <FormLabel component="legend" style={{ fontSize: '0.85rem' }}>
                    Identidade de genêro
                  </FormLabel>
                  <Controller
                    as={
                      <RadioGroup
                        aria-label="gender"
                        style={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio color="primary" />}
                          label="masculino"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio color="primary" />}
                          label="feminino"
                        />
                      </RadioGroup>
                    }
                    defaultValue={user.gender}
                    name="gender"
                    control={control}
                  />
                </FormControl> */}

                {/* <div style={{ width: '100%' }} className={classes.spaceTop}>
                  <MultiAreaCascade />
                </div> */}
                <div className={classes.spaceTop}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label={t('desejo_receber_as_notificacoes_por_email')}
                    style={{ width: 'auto' }}
                    name="emailNotification"
                    inputRef={register}
                  />
                </div>
                <div style={{ fontSize: 14 }}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    style={{ width: 'auto', marginRight: 0 }}
                    name="terms"
                    inputRef={register}
                  />
                  <span>aceito os </span>
                  <Link to="/regulation" target="_blank">
                    Termos de Uso e Políticas de privacidade
                  </Link>
                </div>
                <div
                  className={clsx(classes.spaceTop, classes.containerButton)}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ textTransform: 'lowercase', marginRight: 10 }}
                    color="primary"
                    startIcon={<Save />}
                  >
                    {t('salvar_perfil')}
                  </Button>
                  <Button
                    onClick={() => history.push('/login')}
                    variant="outlined"
                    color="primary"
                  >
                    {t('cancelar')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export { RegisterPage };
