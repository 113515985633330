import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4)
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
}))(MuiSkeleton);

export function SkeletonUserProfile() {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Skeleton width={200} height={22} variant="text" />
      <Skeleton width={100} height={17} variant="text" />
    </Card>
  );
}