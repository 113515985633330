import { languageConstanst } from '../constants';
import update from 'react-addons-update';

let initialState = localStorage.getItem('i18nextLng') || 'pt_BR';

export function language(state = initialState, action) {
  switch (action.type) {
    case languageConstanst.CHANGE_LANGUAGE:
      return update(state, { $set: action.payload.language });

    default:
      return state;
  }
}
