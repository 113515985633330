import React from 'react';
import { 
  makeStyles, 
  Container,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
    background: theme.palette.primary.lightest ,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '700',
    padding: theme.spacing(3.5, 0),
    textTransform: 'uppercase'
  }
}));

function FaqTitle({title}) {
  const classes = useStyles();
    return(
     <Box className={classes.title}> 
        <Container maxWidth="lg">
          {title}
        </Container>
      </Box>
    );
};

export { FaqTitle };
