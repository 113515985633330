import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  IconButton,
  Dialog,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { feedbackActions } from '../../actions';
import { DialogTitle, DialogContent, DialogActions } from './feedbackStyles';

export function DiscartFeedback(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { discartFeedback, closeFeedback } = props;

  function handleCloseFeedback() {
    dispatch(feedbackActions.clearFeedback());
    dispatch(feedbackActions.openFeedback(false));
  }
  
  return (
    <Dialog fullWidth open={discartFeedback} onClose={closeFeedback}>
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {t('descartar_feedback')}
        </Typography>
        <IconButton>
          <CloseIcon onClick={closeFeedback} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h3">
          {t('deseja_descartar_seu_feedback')}
        </Typography>
        <Typography variant="body1">
          {t('ele_nao_sera_compartilhado_conosco')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="outlined"
            color="primary"
            onClick={closeFeedback}
          >
            {t('continuar_feedback')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseFeedback}
          >
            {t('descartar')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
