export const tutorialsConstants = {
  REQUEST_GET_TUTORIALS: "REQUEST_GET_TUTORIALS",
  SUCCESS_GET_TUTORIALS: "SUCCESS_GET_TUTORIALS",
  FAILURE_GET_TUTORIALS: "FAILURE_GET_TUTORIALS",
  REQUEST_PUT_TUTORIALS: "REQUEST_PUT_TUTORIALS",
  SUCCESS_PUT_TUTORIALS: "SUCCESS_PUT_TUTORIALS",
  SUCCESS_PUT_TUTORIAL: "SUCCESS_PUT_TUTORIALS",
  FAILURE_PUT_TUTORIALS: "FAILURE_PUT_TUTORIALS",
  HANDLE_DIALOG_TUTORIAL: "HANDLE_DIALOG_TUTORIAL",
  DELETE_TUTORIAL_FILE: "DELETE_TUTORIAL_FILE",
  SET_TUTORIAL_URL: "SET_TUTORIAL_URL"
};