import { useTheme } from "@material-ui/core";

export function usePieChartColors() {
  const theme = useTheme();

  const colors = [
    theme.palette.auxiliary.blue.light,
    theme.palette.auxiliary.grape.light,
    theme.palette.auxiliary.green.main,
    theme.palette.auxiliary.yellow.light,
    theme.palette.auxiliary.red.light,
    theme.palette.auxiliary.pink.light,
    theme.palette.auxiliary.cyan.light,
    theme.palette.auxiliary.indigo.light,
    theme.palette.auxiliary.blue.main,
    theme.palette.primary.medium,
    theme.palette.auxiliary.green.main,
    theme.palette.auxiliary.blue.main,
    theme.palette.auxiliary.red.main,
    theme.palette.auxiliary.yellow.main,
    theme.palette.auxiliary.orange.main,
    theme.palette.auxiliary.pink.main,
    theme.palette.auxiliary.cyan.main,
    theme.palette.auxiliary.indigo.main,
  ];

  return {
    theme,
    colors
  }
}