import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const userFunctionService = {
  getUsers,
  getBusiness,
  getUserType,
  submitUser,
  getUserProfile,
  getUser,
}

function getUsers() { 
  return axios.get(`${config.URL_BASE}/user/profile/?q=&search=name&page=1&methodology=1&quantityPerPage=1000`, requestHeaders())
    .then(handleResponse)
}

function getUser(userId) {
  return axios.get(`${config.URL_BASE}/user/${userId}/profile`, requestHeaders())
}

function getBusiness() {
  return axios.get(`${config.URL_BASE}/domains/business-unit-locals`, requestHeaders())
    .then(handleResponse)
}

function getUserType() {
  return axios.get(`${config.URL_BASE}/domains/type-profiles`, requestHeaders())
    .then(handleResponse)
}

function submitUser(userId, data) {
  return axios.put(`${config.URL_BASE}/user/${userId}/profile`, data, requestHeaders())
}

function getUserProfile(userId) {
  return axios.get(`${config.URL_BASE}/user/${userId}/profile`, requestHeaders())
}