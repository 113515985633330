import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export const SkeletonDashboardTops = () => {
  const arrayCells = Array.from(Array(9));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton variant="rect" width={'50%'} height={27} />
          <Skeleton variant="circle" width={27} height={27} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {arrayCells.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Skeleton variant="rect" width={'100%'} height={18} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
