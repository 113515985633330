import React, { useEffect, useState } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  Box,
  IconButton,
  Badge,
  Menu,
  Paper,
  makeStyles,
  MenuList,
  MenuItem,
  Button,
  useTheme,
  Divider,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../actions';
import { dateFormat, history, timeFormat } from '../../helpers';
import CloseIcon from '@material-ui/icons/Close';
import InfiniteScroll from 'react-infinite-scroll-component';
import config from '../../config';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { useTranslation } from 'react-i18next';
import LabelIcon from '@material-ui/icons/Label';
import { useWindowSize } from '../../hooks';
import { menuCss } from './menuCss';
import { EmptyState } from '../../components/Shared';
import iconEmptyStateNotification from '../../assets/imgs/emptystate/illustration_empty-state-notifications.svg';

const useStyles = makeStyles((theme) => ({
  menu: {
    position: 'relative',
    overflowY: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 2, 4),
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 9999,
  },
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
    textTransform: 'lowercase',
  },
  item: {
    padding: theme.spacing(2, 2, 3, 4),
  },
  paper: {
    overflowY: 'hidden',
  },
  icone: {
    marginRight: 10,
    color: theme.palette.neutrals.low.light,
  },
  notificationTitle: {
    fontSize: 14.22,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.dark,
  },
  readNotificationTitle: {
    fontSize: 14.22,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.light,
  }
}));

function Notification() {
  const classes = useStyles();
  const classesMenu = menuCss();
  const dispatch = useDispatch();
  const { paging, notifications, loading } = useSelector(
    (state) => state.notification
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const theme = useTheme();

  useEffect(() => {
    dispatch(notificationActions.getNotification(1, paging.sort));
  }, [dispatch, paging.sort]);

  useEffect(() => {
    if (!anchorEl && config.LOOPING_NOTIFICATION === true) {
      const interval = setInterval(() => {
        dispatch(notificationActions.getNotification(1));
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [anchorEl, dispatch]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    dispatch(notificationActions.updateVisualized());
  }

  function loadMoreNotification() {
    if (!loading) {
      dispatch(
        notificationActions.loadMoreNotification(paging.page, paging.sort)
      );
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function markAllRead() {
    dispatch(notificationActions.markAllRead());
  }

  function checkAction(notification, indexNotification) {
    dispatch(notificationActions.checkAction(notification, indexNotification));
  }

  function renderIcon(type) {
    if (type === 'send') {
      return <EmojiObjectsIcon />;
    }

    if (type === 'up') {
      return <ThumbUpIcon />;
    }

    if (type === 'comment') {
      return <ChatBubbleIcon />;
    }

    if (type === 'execution') {
      return <LabelIcon />;
    }
    if (type === 'challenge') {
      return <EmojiObjectsIcon />;
    }
  }

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={paging.total} overlap="circular" classes={{ badge: classesMenu.badgeColor }}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-notification"
        anchorEl={anchorEl}
        MenuListProps={{ disablePadding: true }}
        classes={{
          paper: classes.paper,
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <Paper className={classes.menu}>
          <div className={classes.header}>
            <div className={classes.title}>{t('notificacoes')}</div>

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          
          {notifications.every(({ read }) => read) ? (<>
            <Box padding="20px">
                <EmptyState
                  icon={iconEmptyStateNotification}
                  description={<>
                    {t('tudo_atualizado')} {' '} <br />
                    {t('nao_ha_novas_notificacoes_por_aqui')}
                  </>}
                />
              </Box>
            <Divider />
            <Box display="flex" justifyContent="center" padding={1} paddingBottom={1}>
              <Button
                style={{ fontSize: 16 }}
                onClick={() => {
                  history.push('/notifications')
                }}
                color="primary"
              >
                {t('ver_todas_as_notificacoes')}
              </Button>
            </Box>
          </>
            ) : (
              <MenuList>
                <InfiniteScroll
                  dataLength={notifications.length}
                  next={loadMoreNotification}
                  hasMore={true}
                  height={width > config.RESPONSIVE_MOBILE ? 300 : 240}
                  scrollThreshold={'70%'}
                >
                  {notifications.map((notification, index) => (
                    <MenuItem
                      key={notification._id}
                      onClick={() => {
                        checkAction(notification, index);
                        handleClose();
                      }}
                      className={classes.item}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.icone}>
                          {renderIcon(notification.type)}
                        </div>
                        <div>
                          <div
                          className={notification.read ? classes.readNotificationTitle : classes.notificationTitle}
                          >
                            {notification.message}
                          </div>
                        <div style={{ fontSize: 11.44, color: theme.palette.neutrals.low.light }}>
                            {dateFormat(notification.date, language)} |{' '}
                            {timeFormat(notification.date)}
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                </InfiniteScroll>
                <Box display="flex" justifyContent="center" padding="16px">
                  <Button
                    style={{ fontSize: 16 }}
                  onClick={() => {
                    history.push('/notifications')
                  }}
                    color="primary"
                >
                  {t('ver_todas_as_notificacoes')}
                </Button>
                <Button
                  variant='text'
                  style={{ fontSize: 16 }}
                  onClick={markAllRead}
                  >
                    {t('marcar_todos_como_lidos')}
                  </Button>
                </Box>
              </MenuList>
            )}
        </Paper>
      </Menu>
    </React.Fragment>
  );
}

export { Notification };
