import { portfolioConstants } from '../../constants/portfolio.constants';
import { formatReal } from "./../../helpers"

const initialStateCosts = {
  description: '',
  estimatedValue: 0,
}

const initialState = {
  loading: false,
  costs: [initialStateCosts],
  estimatedFinancialReturn: 0,
}

export function portfolioDialogFinnancial(state = initialState, action) {
  switch (action.type) {
    case portfolioConstants.LOAD_CREATE_FINANCIAL_PLANNING_DIALOG:
      return { 
        ...state,
        estimatedFinancialReturn: action.payload.estimatedFinancialReturn,
        costs: action.payload.costs.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              description: item.description,
              estimatedValue: formatReal(item.estimatedValue),
            }
          }
          return item;
        })
      }

    case portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }

    case portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_FAILURE:
      return {
        ...state,
        loading: false,
        success: true,
      }

    case portfolioConstants.CLEAR_FINANCIAL_PLANNING_FLAGS: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case portfolioConstants.PORTFOLIO_UPDATE_FINANCIAL_PLANNING_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case portfolioConstants.PORTFOLIO_UPDATE_FINANCIAL_PLANNING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }

    case portfolioConstants.PORTFOLIO_UPDATE_FINANCIAL_PLANNING_FAILURE:
      return {
        ...state,
        loading: false,
        success: true,
      }

    case portfolioConstants.PORTFOLIO_CHANGE_FIELD_EXPENSE:
      return {
        ...state,
        costs: state.costs.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              [action.payload.name]: action.payload.value
            }
          }
          return item
        })
      }

    case portfolioConstants.PORTFOLIO_ADD_NEW_EXPENSE:
      return {
        ...state,
        costs: [
          ...state.costs,
          initialStateCosts,
        ]
      }

    case portfolioConstants.PORTFOLIO_REMOVE_EXPENSE:
      return {
        ...state,
        costs: state.costs.filter((item, index) => index !== action.payload)
      }

    case portfolioConstants.PORTFOLIO_SET_ESTIMATED_VALUE_RETURN:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }

    case portfolioConstants.CLEAR_PORTFOLIO_DIALOG_FINANCIAL_PLANNING:
      return initialState
  
    default: 
      return state;
      
  }
}