import { tutorialsConstants } from "../constants";
import { uploadsService } from "../services";
import { alertActions } from "./alert.actions";
import { tutorialsActions } from "./tutorials.actions";

export const uploadsAcitons = {
  setVideo,
  postVideo,
  postYTVideo,
  setFiles,
  postTutorialFile,
  setFileTitle,
  deleteFile,
}

function deleteFile(id) {
  return dispatch => {
    dispatch({ type: tutorialsConstants.DELETE_TUTORIAL_FILE, payload: { id } });
  }
}

function postTutorialFile(file, title, message="") {
  return dispatch => {
    dispatch({ type: "REQUEST_POST_TUTORIAL" })
    uploadsService.postTutorialFile(file).then(({ data }) => {
      uploadsService.postTutorial(title, data).then(() => {
        dispatch({ type: "SUCCESS_POST_TUTORIAL" })
        dispatch(tutorialsActions.getTutorials())
        dispatch(alertActions.success(message))
      }).catch(err => console.log(err))
    }).catch(err => {
      dispatch({ type: "FAILURE_POST_TUTORIAL" });
      dispatch(alertActions.error("Tente novamente"))
      console.error(err)
    })
  }
}

function setFileTitle(title) {
  return dispatch => dispatch({ type: "SET_FILE_NAME", payload: { title } });
}

function setFiles(files) {
  return dispatch => {
    dispatch({ type: 'SET_UPLOAD_FILES', payload: { files } });
  }
};

function postYTVideo(link, lastVideoId, message="") {
  return (dispatch) => {
    if (!!lastVideoId.length) {
      dispatch({ type: 'REQUEST_DELETE_LAST_VIDEO' });
      uploadsService.deleteVideo(lastVideoId).then(() => {
        dispatch({ type: 'REQUEST_POST_YT_VIDEO' });
        uploadsService.postYTVideo(link).then(() => {
        dispatch(alertActions.success(message));
        dispatch({ type: 'SUCCESS_POST_YT_VIDEO' })}).catch(() => dispatch({ type: 'FAILURE_POST_YT_VIDEO' }));
      }).catch(err => {
        console.error(err);
        dispatch({ type: 'FAILURE_DELETE_LAST_VIDEO' });
      })
    } else {
      dispatch({ type: 'REQUEST_POST_YT_VIDEO' });
      uploadsService.postYTVideo(link).then(() => dispatch({ type: 'SUCCESS_POST_YT_VIDEO' })).catch(() => dispatch({ type: 'FAILURE_POST_YT_VIDEO' }));
    }
  };
}

function postVideo(video, lastVideoId, message="") {
  return (dispatch) => {
    if (!!lastVideoId.length) {
      dispatch({ type: 'REQUEST_DELETE_LAST_VIDEO' });
      uploadsService.deleteVideo(lastVideoId).then(() => {
        dispatch({ type: 'REQUEST_POST_VIDEO' });
        uploadsService.postVideo(video).then(() => {
          dispatch(alertActions.success(message));
          dispatch({ type: 'SUCCESS_POST_VIDEO' });
        }).catch(() => dispatch({ type: 'FAILURE_POST_VIDEO' }));
      }).catch(err => {
        console.error(err);
        dispatch({ type: 'FAILURE_DELETE_LAST_VIDEO' });
      })
    } else {
      dispatch({ type: 'REQUEST_POST_VIDEO' });
      uploadsService.postVideo(video).then(() => dispatch({ type: 'SUCCESS_POST_VIDEO' })).catch(() => dispatch({ type: 'FAILURE_POST_VIDEO' }));
    }
  };
}

function setVideo(video) {
  return (dispatch) => {
    const payload = {
      video,
    };

    dispatch({ type: 'UPLOADS_SET_VIDEO', payload });
  };
}
