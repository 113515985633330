import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { articleActions } from '../../actions';
import ImagineSlider from '../Shared/ImagineSlider';
import { ArticleCard } from './ArticleCard';

function HighLightArticleList() {
  const dispatch = useDispatch();
  const { articles, paging: { page, sort, searchText } } = useSelector(state => state.articlesReducer);

  useEffect(() => {
    dispatch(articleActions.getArticles(page, sort, 100, searchText));
  }, []);

  const articlesToRender = useMemo(() => {
    return articles.filter((({ status, highlight }) => status && highlight))
  }, [articles])

  return (
   <ImagineSlider settings={{
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: articlesToRender.length >= 3 ? 3 : articlesToRender.length,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 7000,
  }}>
    {articlesToRender.map((item, index) => (<ArticleCard  key={index} title={item.title} image={item.thumbnail.url} description={item.description} link={`/article/detail/${item._id}`}/>))}
  </ImagineSlider>
  );
}

export { HighLightArticleList };
