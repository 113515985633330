import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Button,
  IconButton,
  Dialog,
  Typography,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { feedbackActions } from '../../actions';
import { DiscartFeedback } from './DiscartFeedback';
import { DialogTitle, DialogContent, DialogActions } from './feedbackStyles';
import illustrationFeedback from '../../assets/imgs/illustration-feedback.svg';

export function DialogFeedback({ open, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { feedbackText, discartFeedback } = useSelector((state) => state.feedback);

  function handleDiscartDialogFeedback() {
    dispatch(feedbackActions.discartFeedback(!discartFeedback));
  }

  function handleSetFeedbackText(e) {
    dispatch(feedbackActions.setFeedback(e.target.value));
  }

  function handleSubmitFeedback() {
    dispatch(feedbackActions.sendFeedback({ feedbackText }));
    dispatch(feedbackActions.clearFeedback());
    onClose();
  }
  
  return (
    <>
      <DiscartFeedback
        discartFeedback={discartFeedback}
        closeFeedback={handleDiscartDialogFeedback}
      />
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle disableTypography>
          <Typography variant="h3">
            {t('enviar_feedback')}
          </Typography>
          <IconButton onClick={handleDiscartDialogFeedback}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <img src={illustrationFeedback} alt="illustration Feedback" />
          <Typography variant="h3">
            {t('gostaria_de_nos_ajudar_a_melhorar_o_imagine')}
          </Typography>
          <Typography variant="body1">
            {t('compartilhe_conosco_a_sua_experiencia_com_a_plataforma')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                id="feedbackText"
                name="feedbackText"
                label="feedback"
                variant="filled"
                minRows={8}
                onBlur={handleSetFeedbackText}
                defaultValue={feedbackText}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={handleSubmitFeedback}
              >
                {t('enviar')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
