import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom"

import { Box, Grid, TextField, Button, makeStyles, Divider, useTheme, Typography } from '@material-ui/core';
import { Autocomplete, createFilterOptions  } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { ReactComponent as PushPinIcon } from '../../assets/imgs/push_pin.svg';
import { ReactComponent as FactCheckIcon} from '../../assets/imgs/fact_check.svg';
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from '@material-ui/icons/Save';

import { DialogPortifolio } from './DialogPortifolio';
import { DialogPortfolioDelete } from './DialogPortfolioDelete';
import { DialogTitleWithIcon } from "./DialogTitleWithIcon";
import { TextArea } from "../../components/Shared/TextArea";
import { portfolioDialogTaskActions } from "./../../actions/portfolio/portfolio-dialog-task.actions";
import { SubtaskRow } from "./Dialogs/SubtaskRow";

const filterOptions = createFilterOptions({
  limit: 200,
});

const useStyles = makeStyles((theme) => ({
  description: {
    position: 'relative',
  },
  characters: {
    position: 'absolute',
    right: 18,
    fontSize: 12,
    padding: theme.spacing(1, 0),
  },
  btnFloat: {
    float: 'right',
  },
  deleteIcon: {
    cursor: "pointer",
    color: theme.palette.grey.darkest
  }
}));

export const DialogCreateTask = ({ open, onClose, task=false }) => {
  const theme = useTheme();
  const { id: projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDialogDeleteTask, setOpenDialogDeleteTask] = useState(false);
  const [subtasksState, setSubtasksState] = useState([]);
  const { taskTabChosed, tasks, teams } = useSelector(state => state.portfolioDetails);
  const { success, loading, name, description, responsible, conclusionDate, subTasks  } = useSelector(state => state.portfolioDialogTask);
  const { projectTasksStatus } = useSelector(state => state.domains);

  useEffect(() => {
    if (task) {
      dispatch(portfolioDialogTaskActions.loadCreateTaskDialog(task))
    }

    return () => dispatch(portfolioDialogTaskActions.clearDialogTask());
  }, []);

  //forcando uma re-renderizacao do componenete de subtasks rows, porque estava mostrando os valores errados
  useEffect(() => {
    setSubtasksState(subTasks);
  }, [subTasks])

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success])

  function handleBlurTaskName(e) {
    dispatch(portfolioDialogTaskActions.changeFieldTask("name", e.target.value))
  }

  function handleBlurTaskDescription(e) {
    dispatch(portfolioDialogTaskActions.changeFieldTask("description", e.target.value))
  }

  function handleChangeResponsible(data) {
    dispatch(portfolioDialogTaskActions.changeFieldTask("responsible", data.option?.value || ""));
  }

  function handleChangeConclusion(date) {
    if (date instanceof Date && isFinite(date)) {
      dispatch(portfolioDialogTaskActions.changeFieldTask("conclusionDate", date))
    }
  }

  function handleAddNewSubtask() {
    dispatch(portfolioDialogTaskActions.addSubtask())
  }

  function handleCloseDialog() {
    onClose();
    dispatch(portfolioDialogTaskActions.clearDialogTask());
  }

  function handleOpenDialogDeleteTask() {
    setOpenDialogDeleteTask(true);
  }

  function handleCloseDialogDeleteTask() {
    setOpenDialogDeleteTask(false);
  }

  function handleDeleteTask() {
    const taskPayload = {
      page: 1,
      limit: 10,
      taskStatusCode: taskTabChosed,
      projectId
    }

    dispatch(portfolioDialogTaskActions.deleteTask(task._id, t('tarefa_excluida'), taskPayload, projectTasksStatus));
  }

  function handleSubmit() {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const data = {
      name: name,
      description: description,
      responsible: responsible,
      startDate: today.toISOString(),
      conclusionDate: conclusionDate,
      projectId: projectId,
      subTasks: subTasks.map(item => {
        if ( item._id) {
          return {
            _id: item._id || null,
            description: item.description,
            responsible: item.responsible.value,
            startDate: today.toISOString(),
            conclusionDate: item.conclusionDate
          }
        } 

        return {
          description: item.description,
          responsible: item.responsible.value,
          startDate: today.toISOString(),
          conclusionDate: item.conclusionDate
        }
      })
    }

    const taskPayload = {
      page: tasks.items?.paging?.page,
      limit: tasks.items?.paging?.limit,
      taskStatusCode: taskTabChosed,
      projectId
    }

    if (task) {
      dispatch(portfolioDialogTaskActions.updateTask(task._id, data, t('tarefa_editada'), taskPayload, projectTasksStatus));
     /*  if (task.subTasks?.length > 0) {
        dispatch(portfolioDialogTaskActions.updateSubTask(task.subTasks.map(item => item._id), data, taskPayload, projectTasksStatus, false));
      } */
    } else {
      dispatch(portfolioDialogTaskActions.postCreateTask(data, t('tarefa_criada'), taskPayload, projectTasksStatus));
    }
  }

  return (
    <DialogPortifolio 
      open={open}
      onClose={handleCloseDialog}
      maxWidth="md"
      title={task ? t('editar_tarefa') : t('nova_tarefa')}
      content={
        <>
          <Grid item xs={12}>
            <DialogTitleWithIcon 
              icon={<PushPinIcon style={{ fill: theme.palette.primary.main, transform: 'scale(0.75)' }} />} 
              text={t("tarefas")} 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='filled'
              label={t('nome_da_tarefa')}
              onBlur={handleBlurTaskName}
              defaultValue={name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              limitChars={256}
              onBlur={handleBlurTaskDescription}
              label={t("descricao_da_tarefa")}
              value={description}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete 
              filterOptions={filterOptions}
              loadingText={t('carregando')}
              noOptionsText={t('sem_opcoes')}
              id="checkboxes-responsible"
              options={teams.details.map(item => ({ name: item.user.name, value: item.user._id}))}
              loading={teams.details.length === 0}
              defaultValue={responsible}
              onChange={(e, items, options, details) => handleChangeResponsible(details)}
              getOptionLabel={(input) => input.name || ""}
              renderInput={(params) => <TextField {...params} label={t('responsavel')} variant="filled" />}
              renderTags={(option, state) => (<span>{`${option[0].name}`}</span>)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <KeyboardDatePicker
              variant="inline"
              inputVariant="filled"
              format="dd/MM/yyyy"
              fullWidth
              label={t("data_de_conclusao")}
              KeyboardButtonProps={{ 'aria-label': 'change date'}}
              name="concludedDate"
              onChange={handleChangeConclusion}
              value={conclusionDate}
              autoOk
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <DialogTitleWithIcon
              icon={<FactCheckIcon style={{ fill: theme.palette.primary.main, transform: 'scale(0.75)' }} />}
              text={t("subtarefa")}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {subtasksState?.map((item, index) => (
                  <SubtaskRow item={item} index={index} key={index}/>
                ))}
              </Grid>
           
              <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AddCircleRoundedIcon color="primary" />}
                  onClick={handleAddNewSubtask}
                  disabled={loading}
                >
                  {t("adicionar_subtarefa")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      action={
        <>
          {task ? (
            <Grid container>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  disabled={loading}
                  onClick={handleOpenDialogDeleteTask}
                >
                  {t('excluir_tarefa')}
                </Button>
                <DialogPortfolioDelete
                  title={t('excluir_tarefa')}
                  open={openDialogDeleteTask}
                  onClose={handleCloseDialogDeleteTask}
                  subTitle={<>{t('deseja_excluir_a_tarefa')}{' '}{task.name}{' '}{t('do_projeto')}?</>}
                  description={t('as_informacoes_sobre_a_tarefa_e_subtarefas_vinculadas_serao_excluidas_permanentemente')}
                  goBack={handleCloseDialogDeleteTask}
                  deleteItem={handleDeleteTask}
                  textDeleteItem={t('excluir_tarefa')}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnFloat}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {t('salvar_tarefa')}
                </Button>
              </Grid>
            </Grid>
          ): (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnFloat}
              onClick={handleSubmit}
              disabled={loading}
            >
              {t('criar_tarefa')}
            </Button>
          )}
        </>
      }
    />
  )
}
