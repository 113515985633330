export const ideaCreateDialogsConstans = {
  OPEN_DIALOG_TITLE_DESCRIPTION: 'OPEN_DIALOG_TITLE_DESCRIPTION',
  CLOSE_DIALOG_TITLE_DESCRIPTION: 'CLOSE_DIALOG_TITLE_DESCRIPTION',

  OPEN_DIALOG_AREA: 'OPEN_DIALOG_AREA',
  CLOSE_DIALOG_AREA: 'CLOSE_DIALOG_AREA',

  OPEN_DIALOG_CHALLENGE: 'OPEN_DIALOG_CHALLENGE',
  CLOSE_DIALOG_CHALLENGE: 'CLOSE_DIALOG_CHALLENGE',

  OPEN_DIALOG_SEND_IDEA: 'OPEN_DIALOG_SEND_IDEA',
  CLOSE_DIALOG_SEND_IDEA: 'CLOSE_DIALOG_SEND_IDEA',

  OPEN_DIALOG_PHOTO: 'OPEN_DIALOG_PHOTO',
  CLOSE_DIALOG_PHOTO: 'CLOSE_DIALOG_PHOTO',

  OPEN_DIALOG_VIDEO: 'OPEN_DIALOG_VIDEO',
  CLOSE_DIALOG_VIDEO: 'CLOSE_DIALOG_VIDEO',

  OPEN_DIALOG_FILE: 'OPEN_DIALOG_FILE',
  CLOSE_DIALOG_FILE: 'CLOSE_DIALOG_FILE',

  OPEN_DIALOG_LINKS: 'OPEN_DIALOG_LINKS',
  CLOSE_DIALOG_LINKS: 'CLOSE_DIALOG_LINKS',

  OPEN_DIALOG_CANVAS: 'OPEN_DIALOG_CANVAS',
  CLOSE_DIALOG_CANVAS: 'CLOSE_DIALOG_CANVAS',

  OPEN_DIALOG_VALUE_CANVAS: 'OPEN_DIALOG_VALUE_CANVAS',
  CLOSE_DIALOG_VALUE_CANVAS: 'CLOSE_DIALOG_VALUE_CANVAS',

  OPEN_DIALOG_CLOSE: 'OPEN_DIALOG_CLOSE',
  CLOSE_DIALOG_CLOSE: 'CLOSE_DIALOG_CLOSE',

  CLOSE_ALL: 'CLOSE_ALL',

  OPEN_DIALOG_MULTI_AREA: "OPEN_DIALOG_MULTI_AREA",
  CLOSE_DIALOG_MULTI_AREA: "CLOSE_DIALOG_MULTI_AREA",
  OPEN_DIALOG_CLASSIFICATION: 'OPEN_DIALOG_CLASSIFICATION',
  CLOSE_DIALOG_CLASSIFICATION: 'CLOSE_DIALOG_CLASSIFICATION',
  PREVIOUS_DIALOG_CREATE_IDEA: "PREVIOUS_DIALOG_CREATE_IDEA"
};
