import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, makeStyles, useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { helpCenterService } from '../../services/help-center.service'
import { useTranslation } from 'react-i18next';
import { SkeletonTable } from '../Skeletons';
import Typography from '@material-ui/core/Typography';
import { TableMenu } from './TableMenu';
import { Delete, Edit } from '@material-ui/icons';
import { useMemo } from 'react';
import { tutorialsActions } from '../../actions/tutorials.actions';
import { useDispatch, useSelector } from 'react-redux';
import { uploadsAcitons } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 124,
    marginBottom: 24,
    flexWrap: 'wrap'
  },
  item: {
    background: "theme.palette.neutrals.high.main 0% 0% no-repeat padding-box",
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  download: {
    color: theme.palette.primary.main,
    textTransform: "uppercase"
  },
  link: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    width: 144,
    height: 40,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  iconDownload: {
    marginRight: 8,
  },
}));

function TutoriaisTable({ isTutorialEdit = false }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { tutorialList, loading } = useSelector(state => state.tutorialsReducer)


  useEffect(() => {
    dispatch(tutorialsActions.getTutorials())
  }, [dispatch]);

  function handleDelete(id) {
    dispatch(tutorialsActions.deleteTutorial(id, t('deletado_com_sucesso')))
  }

  return (
    <div className={classes.root} >
      {!loading ? (
        <>
          <div className={classes.header}>
            <strong>{t('titulo')}</strong>
            <strong>{isTutorialEdit ? "" : t('link')}</strong>
          </div>
          {tutorialList.map((item, index) => (
            <div className={classes.item} key={index}>
              <Typography variant="body2">{item.title}</Typography>
              {isTutorialEdit ? <TableMenu>
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      dispatch(uploadsAcitons.setFiles([item]))
                      dispatch(tutorialsActions.handleDialog({ open: true, isEdit: true, tutorialId: item._id }))
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography>
                        {t('editar')}
                      </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem onClick={() => handleDelete(item._id)} button>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography>
                        {t('deletar')}
                      </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </TableMenu> :
                <a className={classes.link} href={item.documentUrl} rel="noopener noreferrer" target='_blank'>
                  <GetAppIcon
                    htmlColor={theme.palette.primary.main}
                    className={classes.iconDownload}
                  />
                  <Typography variant="subtitle2" align="left" className={classes.download}>
                    DOWNLOAD
                  </Typography>
                </a>
              }
            </div>
          ))}
        </>
      ) : <SkeletonTable cols={2} />}
    </div>
  )
}

export { TutoriaisTable }