import { ideaGeneralEvaluationConstants } from '../constants';

const initialState = {
  evaluations: [],
  indicators: {},
  evaluationDetails: {},
  followUp: {
    comments: [],
  },
  newEvaluations: {},
  comment: '',
  loading: false,
}

export function ideaGeneralEvaluation(state = initialState, action) {
  switch (action.type) {
    case ideaGeneralEvaluationConstants.SET_EVALUATION_RATES:
      state.newEvaluations[action.payload.personalizedEvaluation] = action.payload.newValue;
      return {
        ...state,
      }

    case ideaGeneralEvaluationConstants.SET_EVALUATION_COMMENT:
      return {
        ...state,
        comment: action.payload.value
      }

    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_REQUEST:
    case ideaGeneralEvaluationConstants.GET_INDICATORS_BY_IDEA_REQUEST:
    case ideaGeneralEvaluationConstants.GET_COMMENTS_BY_IDEA_REQUEST:
    case ideaGeneralEvaluationConstants.GET_EVALUATION_BY_IDEAID_AND_USER_REQUEST:
    case ideaGeneralEvaluationConstants.CREATE_RATES_AND_COMMENT_IN_IDEA_REQUEST:
      return {
        ...state,
        loading: true
      }

    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_SUCCESS:
      action.payload.forEach(({_id}) => state.newEvaluations[_id] = 1);
      return {
        ...state,
        evaluations: action.payload,
        loading: false
      }

    case ideaGeneralEvaluationConstants.GET_INDICATORS_BY_IDEA_SUCCESS:
      return {
        ...state,
        indicators: action.payload,
        loading: false
      }

    case ideaGeneralEvaluationConstants.GET_COMMENTS_BY_IDEA_SUCCESS:
      return {
        ...state,
        followUp: {
          ...state.followUp,
          comments: action.payload.comments,
        },
        loading: false
      }

    case ideaGeneralEvaluationConstants.GET_EVALUATION_BY_IDEAID_AND_USER_SUCCESS:
      return {
        ...state,
        evaluationDetails: action.payload,
        loading: false
      }

    case ideaGeneralEvaluationConstants.CREATE_RATES_AND_COMMENT_IN_IDEA_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_FAILURE:
    case ideaGeneralEvaluationConstants.GET_INDICATORS_BY_IDEA_FAILURE:
    case ideaGeneralEvaluationConstants.GET_COMMENTS_BY_IDEA_FAILURE:
    case ideaGeneralEvaluationConstants.GET_EVALUATION_BY_IDEAID_AND_USER_FAILURE:
    case ideaGeneralEvaluationConstants.CREATE_RATES_AND_COMMENT_IN_IDEA_FAILURE:
      return {
        ...state,
        loading: false
      }

    case ideaGeneralEvaluationConstants.CLEAR_GENERAL_EVALUATIONS_COMMENT_FORM:
      return initialState;

    default:
      return state;
  }
}
