import { enUS, ptBR } from '@material-ui/core/locale';

export const selectedLanguageTheme = (language) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'pt_BR':
      return ptBR;
    default:
      return ptBR;
  }
};
