import { multiAreaCascadeConstants } from "./../constants"

const initialState = { 
  loading: false,
  fields: [],
  locals: [],
}

function loadBusinessUnitOptions(locals = [], selectedBusinessUnit = false) {
  const businessUnitOptions = [...new Set(locals.map((item) => { 
    return {
      label: item.businessUnit.name,
      value: item.businessUnit._id
    }
  }).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

  if (selectedBusinessUnit) {
    return {
      options: businessUnitOptions,
      selectedOption: businessUnitOptions.filter(item => item.value === selectedBusinessUnit)[0]
    }
  }

  if (businessUnitOptions.length === 1) {
    return {
      options: businessUnitOptions,
      selectedOption: businessUnitOptions[0]
    }
  }
  
  return {
    options: businessUnitOptions,
    selectedOption: { label: "", value: "" }
  };
}

function loadLocalOptions(locals = [], selectedBusinessUnit, selectedLocal = false) {
  if (selectedBusinessUnit) {
    const localOptions = [...new Set(locals.filter(item => item.businessUnit._id === selectedBusinessUnit).map((item) => { 
      return {
        label: item.local.name,
        value: item.local._id
      }
    }).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
  
    if (selectedLocal) {
      return {
        options: localOptions,
        selectedOption: localOptions.filter(item => item.value === selectedLocal)[0]
      }
    }
  
    if (localOptions.length === 1) {
      return {
        options: localOptions,
        selectedOption: localOptions[0]
      }
    }
  
    return {
      options: localOptions,
      selectedOption: { label: "", value: "" }
    }
  }

  return defaultOptions()
}

function loadOccupationAreaOptions(locals = [], selectedBusinessUnit, selectedLocal, selectedOccupationArea = false) {
  if (selectedBusinessUnit && selectedLocal) {
    const occupationAreaOptions = [...new Set(locals
      .filter(item => (item.businessUnit._id === selectedBusinessUnit) && (item.local._id === selectedLocal))
      .map((item) => { 
        return {
          label: item.occupationArea.name,
          value: item.occupationArea._id
        }
    }).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
  
    if (selectedOccupationArea) {
      return {
        options: occupationAreaOptions,
        selectedOption: occupationAreaOptions.filter(item => item.value === selectedOccupationArea)[0]
      }
    }
  
    if (occupationAreaOptions.length === 1) {
      return {
        options: occupationAreaOptions,
        selectedOption: occupationAreaOptions[0]
      }
    }
  
    return {
      options: occupationAreaOptions,
      selectedOption: { label: "", value: "" }
    }
  }

  return defaultOptions()
}

function loadSelectedBUL(locals = [], selectedBusinessUnit, selectedLocal, selectedOccupationArea) {
  if (selectedBusinessUnit && selectedLocal && selectedOccupationArea) {
    const selectedBusinessUnitLocals = [...new Set(locals
      .filter(item => 
        item.businessUnit._id === selectedBusinessUnit && 
        item.local._id === selectedLocal && 
        item.occupationArea._id === selectedOccupationArea)
      .map((item) => item).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
  
    if (selectedBusinessUnitLocals.length > 0) {
      return selectedBusinessUnitLocals[0]
    }
  
    console.log("nao foi encontrado o BUL representante")
    return false
  }

  return false  
}

function defaultOptions() {
  return { 
    options: [],
    selectedOption:  { label: "", value: "" }
  }
}

export const multiAreaCascade = (state = initialState, action) => {
  switch(action.type) {
    case multiAreaCascadeConstants.MULTI_AREA_CASCADE_INITIAL: {
      const locals = action.payload.locals;
      const businessUnitOptions = loadBusinessUnitOptions(locals)
      const localOptions = loadLocalOptions(locals, businessUnitOptions.selectedOption.value)
      const occupationAreaOptions = loadOccupationAreaOptions(locals, businessUnitOptions.selectedOption.value, localOptions.selectedOption.value)
      const selectedBul = loadSelectedBUL(locals, businessUnitOptions.selectedOption.value, localOptions.selectedOption.value, occupationAreaOptions.selectedOption.value)
      return {
        ...state,
        fields: [{
          businessUnit: businessUnitOptions,
          local: localOptions,
          occupationArea: occupationAreaOptions,
          selectedBusinessUnit: selectedBul,
        }]
      }
    }

    case multiAreaCascadeConstants.LOAD_MULTI_AREA_CASCADE_FIELDS: {
      let businessUnitArray = [];

      return {
        ...state,
        fields: action.payload?.businessUnitLocals?.map(item => {
          businessUnitArray.push(item?._id)
          return {
            businessUnit: {
              selectedOption: { label: item.businessUnit?.name, value: item.businessUnit?._id },
              //isso aqui parece estranho mas so estou filtrando o array businessUnitArray tirando ele mesmo, 
              //porque se eu incluir o mesmo, nao vai aparecer no dropdown, mas quero filtrar os proximos, para nao aparecer o anterior
              options: [...new Set(action.payload.locals.filter(item => !businessUnitArray.filter(item => item !== item._id).includes(item._id)).map(item => ({
                label: item.businessUnit?.name,
                value: item.businessUnit?._id
              })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
            },
            local: {
              selectedOption: { label: item.local?.name, value: item.local?._id },
              options: [...new Set(action.payload.locals.filter(businessUnitLocal => businessUnitLocal.businessUnit?._id === item.businessUnit?._id).map(item => ({
                label: item.local?.name,
                value: item.local?._id
              })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
            },
            occupationArea: {
              selectedOption: { label: item.occupationArea.name, value: item.occupationArea._id },
              options: [...new Set(action.payload.locals.filter(
                businessUnitLocal => businessUnitLocal.businessUnit?._id === item.businessUnit?._id && businessUnitLocal.local?._id === item.local?._id).map(item => ({
                label: item.occupationArea?.name,
                value: item.occupationArea?._id
              })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
            },
            selectedBusinessUnit: item,
          }})
      }
    }

    case multiAreaCascadeConstants.ADD_MULTI_AREA_NEW_FIELD: {
      const locals = action.payload.locals
      const allSelectedBUL = state.fields.map(field => field.selectedBusinessUnit?._id);

      function localsWithoutSelectedBUL() {
        if (locals.length === 1) {
          return locals
        }
        return locals.filter(item => !allSelectedBUL.includes(item._id));
      }

      const localsFilteredArray = localsWithoutSelectedBUL()

      const businessUnitOptions = loadBusinessUnitOptions(localsFilteredArray)
      const localOptions = loadLocalOptions(locals, businessUnitOptions.selectedOption.value)
      const occupationAreaOptions = loadOccupationAreaOptions(locals, businessUnitOptions.selectedOption.value, localOptions.selectedOption.value)
      const selectedBul = loadSelectedBUL(locals, businessUnitOptions.selectedOption.value, localOptions.selectedOption.value, occupationAreaOptions.selectedOption.value)
      return {
        ...state,
        fields: [
          ...state.fields,
          {
            businessUnit: businessUnitOptions,
            local: localOptions,
            occupationArea: occupationAreaOptions,
            selectedBusinessUnit: selectedBul,
          }
        ]
      }
    }

    case multiAreaCascadeConstants.REMOVE_MULTI_AREA_FIELD: {
      return {
        ...state,
        fields: state.fields.filter((field, index) => index !== parseInt(action.payload))
      }
    }

    case multiAreaCascadeConstants.CHANGE_MULTI_AREA_FIELD: {
      const locals = action.payload.locals
     
      switch(action.payload.unit) {
        case "businessUnit":
          return {
            ...state,
            fields: state.fields.map((item, index) => {
              if (index === action.payload.fieldIndex) {
                const businessUnitOptions = loadBusinessUnitOptions(locals, action.payload.value)
                const localOptions = loadLocalOptions(locals, action.payload.value)
                const occupationAreaOptions = loadOccupationAreaOptions(locals, action.payload.value, localOptions.selectedOption.value)
                const selectedBul = loadSelectedBUL(locals, action.payload.value, localOptions.selectedOption.value, occupationAreaOptions.selectedOption.value)

                return {
                  businessUnit: businessUnitOptions,
                  local: localOptions,
                  occupationArea: occupationAreaOptions,
                  selectedBusinessUnit: selectedBul,
                }
              }
              return item;
            })
          }
        case "local":
          return {
            ...state,
            fields: state.fields.map((item, index) => {
              if (index === action.payload.fieldIndex) {
                const localOptions = loadLocalOptions(locals, item.businessUnit.selectedOption.value, action.payload.value)
                const occupationAreaOptions = loadOccupationAreaOptions(locals, item.businessUnit.selectedOption.value, action.payload.value)
                const selectedBul = loadSelectedBUL(locals, item.businessUnit.selectedOption.value, action.payload.value, occupationAreaOptions.selectedOption.value)
                return {
                  ...item,
                  local: localOptions,
                  occupationArea: occupationAreaOptions,
                  selectedBusinessUnit: selectedBul,
                }
              }
              return item;
            })
          }

        case "occupationArea":
          return {
            ...state,
            fields: state.fields.map((item, index) => {
              if (index === action.payload.fieldIndex) {
                const occupationAreaOptions = loadOccupationAreaOptions(locals, item.businessUnit.selectedOption.value, item.local.selectedOption.value, action.payload.value)
                const selectedBul = loadSelectedBUL(locals, item.businessUnit.selectedOption.value, item.local.selectedOption.value, action.payload.value)
                return {
                  ...item,
                  occupationArea: occupationAreaOptions,
                  selectedBusinessUnit: selectedBul,
                }
              }
              return item;
            })
          }
        
        default: {
          return state;
        }
      }
    }

    case multiAreaCascadeConstants.CLEAR_ALL_MULTI_AREA: {
      return initialState;
    }

    default:
      return state;
  }
}