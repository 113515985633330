import { TextField } from '@material-ui/core';

// Adicionar props customizadas...

export function Input({ ...props }) {
  return (
    <TextField
      {...props}
      fullWidth
      margin="normal"
    />
  );
}