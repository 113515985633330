import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './alert.actions';
import config from '../config';
import { history } from '../helpers';

export const userActions = {
  getUser,
  uploadFile,
  updateUser,
  getUserDetailsRaking,
  deleteFile,
  passwordRecover,
  confirmPasswordRecover,
  setUserEmail,
};

function getUser(from) {
  return (dispatch) => {
    dispatch(request);
    userService.getUser().then(
      (user) => {
        dispatch(success(user));
        if (from === 'authentication') {
          if (user.isFullRegister) {
            history.push(`${config.DEFAULT_ROUTE_AFTER_LOGIN}` ?? '/');
          } else {
            history.push('register');
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }

  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error };
  }
}

function uploadFile(file, id) {
  return (dispatch) => {
    dispatch(request());
    userService.uploadPhoto(file, id).then(
      () => {
        dispatch(success());
        dispatch(getUser());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UPLOAD_USER_PHOTO_REQUEST };
  }

  function success() {
    return { type: userConstants.UPLOAD_USER_PHOTO_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.UPLOAD_USER_PHOTO_FAILURE, error };
  }
}

function deleteFile(user) {
  return (dispatch) => {
    dispatch(request());
    userService.deletePhoto(user).then(
      () => {
        dispatch(success());
        dispatch(getUser());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_USER_PHOTO_REQUEST };
  }

  function success() {
    return { type: userConstants.DELETE_USER_PHOTO_SUCCESS };
  }

  function failure(error) {
    return { type: userConstants.DELETE_USER_PHOTO_FAILURE, error };
  }
}

function updateUser(user, successMessage) {
  return (dispatch) => {
    dispatch({ type: userConstants.UPDATE_USER_REQUEST });
    userService.updateUser(user)
    .then(() => {
      dispatch({ type: userConstants.UPDATE_USER_SUCCESS });
      dispatch(alertActions.success(successMessage));
      dispatch(getUser());
    },
    (error) => {
      dispatch({ type: userConstants.UPDATE_USER_FAILURE, error });
    });
  }
}

function getUserDetailsRaking() {
  return (dispatch) => {
    userService.userDetails().then(
      (details) => {
        dispatch(success(details));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(details) {
    const payload = {
      details,
    };

    return { type: userConstants.GET_USER_RANKING_DETAILS_SUCCESS, payload };
  }

  function failure(error) {
    return { type: userConstants.GET_USER_RANKING_DETAILS_FAILURE, error };
  }
}
function passwordRecover(email, successMessage, errorMessage) {
  return (dispatch) => {
    userService.passwordRecover(email).then(
      () => {
        dispatch(success());
        dispatch(alertActions.success(successMessage));
        setTimeout(() => {
          history.push('/confirm-password-recover');
        }, 5000);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(errorMessage));
        history.push('/password-recover');
      }
    );
  };
  function success(email) {
    return { type: userConstants.RECOVER_PASSWORD_SUCCESS, email };
  }
  function failure(error) {
    return { type: userConstants.RECOVER_PASSWORD_FAILURE, error };
  }
}
function confirmPasswordRecover(code, password, confirmPassword) {
  return (dispatch) => {
    userService.confirmPasswordRecover(code, password, confirmPassword).then(
      () => {
        dispatch(success());
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function success(code, password, confirmPassword) {
    const payload = {
      code, 
      password, 
      confirmPassword,
    };
    return { type: userConstants.CONFIRM_PASSWORD_RECOVER_SUCCESS, payload };
  }
  function failure(error) {
    return { type: userConstants.CONFIRM_PASSWORD_RECOVER_FAILURE, error };
  }
}
function setUserEmail(email) {
  return dispatch => {
    dispatch({ 
      type: userConstants.SET_USER_EMAIL,
      payload: email
    });
  } 
}
