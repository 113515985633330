import React from 'react';
import { Box, Card, Grid, makeStyles, withStyles } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(3),
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  text: {
    transform: 'none',
  },
})) (MuiSkeleton);

export function SkeletonDetailsTask() {
  const classes = useStyles();
  const arrayCells = Array.from(Array(3));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ paddingTop: '3rem' }}>
        <Grid container spacing={3}>
          {arrayCells.map((item, index) => (
            <Grid item xs={4} key={index}>
              <Card elevation={0} className={classes.card}>
                <Skeleton variant="text" width={62} height={24} />
                <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="1.5rem">
                  <Skeleton variant="circle" width={43} height={43} />
                  <Skeleton variant="text" width={100} height={24} />
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        <Card elevation={0} className={classes.card} style={{ padding: 16 }}>
          <Grid container spacing={3}>
            {arrayCells.map((item, index) => (
              <Grid item xs={4} key={index}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Skeleton variant="text" width={100} height={24} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0} style={{ backgroundColor: 'inherit', padding: 16 }}>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <Box style={{ width: 20, height: 20 }} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width={'40%'} height={20} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width={'40%'} height={20} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width={'40%'} height={20} />
            </Grid>
            <Grid item xs={2}>
              <Skeleton variant="text" width={'40%'} height={20} />
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={3}>
          {arrayCells.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Card elevation={0} className={classes.card} style={{ padding: 16 }}>
                <Grid container spacing={3}>
                  <Grid item xs={1}>
                    <Skeleton variant="text" width={20} height={20} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton variant="text" width={'80%'} height={20} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton variant="text" width={'80%'} height={20} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton variant="text" width={'80%'} height={20} />
                  </Grid>
                  <Grid item xs={2}>
                    <Skeleton variant="text" width={'80%'} height={20} />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}