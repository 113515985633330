import React, { useEffect, useState } from 'react'
import { makeStyles, Container, Grid, Button, Box, Card } from '@material-ui/core';
import {
  BackNavAdmin,
  DialogImagineLight,
  ListClassificationCard
} from '../../components';



import { useWindowSize } from '../../hooks';
import config from '../../config';
import { useSelector, useDispatch } from "react-redux";
import { alertActions, personalizedClassificationActions } from '../../actions';
import AddCircle from '@material-ui/icons/AddCircle';
import { history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { Delete } from '@material-ui/icons';
import Save from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filters: {
    width: '100%',
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    float: "right"
  },
  card: {
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
    position: 'fixed'
  },
}));

export const PersonalizedClassificationPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dialogConclusion, setDialogConclusion] = useState(false);

  const { personalizedClassification: {
    personalizedClassification,
    classificationsToDelete,
    loading
  } } = useSelector(state => state);

  function handleDialogConclusion() {
    setDialogConclusion(!dialogConclusion);
  };

  function handleDeleteClassification() {
    dispatch(personalizedClassificationActions.deleteAllPersonalizedClassification());
    setDialogConclusion(false);
    setTimeout(() => {
      dispatch(personalizedClassificationActions.getPersonalizedClassification());
    }, 1000);
  };

  function handleSaveClassifications() {
    const shouldSendClassifications = personalizedClassification.every(({ name, description }) => !!name.length && description.length);
    if (shouldSendClassifications) {
      const personalizedToPost = personalizedClassification.filter((item) => !item._id);
      const personalizedToPut = personalizedClassification.filter((item) => item.isEdit);
      !!personalizedToPost.length && dispatch(personalizedClassificationActions.postPersonalizedClassification(personalizedToPost, t('a_classificacao_personalizada_foi_salva')));

      !!personalizedToPut.length && dispatch(personalizedClassificationActions.putPersonalizedClassification(personalizedToPut));

      !!classificationsToDelete.length && dispatch(personalizedClassificationActions.deleteMany(classificationsToDelete));

      dispatch(personalizedClassificationActions.setLoading(true));
      setTimeout(() => dispatch(personalizedClassificationActions.clear()), 500);
    } else {
      dispatch(alertActions.error(t('por_favor_preencher_todos_os_campos')));
    }
  };

  return (
    <>
      <DialogImagineLight
        open={dialogConclusion}
        onClose={handleDialogConclusion}
        title={t('excluir_classificacao')}
        subtitle={t('deseja_excluir_classificacao')}
        description={t('as_informacoes_sobre_a_classificacao_serao_excluidas_permanentemente.')}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDialogConclusion}
        >
          {t('voltar')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          startIcon={<Delete />}
          style={{ marginRight: 10 }}
          onClick={handleDeleteClassification}
        >
          {t('excluir_classificacao')}
        </Button>
      </DialogImagineLight>
      <BackNavAdmin title={t('classificacao_das_ideias')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Box className={classes.card}>
              <ListClassificationCard />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.buttonContainer}>
              <Button
                color="primary"
                startIcon={<Save />}
                onClick={handleSaveClassifications}
                variant="contained"
                disabled={loading}
                style={{ marginBottom: 32 }}
              >
                {t('salvar_classificacao')}
              </Button>
              <Button
                color="primary"
                startIcon={<Delete />}
                disabled={loading}
                variant="outlined"
                onClick={handleDialogConclusion}
              >
                {t('excluir_classificacao')}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
