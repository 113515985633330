import React, { useState } from 'react'
import { 
  Grid, 
  Box, 
  Paper,
  ButtonBase
} from "@material-ui/core"
import { ReportCardDialog } from "./ReportCardDialog"
import { useStyles } from "./styles"

export const ReportCard = ({ reportItems, reportItem, index }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false)

  function handleClickModal() {
    setIsOpen(true)
  }

  function handleCloseDialog() {
    setIsOpen(false)
  }

  return (
    <Paper elevation={2} className={classes.cardItemInner}>
      <ReportCardDialog isOpen={isOpen} handleCloseDialog={handleCloseDialog}>
        { reportItems.dialogContent[index].map((item, key) => (
          <Box display="flex" flexDirection="column" className={classes.cardItem}>
            <b className={classes.cardItemTitle}>{item.name}</b>
            <p className={classes.cardItemDescription}>{item.value}</p>
          </Box>
        ))}
      </ReportCardDialog>
      <Grid container spacing={1}>
        { reportItem.map((item, key) => (
          <Grid item xs={12} key={key}>
            <Box display="flex" flexDirection="column" className={classes.cardItem}>
              <b className={classes.cardItemTitle}>{item.name}</b>
              <p className={classes.cardItemDescription}>{item.value}</p>
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <ButtonBase className={classes.seeMoreButton} onClick={() => handleClickModal() }>ver mais</ButtonBase>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
