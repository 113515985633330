import React from 'react';
import {
  Card,
  CardActions,
  Collapse,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { HeaderCollapse } from './HeaderCollapse';
import { collapseCss } from './collapseCss';
import { useDispatch, useSelector } from 'react-redux';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { executionActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { currency, locales } from '../../helpers/currency-to-locales-string';
const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.neutrals.low.dark,
  },
}));

function ReadCostStructureCollapse({ expanded, costs, costsLength }) {
  const classes = useStyles();
  const collapseClasses = collapseCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const theme = useTheme();

  function toggleExpand() {
    dispatch(executionActions.toggleCostStructureCollapse());
  }

  return (
    <Card>
      <CardActions className={collapseClasses.actions}>
        <HeaderCollapse
          title={t('estrutura_de_custos')}
          icon={<MoneyOffIcon color="primary" />}
          expanded={expanded}
          toggleExpand={toggleExpand}
        />
      </CardActions>
      <Collapse in={expanded} timeout="auto">
        <CardContent className={collapseClasses.content}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                {t('custos')}
              </Grid>
              <Grid item xs={3}>
                {t('valor_estimado')} ({t('dinheiro')})
              </Grid>
                <Grid item xs={3}>
                  {t('valor_real')} ({t('dinheiro')})
                </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {costs.map((cost, index) => (
              <Grid container spacing={2} key={cost.id}>
                <Grid
                  item
                  xs={1}
                  style={{
                    color: theme.palette.neutrals.low.dark,
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {index + 1}º
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name={`costs[${index}].costDescription`}
                    id={`costDescription-${index}`}
                    autoComplete="costDescription"
                    type="text"
                    variant="filled"
                    margin="normal"
                    placeholder={t('informe_o_nome_da_empresa')}
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        root: collapseClasses.labelRoot,
                      },
                    }}
                    defaultValue={cost.costDescription}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name={`costs[${index}].estimatedCost`}
                    id={`estimatedCost-${index}`}
                    autoComplete="estimatedCost"
                    type="number"
                    variant="filled"
                    margin="normal"
                    placeholder="0"
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        root: collapseClasses.labelRoot,
                      },
                    }}
                    defaultValue={cost.estimatedCost}
                    disabled
                  />
                </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name={`costs[${index}].cost`}
                      id={`cost-${index}`}
                      autoComplete="cost"
                      type="number"
                      variant="filled"
                      margin="normal"
                      placeholder="0"
                      fullWidth
                      InputLabelProps={{
                        classes: {
                          root: collapseClasses.labelRoot,
                        },
                      }}
                      defaultValue={cost.cost}
                      disabled
                    />
                  </Grid>
              </Grid>
            ))}

            <Grid container spacing={3}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <div className={classes.value}>{t('total')}</div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.value}>
                  {costs
                    .map((cost) => cost.estimatedCost)
                    .reduce((prev, next) => prev + next, 0)
                    .toLocaleString(locales(language), {
                      style: 'currency',
                      currency: currency(language),
                    })}
                </div>
              </Grid>
                <Grid item xs={3}>
                  <div className={classes.value}>
                    {costs
                      .map((cost) => cost.cost)
                      .reduce((prev, next) => prev + next, 0)
                      .toLocaleString(locales(language), {
                        style: 'currency',
                        currency: currency(language),
                      })}
                  </div>
                </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export { ReadCostStructureCollapse };
