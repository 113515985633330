import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 40,
    height: 45,
  },
  info: {
    width: '40%',
    height: 45,
  },
  label: {
    width: '40%',
    height: 25,
  },
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(2)
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  }
}))(MuiSkeleton);

export function SkeletonInfoDashboard() {
  const classes = useStyles();

  return(
    <Grid container spacing={2}>
      <Grid item xs={3} className={classes.flex}>
        <Card className={classes.card} elevation={0}>
          <Skeleton variant="text" className={classes.icon}/>
          <Skeleton variant="text" className={classes.info}/>
          <Skeleton variant="text" className={classes.label}/>
        </Card>
      </Grid>
      <Grid item xs={3} className={classes.flex}>
        <Card className={classes.card} elevation={0}>
          <Skeleton variant="text" className={classes.icon}/>
          <Skeleton variant="text" className={classes.info}/>
          <Skeleton variant="text" className={classes.label}/>
        </Card>
      </Grid>
      <Grid item xs={3} className={classes.flex}>
        <Card className={classes.card} elevation={0}>
          <Skeleton variant="text" className={classes.icon}/>
          <Skeleton variant="text" className={classes.info}/>
          <Skeleton variant="text" className={classes.label}/>
        </Card>
      </Grid>
      <Grid item xs={3} className={classes.flex}>
        <Card className={classes.card} elevation={0}>
          <Skeleton variant="text" className={classes.icon}/>
          <Skeleton variant="text" className={classes.info}/>
          <Skeleton variant="text" className={classes.label}/>
        </Card>
      </Grid>
      <Grid item xs={6} className={classes.flex}>
        <Card className={classes.card} elevation={0}>
          <Skeleton variant="text" className={classes.icon}/>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Skeleton variant="text" className={classes.info}/>
            <Skeleton variant="text" className={classes.label}/>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={6} className={classes.flex}>
        <Card className={classes.card} elevation={0}>
          <Skeleton variant="text" className={classes.icon}/>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Skeleton variant="text" className={classes.info}/>
            <Skeleton variant="text" className={classes.label}/>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
} 