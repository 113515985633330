import React from 'react';
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.neutrals.high.light
  },
  title: {
    padding: theme.spacing(4, 0),
    color: theme.palette.neutrals.low.main
  },
  subTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  description: {
    fontSize: 15,
    lineHeight: 1.8,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  link: {
    transition: '0.5s ease-in-out',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.neutrals.low.main
    }
  }
}));

export function PrivacyPolicyPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" className={classes.title}>
        {t('politicas_e_termos_de_privacidade')}
      </Typography>
      
      {/* <Typography variant="h6" className={classes.subTitle}>
        POLÍTICAS INTERNAS
      </Typography> */}

      <Typography variant="body1" className={classes.description}>
        
      <a 
            href="https://imagine-files.imagineinovacao.com.br/" 
            target="_blank" 
            className={classes.link}
          >
            Acessar Políticas de Privacidade Interna
          </a> <br />

      </Typography>
 
    </Container>
  );
}