import update from 'react-addons-update';
import { fluxOfIdeasConstants, ideaGeneralEvaluationConstants } from '../constants';

const initialState = {
  ideaDetailsFlux: {},
  ideasFlux: [],
  managerObservation: '',
  evaluationInFluxIdea: [],
  paging: {
    page: 1,
    sort: { created: -1 },
    limit: 10,
    query: '',
    total: 0
  },
  evidenceAttachment: [],
  images: [],
  video: [],
  files: [],
  attachmentDialog: false,
  imageDialog: false,
  videoDialog: false,
  documentDialog: false,
  loadingFlux: false,
  loadingUpdate: false,
  loadingEvidences: false,
  isFetching: false,
  evidenceSuccess: false
};

export function fluxIdeas(state = initialState, action) {
  switch (action.type) {
    case fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_REQUEST:
      return {
        ...state,
        loadingFlux: true,
      }
    case fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_SUCCESS:
      return {
        ...state,
        ideasFlux: action.payload.ideasFlux.data,
        paging: {
          page: parseInt(action.payload.ideasFlux.paging.page),
          query: action.payload.query,
          limit: action.payload.ideasFlux.paging.limit,
          sort: action.payload.ideasFlux.paging.sort,
          total: action.payload.ideasFlux.paging.total,
        },
        loadingFlux:  false
      }
    case fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_FAILURE:
      return {
        ...state,
        loadingFlux: false,
      }
    case fluxOfIdeasConstants.SET_MANAGER_OBSERVATION:
      return {
        ...state,
        managerObservation: action.payload.value
      }

    case fluxOfIdeasConstants.CLEAR_FLUX_OF_IDEAS:
      return {
        ...state,
        managerObservation: ''
      }

    case fluxOfIdeasConstants.SET_EVALUATION_RATES_IN_FLUX_IDEA:
      return {
        ...state,
        // evaluationInFluxIdea: state.evaluationInFluxIdea.map((item, index) => {
        //   if (index === action.payload.rate) {
        //     return {
        //       ...item,
        //       rate: action.payload.rate
        //     }
        //   }
        // }),
      }

    case fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_REQUEST:
      return {
        ...state,
        ideaDetailsFlux: {
          ...state.ideaDetailsFlux,
          timeline: action.payload
        },
        loadingFlux: true,
      }
    case fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_SUCCESS: 
      return {
        ...state,
        loadingFlux: false,
      }
    case fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_FAILURE:
      return {
        ...state,
        loadingFlux: false,
      }
    case fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_REQUEST:
    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_REQUEST:
      return {
        ...state,
        loadingFlux: true
      }

    case fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_SUCCESS:
      return {
        ...state,
        ideaDetailsFlux: action.payload.idea,
        loadingFlux: false
      }

    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_SUCCESS:
      return {
        ...state,
        evaluationInFluxIdea: action.payload.map(item => {
          return {
            personalizedEvaluation: item,
            rate: 1
          }
        }),
        loadingFlux: false
      }

    case fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_FAILURE:
    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_FAILURE:
      return {
        ...state,
        loadingFlux: false
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_REQUEST:
      return {
        ...state,
        loadingUpdate: true
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_SUCCESS:
      return {
        ...state,
        loadingUpdate: false
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_FAILURE:
      return {
        ...state,
        loadingUpdate: false
      }

    case fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_REQUEST:
      return {
        ...state,
        loadingFlux: true,
        isFetching: false
      }
    case fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_SUCCESS:
      return {
        ...state,
        loadingFlux: false,
        isFetching: true
      }
    case fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_FAILURE:
      return {
        ...state,
        loadingFlux: false,
        isFetching: false
      }

    case fluxOfIdeasConstants.IDEA_FLUX_OPEN_ATTACHMENT_DIALOG:
      return { 
        ...state,
        attachmentDialog: !state.attachmentDialog
      };

    case fluxOfIdeasConstants.IDEA_FLUX_SET_IMAGES_EVIDENCE:
      return update(state, {
        evidenceAttachment: { $push: action.payload.images },
        images: { $push: action.payload.images }
      });

    case fluxOfIdeasConstants.IDEA_FLUX_SET_VIDEO_EVIDENCE:
      return update(state, {
        evidenceAttachment: { $push: action.payload.video },
        video: { $push: action.payload.video }
      });

    case fluxOfIdeasConstants.IDEA_FLUX_SET_FILES_EVIDENCE:
      return update(state, {
        evidenceAttachment: { $push: action.payload.files },
        files: { $push: action.payload.files }
      });

    case fluxOfIdeasConstants.IDEA_FLUX_REMOVE_IMAGES_EVIDENCE:
      const images = state.images.filter((image) => image.id !== action.payload.id);
      const attachmentsI = state.evidenceAttachment.filter((file) => file.id !== action.payload.id);
      return update(state, {
        evidenceAttachment: { $set: attachmentsI },
        images: { $set: images },
      });

    case fluxOfIdeasConstants.IDEA_FLUX_REMOVE_VIDEO_EVIDENCE:
      const videos = state.video.filter((video) => video.id !== action.payload.id);
      const attachmentsV = state.evidenceAttachment.filter((file) => file.id !== action.payload.id);
      return update(state, {
        evidenceAttachment: { $set: attachmentsV },
        video: { $set: videos },
      });

    case fluxOfIdeasConstants.IDEA_FLUX_REMOVE_FILES_EVIDENCE:
      const files = state.files.filter((file) => file.id !== action.payload.id);
      const attachmentsF = state.evidenceAttachment.filter((file) => file.id !== action.payload.id);
      return update(state, {
        evidenceAttachment: { $set: attachmentsF },
        files: { $set: files },
      });

    case fluxOfIdeasConstants.IDEA_FLUX_LOAD_EVIDENCES:
      let imagesEvidences = [];
      let videosEvidences = [];
      let filesEvidences = [];

      if (action.payload.length > 0) {
        imagesEvidences = action.payload.filter(
          (attachment) => attachment.mimeType.container === 'images'
        );

        videosEvidences = action.payload.filter(
          (attachment) => attachment.mimeType.container === 'videos'
        );

        filesEvidences = action.payload.filter(
          (attachment) => attachment.mimeType.container === 'dynamic-files'
        );
      }

      return {
        ...state,
        files: filesEvidences,
        images: imagesEvidences,
        video: videosEvidences,
      }

    case fluxOfIdeasConstants.IDEA_FLUX_CLEAR_EVIDENCES:
      return {
        ...state,
        evidenceAttachment: []
      }

    case fluxOfIdeasConstants.IDEA_FLUX_UPLOAD_EVIDENCES_REQUEST:
      return {
        ...state,
        loadingEvidences: true,
        evidenceSuccess: false
      }

    case fluxOfIdeasConstants.IDEA_FLUX_UPLOAD_EVIDENCES_SUCCESS:
      return {
        ...state,
        loadingEvidences: false,
        evidenceSuccess: true
      }

    case fluxOfIdeasConstants.IDEA_FLUX_UPLOAD_EVIDENCES_FAILURE:
      return {
        ...state,
        loadingEvidences: false,
        evidenceSuccess: false
      }

    default: {
      return state;
    }
  }
}
