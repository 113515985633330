export const adminsConstants = {
  REQUEST_GET_ADMINS: "REQUEST_GET_ADMINS",
  SUCCESS_GET_ADMINS: "SUCCESS_GET_ADMINS",
  FAILURE_GET_ADMINS: "FAILURE_GET_ADMINS",
  REQUEST_PUT_ADMINS: "REQUEST_PUT_ADMINS",
  SUCCESS_PUT_ADMINS: "SUCCESS_PUT_ADMINS",
  SUCCESS_PUT_ADMIN: "SUCCESS_PUT_ADMINS",
  FAILURE_PUT_ADMINS: "FAILURE_PUT_ADMINS",
  HANDLE_DIALOG_ADMIN: "HANDLE_DIALOG_ADMIN",
  DELETE_ADMIN_FILE: "DELETE_ADMIN_FILE",
  SET_ADMIN_URL: "SET_ADMIN_URL",
  SUCCESS_POST_ADMIN: "SUCCESS_POST_ADMIN",
  HANDLE_EMAIL: "HANDLE_EMAIL",
  HANDLE_PHONE: "HANDLE_PHONE",
  HANDLE_ADMINID: "HANDLE_ADMINID",
  HANDLE_DIALOG_CONFIRMATION: "HANDLE_DIALOG_CONFIRMATION",
  HANDLE_ADMIN_NAME: "HANDLE_ADMIN_NAME",
  HANDLE_IMAGE_URL: "HANDLE_IMAGE_URL"
};