import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';

import { DialogResetPassword } from './DialogResetPassword';
import { userActions, userFunctionActions } from '../../../actions';
import { DialogTitle, DialogContent, DialogActions, CustomTextField } from './dialogStyles';

export function DialogForgotPassword({ open, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialogResetPassword, setOpenDialogResetPassword] = useState(false);
  const { email } = useSelector(state => state.user);

  function handleSetEmail(event) {
    dispatch(userActions.setUserEmail(event.target.value));
  }

  function handleOpenDialogResetPassword() {
    setOpenDialogResetPassword(!openDialogResetPassword);
  }

  function handleDirectEmail() {
    dispatch(userFunctionActions.sendRequestNewPassword(email, t('email_enviado'), t('email_invalido')));
    onClose();
    setOpenDialogResetPassword(!openDialogResetPassword);
  }

  return (<>
    <DialogResetPassword
      open={openDialogResetPassword}
      onClose={handleOpenDialogResetPassword}
    />

    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h3">{t('esqueceu_a_senha')}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('para_redefinir_a_senha_enviaremos_um_codigo_de_recuperacao_para_o_endereco_de_email_associado_a_sua_conta')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              fullWidth
              variant="filled"
              id="forgot-password"
              name="forgot-password"
              placeholder={t('email')}
              defaultValue={email}
              onBlur={handleSetEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleDirectEmail}
        >
          {t('enviar_email')}
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}