export const notificationConstants = {
  GET_NOTIFICATION_REQUEST: 'NOTIFICATION_GET_NOTIFICATION_REQUEST',
  GET_NOTIFICATION_SUCCESS: 'NOTIFICATION_GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FAILURE: 'NOTIFICATION_GET_NOTIFICATION_FAILURE',

  LOAD_MORE_NOTIFICATION_REQUEST: 'NOTIFICATION_LOAD_MORE_NOTIFICATION_REQUEST',
  LOAD_MORE_NOTIFICATION_SUCCESS: 'NOTIFICATION_LOAD_MORE_NOTIFICATION_SUCCESS',
  LOAD_MORE_NOTIFICATION_FAILURE: 'NOTIFICATION_LOAD_MORE_NOTIFICATION_FAILURE',

  UPDATE_VISUALIZED_NOTIFICATION_REQUEST:
    'UPDATE_VISUALIZED_NOTIFICATION_REQUEST',
  UPDATE_VISUALIZED_NOTIFICATION_SUCCESS:
    'UPDATE_VISUALIZED_NOTIFICATION_SUCCESS',
  UPDATE_VISUALIZED_NOTIFICATION_FAILURE:
    'UPDATE_VISUALIZED_NOTIFICATION_FAILURE',

  MARK_ALL_NOTIFICATION_READ_REQUEST: 'PUT_MARK_ALL_NOTIFICATION_READ_REQUEST',
  MARK_ALL_NOTIFICATION_READ_SUCCESS: 'PUT_MARK_ALL_NOTIFICATION_READ_SUCCESS',
  MARK_ALL_NOTIFICATION_READ_FAILURE: 'PUT_MARK_ALL_NOTIFICATION_READ_FAILURE',

  CHECK_ACTION_REQUEST: 'PUT_CHECK_ACTION_REQUEST',
  CHECK_ACTION_SUCCESS: 'PUT_CHECK_ACTION_SUCCESS',
  CHECK_ACTION_FAILURE: 'PUT_CHECK_ACTION_FAILURE',
};
