import React from 'react';

import {
  Box,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  makeStyles,
  withStyles,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  dialogContent: {
    padding: theme.spacing(3, 4),
  },
  dialogActions: {
    padding: theme.spacing(3, 4),
  },
}));

const DialogStyled = withStyles({
   paperWidthSm: {
    maxWidth: 700
   }
})(Dialog);

export function DialogConfirmDeleteAssociation({ open, onClose, onDelete }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <DialogStyled open={open} onClose={onClose} className={classes.dialog} >
      <Box className={classes.dialogTitle}>
        <Typography className={classes.title}>{t('excluir_infraestrutura')}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />

      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Typography className={classes.descriptionDelete}>
            {t('As informações de infraestrutura serão excluídas permanentemente e ela será desvinculada das outras subdivisões de infraestruturas.')}
          </Typography>
        </Grid>
      </DialogContent>
          
      <DialogActions className={classes.dialogActions}>
        <Grid container spacing={2}>
           <Grid item xs={6}>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            {t('voltar')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.btnFloat}
            startIcon={<Delete />}
            onClick={onDelete}
          >
            {t('excluir_infraestrutura')}
          </Button>
        </Grid>
        </Grid>
      </DialogActions>
    </DialogStyled>
  );
}