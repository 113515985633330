import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles ((theme) => ({
  loadingButton: {
    backgroundColor: theme.palette.neutrals.high.dark,
    '&:hover': {
      backgroundColor: theme.palette.neutrals.high.dark
    },
  },
  typography: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 8,
    color: theme.palette.neutrals.low.light,
  }
}));

function LoadingButton() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button className={classes.loadingButton}>
      <CircularProgress size={22} color="primary" />
      <Typography className={classes.typography}>
        {t('carregando')}
      </Typography>
    </Button>
  );
}

export { LoadingButton };