import { styled } from '@material-ui/core/styles';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';

export const Tab = styled(MuiTab)({
  '& .MuiTab-wrapper': {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  }
});

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  background: theme.palette.neutrals.high.main,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  borderRadius: '8px 8px 0px 0px'
}));