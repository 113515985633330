import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
  },
  cardHeader: {
    padding: theme.spacing(4, 4, 2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardContent: {
    padding: theme.spacing(2, 4, 3),
    alignItems: 'flex-end'
  },
  cardActions: {
    padding: theme.spacing(3, 4, 4),
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.neutrals.high.dark}`
  },
  sectionTitle: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    borderRadius: 8,
  },
  imgBox: {
    height: 200,
    borderRadius: 8
  },
  fileBox: {
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: 8,
    minWidth: '100%',
    maxHeight: 80,
    backgroundColor: theme.palette.neutrals.low.lightest,
    gap: 6
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));