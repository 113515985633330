import React, { useState, Fragment, useEffect } from 'react'
import { ReportLayout } from "../../components"
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  TableCell,
  Typography,
} from "@material-ui/core";
import TableChartIcon from '@material-ui/icons/TableChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { TablePanel, ReportCards } from "../../components"
import { GraphicPanel, PieChartImagine } from "../../components"
import { useSelector, useDispatch } from "react-redux";
import { useWindowSize } from '../../hooks';
import config from '../../config';
import moment from 'moment';
import { tableCss } from '../../components/Tables/TableCss';
import { useTranslation } from 'react-i18next';
import { reportActions } from "../../actions"
import { CustomTooltip } from '../../components/Shared';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  tabs: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: 'white',
  },
  itemTab: {
    fontsize: 14,
    fontWeight: 600,
    textTransform: "lowercase",
    marginLeft: theme.spacing(0.8)
  },
}))

export const ReportAccessColaboratorByMonth = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableClasses = tableCss();
  const [tabIndex, setTabIndex] = useState(0);

  const { report, chart, tab } = useSelector(state => state.report);
  const { t } = useTranslation();

  function handleView(id) {
    history.push(`/profile/${id}`);
  }

  useEffect(() => dispatch(reportActions.clearFilters()), []);

  const tableInfo = {
    columns: [
      { id: 'colaborador', label: t('colaborador') },
      { id: 'matricula', label: t('matrícula') },
      { id: 'email', label: t('email') },
      { id: 'unidade', label: t('unidade') },
      { id: 'local', label: t('local') },
      { id: 'area', label: t('area') },
      { id: 'perfil', label: t('perfil') },
      { id: 'engajamento', label: t('engajamento') },
      { id: 'comentarios', label: t('comentarios') },
      { id: 'ideias', label: t('ideias') },
    ],
    data: report.map(item => (
      <Fragment>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          <CustomTooltip title={item.user?.name} aria-label={item.user?.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item.user?._id)}>{item.user?.name}</Typography>
          </CustomTooltip>
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.enrolment}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.email}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.businessUnitLocal?.businessUnit.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.businessUnitLocal?.local.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.businessUnitLocal?.occupationArea.name}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.typeProfile.description}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.totalUps}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.user?.totalComments}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>{item.user?.totalIdeasCreated}</TableCell>
      </Fragment>
    )),
  }

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    }
  }, [dispatch])

  function handleChangeTab(index) {
    dispatch(reportActions.changeTab(index === 0 ? "table" : "graph"));
    setTabIndex(index)
  }

  return (
    <ReportLayout title={t('acessos_mensais')} tableInfo={tableInfo.columns}>
      <Tabs className={classes.tabs} value={tabIndex} onChange={(e, value) => handleChangeTab(value)} aria-label="Tabs de grafico e tabela" indicatorColor="primary" textColor="primary" variant="fullWidth">
        <Tab
          aria-controls="Panel tabela"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <TableChartIcon />
              <Typography className={classes.itemTab}>
                {t('tabela')}
              </Typography>
            </Box>
          )}
        />
        <Tab
          aria-controls="Panel grafico"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <DonutLargeIcon />
              <Typography className={classes.itemTab}>
                {t('grafico')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      {tab === "table" && (
        <Fragment>
          <TablePanel tableInfo={tableInfo} /> :
        </Fragment>
      )}

      {tab === "graph" && (
        <GraphicPanel>
          <PieChartImagine data={chart} />
        </GraphicPanel>
      )}
    </ReportLayout>
  )
}