import React from 'react';
import { makeStyles, FormControl, NativeSelect } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { timelineActions } from '../../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 14,
  },
  line: {
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: '0',
      right: '0',
      borderTop: `1px solid ${theme.palette.neutrals.high.medium}`,
      zIndex: '-1',
      width: 'calc(100% - 280px)',
    },
  },
  sortBy: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    fontWeight: 600,
    fontSize: 14,
    marginLeft: theme.spacing(1),
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}));

function FilterTimeline() {
  const classes = useStyles();
  const { sort } = useSelector((state) => state.timeline.paging);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleChange(e) {
    dispatch(timelineActions.sortTimeline(e.target.value));
  }

  function getDefaultValue(sort) {
    return Object.keys(sort)[0];
  }

  return (
    <div className={classes.container}>
      <div className={classes.line}></div>
      <div className={classes.sortBy}>
        <span>{t('classificar_por')}: </span>
        <FormControl className={classes.formControl}>
          <NativeSelect
            className={clsx(classes.underline, classes.select)}
            defaultValue={getDefaultValue(sort)}
            onChange={handleChange}
            inputProps={{
              name: 'age',
              id: 'age-native-simple',
            }}
          >
            <option value="timelineDate">{t('mais_recentes')}</option>
            <option value="upsLength">{t('mais_relevantes')}</option>
            <option value="favorites">{t('favoritos')}</option>
          </NativeSelect>
        </FormControl>
      </div>
    </div>
  );
}

export { FilterTimeline };
