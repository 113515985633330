import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { Dialog, DialogTitle, DialogContent, DialogActions } from './styles';
import { generalEvaluationActions, generalEvaluationFunctionsActions } from '../../../actions';
import { isValidObjectId } from '../../../helpers';
import { useMemo } from 'react';

export function DialogEvaluationEdit({ open, onClose }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id: groupIdParams } = useParams();
  const { evaluations, group, criteriasToDelete, icons } = useSelector(state => state.generalEvaluation);

  const groupId = useMemo(() => groupIdParams, [group]);
  const groupTitle = useMemo(() => group[0]?.description, [group]);

  const iconsToPostedCriterias = useMemo(() => icons.filter(({ criteriaId }) => isValidObjectId(criteriaId)), [icons]);
  const iconsToNewCriterias = useMemo(() => icons.filter(({ criteriaId }) => !isValidObjectId(criteriaId)), [icons]);

  const successMessage =  t('avaliacao_salva_com_sucesso');

  function handleSubmit() {
    const dataToPost = evaluations.filter((item) => !isValidObjectId(item._id)).map((item) => ({
      personalizedEvaluationGroup: groupId,
      name: item.name,
      description: item.description,
      attachment: item.attachment,
      order: item.order,
      rateRange: "1-5",
      status: true
    }));
    const dataToPut = evaluations.filter((item) => isValidObjectId(item._id));

    if (groupId !== undefined) {
      dispatch(generalEvaluationActions.updateEvaluationGroup(groupId, { description: groupTitle }));
    } else {
      dispatch(generalEvaluationActions.createEvaluationGroup({ description: groupTitle }, successMessage, dataToPost, iconsToNewCriterias));
    }

    if (dataToPost.length) {
      dispatch(generalEvaluationActions.createGeneralEvaluation(dataToPost, iconsToNewCriterias, successMessage));
    }

    if (iconsToPostedCriterias.length) {
      dispatch(generalEvaluationFunctionsActions.uploadCriteriaIcons(iconsToPostedCriterias));
    }

    if (dataToPut.length) {
      dispatch(generalEvaluationActions.updateGeneralEvaluation(dataToPut, successMessage));
    }

    if (criteriasToDelete.length > 0) {
      dispatch(generalEvaluationActions.deleteGeneralEvaluation(criteriasToDelete, successMessage));
    }

    onClose();
    history.goBack();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {groupId !== undefined ? t('salvar_edicao_da_avaliacao') : t('salvar_avaliacao')}
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h3" style={{ marginBottom: 16 }}>
          {groupId !== undefined ? t('deseja_editar_as_informacoes_de_avaliacao') : t('deseja_salvar_as_informacoes_de_avaliacao')}
        </Typography>
        <Typography variant="body1">
          {groupId !== undefined
            ? t('as_informacoes_de_avaliacao_serao_editadas_e_as_avaliacoes_realizadas_serao_ajustadas_com_base_na_edicao')
            : t('a_secao_de_avaliacao_ficara_disponivel_no_detalhamento_das_ideias_para_que_os_gestores_possam_avalia-las')
          }
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          {t('voltar')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSubmit}
        >
          {groupId !== undefined ? t('salvar_edicao') : t('salvar_avaliacao')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

