import React from 'react';
import { Box, Card, Grid } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.neutrals.high.dark}`,
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    padding: theme.spacing(4)
  },
  container: {
    padding: theme.spacing(2)
  },
  gridFlex: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest,

  },
  rect: {
    borderRadius: 4
  },
}))(MuiSkeleton);

export function SkeletonIdeaStepFluxCard() {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.gridFlex}>
        <Card elevation={0} className={classes.card}>
          <Skeleton variant="rect" width="50%" height={50} />
          <Skeleton variant="rect" width="50%" height={27} />
          <Skeleton variant="rect" width="50%" height={27} />
          <Box display="flex" style={{ gap: 32 }}>
            <Skeleton variant="rect" width="50%" height={27} />
            <Skeleton variant="rect" width="50%" height={27} />
          </Box>
          <Skeleton variant="rect" width="50%" height={27} />
          <Box display="flex" style={{ gap: 32 }}>
            <Skeleton variant="rect" width="50%" height={50} />
            <Skeleton variant="rect" width="50%" height={50} />
          </Box>
        </Card>

        <Card elevation={0} className={classes.card}>
          <Skeleton variant="rect" width="50%" height={27} />
          <Skeleton variant="rect" width="40%" height={27} />
          <Skeleton variant="rect" width="40%" height={27} />
          <Skeleton variant="rect" width="40%" height={27} />
          <Skeleton variant="rect" width="100%" height={27} />
          <Box display="flex" style={{ gap: 32 }}>
            <Skeleton variant="rect" width="50%" height={50} />
            <Skeleton variant="rect" width="50%" height={50} />
          </Box>
        </Card>

        <Card elevation={0} className={classes.card}>
          <Skeleton variant="rect" width="50%" height={27} />
          <Skeleton variant="rect" width="40%" height={27} />
          <Skeleton variant="rect" width="40%" height={27} />
          <Skeleton variant="rect" width="40%" height={27} />
          <Skeleton variant="rect" width="100%" height={27} />
          <Skeleton variant="rect" width="100%" height={27} />
        </Card>
      </Grid>
    </Grid>
  );
}