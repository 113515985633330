import React, { useEffect } from 'react';
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core"
import ShowMoreText from "react-show-more-text";
import { contentsActions } from "./../../actions";
import { useDispatch, useSelector } from 'react-redux';
import defaultThumb from '../../assets/imgs/Imagem_conte_C3_BAdo_fixado_web_402x.png';
import { ImagineLinkify } from "../TextLinkify";
import { SkeletonHighlightPost } from "./../../components"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  fixedCardContent: {
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column"
  },
  fixedCardContentTitleNoLink: {
    fontWeight: "bold",
    marginBottom: "0.75rem",
    // cursor: "pointer",

  },
  fixedCardContentTitle: {
    fontWeight: "bold",
    marginBottom: "0.75rem",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    }
  },
  fixedCardDescription: {
    fontSize: "14px",
    whiteSpace: "pre-line"
  },
  fixedCardImageWrapper: {
    height: "200px",
    width: "100%",
  },
  fixedCardImage: {
    width: "inherit",
    height: "inherit",
    objectFit: "cover",
  },
}))
export const HighlightCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fixedContent, fixedLoading } = useSelector(state => state.contents)

  useEffect(() => {
    dispatch(contentsActions.getFixedContent());
  }, [dispatch]);

  return (
    <>
      {!fixedLoading && fixedContent ? (
        <Card className={classes.root} elevation={0}>
          <div className={classes.fixedCardImageWrapper}>
            <img src={fixedContent?.banner?.url ? fixedContent?.banner.url : defaultThumb} alt={fixedContent.title} className={classes.fixedCardImage} />
          </div>
          <CardContent className={classes.fixedCardContent}>
            {fixedContent.link ? (
              <Typography variant="h6" component="h1" color="primary" className={classes.fixedCardContentTitle} onClick={() => window.open(fixedContent.link, "_blank")}>
                {fixedContent.title}
              </Typography>
            ) : (
              <Typography variant="h6" component="h1" color="primary" className={classes.fixedCardContentTitleNoLink}>
                {fixedContent.title}
              </Typography>
            )

            }

            <Typography
              color="textSecondary"
              component="div"
              className={classes.fixedCardDescription}
            >
              <ShowMoreText
                lines={3}
                anchorClass="readMoreText"
                more="visualizar mais"
                less="visualizar menos"
                truncatedEndingComponent={"..."}
              >
                <ImagineLinkify>
                  {fixedContent.description}
                </ImagineLinkify>
              </ShowMoreText>
            </Typography>
          </CardContent>
        </Card>
      ) : (fixedLoading &&
        <SkeletonHighlightPost />
      )}
    </>
  )
}