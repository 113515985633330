import { Box, Card, makeStyles, useTheme } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import 'moment/locale/pt-br';
import { SelectIdeasByPeriod } from '../SelectIdeasByPeriod';
import { SkeletonLineChart } from '../Skeletons';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(4),
    boxShadow: 'none'
  },
  title: {
    fontSize: 18,
    color: theme.palette.neutrals.low.light,
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start'
  }
}));

export function IdeaEvolutionBarChart({ dataIdeaEvolutionBarChart = [], loading = false }) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  moment.locale('pt-br')

  const data = useMemo(() => dataIdeaEvolutionBarChart.map((item, index) => ({ ...item, index: index < 9 ? `0${index + 1}` : index + 1, [t('ideias').toLowerCase()]: item.count })), [dataIdeaEvolutionBarChart])

  return (
    <Card className={classes.card}>

      <Box className={classes.titleContainer}>
        <Box className={classes.title}>{t('evolucao_de_ideias')}</Box>
        <SelectIdeasByPeriod />
      </Box>
      {
        loading ? <SkeletonLineChart /> :
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="1" vertical={false} />
              <XAxis dataKey="index" />
              <YAxis />
              <Tooltip labelFormatter={(_label, payload) => moment(payload[0]?.payload?.dayMonth, 'D/M').format('DD/MMM')} />
              <Bar dataKey={t('ideias').toLowerCase()} fill={theme.palette.primary.medium} barSize={10} radius={[6, 6, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
      }
    </Card>
  );
}
