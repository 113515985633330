import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  Box,
  useTheme
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
  },
  card: {
    padding: theme.spacing(3),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  status: {
    color: theme.palette.neutrals.high.main,
    fontSize: 11.24,
    fontWeight: 600,
    textTransform: 'uppercase',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
  },
  contentBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3)
  },
  totalNumber: {
    fontSize: 32,
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  description: {
    color: theme.palette.neutrals.low.medium,
    fontSize: 12,
    textAlign: 'right',
    fontWeight: 600
  },
}));

function CurantionCardsStatus() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { statusIdeas } = useSelector((state) => state.idea);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={4} xs={12}>
        <Card className={classes.card} elevation={0}>
          <span className={classes.status} style={{ backgroundColor: theme.palette.secondary.dark }}>
            {t('aguardando_qualificacao')}
          </span>
          <Box className={classes.contentBottom}>
            <span className={classes.totalNumber}>
              {statusIdeas.AWAITING_QUALIFICATION > 0 
                ? statusIdeas.AWAITING_QUALIFICATION
                : 0
              }
            </span>
            <Typography className={classes.description}>
              {t('ideias_aguardando')} <br/> 
              {t('qualificacao')}
            </Typography>
          </Box>
        </Card>
      </Grid>

      <Grid item md={4} xs={12}>
        <Card className={classes.card} elevation={0}>
          <span className={classes.status} style={{ backgroundColor: theme.palette.auxiliary.cyan.main }}>
            {t('fila_de_execucao')}
          </span>
          <Box className={classes.contentBottom}>
            <span className={classes.totalNumber}>
              {statusIdeas.EXECUTION_QUEUE > 0 
                ? statusIdeas.EXECUTION_QUEUE
                : 0
              }
            </span>
            <Typography className={classes.description}>
              {t('ideias_aguardando')}{' '} <br/> 
              {t('planejamento_de_execucao')}
            </Typography>
          </Box>
        </Card>
      </Grid>

      <Grid item md={4} xs={12}>
        <Card className={classes.card} elevation={0}>
          <span className={classes.status} style={{ backgroundColor: theme.palette.primary.medium }}>
            {t('em_execucao')}
          </span>
          <Box className={classes.contentBottom}>
            <span className={classes.totalNumber}>
              {statusIdeas.EXECUTING > 0 
                ? statusIdeas.EXECUTING
                : 0
              }
            </span>
            <Typography className={classes.description}>
              {t('ideias_aguardando')} <br/> 
              {t('conclusao_de_execucao')}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export { CurantionCardsStatus }