import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, withStyles } from '@material-ui/core';

const TabMenu = withStyles({
  wrapper: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  },
})(Tab);

export function PortifolioDetailsMenu({ handleChangeTab, tabChosed }) {
  const { t } = useTranslation();

  return (
    <Tabs
      centered
      value={tabChosed}
      textColor="primary"
      indicatorColor="primary"
      aria-label="portfolio menu tabs"
      onChange={(e, value) => handleChangeTab(value)}
    >
      <TabMenu label={t('dados_gerais')} />
      <TabMenu label={t('tarefas')} />
    </Tabs>
  );
}