import React from 'react';
import { makeStyles, Typography, Box, Card } from '@material-ui/core';
import { RankingMedalColors } from './RankingMedalColors';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  position: { 
    fontSize: 14,
    fontWeight: 700,
    paddingLeft: 8,
    textTransform: 'lowercase',
    color: theme.palette.neutrals.low.main,
  },
  typography: {
    paddingLeft: 8,
    color: theme.palette.neutrals.low.main,
  },
}));

export function RankingCard(props) {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Box>
        {props.ranking.position <= 5 && (
          <RankingMedalColors position={props.ranking.position} />
        )}
        <Typography variant="subtitle2" className={classes.position}>
          {props.ranking.position}º
        </Typography>
      </Box>
      <Box>
        <Typography variant="h2" className={classes.typography}>
          {parseInt(props.ranking.score)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" className={classes.typography}>
          {props.ranking.userName}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" className={classes.typography}>
          {props.ranking.businessUnitLocals?.businessUnit?.name}
        </Typography>
      </Box>
    </Card>
  );
}