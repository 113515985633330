import React, { useState } from 'react';
import { AvatarWithoutPhoto } from '../Shared';
import { Avatar, Button, makeStyles } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions';
import { ImageCrop } from '../';

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      width: '100%',
      display: 'block',
    },
  },
  avatar: {
    width: 96,
    height: 96,
  },
  containerButton: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      justifyContent: 'center',
      flexDirection: 'column'
    }
  },
  btnPhoto: {
    textTransform: 'lowercase',
    marginLeft: 14,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 16,
      width: '100%'
    }
  },
  btnDelete: {
    textTransform: 'lowercase',
    fontWeight: 600,
    marginLeft: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      fontWeight: 600,
      marginLeft: 0,
      width: '100%'
    },
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}));

function UploadImageUser({ user, deleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const inputRef = React.createRef();
  const { t } = useTranslation();

  const [src, setSrc] = useState(null);
  const [open, setOpen] = useState(false);

  function handleInputChange(e) {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setOpen(true);
  }

  function handleDispatchImage(blob) {
    dispatch(userActions.uploadFile(blob, user._id))
  };

  function handleClick(e) {
    // empty File list
    inputRef.current.value = '';
  }

  function handleDelete() {
    dispatch(userActions.deleteFile(user));
  }

  function closeCropper() {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        {user.attachment?.url ? (
          <Avatar
            src={user.attachment.url}
            alt={user.name}
            className={classes.avatar}
          />
        ) : (
          <AvatarWithoutPhoto large userName={user.name} />
        )}
      </div>
      <ImageCrop src={src} dispatch={handleDispatchImage} open={open} close={closeCropper} aspect={1/1} shape='round'/>
      <div className={classes.containerButton}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PhotoCamera />}
          className={classes.btnPhoto}
          disabled={loading}
          component="label"
        >
          {loading ? t('carregando_imagem') : t('carregar_imagem')}
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleInputChange}
            onClick={handleClick}
            ref={inputRef}
          />
          <div>
          </div>
        </Button>
        {deleteButton && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.btnDelete}
            onClick={handleDelete}
            disabled={loading}
          >
            {t('excluir_imagem')}
          </Button>
        )}
      </div>
    </div>
  );
}

export { UploadImageUser };
