import { portfolioConstants } from "./../../constants/portfolio.constants"

const initialState = {
  loading: false,
  projects: []
}

export function portfolioExecutionFlow(state = initialState, action) {
  switch(action.type) {
    case portfolioConstants.GET_ALL_PROJECTS_EXEUTION_FLOW_REQUEST:
      return {
        ...state,
        loading: true
      };
  
    case portfolioConstants.GET_ALL_PROJECTS_EXEUTION_FLOW_FAILURE:
      return {
        ...state,
        loading: false
      };
  
    case portfolioConstants.GET_ALL_PROJECTS_EXEUTION_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [...action.payload]
      };

    default: 
      return state;

  }
}