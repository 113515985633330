import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import emptyStateImg from '../../assets/imgs/emptystate/illustration_empty-state-ideas-curation.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "center",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey.main}`,
    backgroundColor: theme.palette.neutrals.high.main,
    marginTop: theme.spacing(3), 
    padding: theme.spacing(6),
  },
  emptyStateSvg: {
    width: 96,
    height: 96,
    marginBottom: theme.spacing(2),
  },
  emptyStateText: {
    fontSize: 16,
    color: theme.palette.neutrals.low.medium,
  }
}));

export function ReportEmptyState() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <img className={classes.emptyStateSvg} src={emptyStateImg} alt="Empty State" />
        <Typography className={classes.emptyStateText}> 
          {t('ainda_nao_ha_informacoes_por_aqui')} {' '} <br />
          {t('utilize_o_filtro_para_indicar_os_dados')}
        </Typography>
      </Box>
    </Paper>
  );
}