import React from 'react'
import { FbGridImage } from './FbGridImage';

export const AttachmentImages = ({ attachments }) => {
  const images = attachments.filter((image) => image.mimeType.container === 'images');

  return (
   images.length > 0 && (
    <FbGridImage images={images} />
   )
  )
}
