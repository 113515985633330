import { progressBarConstants } from '../constants';

const INITIAL_STATE = {
  itemList: [
    { 
      name: '', 
      status: 'disabled', 
      handleRenderIcon: (color) => null 
    }
  ]
}

export function progressBar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case progressBarConstants.PROGRESS_BAR_GET_LIST:
      return {
        ...state,
        itemList: action.payload
      }

    case progressBarConstants.PROGRESS_BAR_NEXT_STEP:
      const itsNotLastItem = state.itemList[action.payload.index + 1] !== state.itemList[state.itemList.length - 1];
      const itemIsDisabled = state.itemList[action.payload.index + 1].status === 'disabled';
      state.itemList[action.payload.index].status = 'concluded';

      if (itsNotLastItem && itemIsDisabled) {
        state.itemList[action.payload.index + 1].status = 'active';
      }
      
      return state;

    case progressBarConstants.PROGRESS_BAR_REMOVE_STEP:
      const itsNotActive = state.itemList[action.payload.index].status !== 'active';
      
      if (itsNotActive) {
        state.itemList[action.payload.index].status = 'disabled';
      }

      return state;

    default:
    return state;
  }
}