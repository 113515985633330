import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { BackNavAdmin } from '../../components/Shared';
import { generalEvaluationActions, generalEvaluationFunctionsActions } from '../../actions';
import { EvaluationForm, EvaluationFormActions } from '../../components/GeneralEvaluation';
import { SkeletonEvaluationForm, SkeletonEvaluationFormActions } from '../../components/Skeletons';

export function GeneralEvaluationEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: groupId } = useParams();
  const { loading } = useSelector(state => state.generalEvaluation);

  useEffect(() => {
    if (groupId) {
      dispatch(generalEvaluationActions.getEvaluationGroupById(groupId));
      dispatch(generalEvaluationActions.getAllEvaluationsByGroup(groupId));
    }

    dispatch(generalEvaluationFunctionsActions.clearCriteriasToRemove());
  }, [groupId]);

  return (<>
    <BackNavAdmin title={t('avaliacao_geral')} />

    <Container maxWidth="lg" style={{ padding: 32 }}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          {loading 
            ? <SkeletonEvaluationForm /> 
            : <EvaluationForm />
          }
        </Grid>
        <Grid item xs={4}>
          {loading 
            ? <SkeletonEvaluationFormActions /> 
            : <EvaluationFormActions />
          }
        </Grid>
      </Grid>
    </Container>
  </>);
}