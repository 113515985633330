import React, { useMemo } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { ideaCreateActions } from '../../actions';
import { ideaUpdateActions } from '../../actions/ideaUpdate.actions';
import { VideocamOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  removeButton: {
    top: 0,
    left: 0,
  },
}));

function VideoList({ videos, onRemove}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  function removeVideo(videoId) {
      dispatch(onRemove(videoId));
  }

  return (
    <div>
      {videos.map((video) => (
        <div style={{ width: '100%', position: 'relative' }} key={video.id}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box display='flex' alignItems='center'>
              <VideocamOutlined />
              <Box marginLeft={2}>
                <Typography variant='subtitle2'>{video.name}</Typography>
                <Typography variant='caption'>{video.readableSize}</Typography>
              </Box>
            </Box>
            <IconButton
              className={classes.removeButton}
              onClick={() => removeVideo(video.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>

          <video style={{ width: '100%' }} height="255" controls>
            <source
              src={video.value ? video.value : video.preview}
              type={video.type}
            />
          </video>
        </div>
      ))}
    </div>
  );
}

export { VideoList };
