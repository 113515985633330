import React from 'react';

import { Box, Dialog, Divider, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import config from '../../config';
import { infraCss } from './infraCss';
import { useWindowSize } from '../../hooks';
import { TransitionUp, Transition } from '../CreateIdeaDialogs/DialogTransition';

export function DialogResponsive({ open, onClose, title, children }) {
  const classes = infraCss();
  const [width] = useWindowSize();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      fullScreen={width < config.RESPONSIVE_MOBILE ? true : false}
      TransitionComponent={
        width < config.RESPONSIVE_MOBILE ? TransitionUp : Transition
      }
    >
      <Box className={classes.titleWrapper}>
        <Typography className={classes.titleDialog}>
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      {children}
    </Dialog>
  );
}