import { useSelector } from "react-redux";

function useCanEditableIdea(ideaStepId) {
  const ideasStepsFlux = useSelector(state => state.domains.ideasStepsFlux);

  const step = ideasStepsFlux?.find(item => item._id === ideaStepId);
  
  return step?.canEditIdea || false;
}
export { useCanEditableIdea }
