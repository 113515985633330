import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4)
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  text: {
    transform: 'none',
  },
}))(MuiSkeleton);

export const SkeletonUserEdit = () => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.flex}>
          <Skeleton variant="circle" width={56} height={56} />
          <Skeleton variant="text" width={200} height={50} style={{ margin: '0 14px' }} />
          <Skeleton variant="text" width={100} height={50} /> 
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" width={'100%'} height={53} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" width={'100%'} height={53} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" width={'100%'} height={53} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" width={'100%'} height={53} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" width={'100%'} height={53} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" width={'100%'} height={53} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" width={'100%'} height={53} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" width={'100%'} height={150} />
        </Grid>
      </Grid>
    </Card>
  )
}
