import {
  styled,
  Box,
  Typography,
  Card as MuiCard,
  LinearProgress as MuiLinearProgress
} from '@material-ui/core';

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.grey.main}`,
}));

export const Header = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey.main}`,
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  }
}));

export const Title = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  height: 36
});

export const Value = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  color: theme.palette.neutrals.low.light,
}));

export const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),

  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(2),
  }
}));

export const SubtitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginTop: 11
}));

export const Circle = styled(Box)(({ theme }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%'
}));

export const ValuesContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 15
});

export const LinearProgressGray = styled(MuiLinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.neutrals.low.lightest,
  },
}));

export const LinearProgressGreen = styled(MuiLinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.auxiliary.green.light,
  },
}));

export const LinearProgressRed = styled(MuiLinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.auxiliary.red.light,
  },
}));