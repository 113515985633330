import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const adminsService = {
  getAdmins,
  postAdminContact,
  deleteAdminContact,
  putAdminContact
};

async function getAdmins() {
  return axios.get(`${config.URL_BASE}/help/administrators/all-auto-complete`, requestHeaders()).then(handleResponse);
};

async function postAdminContact(phone, email, id) {
  return axios.post(`${config.URL_BASE}/help/administrators`, {
    userId: id,
    email: email,
    phone: phone
  }, requestHeaders()).then(handleResponse);
};

async function putAdminContact(phone, email, id) {
  return axios.put(`${config.URL_BASE}/help/administrators/${id}`, {
    email: email,
    phone: phone
  }, requestHeaders()).then(handleResponse);
};

async function deleteAdminContact(id) {
  return axios.delete(`${config.URL_BASE}/help/administrators/${id}`, requestHeaders()).then(handleResponse);
};



