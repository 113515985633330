import { styled } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import MuiCard from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import MuiButton from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export const SettingsTitle = styled(Typography)({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginBottom: 8,
});

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderRadius: '8px 8px 0 0', 
  backgroundColor: theme.palette.neutrals.high.main,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
}));

export const Label = styled(Typography)({
  marginLeft: 8,
  textTransform: 'lowercase'
});

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
}));

export const Form = styled('form')({

});

export const Header = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2)
}));

export const AvatarIcon = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.light
}));

export const Actions = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 16,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 16
  }
}));

export const Button = styled(MuiButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

export const VisibilityIcon = styled(Visibility)(({ theme }) => ({
  color: theme.palette.neutrals.low.light,
}));

export const VisibilityOffIcon = styled(VisibilityOff)(({ theme }) => ({
  color: theme.palette.neutrals.low.light,
}));

export const ErrorMessage = styled('span')(({ theme }) => ({
  color: theme.palette.auxiliary.red.main,
  fontSize: 11,
  fontWeight: 600
}));