import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from "@material-ui/core"
import Info from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import { ideaEditStyles } from './IdeaEditStyles';
import dialogVideoSvg from '../../assets/imgs/dialog-video.svg';
import { EditUpload, EditUploadButton } from './../../components/Upload';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';
import { ideaUpdateActions } from '../../actions';

export const IdeaEditGeneralVideo = ({ ideaUpdate }) => {
  const classes = ideaEditStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function removeVideo() {
    dispatch(ideaUpdateActions.removeVideo(ideaUpdate.video.id ?? ideaUpdate.video._id));
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatarIcon}>
              <VideocamIcon color="primary" />
            </Avatar>
            <Typography variant="h3">{t('videos')}</Typography>
          </Box>
          {ideaUpdate.video.name || ideaUpdate.video.fileName ? (
            <Button
              variant="text"
              color="primary"
              startIcon={<DeleteIcon />}
              onClick={removeVideo}
            >
              remover video
            </Button>
          ) : (
            <EditUploadButton 
              type="video"
              variant="text"
              text={t('adicionar_video')}
              icon={<AddCircleOutlinedIcon />}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <EditUpload
            type="video"
            img={dialogVideoSvg}
            name="video"
            accept="video/*"
            message=""
            maxSize={32428800}
          />
          <Box display="flex" alignItems="center" className={classes.info}>
            <Info style={{fontSize: 15, marginRight: 7 }}/>
            <Typography variant="caption">
              {t('o_arquivo_deve_possuir_tamanho_maximo_de')}{' '}<strong>32mb</strong>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
