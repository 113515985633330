import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4)
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  text: {
    transform: 'none',
  },
}))(MuiSkeleton);

export const SkeletonAdministrators = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} style={{ margin: '5px 10px 0'}}>
      {[0,1,2].map((item, index) => (
        <Grid item xs={4} key={index}>
          <Card elevation={0} className={classes.card}>
            <Box display="flex" alignItems="center">
              <Skeleton variant="circle" width={40} height={40} style={{ marginRight: 10 }} />
              <Skeleton variant="text" width={'100%'} height={22} />
            </Box>
            <Box marginTop="16px">
              <Skeleton variant="text" width={'100%'} height={20} />
              <Skeleton variant="text" width={'80%'} height={20} style={{ margin: '10px 0' }} />
              <Skeleton variant="text" width={'80%'} height={20} />
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}