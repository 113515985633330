import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Hidden, CircularProgress, Card as MuiCard, styled } from '@material-ui/core';
import {
  IdeaGeneral,
  IdeaResults,
  IdeaExecution,
  IdeaConclusion,
  IdeaEngagement,
  IdeaQualification,
  IdeaQualificationTabs,
  IdeaGeneralEvaluationLayout,
  EmptyState,
  SidebarIdeaPoint,
  CustomSelect
} from '../../components';
import {
  SkeletonCardIdeaDetails,
  SkeletonSidebarIdeaDetails
} from '../Skeletons/SkeletonIdeaDetails';
import { optionConstants } from '../../constants';
import emptyStateIcon from '../../assets/imgs/emptystate/illustration_empty-state-general-evaluation.svg';
import { useIdeaDetailsController } from '../../containers/IdeaDetailsPage/hooks/useIdeaDetailsController';

const Card = styled(MuiCard)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  borderRadius: 8,
  padding: theme.spacing(4),
}));

export function IdeaDetailsLayout() {
  const history = useHistory()
  const { typeProfile, _id: userId } = useSelector((state) => state.user);
  const { page, loading, ideaDetails } = useIdeaDetailsController();

  function handleChangePage(key) {
    history.push(`/idea/${ideaDetails._id}/${key}`)
  }

  function getOptionsIdeaMobile() {
    const status = ideaDetails.statusIdea.code;
    const arrOptions = [optionConstants.GENERAL];

    //qualification so pode ser acessada por admins e nao pode estar aguardando qualficacao  
    if (typeProfile.type !== "colaborador") {
      // if (['AWAITING_QUALIFICATION'].includes(status)) {

      arrOptions.push(optionConstants.QUALIFICATION)
      // }
    }

    //acesso a engagement, nao deve exibir se nao foi publicada 
    if (!['AWAITING_QUALIFICATION', 'DRAFT', 'PATENT_PENDING', 'PENDING_REVIEW'].includes(status)) {
      arrOptions.push(optionConstants.ENGAGEMENT)
    }


    //ACESSO A CONCLUSION
    if (ideaDetails?.contributors.filter(item => item?._id === userId).length > 0 || ideaDetails.user?._id === userId || typeProfile.type !== 'colaborator') {
      if (!['EXECUTING', 'EXECUTION_QUEUE'].includes(ideaDetails.statusIdea.code)) {
        arrOptions.push(optionConstants.CONCLUSION)
      }
    }

    //ACESSO A RESULTS
    if (ideaDetails.statusIdea.code === 'CONCLUDED') {
      arrOptions.push(optionConstants.RESULTS)
    }

    return arrOptions;
  }

  return (
    <Grid container spacing={4}>
      <Hidden smDown>
        <Grid item xs={3}>
          {loading 
            ? <SkeletonSidebarIdeaDetails /> 
            : ideaDetails?.statusIdea?.code && <SidebarIdeaPoint page={page} />
          }
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12}>
          {ideaDetails?.statusIdea?.code && (
            <CustomSelect
              optionsAvaiable={getOptionsIdeaMobile()}
              handlePage={handleChangePage}
              startOption={optionConstants.GENERAL}
            />
          )}
        </Grid>
      </Hidden>
      <Grid item xs={12} md={9}>
        {page && (
          <Fragment>
            {loading 
              ? <SkeletonCardIdeaDetails /> 
              : page === "general" && ideaDetails?._id && <IdeaGeneral />
            }

            {page === "qualification" && ideaDetails?._id && (
              <Fragment>
                {(typeProfile.type === 'admin' || typeProfile.type === 'manager') &&
                  ideaDetails?.statusIdea?.code === 'AWAITING_QUALIFICATION' ? (
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <IdeaQualificationTabs />
                    </Grid>
                  </Grid>
                ) : (
                  <IdeaQualification
                    idea={ideaDetails}
                    createdAt={ideaDetails?.createdAt}
                    approveDate={ideaDetails?.approveDate}
                  />
                )}
              </Fragment>
            )}
          {/* 
            {loading ? <CircularProgress /> : (
              page === "execution" && ideaDetails._id && !!projects?.length ? 
              (<LinkedProjects projects={projects} />) : (
              <IdeaExecution />
            ))} */}
            {page === "general-evaluation" && ideaDetails?._id && (<>
              {ideaDetails?.personalizedEvaluationGroup !== undefined 
                ? <IdeaGeneralEvaluationLayout />
                : (
                  <Card elevation={0}>
                    <EmptyState
                      icon={emptyStateIcon}
                      title="Ainda não há avaliações por aqui!"
                      description="A avaliação geral será exibida aqui quando liberada."
                    />
                  </Card>
                )
              }
            </>)}
            {loading ? <CircularProgress /> : 
              page === "execution" && ideaDetails?._id &&   (
              <IdeaExecution />
            )}
            {page === "conclusion" && ideaDetails?._id && (
              <IdeaConclusion />
            )}

            {page === "results" && ideaDetails?._id && (
              <IdeaResults />
            )}

            {page === "engagement" && ideaDetails?._id && (
              <IdeaEngagement />
            )}
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
}
