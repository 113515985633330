import React from 'react';
import {
  makeStyles,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import { DialogCustomTitle } from './DialogCustomTitle';
import { dialogCss } from './dialogCss';
import { MenuInformation } from '../Shared';
import clsx from 'clsx';
import { DialogCreateIdea } from './DialogCreateIdea';
import { useWindowSize } from '../../hooks';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dividerBottom: {
    borderBottom: 'none',
  },
  title: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  button: {
    textTransform: 'lowercase',
  },
  grid: {
    position: 'relative',
  },
  buttonInformation: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 999,
  },
  buttonBottom: {
    top: 242,
  },
}));

function CanvasRead(props) {
  const dialogClasses = dialogCss();
  const classes = useStyles();
  const [width] = useWindowSize();
  const { t } = useTranslation();

  return (
    <DialogCreateIdea maxWidth="lg" open={props.isOpen}>
      <DialogCustomTitle id="customized-dialog-title" onClose={props.onClose}>
        canvas
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t(
                  'faca_uma_lista_dos_tres_principais_problemas_que_precisam_ser_resolvidos'
                )}
                .{' '}
                {t(
                  'esse_e_o_momento_de_avaliar_como_os_seus_clientes_em_potencial_estao_resolvendo_o_problema_que_a_sua_empresa_se_propoe_solucionar'
                )}
              </MenuInformation>
            </div>

            <TextField
              disabled
              name="Q1"
              id="Q1"
              variant="filled"
              label={t('problema')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 23 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 23 : 8}
              placeholder={t('qual_o_pricipal_problema_que_voce_identificou')}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[0]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t('neste_ponto')},{' '}
                {t(
                  'descreva_de_forma_sucinta_as_melhores_solucoes_do_seu_produto_e_aposte_tambem_em_elaborar_um_mvp_produto_minimo_viavel'
                )}
                ,{' '}
                {t(
                  'que_consiste_em_um_produto_com_o_minimo_de_recursos_possivel'
                )}
                ,{' '}
                {t(
                  'mas_que_serve_para_conhecer_a_reacao_do_mercado_sobre_a_sua_viabilidade'
                )}
                .
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q2"
              id="Q2"
              variant="filled"
              label={t('solucao')}
              multiline
              fullWidth
              placeholder={t('como_sua_ideia_resolve_este_problema')}
              style={{ marginBottom: 16 }}
              rows={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[1]?.answer}
            />

            <div
              className={clsx(classes.buttonInformation, classes.buttonBottom)}
            >
              <MenuInformation>
                {t(
                  'defina_as_principais_acoes_e_metricas_que_darao_suporte_a_geracao_de_receitas_e_como_sera_feito_o_contanto_com_o_usuario_e_a_retencao_do_mesmo'
                )}
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q3"
              id="Q3"
              variant="filled"
              label={t('metricas_chave')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              placeholder={t(
                'quais_as_principais_metricas_para_medir_os_resultados_da_sua_ideia'
              )}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[2]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t(
                  'este_e_um_momento_para_escolher_bem_as_palavras_e_resumir_em_que_consiste_o_seu_produto_servico'
                )}
                ,{' '}
                {t(
                  'demonstrando_o_porque_ele_ser_merecedor_do_dinheiro_dos_clientes'
                )}
                .{' '}
                {t(
                  'aqui_e_bom_ter_cuidado_para_nao_fazer_promessas_que_nao_podem_ser_coumpridas'
                )}
                .
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q4"
              id="Q4"
              variant="filled"
              label={t('proposta_de_valor')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 23 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 23 : 8}
              placeholder={t('qual_o_principal_beneficio_da_sua_solucao_ideia')}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[3]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t(
                  'aqui_e_que_voce_precisa_concentrar_sua_energia_para_pensar_no_grande_diferencial_da_sua_startup'
                )}
                ,{' '}
                {t(
                  'que_a_fara_se_destacar_diante_da_concorrencia_e_que_ira_trazer_inovacao_para_o_mercado_em_que_voce_esta_inserido'
                )}
                .
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q5"
              id="Q5"
              variant="filled"
              label={t('vantagem_injusta')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              placeholder={t(
                'qual_o_maior_diferencial_da_sua_ideia_quando_comparado_ao_que_ja_existe'
              )}
              style={{ marginBottom: 16 }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[4]?.answer}
            />

            <div
              className={clsx(classes.buttonInformation, classes.buttonBottom)}
            >
              <MenuInformation>
                {t(
                  'e_hora_de_pensar_em_como_o_seu_produto_ou_servico_chegara_ate_os_seus_potenciais_clientes_e_avaliar_os_custos_dessa_comunicacao'
                )}
                .
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q6"
              id="Q6"
              variant="filled"
              label={t('canais')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              placeholder={t(
                'como_sera_que_os_segmentos_de_clientes_utilizarao_a_solucao'
              )}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[5]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t(
                  'busque_entender_quem_sao_os_clientes_que_podem_se_interessar_e_precisam_da_sua_solucao'
                )}
                .{' '}
                {t(
                  'caso_seja_percebido_que_voce_tem_mais_de_um_tipo_de_cliente'
                )}
                ,{' '}
                {t(
                  'o_ideal_e_executar_um_quadro_lean_canvas_para_cada_um_deles'
                )}
                .
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q7"
              id="Q7"
              variant="filled"
              label={t('clientes')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 23 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 23 : 8}
              placeholder={t(
                'quem_sao_as_pessoas_clientes_que_utilizarao_esta_solucao'
              )}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[6]?.answer}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t(
                  'organize_em_uma_lista_todos_os_custos_fixos_e_variaveis_da_sua_startup'
                )}
                .
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q8"
              id="Q8"
              variant="filled"
              label={t('estrutura_de_custos')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              placeholder={t(
                'quais_os_recursos_necessarios_para_operacionalizar_a_sua_ideia'
              )}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[7]?.answer}
            />
          </Grid>

          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t('e_preciso_determinar_qual_sera_o_modelo_de_receita')},{' '}
                {t('como_tambem_definir_valores_para_o_produto_ou_servico')}.{' '}
                {t('um_bom_comeco_pode_ser_oferecer_um_unico_plano')},{' '}
                {t('com_uma_avalicao_inicial_gratuita')},
                {t('para_atrair_a_atencao_dos_clientes_em_prospeccao')}.
              </MenuInformation>
            </div>
            <TextField
              disabled
              name="Q9"
              id="Q9"
              variant="filled"
              label={t('fontes_de_receita')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 10 : 8}
              placeholder={t(
                'quais_os_ganhos_potenciais_que_sua_ideia_pode_trazer'
              )}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                  whiteSpace: 'pre-line',
                },
              }}
              defaultValue={props.idea.canvas[8]?.answer}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <Button
              className={dialogClasses.button}
              variant="contained"
              type="submit"
              color="primary"
              onClick={props.onClose}
            >
              {t('fechar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { CanvasRead };
