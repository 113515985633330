import { feedbackConstants } from '../constants';
import { feedbackService } from '../services';
import { alertActions } from './alert.actions';

export const feedbackActions = {
  setFeedback,
  clearFeedback,
  openFeedback,
  discartFeedback,
  sendFeedback,
  getFeedback,
  getFeedbackScroll
};

function setFeedback(feedbackText) {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.SET_FEEDBACK_TEXT,
      payload: feedbackText
    });
  };
}

function clearFeedback() {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.CLEAR_FEEDBACK_TEXT,
    })
  }
}

function openFeedback(payload) {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.OPEN_FEEDBACK,
      payload
    })
  }
}

function discartFeedback(payload) {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.DISCART_FEEDBACK,
      payload
    })
  }
}

function sendFeedback(data) {
  return (dispatch) => {
    dispatch({ type: feedbackConstants.POST_USER_FEEDBACK_REQUEST })
    feedbackService.sendFeedback(data)
      .then(({ data }) => {
        dispatch({
          type: feedbackConstants.POST_USER_FEEDBACK_SUCCESS,
          payload: data.data,
        });
        dispatch(alertActions.success('O seu Feedback foi enviado!'))
      })
      .catch((error) => {
        dispatch({
          type: feedbackConstants.POST_USER_FEEDBACK_FAILURE,
          error
        });
      })
  }
}

function getFeedback(page, sort, limit) {
  return dispatch => {
    dispatch({ type: feedbackConstants.REQUEST_GET_FEEDBACKS });
    feedbackService.getFeedback(page, sort, limit).then(({ data: { paging, data } }) => {
      dispatch({ type: feedbackConstants.SUCCESS_GET_FEEDBACKS, payload: { paging, data } })
    })
      .catch(err => {
        dispatch({ type: feedbackConstants.FAILURE_GET_FEEDBACKS });
        console.error(err);
      })
  }
}

function getFeedbackScroll(page, sort, limit) {
  return dispatch => {
    feedbackService.getFeedback(page, sort, limit).then(({ data: { paging, data } }) => {
      dispatch({ type: feedbackConstants.SUCCESS_GET_FEEDBACKS_SCROLL, payload: { paging, data } })
    })
      .catch(err => {
        dispatch({ type: feedbackConstants.FAILURE_GET_FEEDBACKS_SCROLL });
        console.error(err);
      })
  }
}