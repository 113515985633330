import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Container, Button, Box } from '@material-ui/core';
import { BackNavAdmin, ListClassificationPersonalized } from '../../components';
import AddCircle from '@material-ui/icons/AddCircle';
import { history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import iconEmptyStateClassification from '../../assets/imgs/emptystate/illustration_empty-state-idea-classification.svg';
import { personalizedClassificationActions } from '../../actions';
import { EmptyState } from '../../components/Shared';
import { SkeletonClassificationPersonalized } from '../../components/Skeletons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  button: {
    float: "right"
  },
}));

export const PersonalizedClassificationListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { personalizedClassification: {
    personalizedClassification,
    loading
  } } = useSelector(state => state);
  console.log(personalizedClassification)
  useEffect(() => {
    dispatch(personalizedClassificationActions.getPersonalizedClassification());
  }, []);

  function handleCondition(a, b) {
    return personalizedClassification[0].name !== '' && personalizedClassification[0].description !== '' ? b : a;
  };

  function handleClick() {
    history.push('/preferences/personalized-classification/edit');
  };

  return (
    <>
      <BackNavAdmin title={t('classificacao_das_ideias')}>
        <Button
          className={classes.button}
          variant={handleCondition('contained', 'outlined')}
          color="primary"
          onClick={handleClick}
          disabled={loading}
          startIcon={handleCondition(<AddCircle />, <EditIcon />)}
        >
          {handleCondition(t('nova_classificacao'), t('editar_classificacao'))}
        </Button>
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        {loading ? <SkeletonClassificationPersonalized /> : (
          personalizedClassification[0].name !== '' && personalizedClassification[0].description !== '' ? (
            <ListClassificationPersonalized />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box maxWidth="550px">
                <EmptyState
                  icon={iconEmptyStateClassification}
                  title={t('ainda_nao_ha_classificacao_personalizada_de_ideias')}
                  description={t('que_tal_adicionar_uma_nova_classificacao_para_incrementar_processo_de_entendimento_das_ideias')}
                />
              </Box>
            </Box>
          )
        )}
      </Container>
    </>
  );
}
