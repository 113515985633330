import React from 'react';
import { Box } from "@material-ui/core"
import { collapseCss } from './collapseCss';


function HeaderCollapse({ title, icon }) {
  const collapseClasses = collapseCss();


  return (
    <Box display='flex' alignItems='center'>
      <Box className={collapseClasses.iconDiscription}>{icon}</Box>
      <Box className={collapseClasses.description}>{title}</Box>
    </Box>
  );
}

export { HeaderCollapse };
