import React, { Fragment } from 'react';
import { Card, CardActions, Grid, Box, CardContent, Typography } from '@material-ui/core';
import { collapseCss } from './collapseCss';
import EventIcon from '@material-ui/icons/Event';
import { HeaderCollapse } from './HeaderCollapse';
import { useDispatch, useSelector } from 'react-redux';
import { executionActions, ideaConclusionActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { selectedLanguageDateInputFormat } from '../../helpers/input-date-format';
import moment from 'moment';
import { DatePicker } from '../Shared/DatePicker';

function DeadlineCollapse({ isConclusion, isResults = false }) {
  const collapseClasses = collapseCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const {
    estimatedStartDate,
    estimatedConclusionDate
  } = useSelector((state) => state.execution);
  const {
    startDate,
    conclusionDate
  } = useSelector(state => state.ideaConclusion);

  function handleChange(date, name) {
    if (date instanceof Date && isFinite(date)) {
      if (!isConclusion && !isResults) {
        dispatch(executionActions.setInputDeadline(date, name));
      } else {
        dispatch(ideaConclusionActions.setConclusionInput(date, name))
      }
    }
  }

  const Label = ({ label, value }) => (
    <Box>
      <strong>{label}</strong>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );

  return (
    <Card className={collapseClasses.card} elevation={0}>
      <CardActions className={collapseClasses.actions}>
        <HeaderCollapse
          title={t('prazo_execucao')}
          icon={<EventIcon color="primary" />}
        />
      </CardActions>
      <CardContent className={collapseClasses.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Typography variant="subtitle2">{t('estimado')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            {isConclusion || isResults ? (
              <Label
                label={t('inicio')}
                value={moment(estimatedStartDate).format("DD/MM/YYYY")}
              />
            ) : (
              <DatePicker
                required={true}
                format={selectedLanguageDateInputFormat(language)}
                id="estimatedStartDate"
                name="estimatedStartDate"
                label={t('inicio')}
                invalidDateMessage={t('data_de_inicio_invalida')}
                value={estimatedStartDate}
                onChange={(data) => handleChange(data, 'estimatedStartDate')}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={5}>
            {isConclusion || isResults ? (
              <Label
                label={t('termino')}
                value={moment(estimatedConclusionDate).format("DD/MM/YYYY")}
              />
            ) : (
              <DatePicker
                required={true}
                format={selectedLanguageDateInputFormat(language)}
                id="estimatedConclusionDate"
                name="estimatedConclusionDate"
                label={t('termino')}
                invalidDateMessage={t('data_de_termino_invalida')}
                value={estimatedConclusionDate}
                minDate={estimatedStartDate}
                onChange={(data) => handleChange(data, 'estimatedConclusionDate')}
                minDateMessage={
                  <>
                    {t('atencao')}!{' '}
                    {t('a_data_de_termino_das_inscricoes_nao_pode_ser_inferior_a_data_de_inicio_e_de_cadastro_de_desafio')}
                  </>
                }
              />
            )}
          </Grid>
          {(isConclusion || isResults) && (
            <Fragment>
              <Grid item xs={12} lg={2}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <Typography variant="subtitle2">{t('real')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={5}>
                {isResults ? (
                  startDate && <Label label={t('inicio')} value={moment(startDate).format("DD/MM/YYYY")} />
                ) : (
                  <DatePicker
                    format={selectedLanguageDateInputFormat(language)}
                    margin="normal"
                    id="date-picker-inline"
                    label={t('inicio')}
                    invalidDateMessage={t('data_de_inicio_invalida')}
                    name="startDate"
                    value={startDate}
                    onChange={(data) => handleChange(data, 'startDate')}
                    disabled={isResults}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={5}>
                {isResults ? (
                  conclusionDate && <Label label={t('termino')} value={moment(conclusionDate).format("DD/MM/YYYY")} />
                ) : (
                  <DatePicker
                    format={selectedLanguageDateInputFormat(language)}
                    margin="normal"
                    id="date-picker-inline"
                    label={t('termino')}
                    disabled={isResults}
                    invalidDateMessage={t('data_de_termino_invalida')}
                    name="conclusionDate"
                    value={conclusionDate}
                    minDate={startDate}
                    onChange={(data) => handleChange(data, 'conclusionDate')}
                    minDateMessage={
                      <>
                        {t('atencao')}!{' '}
                        {t(
                          'a_data_de_termino_das_inscricoes_nao_pode_ser_inferior_a_data_de_inicio_e_de_cadastro_de_desafio'
                        )}
                      </>
                    }
                  />
                )}
              </Grid>
            </Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export { DeadlineCollapse };
