import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
  }
}));

export const FeedbackSkeletonCard = () => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
        <CardHeader
          avatar={<Skeleton variant="circle" width={40} height={40} />}
          title={<Skeleton variant="text" width={150} />}
          subheader={<Skeleton variant="text" width={200} />}
        />
        <CardContent>
          <Skeleton variant="text" width="100%" height={150} />
        </CardContent>
      </Card>
    );
};
