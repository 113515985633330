import React, { useEffect, useState } from 'react';
import { helpCenterService } from '../../services/help-center.service'
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  video: {
    width: '90%',
    height: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframe: {
    width: '100%',
    height: '100%',
  }
}));

function FirstAccess() {
  const [urlVideo, setUrlVideo] = useState('');
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const isMp4Video = useMemo(() => urlVideo.endsWith('.mp4'), [urlVideo])

  useEffect(() => {
    helpCenterService.getWelcomeVideo()
      .then(response => {
        setLoading(false);
        setUrlVideo(response.data.value);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])

  const filterYTUrl = useMemo(()=> urlVideo.match(/v=(.*)&/gm) !== null ? urlVideo.match(/v=(.*)&/gm)[0]?.split('v=')[1]?.split('&')[0] : "", [urlVideo])

  return (
    <Box className={classes.root} >
      {!loading ? (
        <Box className={classes.video}>
          {isMp4Video ? <video
            className={classes.iframe}
            width="600"
            height="338"
            controls>
            <source src={`${urlVideo}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video> : !!filterYTUrl.length ?
            <iframe width="600" height="338" src={`https://www.youtube.com/embed/${filterYTUrl}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> : <></>}
        </Box>
      ) : <CircularProgress />}
    </Box>
  );
}

export { FirstAccess };