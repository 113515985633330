import { portfolioConstants } from '../../constants/portfolio.constants';

const initialState = {
  idea: '',
  name: '',
  description: '',
  dateInit: null,
  dateEnd: null,
  author: '',
  loading: false,
  success: false,
  linkedIdeas: []
}

export function portfolioDialogProject(state = initialState, action) {
  switch (action.type) {
    case portfolioConstants.PORTFOLIO_LOAD_LINKED_IDEAS:
      return {
        ...state,
        linkedIdeas: action.payload,
        loading: false
      }

    case portfolioConstants.LOAD_CREATE_PROJECT_DIALOG:
      return {
        ...state,
        idea: state.linkedIdeas.filter(item => action.payload?.idea?._id === item.id)[0] || null, 
        name: action.payload.name,
        description: action.payload.description,
        dateInit: action.payload.dateInit,
        dateEnd: action.payload.dateEnd,
        author: action.payload.author,
      }

    case portfolioConstants.PORTFOLIO_CHANGE_FIELD_PROJECT:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      } 

    case portfolioConstants.CLEAR_CREATE_PROJECT:
      return {
        ...state,
        idea: '',
        name: '',
        description: '',
        dateInit: null,
        dateEnd: null,
        author: '',
        loading: false,
        success: false,
      };

    case portfolioConstants.PORTFOLIO_POST_CREATE_PROJECT_REQUEST:
    case portfolioConstants.DELETE_PORTFOLIO_PROJECT_REQUEST:
    case portfolioConstants.PORTFOLIO_PUT_UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true
      }

    case portfolioConstants.PORTFOLIO_POST_CREATE_PROJECT_SUCCESS:
    case portfolioConstants.DELETE_PORTFOLIO_PROJECT_SUCCESS:
    case portfolioConstants.PORTFOLIO_PUT_UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      }

    case portfolioConstants.PORTFOLIO_POST_CREATE_PROJECT_FAILURE:
    case portfolioConstants.DELETE_PORTFOLIO_PROJECT_FAILURE:
    case portfolioConstants.PORTFOLIO_PUT_UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false
      }

    
    case portfolioConstants.CLEAR_PORTFOLIO_DELETE_FLAGS: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    default: 
      return state;
      
  }
}