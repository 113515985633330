import { challengeService, userService } from '../services';
import { challengeConstants } from '../constants';
import { history } from '../helpers';
import { alertActions } from './alert.actions';

export const challengeActions = {
  getChallenges,
  getMoreChallenges,
  getChallengeById,
  getChallengeToEditById,
  getIdeasChallenge,
  updateChallenge,
  deleteChallenge,
  createChallenge,
  nextFormImage,
  setCriteria,
  setPropChallenge,
  setChallenge,
  setImagem,
  removeImage,
  removeImagemServer,
  imageNextCard,
  setDates,
  setDate,
  addAward,
  removeAward,
  setAward,
  setFilterColaborator,
  clearEdit,
  setWinners,
  postWinners,
  setWinnerCard,
  clear,
  clearSelectWinner,
  clearProgress,
  setImageToRemove,
  getChallengesActive
};

function getChallenges(page, sort, filter, limit) {
  return (dispatch) => {
    dispatch(request());
    challengeService
      .getChallenges(page, sort, filter, limit)
      .then((challenges) => {
        dispatch(success(challenges, page, filter[0].value, filter[1].value));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: challengeConstants.GET_CHALLENGES_REQUEST };
  }

  function success(challenges, page, query, status) {
    const payload = {
      challenges,
      page,
      query,
      status,
    };

    return { type: challengeConstants.GET_CHALLENGES_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_CHALLENGES_FAILURE, error };
  }
}

function getMoreChallenges(page, sort, filter, limit) {
  return (dispatch) => {
    challengeService
      .getChallenges(page, sort, filter, limit)
      .then((challenges) => {
        dispatch(success(challenges, page, filter[0].value, filter[1].value));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success(challenges, page, query, status) {
    const payload = {
      challenges,
      page,
      query,
      status,
    };

    return { type: challengeConstants.GET_MORE_CHALLENGES_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_MORE_CHALLENGES_FAILURE, error };
  }
}

function getChallengeById(id) {
  return (dispatch) => {
    challengeService
      .getChallengeById(id)
      .then((challenge) => {
        dispatch(success(challenge.data));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success(challenge) {
    const payload = {
      challenge,
    };
    return { type: challengeConstants.GET_CHALLENGE_BY_ID_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_CHALLENGE_BY_ID_FAILURE, error };
  }
}

function getChallengeToEditById(id) {
  return (dispatch) => {
    challengeService
      .getChallengeById(id)
      .then((challenge) => {
        dispatch(success(challenge.data));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success(challenge) {
    const payload = {
      challenge,
    };
    return { type: challengeConstants.GET_CHALLENGE_EDIT_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_CHALLENGE_EDIT_FAILURE, error };
  }
}

function getIdeasChallenge(id, page, sort, limit, status) {
  return (dispatch) => {
    challengeService
      .getIdeasChallenge(id, page, sort, limit, status)
      .then((ideas) => {
        dispatch(success(ideas));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success(ideas) {
    const payload = {
      ideas,
    };

    return { type: challengeConstants.GET_IDEAS_CHALLENGE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_IDEAS_CHALLENGE_FAILURE, error };
  }
}

function createChallenge(newChallenge, successMessage) {
  return (dispatch) => {
    dispatch(request());
    challengeService
      .createChallenge(newChallenge)
      .then((challenge) => {
        if (newChallenge.thumbnail.file) {
          challengeService
            .uploadImageChallenge('thumbnailFile', newChallenge.thumbnail, challenge.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        };

        if (newChallenge.banner.file) {
          challengeService
            .uploadImageChallenge('bannerFile', newChallenge.banner, challenge.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        };
        dispatch(success());
        dispatch(alertActions.success(successMessage));
        history.push('/challenges');
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: challengeConstants.POST_CHALLENGE_REQUEST }
  };

  function success() {
    return { type: challengeConstants.POST_CHALLENGE_SUCCESS };
  }

  function failure() {
    return { type: challengeConstants.POST_CHALLENGE_FAILURE };
  }
}

function updateChallenge(challenge, successMessage) {
  return (dispatch) => {
    challengeService
      .updateChallenge(challenge)
      .then(() => {

     if (challenge.thumbnail.file) {
          challengeService
            .uploadImageChallenge('thumbnailFile', challenge.thumbnail, challenge._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        } 


    if (challenge.banner.file) {
          challengeService
            .uploadImageChallenge('bannerFile', challenge.banner, challenge._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        };

        dispatch(success());
        dispatch(alertActions.success(successMessage));
        history.push('/challenges');
      })
      .catch((error) => dispatch(failure(error)));
  };

  function success() {
    return { type: challengeConstants.CLEAR_FORM };
  }

  function failure(error) {
    return { type: challengeConstants.UPDATE_CHALLENGE_FAILURE, error };
  }
}

function nextFormImage() {
  return (dispatch) => dispatch({ type: challengeConstants.NEXT_FORM_IMAGE });
}

function setCriteria(criteria) {
  const payload = {
    criteria,
  };
  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_CRITERIA, payload });
}

function setChallenge(challenge) {
  const payload = {
    challenge,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_CHALLENGE, payload });
}

function setPropChallenge(value, input) {
  const payload = {
    value,
    input,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_PROP_CHALLENGE, payload });
}

function setImagem(type, imagem) {
  const payload = {
    type,
    imagem,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_IMAGEM, payload });
}

function removeImage(type) {
  const payload = {
    type
  };
  return (dispatch) => dispatch({ type: challengeConstants.REMOVE_IMAGEM, payload });
}

function removeImagemServer(id, type) {
  return (dispatch) => {
    challengeService
      .removeImage(id, type)
      .then(() => {
        dispatch(success());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function success() {
    return { type: challengeConstants.REMOVE_IMAGEM };
  }
}

function imageNextCard() {
  return (dispatch) => dispatch({ type: challengeConstants.NEXT_CARD_IMAGE });
}

function setDates(dates) {
  const payload = {
    dates,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_DATES, payload });
}

function setDate(date, input) {
  const payload = {
    date,
    input,
  };

  return (dispatch) => dispatch({ type: challengeConstants.SET_DATE, payload });
}

function addAward() {
  return (dispatch) => dispatch({ type: challengeConstants.ADD_AWARD });
}

function removeAward(index) {
  const payload = {
    index,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.REMOVE_AWARD, payload });
}

function setAward(rewards) {
  const payload = {
    rewards,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_AWARD, payload });
}

function clearEdit() {
  return (dispatch) => dispatch({ type: challengeConstants.CLEAR_FORM });
}

function setFilterColaborator() {
  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_FILTER_COLABORATOR });
}

function setImageToRemove(type) {
  const payload = { type };
  return dispatch => dispatch({ type: challengeConstants.SET_IMAGE_TO_REMOVE,  payload});
}

function deleteChallenge(challenge) {
  return (dispatch) => {
    challengeService
      .deleteChallenge(challenge._id)
      .then(() => {
        dispatch(success());
        dispatch(alertActions.success('Seu desafio foi excluído!'));
        history.push('/challenges');
      })
      .catch((error) => dispatch(failure(error)));
  };

  function success() {
    return { type: challengeConstants.CLEAR_FORM };
  }

  function failure(error) {
    return { type: challengeConstants.UPDATE_CHALLENGE_FAILURE, error };
  }
}

function setWinnerCard(position, ideaName, id) {
  return (dispatch) => {
    userService.getUserById(id).then(
      (_user) => {  
        let url= _user.attachment?_user.attachment.url:'';
        dispatch(success({position, userName: _user.name, ideaName, url}));
      }
    );
  };

  function success(payload) {
    return { type: challengeConstants.SET_WINNER_CARD, payload };
  }
};

function setWinners(payload) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.SET_WINNERS, payload });
  }
}

function postWinners(winners) {
  return (dispatch) => {
    challengeService
      .postWinners(winners)
      .then(() => {
        dispatch(alertActions.success('As ideias premiadas foram salvas!'));
      }).catch((_error) => dispatch(alertActions.error('Erro configurar premiado')))
  };
}

function clear() {
  return (dispatch) => {
    dispatch({ type: challengeConstants.CLEAR });
  }
};

function clearSelectWinner(index) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.CLEAR_SELECT_WINNER, index });
  }
};
function clearProgress() {
  return dispatch => dispatch({ type: challengeConstants.CLEAR_PROGRESS });
};

function getChallengesActive() {
  return (dispatch) => {
    dispatch(request());
    challengeService
      .getChallengesActive()
      .then((challenges) => {
        dispatch(success(challenges));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: challengeConstants.GET_CHALLENGES_REQUEST };
  }

  function success(challenges) {
    const payload = {
      challenges,
    };

    return { type: challengeConstants.GET_CHALLENGES_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_CHALLENGES_FAILURE, error };
  }
}
