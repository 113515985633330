export const selectedLanguageDateInputFormat = (language) => {
  switch (language) {
    case 'en':
      return 'MM/dd/yyyy';
    case 'pt_BR':
      return 'dd/MM/yyyy';
    default:
      return 'dd/MM/yyyy';
  }
};
