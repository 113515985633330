import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Avatar, Box, Grid, Typography, LinearProgress} from '@material-ui/core';
import { styled, withStyles, makeStyles } from '@material-ui/core/styles';
import MuiCard from '@material-ui/core/Card';
import MuiAvatarGroup from '@material-ui/lab/AvatarGroup';
import { InsertInvitation } from '@material-ui/icons';

import { truncate } from '../../../helpers';
import CardMenu from '../CardMenu/CardMenu';
import { AvatarWithoutPhoto } from '../../Shared';

const useStyles = makeStyles((theme) => ({ 
    avatar: {
        width: 30,
        height: 30,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light
    },
    textDate: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.neutrals.low.medium
    },
    progressBar: {
      width: '85%',
      borderRadius: 4,
      height: 6,
    }
}));

const Card = styled(MuiCard)(({ theme }) => ({
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: 8,
    padding: theme.spacing(2),
    height: 200,
    transition: 'all .4s ease-in-out',
    '&:hover': {
        boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
    }
}));

const Title = styled(Typography)(({ theme }) => ({
    wordBreak: 'break-word',
    lineHeight: 1.4,
    color: theme.palette.neutrals.low.dark
}));

  const AvatarGroup = withStyles((theme) => ({
    avatar: {
        width: 30,
        height: 30,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        fontSize: 12,
        fontWeight: 600
    },
  }))(MuiAvatarGroup);

export function DataCard({ project }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const totalTasks = project.tasks.length;
    const concludedTasks = project.tasks.filter(item => item.taskStatus.code === "CONCLUDED").length;
    const progress = (concludedTasks/totalTasks) * 100;

    return (
        <Grid item xs={12}>
            <Card elevation={0}>
                <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
                    <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                        <Title variant="subtitle1">{truncate(project.name, 56)}</Title>
                        <CardMenu project={project}/>
                    </Box>
                    <Box>
                        <Typography variant="body2" title={project.description}>
                            {truncate(project.description, 46)}
                        </Typography>
                    </Box>

                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <LinearProgress variant="determinate" value={progress > 0 ? progress : 1 } className={classes.progressBar} />
                      <span>{concludedTasks}/{totalTasks}</span>
                    </Box>

                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box width='70%' display='flex' justifyContent='space-between' alignItems='center'>
                            <InsertInvitation />
                            <Box>
                                <Typography className={classes.textDate}>{t('inicio')}</Typography>
                                <span>{moment(project.dateInit).format("DD/MM/YYYY")}</span>
                            </Box>
                            <Box>
                                <Typography className={classes.textDate}>{t('termino')}</Typography>
                                <span>{moment(project.dateEnd).format("DD/MM/YYYY")}</span>
                            </Box>
                        </Box>
                        <Box display='flex' justifyContent='flex-end'>
                            <AvatarGroup max={2}>
                                <AvatarWithoutPhoto 
                                    userName={project.user.name} 
                                    src={project.user.attachment?.url} 
                                    className={classes.avatar} 
                                />
                            </AvatarGroup>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
    );
};