import { domainsConstants } from '../constants';
import { domainsService } from '../services';
import { ideaCreateActions } from './idea-create.actions';

export const domainsActions = {
  getDomains,
};

function getDomains() {
  return (dispatch) => {
    domainsService.getDomains().then(
      (domains) => {
        dispatch(success(domains));
        dispatch({ type: domainsConstants.BUL_IMAGINE_KAIZEN, domains });
        dispatch(ideaCreateActions.setMethodology(domains.methodology, 1));
        dispatch(setLocalsImage(domains.businessUnitLocals, 1));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(domains) {
    return { type: domainsConstants.DOMAINS_SUCCESS, domains };
  }

  function failure(error) {
    return { type: domainsConstants.DOMAINS_FAILURE, error };
  }
}

function setLocalsImage(businessUnitLocals, code) {
  return (dispatch) => {
    const payload = {
      businessUnitLocals,
      code,
    };

    dispatch({ type: domainsConstants.SET_LOCALS_IMAGINE, payload });
  };
}
