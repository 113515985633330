import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Grid, Container, makeStyles, CircularProgress } from '@material-ui/core';

import config from '../../config';
import { ChallengeFollowUp } from '../../components/Challenge/ChallengeFollowUp';
import { ChallengeDetailsMenu } from '../../components/Challenge/ChallengeDetailsMenu';
import { ChallengeDetailsContent } from '../../components/Challenge/ChallengeDetailsContent';
import { ChallengeAwardWinningIdeas } from '../../components/Challenge/ChallengeAwardWinningIdeas';
import { ChallengeIdeaTable } from '../../components/Tables/ChallengeIdeaTable';
import { challengeActions, ideaCreateDialogsActions, ideaCreateActions } from '../../actions';
import { BackNavAdmin, DialogAllCreateIdea, BackNavAdminButtons } from '../../components';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  containerLoading: {
    marginTop: theme.spacing(30),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function ChallengeDetailPage() {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabChosed, setTabChosed] = useState(0);
  const { 
    challengeDetail,
    challengeTableIdeas: { ideas, paging },
  } = useSelector((state) => state.challenge);
  const { typeProfile: { type } } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(challengeActions.getChallengeById(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    let status = []
    if (type === 'colaborator') {
      dispatch(challengeActions.setFilterColaborator());
       status = [
        'CONCLUDED',
        'PATENT_PENDING',
        'EXECUTION_QUEUE',
        'EXECUTION',
        'QUALIFIED',
      ];
    } else {
      status = [
        'CONCLUDED',
        'PATENT_PENDING',
        'EXECUTION_QUEUE',
        'EXECUTION',
        'QUALIFIED',
        'AWAITING_QUALIFICATION',
        'EXECUTING'
      ];
    }

    dispatch(
      challengeActions.getIdeasChallenge(
        id,
        1,
        paging.sort,
        paging.limit,
        ['CONCLUDED','AWAITING_QUALIFICATION','PATENT_PENDING','EXECUTION_QUEUE','EXECUTING','QUALIFIED']
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createIdea() {
    const challenge = {
      _id: challengeDetail._id,
      name: challengeDetail.name,
      createdAt: challengeDetail.createdAt,
      status: true,
    };

    dispatch(ideaCreateActions.setChallenge(challenge));
    dispatch(ideaCreateActions.setIdeaCreateChallenge());
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
  }

  return (
    <>
      <DialogAllCreateIdea challenge />
      {(challengeDetail._id != id) ? (
        <>
          <BackNavAdmin title={t('carregando...')} pathname="/challenges" />
          <Container maxWidth="lg" className={classes.containerLoading}>
            <CircularProgress />
          </Container>
        </>
      ) : (
        <>
          <BackNavAdmin title={challengeDetail.name} pathname="/challenges">
            <BackNavAdminButtons type={type} id={id} createIdea={createIdea} />
          </BackNavAdmin>


          <ChallengeDetailsMenu tabChosed={tabChosed} handleChangeTab={setTabChosed} type={type} />
        
          <Container maxWidth="lg" className={classes.container}>
            {tabChosed === 0 && (
              <ChallengeDetailsContent 
                id={id} 
                ideas={ideas} 
                challengeDetail={challengeDetail} 
                paging={paging} 
                type={type} 
              />
            )}
            {tabChosed === 1 && (
              <>
                <ChallengeFollowUp
                  daysAway={challengeDetail.daysAway}
                  dueDate={challengeDetail.dueDate}
                  daysLeft={challengeDetail.daysLeft}
                  allIdeas={challengeDetail.ideas}
                  ups={challengeDetail.ups}
                  comments={challengeDetail.comments}
                  topUser={challengeDetail.topUser}
                  topArea={challengeDetail.topArea}
                  totalParticipants={challengeDetail.totalParticipants}
                  totalIdeasInExecutionQueue={challengeDetail.totalIdeasInExecutionQueue}
                  totalIdeasInAwaitingQualification={challengeDetail.totalIdeasInAwaitingQualification}
                />
                {ideas && ideas.length > 0 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <ChallengeIdeaTable
                        ideas={ideas}
                        paging={paging}
                        id={id}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {config.SHOW_REWARD_FIELDS_ON_CHALLENGE && tabChosed === 2 && (
              <ChallengeAwardWinningIdeas
                id={id}
                ideas={ideas}
                paging={paging}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
}

export { ChallengeDetailPage };
