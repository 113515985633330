import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const faqService = {
  getAllQuestions,
  getQuestionById,
  createQuestion,
  editQuestion,
  deleteQuestion,
};

function getAllQuestions(page, sort, limit) {
  const url = `${config.URL_BASE
    }/faq/questions?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`;

  return axios.get(url, requestHeaders()).then(handleResponse);
}

function getQuestionById(id) {
  return axios.get(`${config.URL_BASE}/faq/questions/${id}`, requestHeaders())
    .then(handleResponse);
}

function createQuestion(questions = [{ title: '', description: '', _id: '' }]) {
  const promises = questions.map(({ title, description, _id }) => {
      if (_id.length === 24) {
          editQuestion({ title, description, _id });
        };
      if (_id < 24) {
      return axios.post(`${config.URL_BASE}/faq/questions`, { title, description }, requestHeaders());
    }
  });
  return Promise.all(promises);
}

function editQuestion(question) {
      return axios.put(`${config.URL_BASE}/faq/questions/${question._id}`, { title: question.title, description: question.description }, requestHeaders())
        .then(handleResponse);
}

function deleteQuestion(id) {
  return axios.delete(`${config.URL_BASE}/faq/questions/${id}`, requestHeaders())
    .then(handleResponse);
}
