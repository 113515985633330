import React from 'react'
import { Grid } from "@material-ui/core"

export const AttachmentVideo = ({ attachments }) => {
  const videos = attachments.filter((video) => video.mimeType.container === 'videos');

  return (
    videos.length > 0 && (
      <Grid item xs={12} key={videos[0]._id}>
        <video style={{ width: '100%' }} controls>
          <source src={videos[0].url} type={videos[0].mimeType.mimeType} />
          <object data={videos[0].url}>
            <embed src={videos[0].url}/>
          </object>
        </video>
      </Grid>
    )
  )
}
