import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiCard from '@material-ui/core/Card';
import { styled, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonEvaluationForm() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={0}>
          <Box display="flex" alignItems="center" marginBottom="20px">
            <Skeleton variant="circle" width={45} height={45} style={{ marginRight: 16 }} />
            <Skeleton variant="rect" width={150} height={45} />
          </Box>
          <Skeleton variant="rect" width={"100%"} height={53} />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="circle" width={45} height={45} style={{ marginRight: 16 }} />
                <Skeleton variant="rect" width={150} height={45} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width={"100%"} height={53} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width={"100%"} height={53} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width={200} height={45} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width={250} height={50} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}