import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import SaveIcon from '@material-ui/icons/Save';

import { infraCss } from './infraCss';
import { DialogResponsive } from './DialogResponsive';
import { infraStructureActions } from './../../actions';
import { useEffect } from 'react';
import { useState } from 'react';
import { infraStructureService } from '../../services';
import { useParams } from 'react-router-dom';

const filterOptions = createFilterOptions({ limit: 100 });

export const DialogAssociationInfra = ({ open, onClose }) => {
  const { id } = useParams();
  const classes = infraCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { locals, occupationAreas } = useSelector(state => state.domains);
  const [local, setLocal] = useState({});
  const [area, setArea] = useState({});
  const [businessUnit, setBusinessUnit] = useState({});


  useEffect(() => {
    infraStructureService.getById('businessUnit', id).then((data) => setBusinessUnit(data)).catch(error => console.error(error));
  }, []);

  function handleChangeAssociation(data) {
    setLocal(data.option);
  }

  function handleChangeAreaAssociation(data) {
    setArea(data.option);
  }

  function handleCloseDialog() {
    onClose();
  }

  function handleSubmit() {
    dispatch(infraStructureActions.postNewAssociation(local, area, businessUnit, id, t('os_vinculos_foram_salvos')));
    onClose();
    setTimeout(() => {
      setLocal({});
      setArea({});
    }, 500);
  }

  return (
    <DialogResponsive open={open} onClose={handleCloseDialog} title={t('vincular_infraestrutura')}>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {t('selecione_o_local_e_respectivas_areas_de_atuacao_a_serem_vinculadas_a_unidade')}{' '}{businessUnit?.name}
            </Typography>
          </Grid>
            <Grid item xs={6}>
                        <Autocomplete
                          loadingText={t('carregando...')}
                          noOptionsText={t('sem_opcoes')}
                          filterOptions={filterOptions}
                          options={locals}
                          loading={locals?.length === 0}
                          getOptionLabel={(input) => input?.name}
                          getOptionSelected={(option, value) => option?.name === value?.name}
                          onChange={(_e, _items, _options, details) => handleChangeAssociation(details)}
                          renderInput={(params) => <TextField {...params} label="local" variant="filled" />}
                          value={local}
                        />              
            </Grid>
            <Grid item xs={6}>
                              <Autocomplete
                          loadingText={t('carregando...')}
                          noOptionsText={t('sem_opcoes')}
                          filterOptions={filterOptions}
                          options={occupationAreas}
                          loading={occupationAreas?.length === 0}
                          getOptionLabel={(input) => input?.name}
                          getOptionSelected={(option, value) => option?.name === value?.name}
                          onChange={(_e, _items, _options, details) => handleChangeAreaAssociation(details)}
                          renderInput={(params) => <TextField {...params} label={t('area_de_ocupacao')} variant="filled" />}
                          value={area}
                        />
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              {t('salvar_vinculos')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogResponsive>
  );
};
