import React from 'react';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
    fontSize: 14.22,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 14.22,
  },
  text: {
    color: theme.palette.grey[500],
    fontSize: 14.22,
  },
  imageChildren: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3, 0),
  },
}));

function UploadFile({ onUpload, children, name, message, accept, maxSize }) {
  const classes = useStyles();

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <div className={classes.text}>{message}</div>;
    }

    if (isDragReject) {
      return <div className={classes.error}>Arquivo não suportado</div>;
    }

    return <div className={classes.success}>Solte a {name} aqui</div>;
  }

  return (
    <Dropzone accept={accept} maxSize={maxSize} onDropAccepted={onUpload}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div {...getRootProps()} style={{ cursor: 'pointer', outline: 0 }}>
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
          <div className={classes.imageChildren}>{children}</div>
        </div>
      )}
    </Dropzone>
  );
}

export { UploadFile };
