import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteIcon from '@material-ui/icons/Delete';

import { infraCss } from './infraCss';
import { DialogResponsive } from './DialogResponsive';
import { infraStructureActions } from './../../actions';

export const DialogDeleteInfra = ({ open, onClose, row }) => {
  const classes = infraCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedTab, filters: { dateSort } } = useSelector((state) => state.infraStructure);

  function handleSubmit() {
    dispatch(infraStructureActions.deleteInfraStructure({ id: row._id, type: row.type }, selectedTab, dateSort, t('infraestrutura_excluida')));
    onClose();
  }

  return (
    <DialogResponsive open={open} onClose={onClose} title={t('excluir_infraestrutura')}>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.subTitle}>
              {t('deseja_excluir_a_infraestrutura')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {t('as_informacoes_sobre')}<b>{` ${row.name}`} </b>{t('serao_excluídas_permanentemente_e_ela_sera_desvinculada')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t('voltar')}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              startIcon={<DeleteIcon />}
            >
              {t('excluir')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogResponsive>
  )
}
