import { generalEvaluationConstants } from '../../constants';
import { generalEvaluationService } from '../../services';

export const generalEvaluationFunctionsActions = {
  addNewCriteria,
  removeCriteria,
  setCriteria,
  changeFieldTitle,
  clearCriteriasToRemove,
  uploadCriteriaIcons,
  deleteFile,
  setCriteriaIcons,
  setTableFilter
}

function setCriteriaIcons(icons) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.SET_CRITERIA_ICON, payload: icons })
  }
}

function setCriteria(id, field, value) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.SET_CRITERIA, payload: { id, field, value } })
  }
}

function addNewCriteria() {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.EVALUATION_ADD_NEW_CRITERIA });
  }
}

function removeCriteria(id) {
  return dispatch => {
    dispatch({ type: generalEvaluationConstants.REMOVE_EVALUATION_CRITERIA, payload: { id } });
  }
}

function changeFieldTitle(name, value) {
  return dispatch => {
    dispatch({
      type: generalEvaluationConstants.CHANGE_FIELD_TITLE,
      payload: { name, value }
    });
  }
}


function clearCriteriasToRemove() {
  return dispatch => {
    dispatch({
      type: generalEvaluationConstants.CLEAR_CRITERIAS_TO_DELETE,
    });
  }
}

function uploadCriteriaIcons(icons) {
  return (dispatch) => {
    dispatch({ type: generalEvaluationConstants.UPLOAD_PHOTO_REQUEST });
    generalEvaluationService.uploadCriteriaIcon(icons).then(
      () => {
        dispatch({ type: generalEvaluationConstants.UPLOAD_PHOTO_SUCCESS });
      },
      (error) => {
        dispatch({ type: generalEvaluationConstants.UPLOAD_PHOTO_FAILURE });
        console.error(error);
      }
    );
  };
}

function deleteFile(id) {
  return dispatch => {
    dispatch({
      type: generalEvaluationConstants.DELETE_CRITERIA_IMAGE,
      payload: { id }
    });
  }
}

function setTableFilter(field, value) {
  return dispatch => {
    dispatch({
      type: generalEvaluationConstants.CHANGE_GENERAL_EVALUATION_TABLE_FILTERS,
      payload: { field, value }
    });
  }
}