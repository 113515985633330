import axios from 'axios';
import config from '../config';
import { requestHeaders } from '../helpers';

export const feedbackService = {
  sendFeedback,
  getFeedback
};

function sendFeedback(data) {
  return axios.post(`${config.URL_BASE}/user-feedback`, data, requestHeaders())
}

function getFeedback(page=1, sort={ createdAt: -1 }, limit=4) {
  return axios.get(`${config.URL_BASE}/user-feedback?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`, requestHeaders());
}