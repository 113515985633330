import update from 'react-addons-update';
import { delegateStepIdeaConstants } from '../constants';

const initialState = {
  observation: '',
  dialogType: '',
  businessUnitLocals: [],
  evaluationsTeamsIds: [],
  usersIds: [],
  users: [],
  userList: [{ id: "0" }],
  selectedStepId: null
};

export function delegateStepIdea(state = initialState, action) {
  switch (action.type) {

    case delegateStepIdeaConstants.SET_OBSERVATION:
      return (update(state, {
        observation: { $set: action.payload.observation }
      }));

    case delegateStepIdeaConstants.CLEAR_OBSERCATION:
      return (update(state, {
        observation: { $set: '' }
      }));

    case delegateStepIdeaConstants.CLEAR_USER_FIELDS:
      return (update(state, {
        userList: { $set: initialState.userList }
      }));

   case delegateStepIdeaConstants.CLEAR_USER_IDS:
      return (update(state, {
        usersIds: { $set: initialState.usersIds }
      }));

    case delegateStepIdeaConstants.SET_TYPE_DIALOG:
      return (update(state, {
        dialogType: { $set: action.payload.type }
      }));

    case delegateStepIdeaConstants.SET_DELEGATE_STEP_FLUX_BUSINESS_UNIT_LOCAL:
      return (update(state, {
        businessUnitLocals: { $set: action.payload.businessUnitLocalsId }
      }));

    case delegateStepIdeaConstants.SET_DELEGATE_STEP_FLUX_EVALUATION_TEAMS:
      return (update(state, {
        evaluationsTeamsIds: { $set: action.payload.evaluationsTeamsIds }
      }));

    case delegateStepIdeaConstants.GET_DELEGATE_STEP_FLUX_USERS:
      const dataUsers = action.payload.data.filter(user => user._id && user.name )     

      return (update(state, {
        users: { $set: dataUsers }
      }));

    case delegateStepIdeaConstants.SET_DELEGATE_STEP_FLUX_USERS:
      const existingUserOption = state.usersIds.some(
        (item) => item.fieldId === action.payload.option.fieldId
      );

      const listWithOutOptionExistent = state.usersIds.filter(({ fieldId }) => fieldId !== action.payload.option.fieldId);

      if (existingUserOption) {
          return (update(state, {
          usersIds: { $set: [...listWithOutOptionExistent, action.payload.option] }
        }));
      } else {
        return (update(state, {
          usersIds: { $push: [action.payload.option] }
        }));
      }

    case delegateStepIdeaConstants.DELEGATE_STEP_FLUX_ADD_USER_FIELD:
        const newItemList = { id: action.payload.id };

        return (update(state, {
          userList: { $push: [newItemList] }
        }));

    case delegateStepIdeaConstants.DELEGATE_STEP_FLUX_REMOVE_USER_FIELD:
        const filteredItemList = state.userList.filter(({id}) => id !== action.payload.id);
        const filteredUserIdList = state.usersIds.filter(({ fieldId }) => fieldId !== action.payload.id);

        return (update(state, {
          userList: { $set: filteredItemList},
          usersIds: { $set: filteredUserIdList }
        }));

    case delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SELECTED_IDEA:
      return {
        ...state,
        selectedStepId: action.payload.value
      }

    case delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_REQUEST:
      return {
        ...state,
        loading: true
      }

    case delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_FAILURE:
      return {
        ...state,
        loading: false
      }

    default:
      return state;
  }
}
