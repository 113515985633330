import React from 'react';
import {
	Card,
	CardActions,
	Collapse,
	CardContent,
	Grid,
	TextField,
	makeStyles,
} from '@material-ui/core';
import { HeaderCollapse } from './HeaderCollapse';
import { collapseCss } from './collapseCss';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { executionActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
	labelRoot: {
		fontWeight: 600,
		lineHeight: 1,
	},
	label: {
		color: theme.palette.neutrals.low.medium,
		fontSize: 12,
	},
}));


function ReadFinancialFeedback({
	expanded,
	estimatedFinancialReturn,
	financialReturn,
}) {
	const classes = useStyles();
	const collapseClasses = collapseCss();
	const { t } = useTranslation();

	const dispatch = useDispatch();

	function toggleExpand() {
		dispatch(executionActions.toggleFinancialFeedbackCollapse());
	}

	return (
		<Card>
			<CardActions className={collapseClasses.actions}>
				<HeaderCollapse
					title={t('retorno_financeiro')}
					icon={<AttachMoneyIcon color="primary" />}
					expanded={expanded}
					toggleExpand={toggleExpand}
				/>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent className={collapseClasses.content}>
					<Grid container spacing={3}>
						<Grid item xs={3}>
							<div className={classes.label}>
								{t('valor_estimado')} ({t('dinheiro')})
							</div>
							<TextField
								name="estimatedFinancialReturn"
								id="estimatedFinancialReturn"
								variant="filled"
								margin="normal"
								placeholder="0"
								fullWidth
								type="number"
								InputLabelProps={{
									classes: {
										root: classes.labelRoot,
									},
								}}
								defaultValue={estimatedFinancialReturn}
								disabled
							/>
						</Grid>
						<Grid item xs={3}>
							<div className={classes.label}>
								{t('valor_real')} ({t('dinheiro')})
							</div>
							<TextField
								name="financialReturn"
								variant="filled"
								margin="normal"
								placeholder="0"
								fullWidth
								type="number"
								InputLabelProps={{
									classes: {
										root: classes.labelRoot,
									},
								}}
								defaultValue={financialReturn}
								disabled
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Collapse>
		</Card>
	);
}

export { ReadFinancialFeedback };
