import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import MuiChip from '@material-ui/core/Chip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { styled, useTheme } from '@material-ui/core/styles'

import { tableCss } from './../Tables/TableCss';
import { CustomTooltip } from '../Shared';

export const GeneralEvaluationRow = ({ row }) => {
  const theme = useTheme();
  const history = useHistory();
  const tableClasses = tableCss();
  const { t } = useTranslation();

  function handleDirectToEvaluation(id) {
    history.push(`/preferences/general-evaluation/${id}`);
  }

  const Chip = styled(MuiChip)(({ theme }) => ({
    borderRadius: 4,
    fontsize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.neutrals.high.main
  }));

  return (
    <TableRow hover classes={{ root: tableClasses.tableRow }}>
      <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
        <CustomTooltip 
          title={row?.description} 
          aria-label={row?.description} 
          placement="bottom-start"
        >
          <Typography 
            variant="subtitle2" 
            className={tableClasses.link} 
            onClick={() => handleDirectToEvaluation(row?._id)}
          >
            {row?.description}
          </Typography>
        </CustomTooltip>
      </TableCell>
      <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>
        <Chip
          label={row?.status === true ? t('ativa') : t('inativa')} size="small"
          style={{
            background: row?.status === true 
              ? theme.palette.auxiliary.cyan.main
              : theme.palette.auxiliary.red.main
          }}
        />
      </TableCell>
    </TableRow>
  );
}
