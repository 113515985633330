import React, { useState, useEffect } from 'react'
import TextField from "@material-ui/core/TextField"
import { formatReal, formatToDolar } from '../../../helpers';

export const MoneyInput = (props) => {
  const [value, setValue] = useState("");
  const regexPattern = new RegExp(',', 'g');
  const regexPointPattern = new RegExp('[.]', 'g');

  function handleChange(e) {
    const formatedValue = e.target.value.replace(regexPointPattern, '').replace(regexPattern, '')
    console.log()
    setValue(formatReal(formatedValue))
    if (!isNaN(formatedValue) && e.target.value.toString().length <= 14) {
      props.onChange && props.onChangeParams && props.onChange(props.onChangeParams, formatedValue)
    }
  }

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [])

  return (
    <TextField { ...props } value={value} onChange={handleChange} />
  )
}
