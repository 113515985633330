import React, { useState } from 'react';
import {
  Card,
  CardActions,
  Box,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { HeaderCollapse } from './HeaderCollapse';
import { collapseCss } from './collapseCss';
import { useDispatch, useSelector } from 'react-redux';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { executionActions, ideaConclusionActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { formatReal } from "./../../helpers"

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  label: {
    color: theme.palette.neutrals.low.medium,
    fontSize: 12,
  },
}));

function FinancialFeedbackCollapse({ isConclusion, isResults = false }) {
  const classes = useStyles();
  const collapseClasses = collapseCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { estimatedFinancialReturn } = useSelector(state => state.execution)
  const { financialReturn } = useSelector(state => state.ideaConclusion)

  function handleFinancialReturn(value) {
    const formatedValue = value.replaceAll(",", "").replaceAll(".", "")
    if (!isNaN(formatedValue) && value.toString().length <= 14) {
      dispatch(executionActions.changeFinancialReturn(formatReal(formatedValue)))
    }
  }

  function handleConclusionFinancialReturn(value, name) {
    if (!isResults) {
      const formatedValue = value.replaceAll(",", "").replaceAll(".", "")
    if (!isNaN(formatedValue) && value.toString().length <= 14) {
        dispatch(ideaConclusionActions.setConclusionInput(formatReal(formatedValue), name))
      }
    }
  }

  return (
    <Card className={collapseClasses.card} elevation={0}>
      <CardActions className={collapseClasses.actions}>
        <HeaderCollapse
          title={t('retorno_financeiro')}
          icon={<AttachMoneyIcon color="primary" />}
        />
      </CardActions>
      <CardContent className={collapseClasses.content}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {isConclusion || isResults ? <>
                  <strong>{`${t('valor_estimado')} (${t('dinheiro')})`}</strong>
                  <Typography>{estimatedFinancialReturn || '0.00'}</Typography>
                </> : <TextField
              name="estimatedFinancialReturn"
              id="estimatedFinancialReturn"
              label={`${t('valor_estimado')} (${t('dinheiro')})`}
              variant="filled"
              placeholder="0"
              fullWidth
              type="text"
              onChange={(e) => handleFinancialReturn(e.target.value)}
              inputRef={{
                required: t('o_retorno_financeiro_e_obrigatorio'),
              }}
              value={estimatedFinancialReturn}
            />}
          </Grid>
          {(isConclusion || isResults) && (
            <Grid item xs={6}>
              {isResults ? <>
                  <strong>{`${t('valor_real')} (${t('dinheiro')})`}</strong>
                  <Typography>{financialReturn || '0.00'}</Typography>
                </> : <TextField
                name="financialReturn"
                id="financialReturn"
                label={`${t('valor_real')} (${t('dinheiro')})`}
                InputLabelProps={{shrink: true}}
                variant="filled"
                placeholder="0"
                fullWidth
                type="text"
                onChange={(e) => handleConclusionFinancialReturn(e.target.value, "financialReturn")}
                inputRef={{
                  required: t('o_retorno_financeiro_e_obrigatorio'),
                }}
                value={financialReturn}
              />}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export { FinancialFeedbackCollapse };
