import update from "react-addons-update";

const initialState = {
  video: {}
};

export function uploadsReducer(state = initialState, action) {
  switch (action.type) {
    case "UPLOADS_SET_VIDEO":
      return update(state, {
        video: { $set: action.payload.video },
      });

    default:
      return initialState;
  }
}