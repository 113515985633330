import React from 'react';
import banner from '../../assets/imgs/desafio_covid19.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  banner: {
    width: '100%',
  },
}));

function Banner() {
  const classes = useStyles();

  return (
    <div>
      <img src={banner} alt="Banner" className={classes.banner} />
    </div>
  );
}

export { Banner };
