import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import config from '../../config';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';
import { ChallengeRankCard } from './ChallengeRankCard';
import { ChallengeSchedule } from './ChallengeSchedule';
import { ChallengeInfoDetails } from './ChallengeInfoDetails';
import { ChallengeInfoAwardsCard } from './ChallengeInfoAwardsCard';
import { ChallengeIdeaTable } from '../Tables/ChallengeIdeaTable';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  },
  media: {
    height: 203,
    backgroundColor: theme.palette.neutrals.low.light,
    borderRadius: 8,
  },
}));

export function ChallengeDetailsContent({ id, challengeDetail, ideas, paging, type }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const imgUrl = challengeDetail?.banner?.url ? challengeDetail?.banner?.url : challengeDetail?.attachment?.url;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardMedia className={classes.media} image={ imgUrl || imageCard }/>
      </Grid>
      <Grid item xs={12} lg={config.SHOW_REWARD_FIELDS_ON_CHALLENGE === false ? 12 : 8}>
        <Card elevation={0} className={classes.card}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {challengeDetail && (
                <ChallengeInfoDetails challenge={challengeDetail} />
              )}
            </Grid>
            <ChallengeSchedule challenge={challengeDetail} />
          </Grid>
        </Card>
      </Grid>
      {config.SHOW_REWARD_FIELDS_ON_CHALLENGE && (
        <Grid item xs={12} lg={4}>
          {challengeDetail?.rewards?.every(reward => reward.win) && (
            <ChallengeRankCard challenge={challengeDetail} />
          )}
          <ChallengeInfoAwardsCard challenge={challengeDetail} />
        </Grid>
      )}
      <Grid item xs={12}>
        {ideas && ideas.length > 0 && (
          <ChallengeIdeaTable id={id} ideas={ideas} paging={paging} userRule={type} />
        )}
      </Grid>
    </Grid>
  );
}