import { portfolioConstants } from '../../constants';
import { portfolioService } from '../../services';
import { alertActions } from './../alert.actions';
import { portfolioDetailsActions } from "./portfolio-details.actions"
import { history } from './../../helpers';

export const portfolioDialogTeamsActions = {
  changeFieldTeam,
  clearFieldTeam,
  addNewParticipant,
  removeParticipant,
  clearDialogTeam,
  loadDialogTeams,
  updateTeam
}


function changeFieldTeam(data, index) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_CHANGE_FIELD_TEAM,
      payload: { data, index }
    });
  }
}

function clearFieldTeam(index) {
  return (dispatch) => {
    dispatch({
      type: portfolioConstants.CLEAR_PORTFOLIO_FIELD_TEAM,
      payload: index
    })
  }
}

function addNewParticipant() {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_ADD_NEW_PARTICIPANT })
  }
}

function removeParticipant(value) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_REMOVE_PARTICIPANT,
      payload: value
    })
  }
}

function clearDialogTeam() {
  return dispatch => {
    dispatch({ type: portfolioConstants.CLEAR_PORTFOLIO_DIALOG_TEAM })
  }
}

function loadDialogTeams(teams) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.LOAD_CREATE_TEAM_DIALOG,
      payload: teams.map(item => ({
        name: item.user.name,
        value: item.user._id
      }))
    })
  }
}

function updateTeam(projectId, data, successMessage) {
  return dispatch => {
    dispatch({ 
      type: portfolioConstants.PORTFOLIO_UPDATE_TEAMS_REQUEST,
      payload: data
    });

    portfolioService.updateTeam(projectId, data)
      .then(({data}) => {
        dispatch({ 
          type: portfolioConstants.PORTFOLIO_UPDATE_TEAMS_SUCCESS,
          payload: data
        });
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioDetailsActions.getTeam(projectId))
      })
      .catch(error => { 
      console.error(error);
      dispatch({ type: portfolioConstants.PORTFOLIO_UPDATE_TEAMS_FAILURE });
    })
  }
};