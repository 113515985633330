import React from 'react';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0),
    },
    display: 'flex',
    alignItems: 'center',
  },
}));

function BackNav({ title, pathname, className }) {
  const classes = useStyles();


  function backHandle(e, pathname) {
    e.stopPropagation();

    if (pathname) {
      history.push(pathname);
    } else {
      history.goBack();
    }
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={(e) => backHandle(e, pathname)}>
        <ArrowBackIcon color="primary" />
      </IconButton>
      <Typography variant="h2">
        {title}
      </Typography>
    </div>
  );
}

export { BackNav };
