export const ideaStepFluxConstants = {
    CREATE_IDEA_STEP_FLUX_REQUEST: 'CREATE_IDEA_STEP_FLUX_REQUEST',
    CREATE_IDEA_STEP_FLUX_SUCCESS: 'CREATE_IDEA_STEP_FLUX_SUCCESS',
    CREATE_IDEA_STEP_FLUX_FAILURE: 'CREATE_IDEA_STEP_FLUX_FAILURE',
    DELETE_IDEA_STEP_FLUX_REQUEST: 'DELETE_IDEA_STEP_FLUX_REQUEST',
    DELETE_IDEA_STEP_FLUX_SUCCESS: 'DELETE_IDEA_STEP_FLUX_SUCCESS',
    DELETE_IDEA_STEP_FLUX_FAILURE: 'DELETE_IDEA_STEP_FLUX_FAILURE',
    SET_IDEA_STEP_FLUX_DATA: 'SET_IDEA_STEP_FLUX_DATA',
    CLEAR_IDEA_STEP_FLUX_DATA: 'CLEAR_IDEA_STEP_FLUX_DATA',
    SET_NEW_CONTENT: "SET_NEW_CONTENT",
    SET_NEW_CONTENT_SWITCH: "SET_NEW_CONTENT_SWITCH",
    SET_DECISION_NAME: "SET_DECISION_NAME",
    SET_EVALUATION_LEVEL: "SET_EVALUATION_LEVEL",
    SET_NOTIFICAITON_LEVEL: "SET_NOTIFICAITON_LEVEL",
    SET_EVALUATION_TEAMS: "SET_EVALUATION_TEAMS",
    EDIT_IDEA_STEP_FLUX_REQUEST: 'EDIT_IDEA_STEP_FLUX_REQUEST',
    EDIT_IDEA_STEP_FLUX_SUCCESS: 'EDIT_IDEA_STEP_FLUX_SUCCESS',
    EDIT_IDEA_STEP_FLUX_FAILURE: 'EDIT_IDEA_STEP_FLUX_FAILURE',
    LOAD_STEP_DETAILS: 'LOAD_STEP_DETAILS',
    SET_IDEA_CONTRIBUTORS_CAN_APPROVE_STEP: 'SET_IDEA_CONTRIBUTORS_CAN_APPROVE_STEP',
    SET_VISUALIZATION_CONFIG: 'SET_VISUALIZATION_CONFIG',
    PUSH_NEW_DECISION: 'PUSH_NEW_DECISION',
    SET_DECISION_NOTIFICATION: 'SET_DECISION_NOTIFICATION',
    UPDATE_GENERIC_DECISION: 'UPDATE_GENERIC_DECISION',
    UPDATE_GENERIC_TITLE: 'UPDATE_GENERIC_TITLE'
}