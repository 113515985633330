import update from "react-addons-update";
import { ideaStepFluxUpdateConstants } from "../constants";

const initialStateEditFlux = {
  _id: "",
  nextStepWhenApproved: "",
  nextStepWhenRejected: "",
  nextStepWhenPendingReview: null,
  isGeneric: false
}

export function ideaStepFluxUpdate(state = initialStateEditFlux, action) {
  switch (action.type) {
    case ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_IS_GENERIC:
      return {
        ...state,
        isGeneric: action.payload.checked
      };

    case ideaStepFluxUpdateConstants.SET_EDIT_IDEA_STEP_FLUX_ASSOCIATIONS_STATE:
      return update(state, {
        _id: { $set: action.payload._id },
        nextStepWhenApproved: { $set: action.payload.nextStepWhenApproved },
        nextStepWhenRejected: { $set: action.payload.nextStepWhenRejected },
        nextStepWhenPendingReview: { $set: action.payload.nextStepWhenPendingReview },
      })

    case ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_ASSOCIATIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case ideaStepFluxUpdateConstants.SET_NEW_CONTENT_FLUX:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });

    case ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_ASSOCIATIONS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_CLEAR_ALL:
      return initialStateEditFlux;

    default: {
      return state;
    }
  }
}