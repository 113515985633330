import React, { useCallback, useEffect, useState } from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Hidden
} from '@material-ui/core';
import {
  BackNavAdmin as BackNavWithLineBotton,
  CustomSelect,
  TutoriaisTable,
  FirstAccess,
  Administrators,
  SidebarHelpCenter,
} from '../../components';

import { optionConstants } from '../../constants';
import { useTranslation } from 'react-i18next';
import { FaqPage } from '../FaqPage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.neutrals.low.medium,
    height: '80vh',
    textTransform: 'lowercase'
  },
  grid: {
    padding: 16,
    paddingLeft: 0,
    paddingRight: 0,
    height: '100%',
  },
  sections: {
    height: '100%',
  },
}));

function HelpCenterPage(props) {
  const classes = useStyles();
  const [currentlyTab, setCurrentlyTab] = useState('primeiro-acesso')
  const { t } = useTranslation();

  function handleChangeCurrentlyTab(tabChosed) {
    setCurrentlyTab(tabChosed)
  }

  const handleTabChosed = useCallback((Tab) => {
    switch (Tab) {
      case 'tutoriais':
        return (
          <TutoriaisTable />
        )
      case 'primeiro-acesso':
        return (
          <FirstAccess />
        )
      case 'administradores':
        return (
          <Administrators />
        )
      case 'faq':
        return (
          <FaqPage />
        )
      default:
        return (
          <div></div>
        )
    }
  }, [])

  useEffect(() => {
    handleTabChosed(currentlyTab)
  }, [currentlyTab, handleTabChosed])

  function formatName(name) {
    switch (name) {
      case 'primeiro-acesso':
        return 'Primeiro Acesso'

      case 'tutoriais':
        return 'Tutoriais'

      case 'administradores':
        return 'Administradores'

      default:
        return
    }
  }

  return (
    <>
      <BackNavWithLineBotton title={t('central_de_ajuda')} complementOfTitle={` - ${formatName(currentlyTab)}`} separetor />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={4} className={classes.sections}>
          <Hidden smDown>
            <Grid item xs={3}>
              <SidebarHelpCenter
                currentlyTab={currentlyTab}
                handleChangeCurrentlyTab={handleChangeCurrentlyTab}
              />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12}>
              <CustomSelect
                optionsAvaiable={[
                  optionConstants.PRIMEIROACESSO,
                  optionConstants.TUTORIAIS,
                  optionConstants.ADMINISTRADORES,
                  optionConstants.FAQ
                ]}
                startOption={optionConstants.PRIMEIROACESSO}
                handlePage={handleChangeCurrentlyTab}
              />
            </Grid>
          </Hidden>
          <Grid className={classes.grid} xs={11} md={9}>
            {!!currentlyTab && handleTabChosed(currentlyTab)}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { HelpCenterPage };
