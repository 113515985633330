import update from 'react-addons-update';
import { infraStructureConstants } from '../constants';

const initialStateAssociations = {
	isToEdit: false,
	local: {
		name: '',
		_id: ''
	},
	occupationArea: {
		name: '',
		_id: ''
	}
}

const initialState = {
	newInfraStructure: {
		type: '',
		name: ''
	},
	selectedTab: 'businessUnit',
	infraStructures: [],
	associations: [],
	associationsToDialog: [],
	newAssociations: [initialStateAssociations],
	filters: {
		dateSort: 'DESC',
		types: ['businessUnit', 'local', 'occupationArea'],
	},
	paging: {
		limit: 10,
		page: 1,
		skip: 0,
		total: 0,
		query: '',
	},
	loading: false,
	loadingAssociations: false,
	idsToRemove: []
}

export function infraStructure(state = initialState, action) {
	switch (action.type) {

		case infraStructureConstants.REQUEST_ALL_ASSOCIATIONS:
			return {
				...state,
				loading: true
			}

		case infraStructureConstants.FAILURE_ALL_ASSOCIATIONS:
			return {
				...state,
				loading: false
			}

		case infraStructureConstants.GET_ALL_ASSOCIATIONS:
			return {
				...state,
				associations: action.payload.data,
				paging: action.payload.paging,
				loading: false
			}


		case infraStructureConstants.CHANGE_INFRASTRUCTURE_FIELD_NAME:
			return {
				...state,
				newInfraStructure: {
					...state.newInfraStructure,
					[action.payload.name]: action.payload.value,
				}
			}

		case infraStructureConstants.CLEAR_INFRASTRUCTURE_DIALOG:
			return {
				...state,
				newInfraStructure: {
					...state.newInfraStructure,
					type: '',
					name: ''
				}
			}

		case infraStructureConstants.LOAD_INFRASTRUCTURE_DIALOG:
			return {
				...state,
				newInfraStructure: {
					...state.newInfraStructure,
					type: action.payload.type,
					name: action.payload.name
				}
			}

		case infraStructureConstants.LOAD_INFRASTRUCTURE_ASSOCIATIONS:
			return {
				...state,
				newAssociations: action.payload,
				loadingAssociations: false
			}

		case infraStructureConstants.CHANGE_INFRASTRUCTURE_FIELD_ASSOCIATION:
			return {
				...state,
				newAssociations: state.newAssociations.map((item, index) => {
					if (index === action.payload.index) {
						return {
							...item,
							isToEdit: true,
							[action.payload.name]: action.payload.value,
						}
					}
					return item
				})
			}

		case infraStructureConstants.ADD_NEW_INFRASTRUCTURE_ASSOCIATION:
			return {
				...state,
				newAssociations: [
					...state.newAssociations,
					initialStateAssociations,
				]
			}

	  case infraStructureConstants.REQUEST_DELETE_BIND:
			return {
				...state,
				loadingAssociations: true
			}

    case infraStructureConstants.REMOVE_INFRASTRUCTURE_ASSOCIATION:
      const filteredAssociations = state.newAssociations.filter((item, i ) => i !== action.payload.index);
      const idsToRemove = [...state.idsToRemove, action.payload.id];

      return update(state, {
        idsToRemove: { $set: idsToRemove },
        newAssociations: { $set: filteredAssociations },
        loadingAssociations: { $set: false }
      });

    case infraStructureConstants.CLEAR_INFRASTRUCTURE_ASSOCIATIONS:
      return {
        ...state,
        newAssociations: [initialStateAssociations]
      }

		case infraStructureConstants.CREATE_INFRASTRUCTURE_FAILURE:
		case infraStructureConstants.UPDATE_INFRASTRUCTURE_FAILURE:
		case infraStructureConstants.DELETE_INFRASTRUCTURE_FAILURE:
		case infraStructureConstants.GET_INFRASTRUCTURE_FAILURE:
			return {
				...state,
				loading: false
			}

		case infraStructureConstants.GET_INFRASTRUCTURE_SUCCESS:
			return {
				...state,
				infraStructures: action.payload.infras,
				paging: action.payload.paging,
				loading: false
			}

		case infraStructureConstants.GET_INFRASTRUCTURE_ASSOCIATION_REQUEST:
		case infraStructureConstants.POST_INFRASTRUCTURE_ASSOCIATION_REQUEST:
			return {
				...state,
				loadingAssociations: true
			}

		case infraStructureConstants.GET_INFRASTRUCTURE_ASSOCIATION_SUCCESS:
			return {
				...state,
				newAssociations: action.payload,
				loadingAssociations: false
			}

		case infraStructureConstants.POST_INFRASTRUCTURE_ASSOCIATION_SUCCESS:
			return {
				...state,
				loadingAssociations: false
			}

		case infraStructureConstants.GET_INFRASTRUCTURE_ASSOCIATION_FAILURE:
		case infraStructureConstants.POST_INFRASTRUCTURE_ASSOCIATION_FAILURE:
			return {
				...state,
				loadingAssociations: false
			}

		case infraStructureConstants.SET_PAGING:
			return (update(state, {
				paging: {
					[action.payload.type]: { $set: action.payload.value }
				}
			}));

		case infraStructureConstants.CHANGE_SELECTED_TAB:
			return {
				...state,
				selectedTab: action.payload
			}

		case infraStructureConstants.CHANGE_INFRA_CLASSIFICATION_FILTER:
			return {
				...state,
				filters: {
					...state.filters,
					dateSort: action.payload,
				}
			}

		case infraStructureConstants.CLEAR_IDS_TO_REMOVE:
			return {
				...state,
				idsToRemove: initialState.idsToRemove
			}

		default:
			return state;
	}
}