import React from 'react';
import clsx from "clsx";
import { Box, Card, Grid, makeStyles, withStyles } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(2),
  },
  gridItemTitle: {
    height: 60
  },
  gridItemInfo: {
    marginTop: theme.spacing(2)
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
})) (MuiSkeleton);

export function SkeletonPortfolioHome() {
  const classes = useStyles();
  const arrayCells = Array.from(Array(3));

  return (
    <Grid container spacing={3} style={{ flexGrow: 1 }}>
      {arrayCells.map((item, index) => (
        <Grid container item xs={12} md={4} key={index}>
          <Grid item xs={12}>
            <Card elevation={0} className={clsx(classes.card, classes.flex, classes.gridItemTitle)}>
              <Skeleton variant="text" width={'50%'} height={50} />
              <Skeleton variant="circle" width={25} height={25} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={0} className={clsx(classes.card, classes.gridItemInfo)}>
              <Box className={classes.flex}>
                <Skeleton variant="text" width={'80%'} height={40} />
                <Skeleton variant="text" width={'10%'} height={40} />
              </Box>
              <Skeleton variant="text" width={'100%'} height={40} />
              <Skeleton variant="text" width={'100%'} height={40} />
              <Box className={classes.flex}>
                <Skeleton variant="text" width={'40%'} height={40} />
                <Skeleton variant="text" width={'40%'} height={40} />
                <Skeleton variant="circle" width={25} height={25} />
              </Box>
            </Card>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}