import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  makeStyles,
  useTheme,
  Box,
  Divider,
} from '@material-ui/core';
import infoSvg from '../../assets/imgs/info.svg';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTitle: {
    fontSize: 20,
    color: 'dialogTitle',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0),
  },
  dialogDescription: {
    fontSize: 14,
    color: theme.palette.neutrals.low.medium,
  },
  dialogActions: {
    padding: theme.spacing(4, 0),
    justifyContent: 'space-between',
  },
}));

function DialogImagineLight({ open, onClose, title, subtitle, description, children }) {
  const classes = useStyles();
  const theme = useTheme();

  function handleCloseDialog() {
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogContent>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogTitle}>{title}</Box>
          <IconButton style={{width: 24, height: 24}} onClick={handleCloseDialog}>
            <CloseIcon style={{ color: theme.palette.neutrals.low.medium }} />
          </IconButton>
        </Box>
        <Divider />
        <Box className={classes.dialogContent}>
          <Box className={classes.dialogTitle}>{subtitle}</Box>
          <Box className={classes.dialogDescription}>{description}</Box>
        </Box>
        <DialogActions className={classes.dialogActions}>
          {children}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export { DialogImagineLight };
