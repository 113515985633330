import { imageUploaderConstants } from "../constants/imageUploader.constants";

export const imageUploaderActions = {
  setImage,
  removeImage,
  clearImageUploader
};

function setImage(image, typeimage="newImage") {
  return (dispatch) =>
    dispatch({
      type: imageUploaderConstants.IMAGEUPLOAD_SET_IMAGE, payload: {
        typeimage,
        image,
      }
    });
};

function removeImage(typeimage="newImage") {
  return (dispatch) => dispatch({ type: imageUploaderConstants.IMAGEUPLOAD_REMOVE_IMAGE, payload: { typeimage } });
};

function clearImageUploader() {
  return (dispatch) => {
      dispatch({ type: imageUploaderConstants.IMAGEUPLOAD_CLEAR });
  };
};
