import { notificationConstants } from '../constants';
import update from 'react-addons-update';

const initialState = {
  notifications: [],
  loading: false,
  paging: {
    page: 1,
    sort: {
      createdAt: -1,
    },
    skip: 0,
    total: 0,
    limit: 10,
  },
};

export function notification(state = initialState, action) {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATION_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case notificationConstants.GET_NOTIFICATION_SUCCESS:
      return update(state, {
        notifications: { $set: action.payload.notifications },
        paging: {
          total: { $set: action.payload.paging.total },
          page: { $set: 2 },
        },
        loading: { $set: false },
      });

    case notificationConstants.GET_NOTIFICATION_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case notificationConstants.LOAD_MORE_NOTIFICATION_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case notificationConstants.LOAD_MORE_NOTIFICATION_SUCCESS:
      return update(state, {
        notifications: { $push: action.payload.notifications },
        paging: {
          total: { $set: action.payload.paging.total },
          page: { $set: action.payload.paging.page + 1 },
        },
        loading: { $set: false },
      });

    case notificationConstants.LOAD_MORE_NOTIFICATION_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case notificationConstants.UPDATE_VISUALIZED_NOTIFICATION_SUCCESS:
      return update(state, {
        paging: {
          total: { $set: 0 },
        },
      });

    case notificationConstants.UPDATE_VISUALIZED_NOTIFICATION_FAILURE:
      return state;

    case notificationConstants.MARK_ALL_NOTIFICATION_READ_SUCCESS:
      return state;

    case notificationConstants.MARK_ALL_NOTIFICATION_READ_FAILURE:
      return state;

    case notificationConstants.CHECK_ACTION_SUCCESS:
      const notifications = state.notifications.filter(
        (notification) => notification._id !== action.payload.notification._id
      );

      return update(state, {
        notifications: { $set: notifications },
      });

    case notificationConstants.CHECK_ACTION_FAILURE:
      return state;

    default:
      return state;
  }
}
