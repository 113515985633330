import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Button,
  Container,
} from "@material-ui/core";
import { BackNavAdmin } from "./../../components";
import { FileCopyOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { reportActions, challengeActions } from "./../../actions";
import { ReportHeader } from "./ReportHeader";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { formatDate } from '../../utils/date';
import config from '../../config';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
  buttonsNavbar: {
    color: theme.palette.neutrals.low.main,
    borderColor: theme.palette.neutrals.low.main,
    "&:not(:first-child)": {
      marginLeft: 16
    }
  },
}))

export const ReportLayout = ({ children, title, tableInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const componentToPrint = useRef();
  const { imagineLocals, statusIdea, personalizedClassifications } = useSelector(state => state.domains)
  const {
    paging,
    filters,
    report,
    csvLoading,
    csvBase64,
    tab,
    chart,
    requestParams
  } = useSelector(state => state.report);
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps)
  const { challenges, loading, paging: { sort, filter } } = useSelector((state) => state.challenge);
  const [splittedPathname] = useState(history.location.pathname.split("/"))
  const { ideasStepsFlux } = useSelector(state => state.domains);  
  const { t } = useTranslation();


  const ideaStatusReportType = config.SHOW_IDEAS_BY_STEP_FLUX ? 'ideas-by-step' : splittedPathname[splittedPathname.length - 1];

  const financialReturnReportType = config.SHOW_IDEAS_BY_STEP_FLUX ? 'ideas-financial-return-by-step' : splittedPathname[splittedPathname.length - 1];

 const reportTypeText = (() => {
    switch(splittedPathname[splittedPathname.length - 1]) {
      case 'ideas-status':
        return ideaStatusReportType;

      case 'ideas-financial-return':
        return financialReturnReportType;

      default:
        return splittedPathname[splittedPathname.length - 1]
    }
  })();

  const isFieldSteps = config.SHOW_IDEAS_BY_STEP_FLUX ? 'stepsIdeas' : 'statusCode';


  useEffect(() => {
    if (imagineLocals) {
      let filteredLocals = [...new Set(imagineLocals.map(item => ({
        value: item.local._id,
        label: item.local.name,
        checked: false,
      })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

      dispatch(reportActions.loadLocal([
        {
          value: "SELECT_ALL",
          label: "Selecionar Todos",
          checked: false,
        },
        ...filteredLocals,
      ]))
    }
  }, [imagineLocals, dispatch])

  useEffect(() => {

if (config.SHOW_IDEAS_BY_STEP_FLUX ){

const steps = ideasStepsFlux.map((b) => ({
        label: b.name,
        value: b._id,
        checked: true
      }));
dispatch(reportActions.loadStatus([{
        checked: true,
        label: t('selecionar_todos'),
        value: 'SELECT_ALL'
      }, ...steps]));
} else {
    if (statusIdea) {
      const statusFiltered = statusIdea.filter(item => item.code !== "DRAFT").map(item => ({
        value: item.code,
        label: item.name,
        checked: true,
      }));
      const selectAllOption = {
        checked: true,
        label: t('selecionar_todos'),
        value: 'SELECT_ALL'
      };
      dispatch(reportActions.loadStatus([selectAllOption,...statusFiltered]))
    }
}
  }, [statusIdea, dispatch])

  useEffect(() => {
    if(personalizedClassifications) {
      const personalizedClassificationsFiltered = personalizedClassifications.map((item) => ({
        value: item._id,
        label: item.name,
        checked: false,
      }));
      const selectAllOption = {
        checked: false,
        label: t('selecionar_todos'),
        value: 'SELECT_ALL'
      };
      dispatch(reportActions.loadPersonalizedClassifications([selectAllOption,...personalizedClassificationsFiltered]));
    }
  }, [personalizedClassifications, dispatch]);

  useEffect(() => {
    if (!loading) {
      dispatch(challengeActions.getChallenges(1, sort, filter, 100));
    }
  }, [dispatch]);

  useEffect(() => {
    if (challenges) {
      dispatch(reportActions.loadChallenge(challenges.map(item => ({
        value: item._id,
        label: item.name,
        checked: false,
      }))))
    }
  }, [challenges, dispatch]);

  useEffect(() => {
      dispatch(reportActions.loadProperties(tableInfo.map(item => ({
        value: item?.id,
        label: item?.label,
        checked: true,
      }))))
  }, [dispatch]);

  useEffect(() => {
    let params = {
      challenge: filters.challenges.filter(item => item.checked).map(item => item.value),
      [isFieldSteps]: filters.status.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      personalizedClassifications: filters.personalizedClassifications.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      startDate: formatDate(filters.startDate),
      endDate: formatDate(filters.endDate),
      reportType: reportTypeText,
      bulWithoutUsers: filters.bulWithoutUsers,
      ideasUngrouped: filters.ideasUngrouped,
      ideaDescriptionOnCsv: filters.ideaDescriptionOnCsv,
      useConclusionDate: filters.useConclusionDate,
      monthYear: moment(filters.monthYear).format('MM/yyyy'),
      searchText: filters.query
    }

    if (tab === "graph" && report.length > 0 && chart.length === 0) {
      params = {
        ...params,
        page: -1,
      }
      dispatch(reportActions.getReportDataChart(params))
    }

    if (tab === "table" && chart.length > 0 && report.length === 0) {
      params = {
        ...params,
        page: 1,
        query: '',
        limit: paging.limit,
      }
      dispatch(reportActions.getReportData(Object.keys(requestParams).length !== 0 ? requestParams : params))
    }
  }, [tab])

  useEffect(() => {
    if (csvBase64) {
      const linkSource = `data:text/csv;base64,${csvBase64.substring(csvBase64.indexOf(";") + 1)}`;
      const downloadLink = document.createElement("a");
      const fileName = getCsvname();
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [csvBase64])

  function handleClickDownloadCsv() {
    let csvParams;
    if (reportTypeText === 'colaborator-access-by-month') {
      csvParams = {
        businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
        locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
        occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
        delimiter: ";",
        page: -1,
        reportType: reportTypeText,
        monthYear: moment(filters.monthYear).format('MM/yyyy'),
        searchText: filters.query
      }
    } else {
      csvParams = {
      challenge: filters.challenges.filter(item => item.checked).map(item => item.value),
      [isFieldSteps]: filters.status.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      personalizedClassifications: filters.personalizedClassifications.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      startDate: formatDate(filters.startDate),
      endDate: formatDate(filters.endDate),
      page: -1,
      delimiter: ";",
      reportType: reportTypeText,
      bulWithoutUsers: filters.bulWithoutUsers,
      ideasUngrouped: filters.ideasUngrouped,
      ideaDescriptionOnCsv: filters.ideaDescriptionOnCsv,
      useConclusionDate: filters.useConclusionDate,
        searchText: filters.query
      }
    }
    const { limit, ...restParams } = requestParams;
    dispatch(reportActions.getReportCsv(Object.keys(requestParams).length !== 0 ? { ...restParams, page: -1 } : csvParams));
  }

  const getCsvname = () => {
    return splittedPathname[splittedPathname.length - 1] + '.xlsx';
  }

  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <BackNavAdmin title={title}>
          <Box display="flex" alignItems="center">
            {/*  <Button
              className={classes.buttonsNavbar}
              variant="outlined"
              startIcon={<Print />}
              onClick={handlePrint}
            >
              {t('imprimir')}
            </Button> */}
            <Button
              className={classes.buttonsNavbar}
              variant="outlined"
              disabled={report.length === 0 || csvLoading}
              startIcon={<FileCopyOutlined />}
              onClick={handleClickDownloadCsv}
            >
              export excel
            </Button>
          </Box>
        </BackNavAdmin>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth="lg" className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ReportHeader properties={tableInfo} />
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}
