import { contentsConstants } from '../constants';

const INITIAL_STATE = {
  fixedContent: false,
  fixedLoading: false,
  contents: [],
  contentLoading: false,
};

export function contents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case contentsConstants.GET_FIXED_CONTENT_REQUEST:
      return {
        ...state,
        fixedLoading: true
      }

    case contentsConstants.GET_FIXED_CONTENT_SUCCESS:
      return {
        ...state,
        fixedLoading: false,
        fixedContent: action.payload
      }

    case contentsConstants.GET_FIXED_CONTENT_FAILURE:
      return state;

    case contentsConstants.GET_ALL_CONTENT_REQUEST:
      return {
        ...state,
        contentLoading: true
      }

    case contentsConstants.GET_ALL_CONTENT_SUCCESS:
      return {
        ...state,
        contentLoading: false,
        contents: action.payload
      }

    case contentsConstants.GET_ALL_CONTENT_FAILURE:
      return {
        ...state,
        contentLoading: false,
      }
   
    default: {
      return state;
    }
  }
}
