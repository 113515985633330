import { portfolioConstants } from "../../constants";
import { portfolioService } from "../../services";

export const portfolioExecutionFlow = {
    getProjects
};

function getProjects(id) {
    return (dispatch) => {
        dispatch({ type: portfolioConstants.GET_ALL_PROJECTS_EXEUTION_FLOW_REQUEST });
        portfolioService.getAllProjects().then(({data}) => {
            const filterById = data.filter(({ idea: { _id } }) => _id === id);
            dispatch({ type: portfolioConstants.GET_ALL_PROJECTS_EXEUTION_FLOW_SUCCESS, payload: filterById });
        }).catch(error => {
            console.log(error);
            dispatch({ type: portfolioConstants.GET_ALL_PROJECTS_EXEUTION_FLOW_FAILURE });
        })
    };
};
