import { Box, ButtonBase, Menu, SvgIcon } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { useState } from 'react';
import makeStyles from "@material-ui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  menuRow: {
    position: "sticky",
    right: "0",
    height: 60,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.neutrals.high.main,
    borderTop: `1px solid ${theme.palette.grey.main}`,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    justifyContent: 'center'
  }
}))


function TableMenu({ children }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = ({ currentTarget }) => { setAnchorEl(currentTarget); };
  const handleClose = () => {setAnchorEl(null);};

  return (
    <Box className={classes.menuRow}>
      <ButtonBase onClick={handleOpen}
        style={{
          paddingTop: '0',
          paddingBottom: '0',
        }}
      >
        <SvgIcon component={MoreVert}></SvgIcon>
      </ButtonBase>
      <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </Box>
  );
};

export { TableMenu };
