import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ImageIcon from '@material-ui/icons/Image';

import { MenuInformation } from '../../Shared';
import { Card, GridFlex, AvatarIcon, EvaluationIcon, GridIndicators, BorderLinearProgress } from './styles';
import { ReactComponent as AverageIcon } from '../../../assets/imgs/average-icon.svg';

export function IdeaFollowUp() {
  const { t } = useTranslation();
  const { indicators } = useSelector(state => state.ideaGeneralEvaluation);

  function roundValue(value) {
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  return (
    <Card elevation={0}>
      <Grid container spacing={3}>
        <GridFlex item xs={12}>
          <AvatarIcon>
            <AverageIcon />
          </AvatarIcon>
          <Typography variant="h3">{t('media_de_avaliacao')}</Typography>
        </GridFlex>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1">{t('a_media_geral_da_ideia_e')}</Typography>
            <Typography variant="h3" color="primary">{roundValue(indicators.averageGeneral)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t('media_por_criterios')}</Typography>
        </Grid>
        {indicators?.averageByEvaluation?.map((item, index) => (
          <GridIndicators key={index} item xs={12}>
            <Box display="flex" alignItems="center">
              <EvaluationIcon
                src={item?.personalizedEvaluation?.attachment?.url} 
                alt="evaluation-icon"
                children={<ImageIcon fontSize="small"/>}
              />
              <Typography variant="body2">{item.personalizedEvaluation.name}</Typography>
            </Box>
            <Box width="50%" display="flex" alignItems="center">
              <MenuInformation>{item.personalizedEvaluation.description}</MenuInformation>
              <BorderLinearProgress variant="determinate" value={20 * item.avg} />
              <Typography variant="subtitle2">{roundValue(item.avg)}</Typography>
            </Box>
          </GridIndicators>
        ))}
      </Grid>
    </Card>
  );
}