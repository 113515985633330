import React from 'react';
import {
  makeStyles,
  IconButton,
  Typography,
  Container,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom"
import { crudTemplateActions } from '../../actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0),
    },

    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  container: {
    display: 'flex',
    justifyContent: "space-between",
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  oneLine: {
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
  childrenDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

function BackNavTemplate({ title, children }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  function backHandle() {
      dispatch(crudTemplateActions.setTypePage('table'));
  };
  
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.title}>
          <IconButton onClick={backHandle}>
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography
            variant="h5"
            component="h5"
            style={{ fontSize: 25, fontWeight: 'bold' }}
            className={classes.oneLine}
          >
            {title}
          </Typography>
        </div>
        {children && <div className={classes.childrenDiv}>{children}</div>}
      </Container>
    </div>
  );
}

export { BackNavTemplate };
