import config from '../config';
import axios from 'axios';
import { handleResponse, requestHeaders } from '../helpers';
// import { authHeader } from '../helpers/auth-headers';

export const PersonalizedClassificationService = {
  getClassificationPersonalized,
  sendPersonalizedClassifications,
  deleteAllPersonalizedClassifications,
  deleteOnePersonalizedClassifications,
  putPersonalizedClassification,
  deleteMany
};

function getClassificationPersonalized(page, sort, query = '', limit = 10, statusCode) {
  return axios
    .get(
      `${config.URL_BASE
      }/personalized-classification?page=${page}&sort=${JSON.stringify(
        sort
      )}&limit=${limit}`,
      requestHeaders()
    )
    .then(handleResponse);
};

function sendPersonalizedClassifications(personalizedClassification) {
  return axios
    .post(`${config.URL_BASE}/personalized-classification`, personalizedClassification, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
};

function deleteAllPersonalizedClassifications() {
  return axios.delete(`${config.URL_BASE}/personalized-classification/delete-all`, requestHeaders());
};

function deleteOnePersonalizedClassifications(id) {
  return axios.delete(`${config.URL_BASE}/personalized-classification/${id}`, requestHeaders());
};

function deleteMany(classificationsToDelete = []) {
  const body = classificationsToDelete.filter(item => item !== null);
  return axios.post(`${config.URL_BASE}/personalized-classification/delete-many`, body, requestHeaders());
};

function putPersonalizedClassification(personalizedClassificationsToPut = []) {
  return axios.put(`${config.URL_BASE}/personalized-classification/update-many`, personalizedClassificationsToPut, requestHeaders());
};
