import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const engagementService = {
  getComments,
  getUps,
};

function getUps(id) {
  return axios.get(`${config.URL_BASE}/timeline/ups/${id}`,requestHeaders())
    .then(handleResponse);
}
function getComments(id) {
  return axios.get(`${config.URL_BASE}/comments/${id}?page=-1&sort={%22createdAt%22:-1}`,requestHeaders())
    .then(handleResponse);
}