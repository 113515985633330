  export default function formatUTCHours(date) {
    const dt = new Date(date)
    const shouldFormat = dt.getUTCHours() === 0;

    if (date !== null && shouldFormat) {
      dt.setUTCHours(3);
      return dt;
    } else {
      return date;
    }
  };
