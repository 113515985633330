import React, {useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core';

export function RankingMedalColors({ position }) {
  const theme = useTheme();
  const [color, setColor] = useState('');

  useEffect(() => {
    switch (position) {
      case 1:
        setColor(theme.palette.auxiliary.yellow.main)
        break;

      case 2:
        setColor(theme.palette.neutrals.low.light)
        break;

      case 3:
        setColor(theme.palette.auxiliary.orange.main)
        break;

      default:
        setColor(theme.palette.neutrals.low.lightest)
        break
    }
  }, [position]);

  return (
    <span
      className="material-icons"
      style={{ width: 30, color: `${color}` }}
    >
      military_tech
    </span>
  );
}