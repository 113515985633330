import React from 'react';
import {
  Box,
  makeStyles,
  IconButton,
  Typography,
  Container,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0),
    },
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  container: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  oneLine: {
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
  childrenDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

function BackNavAdmin({ title, children }) {
  const classes = useStyles();
  const history = useHistory();

  function backHandle() {
    history.goBack();
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Box display="flex" alignItems="center">
          
          { history.length > 1 && (
            <IconButton onClick={backHandle}>
              <ArrowBackIcon color="primary" />
           </IconButton>
          )}

          <Typography variant="h2" className={classes.oneLine}>
            {title}
          </Typography>
        </Box>
        {children && <div className={classes.childrenDiv}>{children}</div>}
      </Container>
    </div>
  );
}

export { BackNavAdmin };
