import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Menu,
  Button,
  Avatar, 
  makeStyles, 
  IconButton 
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import TimeAgo from 'react-timeago';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import { timelineActions } from '../../actions';
import { ImagineLinkify } from '../TextLinkify';
import { formatterTimer, history } from '../../helpers';
import { AvatarWithoutPhoto } from '../Shared/AvatarWithoutPhoto';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  cardComment: {
    borderRadius: 4,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.neutrals.high.lightest,
  },
  boxCommentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  boxCommentName: {
    fontWeight: 600,
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    }
  },
  boxCommentPosition: {
    fontWeight: 'lighter',
    fontSize: 12,
  },
  boxCommentAtTimeAgo: {
    fontWeight: 500,
    fontSize: 11,
    color: theme.palette.grey.darkest,
  },
  boxCommentContent: {
    fontSize: 14,
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line',
  },
  buttonDelete: {
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: theme.palette.neutrals.high.main,
    '&:hover': {
      backgroundColor: theme.palette.neutrals.high.medium,
    }
  },
  colorActive: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  colorDefault: {
    fontSize: 16,
    color: theme.palette.grey.darkest,
  },
  upsInfo: {
    fontSize: 12, 
    fontWeight: 600,
  }
}));

export function IdeaComment(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.user);
  const userIsAdmin = useSelector((state) => state.user && state.user.typeProfile && state.user.typeProfile.type === 'admin');
  const {
    comment,
    user,
    createdAt,
    _id,
    ups,
    hasUserUpped,
    upsLength,
  } = props.comment;
  const { loadingUpComment } = props;
  const [moreButton, setMoreButton] = useState(null);
  const language = useSelector((state) => state.language);

  function upCommentIdea() {
    dispatch(
      timelineActions.upCommentIdea(_id, props.postIndex, props.commentIndex)
    );
  }

  function deleteComment() {
    dispatch(
      timelineActions.removeCommentIdea(
        _id,
        props.postIndex,
        props.commentIndex
      )
    );
  }

  function handleOpenMoreButton(e) {
    setMoreButton(e.currentTarget);
  };

  function handleCloseMoreButton() {
    setMoreButton(null);
  }
  
  return (
    <Grid container className={classes.root}>
      <Grid item xs={2}>
        {user.attachment?.url ? (
          <Avatar className={classes.avatar} src={user.attachment.url} />
        ) : (
          <AvatarWithoutPhoto medium userName={user.name} />
        )}
      </Grid>
      <Grid item xs={10}>
        <Card className={classes.cardComment} elevation={0}>
          <Box className={classes.boxCommentHeader}>
            <div 
              className={classes.boxCommentName}
              onClick={() => history.push(`/profile/${user._id}`)}
            >
              {user.name}
            </div>
            <Box className={classes.boxCommentAtTimeAgo}>
              <TimeAgo date={createdAt} formatter={formatterTimer(language)} />
              {(user._id === userLogged._id || userIsAdmin) && (
                <>
                  <IconButton onClick={handleOpenMoreButton}>
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    anchorEl={moreButton}
                    open={moreButton}
                    onClose={handleCloseMoreButton}
                    getContentAnchorEl={null}
                    MenuListProps={{ disablePadding: true }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Button
                      variant="contained" 
                      startIcon={<DeleteIcon />}
                      className={classes.buttonDelete}
                      onClick={() => deleteComment()}
                    >
                      excluir comentário
                    </Button>
                  </Menu>
                </>
              )}
            </Box>
          </Box>
          <Box className={classes.boxCommentContent}>
            <ImagineLinkify>{comment}</ImagineLinkify>
          </Box>
        </Card>

        <Box>
          { user._id !== userLogged._id && (
            <IconButton
              aria-label="UPS"
              onClick={upCommentIdea}
              disabled={loadingUpComment}
            >
              <ThumbUpIcon 
                className={hasUserUpped ? classes.colorActive : classes.colorDefault}
              />
            </IconButton>
          ) }
        
          <span 
            className={clsx(
              classes.upsInfo,
              hasUserUpped ? classes.colorActive : classes.colorDefault
            )}
          >
            {upsLength || 0}
            <span> UP</span>
            {ups && ups.length > 1 && <span>S</span>}
          </span>
        </Box>
      </Grid>
    </Grid>
  );
}
