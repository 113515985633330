import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

import { Card, Header, GridItem, Divider, AvatarIcon, EvaluationIcon } from './styles';
import { Photo } from '@material-ui/icons';
import { SkeletonEvaluations } from '../../Skeletons';

export function Evaluations() {
  const { t } = useTranslation();
  const { groupDetails, evaluations, loadingCriteria } = useSelector(state => state.generalEvaluation);

  return (
    <Card elevation={0}>
      <Header>
        <AvatarIcon>
          <AssignmentTurnedInOutlinedIcon />
        </AvatarIcon>
        <Typography variant="h3">{groupDetails.description}</Typography>
      </Header>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          {/* Icons */}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{t('criterios')}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="subtitle2">{t('descricao')}</Typography>
        </Grid>
      </Grid>
      {!loadingCriteria ? evaluations.map((evaluation, index) => (
        <Grid container spacing={3} key={evaluation._id}>
          <GridItem item xs={1}>
            <EvaluationIcon
              children={<Photo />}
              src={evaluation?.attachment?.url}
              alt={evaluation?.name}
            />
          </GridItem>
          <GridItem item xs={4}>
            <Typography variant="body2">{evaluation.name}</Typography>
          </GridItem>
          <GridItem item xs={7}>
            <Typography variant="body2">{evaluation.description}</Typography>
          </GridItem>
          {index !== (evaluations.length - 1) && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
        </Grid>
      )) : <SkeletonEvaluations />}
    </Card>
  );
}