import { styled } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const DialogTitle = styled(MuiDialogTitle)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const DialogContent = styled(MuiDialogContent)(() => ({
  borderBottom: 'none',
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  display: 'flex',
  alingItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 3, 3)
}));