import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core';
import { Close, Description, Image, VideoLibrary } from '@material-ui/icons';
import { fluxOfIdeasActions } from '../../../actions';
import { HeaderTitle } from '../../EvaluationTeams/styles';
import { EvidenceImplementationUpload } from './EvidenceImplementationUpload';
import { useStyles } from './styles';

export function EvidenceImplementationAttachmentDialog() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { attachmentDialog, loadingEvidences } = useSelector(state => state.fluxIdeas);

  function handleClose () {
    dispatch(fluxOfIdeasActions.openAttachmentDialog());
  }

  return (
    <Dialog open={attachmentDialog} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <HeaderTitle>{t('adicionar_anexo')}</HeaderTitle>
          <IconButton onClick={handleClose}>
            <Close color='primary' />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent >
        <p>{t('adicionar_anexos_para_invrementar_a_qualificação_da_ideia')}</p>
        {loadingEvidences ? (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center" height="200px">
            <CircularProgress size={22} color="primary" />
          </Box>
        ) : (
          <Box className={classes.dialogContent}>
            <EvidenceImplementationUpload
              type="photo"
              variant="text"
              icon={<Image />}
              text={t('adicionar_imagem')}
            />
            <EvidenceImplementationUpload
              type="video"
              variant="text"
              icon={<VideoLibrary />}
              text={t('adicionar_video')}
            />
            <EvidenceImplementationUpload
              type="file"
              variant="text"
              icon={<Description />}
              text={t('adicionar_documento')}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
