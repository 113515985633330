import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const PremiarService = {
  getUrlPremiar,

};

function getUrlPremiar({ userId }) {
  return axios
    .get(
      `${config.URL_BASE
      }/user/${userId}/premmiar-link`,
      requestHeaders()
    )
    .then(handleResponse);
}


