import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Paper,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { ideaCreateActions, ideaCreateDialogsActions, multiAreaCascadeActions } from '../../actions';
import { DialogCustomTitle } from './DialogCustomTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 370,
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconAddCircle: {
    marginRight: 8,
    color: theme.palette.auxiliary.green.light
  },
  iconRemoveCircle: {
    marginRight: 8,
    color: theme.palette.auxiliary.red.main
  },
  tableHeader: {
    fontSize: 14,
    border: 'none',
    padding: theme.spacing(2, 1),
    background: theme.palette.neutrals.high.main
  },
  tableRow: {
    background: theme.palette.neutrals.high.main
  },
  tableCell: {
    fontSize: 14,
    border: 'none',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 1),
  },
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.main,
    marginBottom: theme.spacing(2),
  },
  content: {
    fontSize: 14.22,
    color: theme.palette.neutrals.low.medium,
  },
  containerContent: {
    textAlign: 'center',
    padding: theme.spacing(4, 0),
  },
  actions: {
    flexDirection: 'column',
    padding: theme.spacing(0, 6, 2),
  },
  button: {
    textTransform: 'lowercase',
    width: '100%',
    marginBottom: 16,
    marginLeft: 0,
  },
}));

function DialogLoginPoints({ opened, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();

const itemList = [ 
{label: 'Matriz - Amparo', points: '01'},
{label: 'Dep.Fechado - BA', points: '11'},
{label: 'Escritório - SP', points: '14'},
{label: 'Fábrica - Goiania', points: '16'},
{label: 'Fábrica - Pacajus', points: '19'},
{label: 'Filial - Salto', points: '02'},
{label: 'Fábrica - Itapissuma', points: '22'},
{label: 'Escritório - Campinas', points: '26'},
{label: 'Fábrica - Bahia', points: '05'},
{label: 'C.D - Bahia', points: '06'},
{label: 'C.D - Anapolis', points: '07'},
{label: 'Fábrica - Anapolis', points: '08'},
{label: 'Higident', points: '36'}
];

  return (
    <Dialog open={opened} onClose={onClose} BackdropProps={{ onMouseDown: onClose }}>
      <DialogCustomTitle
        onClose={onClose}
      ></DialogCustomTitle>
      <DialogContent>
              <Paper className={classes.paper}>
 
          <Box display="flex" alignItems="center" paddingTop="1.2rem">
            <AddCircle className={classes.infoIcon} />
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {t('Lista de estabelecimentos')} 
            </Typography>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="table rule points">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableHeader}>{t('estabelecimento')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('codigo').toLowerCase()}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {itemList.map((item, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {item.label}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {item.points}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

export { DialogLoginPoints };
