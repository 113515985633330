import React, { useState } from 'react'
import { Dialog, Box, ButtonBase } from '@material-ui/core';
import config from '../../config';
import { TransitionUp, Transition } from '../CreateIdeaDialogs/DialogTransition';
import { useWindowSize } from '../../hooks';
import { useStyles } from "./styles"
import CloseIcon from "@material-ui/icons/Close"

export const ReportCardDialog = ({ isOpen, children, handleCloseDialog }) => {
  const [width] = useWindowSize();
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      className={classes.cardItemDialog}
      fullWidth={false}
      fullScreen={width < config.RESPONSIVE_MOBILE ? true : false}
      TransitionComponent={
        width < config.RESPONSIVE_MOBILE ? TransitionUp : Transition
      }
    >
      <Box display="flex" flexDirection="column" padding="0.5rem">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <ButtonBase>
            <CloseIcon className={classes.closeIconDialog} onClick={() => handleCloseDialog()} />
          </ButtonBase>
        </Box>
        { children }
      </Box>
  </Dialog>
  )
}
