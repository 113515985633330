import axios from 'axios';
import config from '../config';
import { requestHeaders } from '../helpers';

export const ideaGeneralEvaluationService = {
  getEvaluationsByGroup,
  getIndicatorsByIdea,
  getCommentsByIdea,
  getEvaluationByIdeaIdAndUser,
  createRatesAndComment
}

function getEvaluationsByGroup(groudId) {
  return axios.get(`${config.URL_BASE}/personalized-evaluation/by-group/${groudId}`, requestHeaders());
}

// Busca Indicadores das Avaliações Personalizadas por Ideia
function getIndicatorsByIdea(ideaId) {
  return axios.get(`${config.URL_BASE}/personalized-evaluation-rate/indicators/idea/${ideaId}`, requestHeaders());
}

// Busca comentários de avaliação personalizada por ideia
function getCommentsByIdea(ideaId) {
  return axios.get(`${config.URL_BASE}/personalized-evaluation-group-comment/idea/${ideaId}`, requestHeaders());
}

// Busca Avaliações Por Id da Idea e Usuario
function getEvaluationByIdeaIdAndUser(ideaId) {
  return axios.get(`${config.URL_BASE}/personalized-evaluation-rate/get-user-evaluation/${ideaId}`, requestHeaders());
}

// Cria Rates de Avaliações Personalizadas e Comentário do Usuário
function createRatesAndComment(data) {
  return axios.post(`${config.URL_BASE}/personalized-evaluation-rate/evaluate-idea`, data, requestHeaders());
}

