import React, { useEffect } from 'react';
import {
  Card,
  CardActions,
  Button,
  CardContent,
  makeStyles,
  TextField,
  Box,
  Typography,
  ButtonBase,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { personalizedClassificationActions } from '../../actions';
import CategoryIcon from '@material-ui/icons/Category';

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px',
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.neutrals.low.medium,
    marginTop: 16,
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: '32px',
  },
  categoryTitle: {
    font: 'normal normal 600 14px/22px Poppins'
  },
  add_category_button: {
    margin: '32px 32px'
  },
  trashButton: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  trashButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

}));

function ListClassificationCard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });
  const { personalizedClassification: {
    personalizedClassification,
    loading,
  } } = useSelector(state => state);

  useEffect(() => {
    dispatch(personalizedClassificationActions.getPersonalizedClassification());
  }, [dispatch]);

  function handleDelete({ id, index }) {
    dispatch(personalizedClassificationActions.deleteOnePersonalizedClassification(id, index));
  };

  function handleAddCategory() {
    dispatch(personalizedClassificationActions.setPersonalizedClassification({
      name: '',
      description: ''
    }));
  };

  function handleSetContent(field, value, index) {
    dispatch(personalizedClassificationActions.setPersonalizedClassificationContent({ field, value, index }));
  };

  function SkeletonCard() {
    return (<>
      <Skeleton variant="text" style={{ height: 30, width: '90%', margin: '0 30px' }} />
      <br />
      <Skeleton variant="rectangular" style={{ height: 40, width: '90%', margin: '0 30px' }} />
      <br />
      <Skeleton variant="rectangular" style={{ height: 200, width: '90%', margin: '0 30px' }} />
    </>);
  };

  return (
    <Card>
      <CardActions className={classes.actions}>
        <Box display='flex' alignItems='center'>
          <Box className={classes.iconDiscription}>
            <CategoryIcon color="primary" />
          </Box>
          <Box className={classes.description}>{t('classificacao_das_ideias')}</Box>
        </Box>
      </CardActions>
      {loading ? <SkeletonCard /> : personalizedClassification?.map(({ name, description, _id }, index) => (
        <Box key={(index + _id)}>
          <CardContent className={classes.content}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography className={classes.categoryTitle}>categoria nº{index + 1}</Typography>
              {personalizedClassification.length > 1 && (<Box className={classes.trashButtonWrapper}>
                <ButtonBase
                  color="transparent"
                  variant="contained"
                  className={classes.trashButton}
                  onClick={() => handleDelete({ id: _id ? _id : null, index })}
                >
                  <DeleteIcon fontSize="medium" />
                </ButtonBase>
              </Box>)}
            </Box>
            <TextField
              name={`name${index}`}
              label={t('nome')}
              type="text"
              variant="filled"
              margin="normal"
              fullWidth
              inputRef={register({
                required: t('o_nome_e_obrigatorio'),
              })}
              error={!!errors[`name${index}`]}
              helperText={errors[`name${index}`] && errors[`name${index}`].message}
              className={classes.marginBetweenInputs}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
              defaultValue={name}
              onBlur={({ target: { value } }) => handleSetContent('name', value, index)}
            />

            <Box className={clsx(classes.about, classes.marginBetweenInputs)}>
              {/* <Box className={classes.characters}>
                <span>{description?.length}/256 caracteres</span>
              </Box> */}
              <TextField
                error={!!errors[`description${index}`]}
                reduxField='description'
                name={`description${index}`}
                label={t('descricao')}
                multiline
                fullWidth
                rows={8}
                variant="filled"
                inputRef={register({
                  maxLength: 256,
                  minLength: 0,
                  required: t('a_descricao_e_obrigatorio'),
                })}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                defaultValue={description}
                onBlur={({ target: { value } }) => handleSetContent('description', value, index)}
              />
              <Typography className={classes.error}>
                {errors[`description${index}`] && errors[`description${index}`].message}
                {errors[`description${index}`] &&
                  errors[`description${index}`].type === 'maxLength' &&
                  'Você passou o limite de caracteres'}
                {errors[`description${index}`] &&
                  errors[`description${index}`].type === 'minLength' &&
                  t('a_quantidade_minima_de_caracteres_da_descricao_e') +
                  ' 100'}
              </Typography>
            </Box>
          </CardContent>
        </Box>
      ))}
      <Button
        color="primary"
        startIcon={<AddCircle />}
        className={classes.add_category_button}
        onClick={handleAddCategory}
        disabled={loading}
      >
        {t('adicionar_nova_categoria')}
      </Button>
    </Card>
  );
}

export { ListClassificationCard };
