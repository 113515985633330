import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {Typography, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import { Dialog, DialogTitle, DialogContent, DialogActions } from './styles';
import { generalEvaluationActions } from '../../../actions';
import { useMemo } from 'react';

export function DialogEvaluationDelete({ open, onClose }) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { group } = useSelector(state => state.generalEvaluation);
  const groupId = useMemo(() => group?.[0]?._id, [group]);

  function handleDeleteGroupAndAllEvaluations() {
    dispatch(generalEvaluationActions.deleteGeneralEvaluationGroup(groupId));
    history.push('/preferences/general-evaluation');
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h3">{t('excluir_avaliacao')}</Typography>
          <IconButton aria-label="close" onClick={onClose} >
            <CloseIcon />
          </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h3" style={{ marginBottom: 16 }}>
          {t('deseja_excluir_a_avaliacao')}
        </Typography>
        <Typography variant="body1">
          {t('as_informacoes_incluidas_sobre_a_avaliacao_serao_excluidas_permanentemente')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          {t('voltar')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={handleDeleteGroupAndAllEvaluations}
        >
          {t('excluir_avaliacao')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

