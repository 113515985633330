import moment from 'moment';

export const selectedLanguageDateFormat = (language) => {
  switch (language) {
    case 'en':
      return 'MM/DD/YYYY';
    case 'pt_BR':
      return 'DD/MM/YYYY';
    default:
      return 'DD/MM/YYYY';
  }
};

export function dateFormat(date, language) {
  return moment(date).format(selectedLanguageDateFormat(language));
}

export function timeFormat(date) {
  return moment(date).format('HH:mm');
}
