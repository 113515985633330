import React, { useState } from 'react';
import {
    Box,
    Menu,
    withStyles,
    makeStyles,
    MenuItem,
    ListItemText,
    useTheme
  } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    filter: {
      display: 'flex',
      height: '100%',
      backgroundColor: theme.palette.neutrals.high.medium,
      color: theme.palette.neutrals.low.medium,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      borderWidth: 2,
      borderColor: theme.palette.neutrals.high.medium,
      borderStyle: 'solid',
      alignItems: 'center'
    },
    primaryBorder: {
        borderColor: theme.palette.primary.main
    },
    paper: {
      boxShadow: `1px 2px 6px 1px ${theme.palette.neutrals.low.lightest}`,
    },
    name: {
        color: theme.palette.neutrals.low.main,
        fontSize: 14,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: "nowrap",
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
    },
}))

const StyledMenuItem = withStyles((theme) => ({
    root: {
        paddingRight: theme.spacing(8),
        color: theme.palette.neutrals.low.main,
    },
}))(MenuItem);  

function RankingMobileFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const classes = useStyles()

  const StyledMenu = withStyles({
    paper: {
      border: `1px solid ${theme.palette.neutrals.high.dark}`,
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  function onSelectFilter(value){
    props.onHandleFilter(value)
    setTimeout(()=>{
        handleClose()
    }, 300)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
        <div className={`${classes.filter} material-icons ${Boolean(anchorEl) && classes.primaryBorder}`}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          filter_alt
        </div>
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{paper: classes.paper}}
        >
            <StyledMenuItem onClick={()=>{onSelectFilter(9000)}}>
                <ListItemText primary="Ranking geral" />
            </StyledMenuItem>
            <StyledMenuItem onClick={()=>{onSelectFilter(30)}}>
                <ListItemText primary="Ranking mensal" />
            </StyledMenuItem>
            <StyledMenuItem onClick={()=>{onSelectFilter(7)}}>
                <ListItemText primary="Ranking semanal" />
            </StyledMenuItem>
        </StyledMenu>
    </Box>
    
  );
}

export { RankingMobileFilter }