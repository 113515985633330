import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { generalEvaluationActions } from '../../actions';
import { GeneralEvaluationTable } from '../../components/GeneralEvaluation';
import { SkeletonTable } from '../../components/Skeletons';
import { BackNavAdmin, EmptyState } from '../../components/Shared';
import emptyStateIcon from '../../assets/imgs/emptystate/illustration_empty-state-general-evaluation.svg';

export function GeneralEvaluationPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { group, loading, paging: { page, sort, limit } } = useSelector(state => state.generalEvaluation);

  useEffect(() => {
    dispatch(generalEvaluationActions.getEvaluationGroup(page, sort, limit));
  }, []);

  function handleCreateEvaluation() {
    history.push('/preferences/general-evaluation/create-evaluation');
  }

  return (<>
    <BackNavAdmin title={t('avaliacao_geral')}>
      <Button
        color="primary"
        variant="contained"
        disabled={loading}
        startIcon={<AddCircleIcon />}
        onClick={handleCreateEvaluation}
      >
        {t('adicionar_avaliacao')}
      </Button>
    </BackNavAdmin>

    <Container maxWidth="lg" style={{ padding: 32 }}>
      <Grid container>
        <Grid item xs={12}>
          {loading 
            ? <SkeletonTable cols={2} />
            : (
              <>
                {group.length > 0 ? <GeneralEvaluationTable /> : (
                  <EmptyState
                    icon={emptyStateIcon}
                    title={t('ainda_nao_ha_avaliacao_cadastrada')}
                    description={t('que_tal_adicionar_uma_nova_avaliacao_para_incrementar_o_processo_de_analise_das_ideias')}
                  />
                )}
              </>
            )
          }
        </Grid>
      </Grid>
    </Container>
  </>);
}