import React, { useEffect } from 'react';
import { Avatar, Box, Card, Container, Divider, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import { BackNav, EmptyState, ImagineFilter, Loading } from '../../components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ForumTwoTone } from '../../assets/imgs/forumTwoTone.svg';
import iconEmptyState from '../../assets/imgs/emptystate/illustration_empty-state-comments.svg';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackActions } from '../../actions';
import { FeedbackCard } from './FeedbackCard';
import { FeedbackSkeletonCard } from './FeedbackSkeletonCard';
import InfiniteScroll from 'react-infinite-scroll-component';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  emptyCard: {
    marginTop: theme.spacing(3),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(5)
  },
  boxIcon: {
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.neutrals.low.dark,
    marginRight: theme.spacing(1),
  },
  titleContainer: {
    marginBottom: 32,
  },
  titleDropBox: {
    padding: 24,
    color: theme.palette.neutrals.low.light
  }
}));

function FeedbackPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { feedbacks, loading, paging } = useSelector((state) => state.feedback);

  useEffect(() => {
    dispatch(feedbackActions.getFeedback());
  }, []);

  const loadMoreFeedbacks = () => {
    dispatch(feedbackActions.getFeedbackScroll(paging.page, paging.sort, paging.limit + 4));
  }
  return (
    <Container maxWidth="lg" className={classes.root}>
      <BackNav title="feedbacks" pathname="/" />

      <Box display="flex" alignItems="center" className={classes.titleContainer}>
        <Avatar className={classes.boxIcon}>
          <ForumTwoTone />
        </Avatar>
        <Typography variant="h3" className={classes.title}>
          {t('listagem_de_feedbacks')}
        </Typography>
      </Box>

      <Box>
        <ImagineFilter children={<>
          <Typography variant="body2" className={classes.titleDropBox}>{t('classificar_por')}</Typography>
          <Divider />
          <Box style={{ padding: 24, display: 'flex', flexDirection: 'column' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={(_, value) => dispatch(feedbackActions.getFeedback(paging.page, { createdAt: value }, paging.limit))}
              >
                <FormControlLabel name="recent" value="-1" control={<Radio />} label={t('mais_recente')} />
                <FormControlLabel name="old" value="1" control={<Radio />} label={t('mais_antigo')} />
              </RadioGroup>
            </FormControl>
          </Box>
        </>
        }
          title={t('classificacao')}
        />
      </Box>

      <Box>
        {loading ? (
          <FeedbackSkeletonCard />
        ) : feedbacks?.length > 0 ? (
          <InfiniteScroll
            dataLength={feedbacks.length}
            next={loadMoreFeedbacks}
            hasMore={true}
            scrollThreshold={'90%'}
            loader={feedbacks.length < paging.total &&
              <FeedbackSkeletonCard />
            }
          >
            {feedbacks.map((feedback) => feedback.user !== null && <FeedbackCard key={feedback._id} feedback={feedback} />)}
          </InfiniteScroll>

        ) : (
          <Card className={classes.emptyCard}>
            <EmptyState icon={iconEmptyState} title={t('ainda_nao_ha_feedbacks_por_aqui')} description={t('consulte_a_pagina_em_outro_momento')} />
          </Card>
        )}
      </Box>
    </Container>
  );
}

export { FeedbackPage };
