import React from 'react';
import Grid from '@material-ui/core/Grid';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles';

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  text: {
    transform: 'none',
  },
})) (MuiSkeleton);

export function SkeletonInfraDialogAssociation() {
  return (
    <Grid container spacing={2} xs={12}>
      <Grid item xs={5}>
        <Skeleton variant="text" width={'100%'} height={53} />
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant="text" width={'100%'} height={53} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="circle" width={50} height={50} />
      </Grid>
    </Grid>
  );
}