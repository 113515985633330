import React from 'react';
import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    color: theme.palette.neutrals.low.dark,
    marginBottom: 12,
    maxHeight: 24
  }
}));

export function TagIcon({ title, icon }) {
  const classes = useStyles();

  return (
    <Chip
      icon={icon}
      label={<Typography variant="subtitle2">{title}</Typography>}
      variant="outlined"
      className={classes.chip}
    />
  );
}