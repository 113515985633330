import { rankingConstants } from '../constants';
import { rankingService } from '../services';

export const rankingActions = {
  getRanking,
  setLimit,
  setPage,
  setFilterPeriod,
  getRankingMobile,
  setTypeRanking
};

function getRanking(filter, page, sort, limit, query) {
  return (dispatch) => {
    dispatch({ 
      type: rankingConstants.GET_RANKING_REQUEST
    })

    rankingService.getRanking(filter, page, sort, limit, query).then(
      (data) => {
        dispatch(success(data.data, filter.userName, data.paging));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(ranking, userName, paging) {
    const payload = {
      ranking,
      userName,
      paging,
    };

    return { type: rankingConstants.GET_RANKING_SUCCESS, payload };
  }

  function failure(error) {
    return { type: rankingConstants.GET_RANKING_FAILURE, error };
  }
}

function getRankingMobile(filter, page, sort, limit) {
  return (dispatch) => {
    dispatch(request())
    rankingService.getRanking(filter, page, sort, limit).then(
      (data) => {
        dispatch(success(data.data, filter.userName, data.paging));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(){
    return { type: rankingConstants.GET_RANKING_MOBILE_REQUEST };
  }

  function success(ranking, userName, paging) {
    const payload = {
      ranking,
      userName,
      paging,
    };

    return { type: rankingConstants.GET_RANKING_MOBILE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: rankingConstants.GET_RANKING_MOBILE_FAILURE, error };
  }
}

function setLimit(limit) {
  const payload = { limit };

  return { type: rankingConstants.SET_LIMIT, payload };
}

function setPage(page) {
  const payload = { page };

  return { type: rankingConstants.SET_PAGE, payload };
}

function setFilterPeriod(period) {
  const payload = { period };

  return { type: rankingConstants.SET_FILTER, payload };
}

function setTypeRanking(column) {
  return (dispatch) => {
    dispatch({
      type: rankingConstants.SET_TYPE_RANKING,
      payload: column
    })
  }
}