import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HelpIcon from '@material-ui/icons/Help';
import AssistentIcon from '@material-ui/icons/Assistant';
import { useTranslation } from 'react-i18next';
import { Copyright } from '../Shared';

const useStyles = makeStyles((theme) => ({
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  activated: {
    color: theme.palette.primary.main,
  }
}));

const useStylesIcon = makeStyles(() => ({
  root: {
    minWidth: 30,
  },
}));

function SidebarHelpCenter({ currentlyTab, handleChangeCurrentlyTab }) {
  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const [location, setLocation] = useState(currentlyTab);
  const { t } = useTranslation();

  const handleShowComponent = (value) => {
    handleChangeCurrentlyTab(value)
  }
  useEffect(() => {
    setLocation(currentlyTab)
  }, [currentlyTab])

  return (
    <>
      <List>
        <ListItem
          button
          onClick={() => handleShowComponent('primeiro-acesso')}
          className={
            location === 'primeiro-acesso'
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <PlayCircleFilledIcon
              color={location === 'primeiro-acesso' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={location === 'primeiro-acesso' ? 'primary' : 'inherit'}
              >
                {t('primeiro_acesso')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          button
          onClick={() => handleShowComponent('tutoriais')}
          className={
            location === 'tutoriais'
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <AssistentIcon
              color={location === 'tutoriais' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={location === 'tutoriais' ? 'primary' : 'inherit'}
              >
                {t('tutoriais')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          button
          onClick={() => handleShowComponent('faq')}
          className={
            location === 'faq'
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <HelpIcon
              color={location === 'faq' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={location === 'faq' ? 'primary' : ''}
              >
                {t('duvidas_frequentes')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          button
          onClick={() => handleShowComponent('administradores')}
          className={
            location === 'administradores'
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <AccountBoxIcon
              color={location === 'administradores' ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={location === 'administradores' ? 'primary' : 'inherit'}
              >
                {t('administradores')}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider style={{ marginTop: 16 }} />
      <Copyright />
    </>
  );
}

export { SidebarHelpCenter };
