import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const contentsService = {
  getFixedContent,
  getCotents
};

function getFixedContent() {
  return axios.get(`${config.URL_BASE}/contents/fixed`, requestHeaders())
    .then(handleResponse);
}

function getCotents(page, sort, limit) {
  return axios.get(`${config.URL_BASE}/contents/?page=${page}&sort={%22createdAt%22:${sort}}&limit=${limit}`, requestHeaders())
    .then(handleResponse)
}