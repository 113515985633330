import React from 'react';
import { uniqueId } from 'lodash';
import fileSize from 'filesize';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Hidden } from '@material-ui/core';
import { fluxOfIdeasActions } from '../../../actions';

export function EvidenceImplementationUpload({ type, text, icon, variant }) {
  const dispatch = useDispatch();
  const { ideaDetailsFlux } = useSelector(state => state.fluxIdeas);

  function handleInputChange(e) {
    const file = e.target.files[0];
    const newUploadedFiles = {
      file,
      type: file.type,
      id: uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      url: null,
    };

    if (type === 'photo') {
      dispatch(fluxOfIdeasActions.setImagesEvidence([newUploadedFiles], ideaDetailsFlux._id));
    } else if (type === 'video') {
      dispatch(fluxOfIdeasActions.setVideoEvidence([newUploadedFiles], ideaDetailsFlux._id));
    } else if (type === 'file') {
      dispatch(fluxOfIdeasActions.setFilesEvidence([newUploadedFiles], ideaDetailsFlux._id));
    }
  }

  function accept(type) {
    if (type === 'photo') {
      return 'image/*';
    }

    if (type === 'video') {
      return 'video/*';
    }

    if (type === 'file') {
      return '.pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,application/msword,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
  }

  return (
    <Button
      variant={variant ?? "outlined"}
      component="label"
      startIcon={icon}
      color="primary"
      style={{ marginRight: 8 }}
    >
      <Hidden smDown>{text}</Hidden>
      <input
        type="file"
        accept={accept(type)}
        style={{ display: 'none' }}
        onChange={handleInputChange}
      />
    </Button>
  );
}