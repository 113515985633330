import React from 'react';
import { Button } from '@material-ui/core';
import { CustomTooltip } from './CustomTooltip';

export function ButtonWithTooltip({ tooltip, text, ...props }) {
  return (
    tooltip ? (
      <CustomTooltip title={tooltip} aria-label={tooltip}>
        <Button color="primary" {...props}>
          {text}
        </Button>
      </CustomTooltip>
    ) : (
      <Button color="primary" {...props}>
        {text}
      </Button>
    )
  );
} 