import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
import { AddCircle, Description } from '@material-ui/icons';
import { EvidenceImplementationAttachmentDialog } from './EvidenceImplementationAttachmentDialog';
import { fluxOfIdeasActions } from '../../../actions';
import { uploadFile } from '../../../assets/imgs';
import { EmptyState } from '../../Shared';
import { useStyles } from './styles';

export function EvidenceImplementation() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ideaDetailsFlux, images, video, files } = useSelector(state => state.fluxIdeas);
  const hasAttachments = ideaDetailsFlux?.implementationAttachments?.length > 0;

  useEffect(() => {
    if (ideaDetailsFlux?.implementationAttachments?.length > 0){
      dispatch(fluxOfIdeasActions.loadEvidences(ideaDetailsFlux?.implementationAttachments));
    }
  }, [dispatch]);

  function openAttachmentsDialog() {
    dispatch(fluxOfIdeasActions.openAttachmentDialog());
  }

  return (
    <>
      <EvidenceImplementationAttachmentDialog />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {'Evidência Implementação'.toLocaleUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={0} className={classes.card}>
            <Box className={classes.cardHeader}>
              <Button
                startIcon={<AddCircle />}
                color="primary"
                variant="text"
                onClick={openAttachmentsDialog}
              >
                {t('adicionar_anexo')}
              </Button>
            </Box>
            <Box className={classes.cardContent}>
              {hasAttachments && (
                <Grid container spacing={3}>
                  {images.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className={classes.sectionTitle}>Imagens</Typography>
                      <Grid container spacing={2}>
                      {images.map(image => (
                        <Grid item>
                          <Box className={classes.imgBox}>
                            <img src={image?.preview ?? image?.url} className={classes.imgBox} />
                          </Box>
                        </Grid>
                      ))}
                      </Grid> 
                    </Grid>
                  )}

                  {video.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className={classes.sectionTitle}>Videos</Typography>
                      <Grid container spacing={2}>
                        {video.map(video => (
                          <Grid item xs={12} sm={12} md={6}>
                            <video style={{ width: '100%' }} height="255" controls>
                              <source src={video?.preview ?? video?.url} type={video?.type ?? video?.mimeType?.mymeType} />
                            </video>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                  {files.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className={classes.sectionTitle}>Documentos</Typography>
                      <Grid container spacing={2}>
                        {files.map(file => (
                          <Grid item>
                            <Box className={classes.fileBox}>
                              <Description />
                              <Box>
                                <Typography variant="subtitle2">{file.name ?? file?.fileName}</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}

              {!hasAttachments && (
                <EmptyState
                  icon={uploadFile}
                  title="Não encontramos nenhuma evidencia"
                />
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
    
  );
}