import { Box } from '@material-ui/core';
import React from 'react';
import { ImageUploader } from '../index';

function ContentImageUploader() {
  return (<>
    <Box marginBottom={6}>
      <ImageUploader typeimage="thumbnail" aspectRatio={1 / 1} cardTitle="miniatura_do_card" isThumb/>
    </Box>
    <Box>
      <ImageUploader typeimage="banner" aspectRatio={16 / 9} cardTitle="miniatura_do_banner" />
    </Box>
  </>
  )
};

export { ContentImageUploader };