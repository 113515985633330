import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { ImagineGrid } from '../ImagineGrid';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3, 3, 0),
  },
  card: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(2.5),
    margin: '10px 0',
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    height: 14,
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  text: {
    transform: 'none',
  },
}))(MuiSkeleton);

export const SkeletonTable = ({ cols }) => {
  const classes = useStyles();
  let rows = new Array(10).fill(0);
  let headerRows = Array(cols).fill(0);

  return (
    <ImagineGrid container spacing={2}>
      <ImagineGrid item xs={12} className={classes.header}>
        <ImagineGrid container spacing={6} cols={cols}>
          { headerRows.map((item, index) => (
            <ImagineGrid item key={index}>
              <Skeleton variant="text" />
            </ImagineGrid>
          ))}
        </ImagineGrid>
      </ImagineGrid>
      
      <ImagineGrid item xs={12}>
        { rows.map((item, index) => (
          <Card elevation={0} key={index} className={classes.card}>
            <ImagineGrid container spacing={6} cols={cols}>
              { headerRows.map((item2, index) => (
                <ImagineGrid item key={index}>
                  <Skeleton variant="text" />
                </ImagineGrid>
              ))}
            </ImagineGrid>
          </Card>
        ))}
      </ImagineGrid>
    </ImagineGrid>
  );
}