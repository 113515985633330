import { evaluationTeamsConstants } from '../constants';

const initialStateParticipant = {
  _id: '',
  name: '',
}

const initialState = {
  teams: [],
  responsibles: [],
  newTeam: {
    name: '',
    status: true,
    participants: [initialStateParticipant]
  },
  loading: false
};

export function evaluationTeams(state = initialState, action) {
  switch (action.type) {
    case evaluationTeamsConstants.SET_EVALUATION_TEAM_NAME:
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          name: action.payload.value
        }
      }
    
    case evaluationTeamsConstants.CHANGE_TEAM_STATUS:
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          status: action.payload
        }
      }

    case evaluationTeamsConstants.EVALUATION_TEAM_CHANGE_PARTICIPANT_FIELD:
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          participants: state.newTeam.participants.map((item, index) => {
            if (index === action.payload.index) {
              return action.payload.data
            }
            return item
          })
        }
      }

    case evaluationTeamsConstants.CLEAR_EVALUATION_TEAM_PARTICIPANT_FIELD:
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          participants: state.participants.map((item, index) => {
            if (index === action.payload.index) {
              return action.payload.data
            }
            return item
          })
        }
      }

    case evaluationTeamsConstants.ADD_PARTICIPANT_FIELD: {
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          participants: [
            ...state.newTeam.participants,
            initialStateParticipant
          ]
        }
      }
    }

    case evaluationTeamsConstants.REMOVE_PARTICIPANT_FIELD:
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          participants: state.newTeam.participants.filter((item, index) => item._id !== action.payload)
        }
      }

    case evaluationTeamsConstants.LOAD_PARTICIPANTS: 
      return {
        ...state,
        responsibles: action.payload
      }

    case evaluationTeamsConstants.LOAD_EVALUATION_TEAM_IN_DIALOG: {
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          name: action.payload.name,
          status: action.payload.status,
          participants: action.payload.participants.map((item, index) => {
            if (item._id === action.payload._id) {
              return {
                ...item,
                _id: action.payload._id,
                name: action.payload.name,
              }
            }
            return item;
          })
        }
      }
    }

    case evaluationTeamsConstants.CLEAR_DIALOG_CREATE_TEAM:
      return {
        ...state,
        newTeam: {
          ...state.newTeam,
          name: '',
          status: true,
          participants: [initialStateParticipant]
        }
      }

    case evaluationTeamsConstants.GET_ALL_EVALUATION_TEAMS_REQUEST:
    case evaluationTeamsConstants.CREATE_EVALUATION_TEAM_REQUEST:
    case evaluationTeamsConstants.DELETE_EVALUATION_TEAM_REQUEST:
    case evaluationTeamsConstants.UPDATE_EVALUATION_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      }

    case evaluationTeamsConstants.GET_ALL_EVALUATION_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
        loading: false
      }

    case evaluationTeamsConstants.CREATE_EVALUATION_TEAM_SUCCESS:
    case evaluationTeamsConstants.DELETE_EVALUATION_TEAM_SUCCESS:
    case evaluationTeamsConstants.UPDATE_EVALUATION_TEAM_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case evaluationTeamsConstants.GET_ALL_EVALUATION_TEAMS_FAILURE:
    case evaluationTeamsConstants.CREATE_EVALUATION_TEAM_FAILURE:
    case evaluationTeamsConstants.DELETE_EVALUATION_TEAM_FAILURE:
    case evaluationTeamsConstants.UPDATE_EVALUATION_TEAM_FAILURE:
      return {
        ...state,
        loading: false
      }

    default: {
      return state;
    }
  }
}
