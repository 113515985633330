import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as OutBox } from '../../../assets/imgs/outbox.svg';
import {
  DialogTitle,
  HeaderTitle,
  DialogContent,
  DialogActions
} from './styles';
import { AvatarIconWithText } from '../../Shared';
import { evaluationTeamsActions } from '../../../actions';
import { ArrowForward, FiberManualRecord } from '@material-ui/icons';
import { Dialog, FormControl, Radio, RadioGroup, useTheme } from '@material-ui/core';
import { FormArea } from './FormArea';
import { EvaluationTeamForm } from './EvaluationTeamsForm';
import { UsersForm } from './UsersForm';
import { ForwardIdeaStepFluxForm } from './ForwardIdeaStepFluxForm';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage/hooks/useIdeaDetailsController';

export function DialogForwardIdea({ open, onClose }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [page, setPage] = useState('index')
  const [nextPage, setNextPage] = useState('index');
  const { hasIdeaFlux } = useIdeaDetailsController();

  const mockCheckListItems = [
    { label: t('area_de_atuacao'), id: 'area' }, 
    { label: t('time_de_avaliacao'), id: 'time' }, 
    { label: t('pessoa_avaliadora'), id: 'user' },
    { label: t('encaminhar_para_etapa'), id: 'step' },
  ];

  function handleCloseDialog() {
    onClose();
    setPage('index');
    history.push(`/idea/${hasIdeaFlux._id}/general`);
    dispatch(evaluationTeamsActions.clearDialogCreateTeam());
  }

  const CheckList = ({ items }) => (
    <FormControl>
      <RadioGroup value={nextPage}>
        {items.map((item, index) => (
          <FormControlLabel 
            onClick={() => setNextPage(item.id)}
            key={index}
            value={item.id}
            control={<Radio color='primary' />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  function RenderContent({ page }) {
    switch (page) {
      case 'index':
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AvatarIconWithText
                style={{ width: 32, height: 32 }}
                icon={<OutBox />}
                variant="subtitle1"
                text={t('encaminhar_ideia')}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {t('direcione_essa_avaliacao_para_uma_outra_area_caso_identifique_que_a_sua_area_nao_e_a_mais_qualificada_para_isso')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CheckList items={mockCheckListItems} />
            </Grid>
          </Grid>
        );

      case 'area':
        return (<FormArea handleBack={setPage} onClose={handleCloseDialog} />);

      case 'time':
        return (<EvaluationTeamForm handleBack={setPage} onClose={handleCloseDialog} />);

      case 'user':
        return (<UsersForm handleBack={setPage} onClose={handleCloseDialog} />);

      case 'step':
        return <ForwardIdeaStepFluxForm handleBack={setPage} onClose={handleCloseDialog} />;

      default:
        return <></>
    }
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle disableTypography>
        <HeaderTitle>{t('encaminhar')}</HeaderTitle>
        <IconButton aria-label="close" onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <RenderContent page={page} />
      </DialogContent>
      {page === 'index' && (
        <>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent='center'>
              <FiberManualRecord color='primary' />
              <FiberManualRecord style={{ fill: theme.palette.primary.light }} />
            </Box>
          </Grid>
          <DialogActions>
            <Box width="100%" display="flex" justifyContent={page !== 'index' ? 'space-between' : 'flex-end'}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setPage(nextPage)}
                startIcon={<ArrowForward />}
              >
                {t('proximo')}
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );

}