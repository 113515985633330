import React, { useEffect } from 'react';
import { Container, makeStyles, Grid, Card } from '@material-ui/core';
import {
  BackNavAdmin,
  ChallengeCreateCard,
  ChallengeImageCard,
  ChallengeCriteriaCard,
  ChallengeProgressCard,
  ChallengeAwardCard,
  ChallengeDatesCard,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { challengeActions } from '../../actions';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  card: {
    marginBottom: 20,
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    top: '76px',
  },
}));

function ChallengeNewPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    newChallenge: {
      rewards,
      name,
      description,
      goal,
      requirement,
      startDate,
      dueDate,
      resultDate,
      thumbnail,
      banner
    },
    progress,
    newChallenge,
    loading
  } = useSelector((state) => state.challenge);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(challengeActions.clearProgress());
  }, [])

  const shouldDisable = (
    !!name?.length &&
    description?.length > 100 &&
    !!requirement?.length &&
    !!goal?.length
  );

  return (
    <div>
      <BackNavAdmin title={t('novo_desafio')}></BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <div className={classes.card}>
              <ChallengeCreateCard
                name={name}
                description={description}
                goal={goal}
              ></ChallengeCreateCard>
            </div>

            <Card className={classes.card}>
              <ChallengeImageCard
                type="thumbnail"
                imagem={thumbnail}
              ></ChallengeImageCard>
              <ChallengeImageCard
                type="banner"
                imagem={banner}
              ></ChallengeImageCard>
            </Card>

            <div className={classes.card}>
              <ChallengeCriteriaCard
                requirement={requirement}
              ></ChallengeCriteriaCard>
            </div>

            <div className={classes.card}>
              <ChallengeDatesCard
                startDate={startDate}
                dueDate={dueDate}
                resultDate={resultDate}
              ></ChallengeDatesCard>
            </div>

            {config.SHOW_REWARD_FIELDS_ON_CHALLENGE && (
            <div className={classes.card}>
                <ChallengeAwardCard rewards={rewards} />
            </div>
            )}
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fixed}>
              <ChallengeProgressCard
                progress={progress}
                challenge={newChallenge}
                loading={loading}
                shouldDisable={!shouldDisable}
              ></ChallengeProgressCard>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export { ChallengeNewPage };
