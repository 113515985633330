import React from 'react';
import { makeStyles, Typography, Box, IconButton } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { dateFormat, truncate } from '../../helpers';
import { StatusIdea } from '../../components';

import { useDispatch, useSelector } from 'react-redux';
import { ideaCreateActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.neutrals.high.main,
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'capitalize',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  challenge: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    paddingTop: theme.spacing(0.5),
  },
  date: {
    color: theme.palette.neutrals.low.light,
    fontSize: 10,
    fontWeight: 400,
    paddingTop: theme.spacing(1.5),
  },
  icons: {
    color: theme.palette.neutrals.low.light,
  },
}));

function IdeaCard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);

  function openDialogDeleteDraft(idea) {
    props.dialog.setOpentDialog(true);
    props.setIdea(idea);
  }

  function openDialogSendIdea(idea) {
    dispatch(ideaCreateActions.getDraft(idea._id));
  }

  function handleView(id) {
    props.history.push(`/idea/${id}/general`);
  }

  function handleEdit(id) {
    props.history.push(`/idea/edit/${id}`);
  }

  return (
    <Box className={classes.card}>
      <Box className={classes.header}>
        {props.idea.statusIdea && (
          <div style={{ paddingTop: 12 }}>
            <StatusIdea status={props.idea.statusIdea} />
          </div>
        )}
        {props.idea.statusIdea.code === 'DRAFT' ? (
          <div>
            <IconButton
              className={classes.icons}
              onClick={() => {
                openDialogSendIdea(props.idea);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.icons}
              onClick={() => {
                openDialogDeleteDraft(props.idea);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ) : (
          <div style={{ paddingRight: 0 }}>
            {(props.idea.statusIdea.code !== 'DRAFT' && props.idea.statusIdea.code !== 'CONCLUDED' && props.idea.statusIdea.code !== 'EXECUTING') && (<IconButton
              style={{ color: '#ABABAB' }}
              onClick={() => { handleEdit(props.idea._id) }}
            >
              <EditIcon />
            </IconButton>)}
            <IconButton
              className={classes.icons}
              onClick={() => {
                handleView(props.idea._id);
              }}
            >
              <ViewIcon />
            </IconButton>
          </div>
        )}
      </Box>
      <div>
        <Typography
          className={classes.title}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="h1"
        >
          {truncate(props.idea.name, 70)}
        </Typography>

        <Typography
          className={classes.challenge}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="p"
        >
          {props.idea.challenge && props.idea.challenge.name
            ? props.idea.challenge.name
            : '-'}
        </Typography>

        <Typography
          className={classes.date}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="p"
        >
          {dateFormat(props.idea.createdAt, language)}
        </Typography>
      </div>
    </Box>
  );
}

export { IdeaCard };
