import { businessUnitPropsConstants } from "./../constants"

const initialState = {
  local: [],
  occupationArea: [],
  businessUnit: [],
  firstField: "businessUnit",
  secondField: "local",
  thirdField: "occupationArea",
}

export const businessUnitProps = (state = initialState, action) => {
  switch(action.type) {

    case businessUnitPropsConstants.LOAD_BUSINESS_UNIT_PROPS: {
      const arr_logical_filters = [action.payload.businessUnitField, action.payload.localField, action.payload.occupationAreaField]

      if (arr_logical_filters.filter(item => item === true).length === 3) {
        state = {
          ...state,
          firstField: "businessUnit",
          secondField: "local",
          thirdField: "occupationArea",
        }
      } else if (arr_logical_filters.filter(item => item === true).length === 2) {
        switch(arr_logical_filters.toString()) {
          case "false,true,true": {
            state = {
              ...state,
              firstField: "local",
              secondField: "occupationArea",
              thirdField: false,
            }
            break;
          }

          case "true,false,true": {
            state = {
              ...state,
              firstField: "businessUnit",
              secondField: "occupationArea",
              thirdField: false,
            }
            break;
          }

          case "true,true,false": {
            state = {
              ...state,
              firstField: "businessUnit",
              secondField: "local",
              thirdField: false,
            }
            break;
          }

          default: {
            state = {
              ...state,
              firstField: "businessUnit",
              secondField: "local",
              thirdField: "occupationArea",
            }
            break;
          }
        }
      } else if (arr_logical_filters.filter(item => item === true).length === 1) {
        switch(arr_logical_filters.toString()) {
          case "true,false,false": {
            state = {
              ...state,
              firstField: "businessUnit",
              secondField: false,
              thirdField: false,
            }
            break;
          }

          case "false,true,false": {
            state = {
              ...state,
              firstField: "local",
              secondField: false,
              thirdField: false,
            }
            break; 
          }

          case "false,false,true": {
            state = {
              ...state,
              firstField: "occupationArea",
              secondField: false,
              thirdField: false,
            }
            break;
          }

          default: {
            state = {
              ...state,
              firstField: "businessUnit",
              secondField: "local",
              thirdField: "occupationArea",
            }
            break;
          }
        }
      }

      let filteredFirstField = [...new Set(action.payload.imagineLocals.map(item => ({
        value: item[state.firstField]?._id,
        label: item[state.firstField]?.name,
        checked: false,
      })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

      return {
        ...state,
        [state.firstField]: [
          { 
            label: "Selecionar Todos",
            value: "SELECT_ALL",
            checked: false
          },
          ...filteredFirstField,
        ]
      }
    }
     

    case businessUnitPropsConstants.CHANGE_BUSINESS_UNIT_PROP: {
      if (action.payload.data.value === "SELECT_ALL") {
        state = {
          ...state,
          [action.payload.businessUnitProp]: state[action.payload.businessUnitProp].map(item => ({
            ...item,
            checked: !action.payload.data.checked
          }))
        }
      } else {
        state = {
          ...state,
          [action.payload.businessUnitProp]: state[action.payload.businessUnitProp].map(item => {
            if (item.value === action.payload.data.value) {
              return {
                ...item,
                checked: !item.checked
              }
            }

            if (item.value === "SELECT_ALL") {
              return {
                ...item,
                checked: false
              }
            }
            return item;
          })
        }
      }

     

      if (state.firstField === action.payload.businessUnitProp && state.secondField) {
        const arr_ids_firstField = state[state.firstField].filter(item => item.checked).map(item => item.value);
        const optionsSecondField = [...new Set(action.payload.imagineLocals.filter(
          item => arr_ids_firstField.includes(item[state.firstField]._id)
        ).map(item => ({
          value: item[state.secondField]._id,
          label: item[state.secondField].name,
          checked: false,
        })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

        state = {
          ...state,
          [state.secondField]: [
            { 
              label: "Selecionar Todos",
              value: "SELECT_ALL",
              checked: false
            },
            ...optionsSecondField
          ],
        }

        if (state.thirdField) {
          state = {
            ...state,
            [state.thirdField]: []
          }
        }
      }

      if (state.secondField === action.payload.businessUnitProp && state.thirdField) {
        const arr_ids_secondField = state[state.secondField].filter(item => item.checked).map(item => item.value);
        const optionsThirdField = [...new Set(action.payload.imagineLocals.filter(
          item => arr_ids_secondField.includes(item[state.secondField]._id)
        ).map(item => ({
          value: item[state.thirdField]._id,
          label: item[state.thirdField].name,
          checked: false,
        })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

        state = {
          ...state,
          [state.thirdField]: [
            { 
              label: "Selecionar Todos",
              value: "SELECT_ALL",
              checked: false
            },
            ...optionsThirdField
          ]
        }
      }

      return state;
    }

    case businessUnitPropsConstants.CLEAR_BUSINESS_UNIT_PROP: {
      state = {
        ...state,
        [action.payload]: state[action.payload].map(item => ({
          ...item,
          checked: false
        }))
      }

      if (state.firstField === action.payload) {
        if (state.secondField) {
          state = {
            ...state,
            [state.secondField]: []
          }
        }

        if (state.thirdField) {
          state = {
            ...state,
            [state.thirdField]: []
          }
        }
      } else if (state.secondField === action.payload) {
        if (state.thirdField) {
          state = {
            ...state,
            [state.thirdField]: []
          }
        }
      }

      return state;
    }

    case businessUnitPropsConstants.CLEAR_ALL_BUSINESS_UNIT_PROP: {
      return {
        ...state,
        local: [],
        occupationArea: [],
        businessUnit: [], 
      }
    }

    default: {
      return state;
    }
  }
}