import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  input: {
    padding: '27px 12px 10px'
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -8,
    marginTop: 8,
  },
  checkboxText: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 18,
    fontWeight: 600,
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  stateTitle: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginRight: 16,
  },
  state: {
    fontSize: 14.22,
    color: theme.palette.neutrals.low.main,
    marginLeft: 8,
  },
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 4)
    }
  },
  marginTopHigh: {
    marginTop: 20
  },
  card: {
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.dark}`
  }
}));