import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  makeStyles,
  DialogActions,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Input, LoadingButton } from '../Shared';
import { ideaConclusionActions, fluxOfIdeasActions } from '../../actions';
import { useIdeaDetailsController } from '../../containers/IdeaDetailsPage';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'initial',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(2, 3)
  },
}));

export function DialogJustification({ open, onClose, id }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { errors, register, handleSubmit } = useForm({
    mode: 'onBlur',
  });
  const { loadingCancelIdea } = useSelector(state => state.ideaConclusion);
  const { loadingUpdate } = useSelector((state) => state.fluxIdeas);
  const { hasIdeaFlux } = useIdeaDetailsController();

  async function onSubmit(data) {
    const ideaStepFlux = hasIdeaFlux?.currentStepFlux?.ideaStepFlux;

    await dispatch(ideaConclusionActions.cancelConclusionIdea(
      id,
      data,
      t('a_execucao_da_ideia_foi_cancelada_com_sucesso')
    ));

    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      let evaluationData = {
        decision: ideaStepFlux?.decisions.filter(item => item.typeDecision === 'NextStepRejected')[0],
        managerObservation: data.executionJustify,
        personalizedEvaluationsRates: ideaStepFlux?.usePersonalizedEvaluation
      }
  
      await dispatch(fluxOfIdeasActions.updateIdeaStageInFlux(
        id,
        evaluationData,
        t('sua_avaliacao_foi_feita_com_sucesso'),
        ideaStepFlux?.attachmentsRequired
      ));
    }

    onClose();
    history.push(`/idea/${hasIdeaFlux._id}/general`);
  }

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open}>
      <DialogTitle onClose={onClose} disableTypography className={classes.dialogTitle}>
        <Typography variant="h3">
          {t('justificar_execucao_nao_concluida')}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.root}>
        <Input
          error={!!errors.executionJustify}
          style={{ margin: 8 }}
          placeholder={
            t('informe_o_motivo_da_execucao_da_ideia_nao_ter_sido_concluida') +
            '...'
          }
          InputProps={{ disableUnderline: true }}
          multiline
          minRows={8}
          name="executionJustify"
          inputRef={register({
            required: t('o_motivo_da_execucao_da_ideia_e_obrigatorio'),
          })}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.25rem', fontWeight: 600 },
          }}
          helperText={errors.executionJustify && errors.executionJustify.message}
        />
      </DialogContent>
      <DialogActions>
        <Box
          className={classes.actions}
          justifyContent={loadingCancelIdea ? 'flex-end' : 'space-between'}
        >
          {(loadingCancelIdea || loadingUpdate) ? <LoadingButton /> : (
            <>
              <Button color="primary" onClick={onClose}>
                {t('cancelar')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {t('enviar_justificativa')}
              </Button>
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}