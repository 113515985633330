export const ideaUpdateConstants = {
  GET_TO_UPDATE_IDEA_USER_DETAILS_REQUEST: 'GET_TO_UPDATE_IDEA_USER_DETAILS_REQUEST',
  GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS: 'GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS',
  GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE: 'GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE',

  IDEA_UPDATE_GET_PARTICIPANTS_REQUEST: 'IDEA_UPDATE_GET_PARTICIPANTS_REQUEST',
  IDEA_UPDATE_GET_PARTICIPANTS_SUCCESS: 'IDEA_UPDATE_GET_PARTICIPANTS_SUCCESS',
  IDEA_UPDATE_GET_PARTICIPANTS_FAILURE: 'IDEA_UPDATE_GET_PARTICIPANTS_FAILURE',
  UPDATE_IDEA_REQUEST: "UPDATE_IDEA_REQUEST",
  UPDATE_IDEA_SUCCESS: 'UPDATE_IDEA_SUCCESS',
  UPDATE_IDEA_FAILURE: 'UPDATE_IDEA_FAILURE',
  
  PUT_TITLE_DESCRIPTION: 'PUT_TITLE_DESCRIPTION',
  PUT_CANVAS: 'PUT_CANVAS',
  PUT_CANVAS_VALUE: 'PUT_CANVAS_VALUE',
  PUT_LINKS: 'PUT_LINKS',
  PUT_SET_IMAGES: 'PUT_SET_IMAGES',

  CLEAR_UPTADE_IDEA_DETAILS: 'CLEAR_UPTADE_IDEA_DETAILS',

  REMOVE_IMAGE: 'REMOVE_IMAGE',

  SET_PUT_SELECTED_BUSINESS: 'SET_PUT_SELECTED_BUSINESS',

  PUT_SET_VIDEO: 'PUT_SET_VIDEO',
  REMOVE_VIDEO: 'REMOVE_VIDEO',

  PUT_SET_FILE: 'PUT_SET_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  LOAD_EDIT_IDEA_CHALLENGES: "LOAD_EDIT_IDEA_CHALLENGES",
  LOAD_EDIT_IDEA_CLASSIFICATIONS: 'LOAD_EDIT_IDEA_CLASSIFICATIONS',
  CHANGE_IDEIA_EDIT_FIELD: "CHANGE_IDEIA_EDIT_FIELD",
  ADD_IDEIA_EDIT_LINK: "ADD_IDEIA_EDIT_LINK",
  REMOVE_IDEIA_EDIT_LINK: "REMOVE_IDEIA_EDIT_LINK",
  CHANGE_IDEA_EDIT_LINK: "CHANGE_IDEA_EDIT_LINK",
  CLEAR_CHALLENGE_FIELD: 'CLEAR_CHALLENGE_FIELD',
  CLEAR_CLASSIFICATION_FIELD: 'CLEAR_CLASSIFICATION_FIELD',
  CHANGE_FIELD_CLASSIFICATION: 'CHANGE_FIELD_CLASSIFICATION',
  IDEA_UPDATE_CHANGE_PARTICIPANT_FIELD: 'IDEA_UPDATE_CHANGE_PARTICIPANT_FIELD',
  IDEA_UPDATE_CLEAR_PARTICIPANT_FIELD: 'IDEA_UPDATE_CLEAR_PARTICIPANT_FIELD',
  IDEA_UPDATE_ADD_NEW_PARTICIPANT: 'IDEA_UPDATE_ADD_NEW_PARTICIPANT',
  IDEA_UPDATE_REMOVE_PARTICIPANT: 'IDEA_UPDATE_REMOVE_PARTICIPANT',
  IDEA_UPDATE_LOAD_PARTICIPANTS: 'IDEA_UPDATE_LOAD_PARTICIPANTS'
};
