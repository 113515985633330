import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: 12,
    marginBottom: theme.spacing(3)
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    padding: theme.spacing(2),
  },
  iconCardAction: {
    marginRight: "0.5rem"
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
}))(MuiSkeleton);

export const SkeletonCardsHome = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {[1,2,3,4,5].map((item, index) => (
          <Card elevation={0} className={classes.card} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.flex}>
                <Skeleton width={56} height={56} variant="circle" />
                <Box width="80%" marginLeft="1rem">
                  <Skeleton width={'40%'} variant="text" />
                  <Skeleton width={'30%'} variant="text" />
                  <Skeleton width={'20%'} variant="text" />
                </Box>
                <Skeleton width={25} height={25} variant="circle" style={{ float: 'right' }} />
              </Grid>

              <Grid item xs={12}>
                <Skeleton width={'70%'} variant="text" style={{ float: 'right' }} />
              </Grid>

              <Grid item xs={12}>
                <Skeleton width="40%" variant="text" />
                <Skeleton width="100%" variant="text" />
                <Skeleton width="100%" variant="text" />
                <Skeleton width="100%" variant="text" />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.actions}>
                  <Grid item xs={3}>
                    <Box display="flex" alignItems="center" width="100%">
                      <Skeleton width="1.5rem" height="1.5rem" variant="circle" className={classes.iconCardAction} />
                      <Skeleton width="40%" variant="text" />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" alignItems="center" width="100%">
                      <Skeleton width="1.5rem" height="1.5rem" variant="circle" className={classes.iconCardAction} />
                      <Skeleton width="calc(80% - 1.5rem)" variant="text" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Grid>
    </Grid>
  );
}