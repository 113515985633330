export const avaliationDialogsConstants = {
  HANDLE_OPEN_QUALIFICATION_ATTACHMENT_DIALOG: 'HANDLE_OPEN_QUALIFICATION_ATTACHMENT_DIALOG',
  HANDLE_OPEN_QUALIFICATION_IMAGE_DIALOG: 'HANDLE_OPEN_QUALIFICATION_IMAGE_DIALOG',
  HANDLE_OPEN_QUALIFICATION_VIDEO_DIALOG: 'HANDLE_OPEN_QUALIFICATION_vIDEO_DIALOG',
  HANDLE_OPEN_QUALIFICATION_DOCUMENT_DIALOG: 'HANDLE_OPEN_QUALIFICATION_DOCUMENT_DIALOG',
  SET_STEP_FLUX_IMAGES: 'SET_STEP_FLUX_IMAGES',
  SET_STEP_FLUX_VIDEO: 'SET_STEP_FLUX_VIDEO',
  SET_STEP_FLUX_FILES: 'SET_STEP_FLUX_FILES',
  REMOVE_STEP_FLUX_IMAGE: 'REMOVE_STEP_FLUX_IMAGE',
  CLEAR_STEP_FLUX_DIALOGS: 'CLEAR_STEP_FLUX_DIALOGS',
  REMOVE_STEP_FLUX_VIDEO: 'REMOVE_STEP_FLUX_VIDEO',
  REMOVE_STEP_FLUX_FILE: 'REMOVE_STEP_FLUX_FILE',
  SAVE_STEP_FLUX_ATTACHMENTS: 'SAVE_STEP_FLUX_ATTACHMENTS',
  REMOVE_STEP_FLUX_IMAGE_RENDER: 'REMOVE_STEP_FLUX_IMAGE_RENDER',
  REMOVE_STEP_FLUX_VIDEO_RENDER: 'REMOVE_STEP_FLUX_VIDEO_RENDER',
  REMOVE_STEP_FLUX_FILE_RENDER: 'REMOVE_STEP_FLUX_FILE_RENDER'
};