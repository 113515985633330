import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  Container,
  makeStyles,
  MenuItem,
  Typography,
  useTheme,
  CircularProgress
} from '@material-ui/core';
import { BackNavAdmin, RoundedIcon } from '../../components';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { notificationActions } from '../../actions';
import { dateFormat, timeFormat } from '../../helpers';
import { ChatBubble, EmojiObjects, Label, ThumbUp } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  menu: {
    position: 'relative',
    overflowY: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 2, 4),
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 9999,
  },
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
    textTransform: 'lowercase',
  },
  item: {
    padding: theme.spacing(2, 2, 3, 4),
  },
  paper: {
    overflowY: 'hidden',
  },
  icone: {
    marginRight: 10,
    color: theme.palette.neutrals.low.light,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginRight: 0,
      marginBottom: 5,
    },
  },
  notificationTitle: {
    fontSize: 14.22,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.dark,
    whiteSpace: 'wrap'

  },
  readNotificationTitle: {
    fontSize: 14.22,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.light,
    whiteSpace: 'wrap'
  }
}));

export function NotificationPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { paging, notifications, loading } = useSelector(
    (state) => state.notification
  );
  const classes = useStyles();
  const language = useSelector((state) => state.language);

  function loadMoreNotification() {
    if (!loading) {
      dispatch(
        notificationActions.loadMoreNotification(paging.page, paging.sort)
      );
    }
  }

  function markAllRead() {
    dispatch(notificationActions.markAllRead());
  }

  function renderIcon(type) {
    if (type === 'send') {
      return <EmojiObjects />;
    }

    if (type === 'up') {
      return <ThumbUp />;
    }

    if (type === 'comment') {
      return <ChatBubble />;
    }

    if (type === 'execution') {
      return <Label />;
    }
    if (type === 'challenge') {
      return <EmojiObjects />;
    }
  }

  return (
    <>
      <BackNavAdmin title={t('notificacoes')} pathname="/" />
      <Container>
        <Card style={{ marginTop: 35, height: '65dvh' }}>
          <CardActions>
            <Box padding={2} width='100%' display='flex' justifyContent='space-between'>
              <Box display='flex' alignItems='center'>
                <RoundedIcon>
                  <NotificationsIcon color='primary' />
                </RoundedIcon>
                <Typography variant="h3">{t('notificacoes')}</Typography>
              </Box>
              <Button
                variant='text'
                style={{ color: theme.palette.primary.main }}
                onClick={markAllRead}
              >
                {t('marcar_todos_como_lidos')}
              </Button>
            </Box>
          </CardActions>
          <InfiniteScroll
            dataLength={notifications.length}
            next={loadMoreNotification}
            hasMore={true}
            height='50dvh'
            loader={loading && <Box width='100%' display='flex' justifyContent='center'><CircularProgress /></Box>}
          >
            {notifications.map((notification) => (
              <MenuItem
                key={notification._id}
                className={classes.item}
              >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box className={classes.icone}>
                    {renderIcon(notification.type)}
                  </Box>
                  <Box>
                    <Typography
                      className={notification.read ? classes.readNotificationTitle : classes.notificationTitle}
                    >
                      {notification.message}
                    </Typography>
                    <Typography style={{ fontSize: 11.44, color: theme.palette.neutrals.low.light }}>
                      {dateFormat(notification.date, language)} |{' '}
                      {timeFormat(notification.date)}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </InfiniteScroll>
        </Card>
      </Container>
    </>
  );
}
