import config from '../config';
import axios from 'axios';
import { handleResponse, requestHeaders } from '../helpers';

export const delegateStepIdeaService = {
  postDelegateStepIdea,
  getAllUsers,
  forwardingStepFlux
};

function postDelegateStepIdea(ideaId, delegateDetails) {
  return axios
    .post(`${config.URL_BASE}/ideas/by-step-flux/delegate-step-idea/${ideaId}`, delegateDetails,requestHeaders())
    .then(handleResponse)
    .then((response) => response);
}

function getAllUsers() {
  return axios.get(`${config.URL_BASE}/user/all-users-name`, requestHeaders()).then(handleResponse);
}

function forwardingStepFlux(ideaId, stepId) {
  return axios.post(`${config.URL_BASE}/ideas/by-step-flux/delegate-idea-to-other-step/${ideaId}/${stepId}`, {}, requestHeaders());
}