import { portfolioConstants } from '../../constants/portfolio.constants';

const initialState = {
  loading: false,
  loadingUpdateTask: false,
  successUpdateTask: false,
  errorUpdateTask: false,
  project: {},
  responsibles: [],
  responsiblesLoading: false,
  taskTabChosed: "",
  teams: {
    loading: false,
    details: [],
    totalTasks: [],
  },
  financialPlanning: {
    loading: false,
    details: {
      costs: [],
      estimatedFinancialReturn: 0,
    }
  },
  allTasks: [],
  tasks: {
    loading: false,
    error: false,
    items: false,
  },
}

export function portfolioDetails(state = initialState, action) {
  switch (action.type) {
    case portfolioConstants.GET_ONE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case portfolioConstants.GET_ONE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false
      };

    case portfolioConstants.GET_ONE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.payload
      };



    case portfolioConstants.GET_PORTFOLIO_TEAMS_REQUEST:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: true
        }
      };

    case portfolioConstants.GET_PORTFOLIO_TEAMS_SUCCESS:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          details: action.payload.teams,
          totalTasks: action.payload.totalTasks,
        }
      };

    case portfolioConstants.GET_PORTFOLIO_TEAMS_FAILURE:
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
        }
      };

    case portfolioConstants.GET_FINANCIAL_PLANNING_REQUEST:
      return {
        ...state,
        financialPlanning: {
          ...state.financialPlanning,
          loading: true
        }
      };

    case portfolioConstants.GET_FINANCIAL_PLANNING_SUCCESS:
      return {
        ...state,
        financialPlanning: {
          ...state.financialPlanning,
          loading: false,
          details: action.payload
        }
      };

    case portfolioConstants.GET_FINANCIAL_PLANNING_FAILURE:
      return {
        ...state,
        financialPlanning: {
          ...state.financialPlanning,
          loading: false,
        }
      };

    case portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }

    case portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_FAILURE:
      return {
        ...state,
        loading: false,
        success: true,
      }

    case portfolioConstants.GET_ALL_TASKS_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: true
        }
      };

    case portfolioConstants.GET_ALL_TASKS_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: false,
          items: action.payload
        }
      };

    case portfolioConstants.GET_ALL_TASKS_FAILURE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          loading: false,
          error: true
        }
      };

    case portfolioConstants.CHANGE_TASKS_PAGING: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          paging: {
            ...state.tasks.paging,
            [action.payload.name]: action.payload.value
          }
        }
      };
    }

  
    case portfolioConstants.CHANGE_TASK_TABLE_FILTERS: {
      return {
        ...state,
        tasks: {
          ...state.details,
          table: {
            ...state.details.table,
            [action.payload.field]: action.payload.value
          }
        }
      }
    }

    case portfolioConstants.PORTFOLIO_GET_ALL_USERS_NAME_REQUEST: {
      return {
        ...state,
        responsiblesLoading: true,
      }
    }

    case portfolioConstants.PORTFOLIO_GET_ALL_USERS_NAME_SUCCESS: {
      return {
        ...state,
        responsiblesLoading: false,
        responsibles: action.payload.map(item => (
          {
            name: item.name,
            value: item._id,
          }
        ))
      }
    }

    case portfolioConstants.PORTFOLIO_GET_ALL_USERS_NAME_FAILURE: {
      return {
        ...state,
        responsiblesLoading: false,
      }
    }

    case portfolioConstants.CHANGE_PORTFOLIO_FIELD: {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    }

    case portfolioConstants.CHANGE_PORTFOLIO_TASK_STATUS_REQUEST: {
      return {
        ...state,
        loadingUpdateTask: true,
      }
    } 
    
    case portfolioConstants.CHANGE_PORTFOLIO_TASK_STATUS_SUCCESS: {
      return {
        ...state,
        loadingUpdateTask: false,
        successUpdateTask: true,
      }
    }

    case portfolioConstants.CHANGE_PORTFOLIO_TASK_STATUS_FAILURE: {
      return {
        ...state,
        loadingUpdateTask: false,
        errorUpdateTask: true,        
      }
    }

    default: 
      return state;
  }
}