import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PushPinIcon } from '../../assets/imgs/push_pin.svg';
import { ReactComponent as FactCheckIcon} from '../../assets/imgs/fact_check.svg';

import { StatusMenu } from './StatusMenu';
import { DialogPortifolio } from './DialogPortifolio';

const useStyles = makeStyles((theme) => ({
  chip: {
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 4,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lightest
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.neutrals.low.dark
  }
}));

export function DialogDetailsTask({ open, onClose, task, status }) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogPortifolio
      style={{ maxWidth: 700 }}
      open={open}
      onClose={onClose}
      title={t('detalhes_da_tarefa')}
      content={
        <>
          <Grid item xs={12}>
            <Chip
              label={t('tarefa')}
              icon={<PushPinIcon style={{ fill: theme.palette.primary.main, transform: 'scale(0.75)' }} />}
              className={classes.chip}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>{t('nome_da_tarefa')}</Typography>
            <Typography className={classes.text}>{task?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>{t('status')}</Typography>
            <StatusMenu 
              selectedStatus={task?.taskStatus} 
              allStatus={status} 
              type={"task"} 
              id={task?._id} 
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>{t('descricao_da_tarefa')}</Typography>
            <Typography className={classes.text}>{task?.description}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>{t('responsavel')}</Typography>
            <Typography className={classes.text}>{task?.responsible?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.subtitle}>{t('data_de_conclusao')}</Typography>
            <Typography className={classes.text}>{moment(task?.conclusionDate).format('DD/MM/YYYY')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Chip
              label={t('subtarefas')}
              icon={<FactCheckIcon style={{ fill: theme.palette.primary.main, transform: 'scale(0.75)' }} />}
              className={classes.chip}
            />
          </Grid>
          {task?.subTasks?.map((item) => (
            <>
              <Grid item xs={4}>
                <Typography className={classes.subtitle}>{t('subtarefa')}</Typography>
                <Typography className={classes.text}>{item?.description}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.subtitle}>{t('responsavel')}</Typography>
                <Typography className={classes.text}>{item?.responsible?.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.subtitle}>{t('data')}</Typography>
                <Typography className={classes.text}>{moment(item?.conclusionDate).format('DD/MM/YYYY')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.subtitle}>{t('status')}</Typography>
                <StatusMenu 
                  selectedStatus={item?.taskStatus} 
                  allStatus={status} 
                  type={"subtask"} 
                  id={item?._id} 
                />
              </Grid>
            </>
          ))}
        </>
      }
    />
  );
}