import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core';
import { ArrowBack, Close, Description, Image, VideoLibrary } from '@material-ui/icons';
import { avaliationDialogsActions } from '../../../actions';
import { HeaderTitle } from '../../EvaluationTeams/styles';

export const AttachmentsDialog = () => {
  const { t } = useTranslation();
  const { attachmentDialog } = useSelector(state => state.avaliationDialogs);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(avaliationDialogsActions.openAttachmentDialog());
  };

  const handleOpenDialogImage = () => {
    dispatch(avaliationDialogsActions.openImageDialog());
  };

  const handleOpenDialogVideo = () => {
    dispatch(avaliationDialogsActions.openVideoDialog());
  };

  const handleOpenDialogDocument = () => {
    dispatch(avaliationDialogsActions.openDocumentDialog());
  };

  return (
    <Dialog open={attachmentDialog} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={handleClose}>
            <ArrowBack color='primary' />
          </IconButton>
          <HeaderTitle>{t('adicionar_anexo')}</HeaderTitle>
          <IconButton onClick={handleClose}>
            <Close color='primary' />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <p>{t('adicionar_anexos_para_invrementar_a_qualificação_da_ideia')}</p>
        <Box display='flex' flexDirection='column' alignItems='start'>
          <Button startIcon={<Image />} onClick={handleOpenDialogImage} color="primary">
            {t('adicionar_imagem')}
          </Button>
          <Button startIcon={<VideoLibrary />} onClick={handleOpenDialogVideo} color="primary">
            {t('adicionar_video')}
          </Button>
          <Button startIcon={<Description />} onClick={handleOpenDialogDocument} color="primary">
            {t('adicionar_documento')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
