import React, { Fragment, useState } from 'react'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core" 
import { useDispatch, useSelector } from "react-redux"
import { multiAreaCascadeActions } from "./../../../actions"
import { MultiAreaCascade } from "./../../../components"

const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: 14,
    borderRadius: 0,
    backgroundColor: "#fff",
    boxShadow: 'none',
    width: "100%",
    textAlign: 'left',
  },
  filterButtonIcon: {
    color: "#343A40",
    marginRight: "0.5rem",
    fontSize: "20px"
  },
  filterButtonArrow: {
    fontSize: "1rem",
    color: "#343A40",
    marginLeft: "0.5rem"
  },
  filterButtonBadge: {
    fontSize: "10px",
    color: "#343A40",
  },
  filterMenu: {
    width: "328px",
    backgroundColor: "#fff",
    padding: "1rem",
  },
  filterMenuHeader: {
    backgroundColor: "#fff",
    padding: "1rem",
    width: "328px",
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
  },
  filterMenuHeaderTitle: {
    color: theme.palette.grey.darkest,
    fontSize: "14px",
    fontWeight: "400",
  },
  select: {
    width: "100%"
  },
}))

export const DashboardTopColaboratorFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    paging,
    loading,
    paging: { quantityPerPage, filter, sort, query },
  } = useSelector((state) => state.wallet);
  const {
    selectedUser,
  } = useSelector(state => state.userFunction)
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function filterClean(e) {
    dispatch(multiAreaCascadeActions.clearAll());
  }

  function handleFilter() {
  
  }

  return (
    <Fragment>
      <Button 
        variant="contained" 
        className={classes.filterButton}
        onClick={handleClick}
        textAlign="left"
        children={(
          <Box display="flex" alignItems="center" width="100%">
            <FilterListIcon className={classes.filterButtonIcon} />
            filtro
          </Box>
        )}
      />
      { Boolean(anchorEl) && (
          <Menu
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box display="flex" flexDirection="column">
            <Box className={classes.filterMenuHeader}>
              <Typography component="p" className={classes.filterMenuHeaderTitle}>filtrar por</Typography>
            </Box>
            <Box className={classes.filterMenu}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MultiAreaCascade columns={'vertical'} instance={2} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <Button variant="outlined" color="primary" onClick={filterClean} disabled={loading}>
                          limpar filtro
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button 
                          variant="contained" 
                          color="primary"
                          startIcon={<FilterListIcon />}
                          onClick={handleFilter}
                          disabled={loading}
                        >
                          filtrar
                        </Button>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Menu>
      )}
    </Fragment>
  )
}
