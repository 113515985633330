import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { evaluationTeamsActions } from '../../../actions';

export function useEvaluationTeamsController() {
  const dispatch = useDispatch();
  const [openDialogCreateEvaluationTeam, setOpenDialogCreateEvaluationTeam] = useState(false);

  function toogleEvaluationTeamDialog() {
    setOpenDialogCreateEvaluationTeam(!openDialogCreateEvaluationTeam);
  }

  function searchTeamOrCollaborator(searchText) {
    dispatch( evaluationTeamsActions.getAllEvaluationTeams(searchText));
  }

  return {
    searchTeamOrCollaborator,
    openDialogCreateEvaluationTeam,
    toogleEvaluationTeamDialog
  }
}