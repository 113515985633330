import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from "@material-ui/core"
import { ideaEditStyles } from './IdeaEditStyles'
import Info from '@material-ui/icons/Info';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import dialogPhotoSvg from '../../assets/imgs/dialog-photo.svg';
import { EditUpload, EditUploadButton } from './../../components/Upload';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';

export const IdeaEditGeneralImage = () => {
  const classes = ideaEditStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatarIcon}>
              <PhotoCameraIcon color="primary" />
            </Avatar>
            <Typography variant="h3">{t('imagens')}</Typography>
          </Box>
          <EditUploadButton 
            type="photo"
            variant="text"
            text={t('adicionar_imagem')}
            icon={<AddCircleOutlinedIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <EditUpload
            type="photo"
            img={dialogPhotoSvg}
            name="foto"
            accept="image/png, image/jpeg"
            message=""
            maxSize={5242880}
          />
          <Box display="flex" alignItems="center" className={classes.info}>
            <Info style={{fontSize: 15, marginRight: 7 }}/>
            <Typography variant="caption">
              {t('o_arquivo_deve_ser_em_formato')}{' '}
              <strong>png</strong>{' '}{t('e')}{' '}
              <strong>jpg</strong>{' '}{t('e')}{' '}
              {t('possuir_tamanho_maximo_de')}{' '}<strong>5mb</strong>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
