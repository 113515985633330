import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
  makeStyles,
  Box
} from '@material-ui/core';
import { tableCss } from './TableCss';
import { truncate } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { manageUsersActions, walletActions } from "../../actions";
import { useTranslation } from 'react-i18next';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';

const useStyle = makeStyles(theme => ({
  icon: {
    color: theme.palette.neutrals.low.light,
    fontSize: 14,
    marginRight: "0.5rem",
  }
}));

function UsersPointsTable() {
  const dispatch = useDispatch();
  const {
    paging,
    wallets,
    points,
    paging: { quantityPerPage, filter, sort, query },
  } = useSelector((state) => state.wallet);
  const { selectedUser } = useSelector(state => state.userFunction)
  const classes = useStyle();
  const { t } = useTranslation();
  const columns = [
    { id: 'date', label: t('data') },
    { id: 'action', label: t('acao') },
    { id: 'ideia', label: t('ideia') },
    { id: 'pontos', label: "pontos" },
  ];

  const tableClasses = tableCss({
    text: t('linhas_por_pagina'),
  });

  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(walletActions.setPage(newPage));
    dispatch(walletActions.getWallets(selectedUser.id, newPage, quantityPerPage, filter, sort, query))
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(walletActions.getUserPoints(selectedUser.id));
    dispatch(walletActions.getWallets(selectedUser.id, 1, limit, filter, sort, query))
  }

  function getIconsWallet(type) {
    switch (type) {
      case 'COMMENT_UP_GIVEN':
        return <ThumbUpIcon className={classes.icon} />;

      case 'COMMENT_UP_GIVEN_REMOVED':
        return <ThumbDownIcon className={classes.icon} />;

      case 'COMMENT_UP_RECEIVED':
        return <ThumbUpIcon className={classes.icon} />;

      case 'COMMENT_UP_RECEIVED_REMOVED':
        return <ThumbDownIcon className={classes.icon} />;

      case 'IDEA_APPROVED':
        return <CheckCircleIcon className={classes.icon} />;

      case 'IDEA_COMMENT':
        return <ChatBubbleIcon className={classes.icon} />;

      case 'IDEA_COMMENT_GIVEN':
        return <ChatBubbleIcon className={classes.icon} />;

      case 'IDEA_COMMENT_GIVEN_COMMENT':
        return <ChatBubbleIcon className={classes.icon} />;

      case 'IDEA_COMMENT_REMOVED':
        return <ChatBubbleIcon className={classes.icon} />;

      case 'IDEA_COMMENT_GIVEN_REMOVED':
        return <ChatBubbleIcon className={classes.icon} />;

      case 'IDEA_EXECUTED':
        return <CheckCircleIcon className={classes.icon} />;

      case 'IDEA_UP':
        return <ThumbUpIcon className={classes.icon} />;

      case 'IDEA_UP_GIVEN':
        return <ThumbUpIcon className={classes.icon} />;

      case 'IDEA_UP_GIVEN_REMOVED':
        return <ThumbDownIcon className={classes.icon} />;

      case 'IDEA_UP_REMOVED':
        return <ThumbDownIcon className={classes.icon} />;

      case 'NEW_IDEA':
        return <EmojiObjectsIcon className={classes.icon} />;

      case 'NEW_USER':
        return <PersonIcon className={classes.icon} />;

      default:
        return null;
    }
  }

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="users table"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  classes={{ root: tableClasses.tableCellHeader }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {wallets.length > 0 && wallets.map(item => {
              return (
                <TableRow
                  hover
                  key={item._id}
                  style={{ cursor: 'pointer' }}
                  classes={{ root: tableClasses.tableRow }}
                >
                  <TableCell classes={{ root: tableClasses.tableCell }}>
                    { moment(new Date(item.createdAt)).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell classes={{ root: tableClasses.tableCell }}>
                    <Box display="flex" alignItems="center">
                      {getIconsWallet(item.score?.type)} {item.score?.label}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: tableClasses.tableCell }}>
                    {truncate(item.idea.name, 30)}
                  </TableCell>
                  <TableCell classes={{ root: tableClasses.tableCell }}>
                    {item.amount}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={paging.total}
        rowsPerPage={paging.quantityPerPage}
        page={paging.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
      />
    </Paper>
  );
}

export { UsersPointsTable };

