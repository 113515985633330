import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textTransform: 'lowercase',
    borderRadius: '20px',
    fontWeight: 400,
    height: 32,
    backgroundColor: theme.palette.primary.main,
  },
}));

function Loading() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button color="primary" variant="contained" className={classes.button}>
      {t('carregando_mais_ideias')}
    </Button>
  );
}

export { Loading };
