import React from 'react';
import { Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ImagineSectionTitle } from '../../components/ImagineTemplates/ImagineSectionTitle';
import { AddCircle, Assistant, Create } from '@material-ui/icons';
import { useState } from 'react';
import { DialogCustomTitle, TutoriaisTable, UploadButton } from '../../components';
import { ImagineFormTextField } from '../../components/ImagineTemplates/ImagineFormTextField';
import { useDispatch, useSelector } from 'react-redux';
import { FileList } from '../../components/Upload/FileList';
import { uploadsAcitons } from '../../actions';
import { useEffect } from 'react';
import { tutorialsActions } from '../../actions/tutorials.actions';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  titleIcon: {
    color: theme.palette.primary.main,
    width: 18
  }
}));

function DialogTutorial() {
  const { tutorialName, tutorialFile, openDialog, dialogIsEdit, fileToDeleteId, fileUrl, tutorialId } = useSelector(state => state.tutorialsReducer)
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleSetTitle(name) {
    dispatch(uploadsAcitons.setFileTitle(name));
  };

  function handlePost() {
    dialogIsEdit ? dispatch(tutorialsActions.putTutorialFile(tutorialName, fileUrl, tutorialId, fileToDeleteId, tutorialFile)) : dispatch(uploadsAcitons.postTutorialFile(tutorialFile, tutorialName));
    dispatch(tutorialsActions.handleDialog({ open: false, isEdit: true, tutorialId: "" }))
  }

  function handleDeleteFile(id) {
    dispatch(uploadsAcitons.deleteFile(id));
  }

  function handleSetUrl(url) {
    dispatch(tutorialsActions.setTutorialUrl(url))
  }
  return (<Dialog open={openDialog} onClose={() => dispatch(tutorialsActions.handleDialog({ open: false, isEdit: true }))}>
    <DialogCustomTitle onClose={() => dispatch(tutorialsActions.handleDialog({ open: false, isEdit: true }))}>{dialogIsEdit ? t('editar_tutorial') : t('novo_tutorial')}</DialogCustomTitle>
    <Divider />
    <DialogContent>
      <ImagineFormTextField name="title" onChange={handleSetTitle} label={t('titulo_do_tutorial')} isRequired defaultValue={tutorialFile.title} />
      <ImagineFormTextField name="link" onChange={handleSetUrl} label={t('link do tutorial (opcional)')} />
      {(tutorialFile.id || tutorialFile._id) && <FileList files={[tutorialFile]} onDelete={handleDeleteFile} />}
    </DialogContent>
    <DialogActions style={{ width: '100%', display: 'flex', justifyContent: 'end', padding: '0 21px 21px 21px' }} >
      <DialogActions style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0 21px 0 21px' }} >
        <UploadButton
          type="file"
          text={t('adicionar_anexo')}
          icon={<AddCircle />}
          tutorialEdit
          disabled={tutorialFile.id || tutorialFile._id}
        />
        <Button variant="contained" color='primary' onClick={() => handlePost()}>{dialogIsEdit ? t('salvar_tutorial') : t('adicionar_tutorial')}</Button>
      </DialogActions>
    </DialogActions>

  </Dialog>)
}


export function TutorialsPage() {
  const { tutorialList, loading } = useSelector(state => state.tutorialsReducer)
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <DialogTutorial />
      <Grid item xs={12} lg={9}>
        <Container maxWidth="lg">
          <Typography gutterBottom>{t('tutoriais').toLocaleUpperCase()}</Typography>
          <Card style={{ padding: 32 }}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <ImagineSectionTitle icon={<Assistant className={classes.titleIcon} />} title={t('tutoriais').toLocaleLowerCase()} />
              <Button startIcon={<Create />} style={{ height: 48 }} variant='outlined' color='primary' onClick={() => dispatch(tutorialsActions.handleDialog({ open: true, isEdit: false }))}>{t('adicionar_tutorial')}</Button>
            </Box>
            <CardContent>
              <TutoriaisTable isTutorialEdit loading={loading} tutorials={tutorialList} />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </>
  );
}