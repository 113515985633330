import { portfolioConstants } from '../../constants/portfolio.constants';

const initialStateTeams = {
  name: '',
  value: ''
}

const initialState = {
  newTeam: [initialStateTeams],
  loading: false,
  success: false,
}

export function portfolioDialogTeams(state = initialState, action) {
  switch (action.type) {
    case portfolioConstants.PORTFOLIO_CHANGE_FIELD_TEAM:
      return {
        ...state,
        newTeam: state.newTeam.map((item, index) => {
          if (index === action.payload.index) {
            return action.payload.data
          }
          return item
        })
      }

    case portfolioConstants.CLEAR_PORTFOLIO_FIELD_TEAM:
      return {
        ...state,
        newTeam: state.newTeam.map((item, index) => {
          if (index === action.payload.index) {
            return action.payload.data
          }
          return item
        })
      }

    case portfolioConstants.PORTFOLIO_ADD_NEW_PARTICIPANT:
      return {
        ...state,
        newTeam: [
          ...state.newTeam,
          initialStateTeams,
        ]
      }

    case portfolioConstants.PORTFOLIO_REMOVE_PARTICIPANT:
      return {
        ...state,
        newTeam: state.newTeam.filter((item) => item.value !== action.payload)
      }

    case portfolioConstants.CLEAR_PORTFOLIO_DIALOG_TEAM:
      return initialState

    case portfolioConstants.LOAD_CREATE_TEAM_DIALOG:
      return { 
        ...state,
        newTeam: action.payload,
      }

    case portfolioConstants.PORTFOLIO_UPDATE_TEAMS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case portfolioConstants.PORTFOLIO_UPDATE_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }

    case portfolioConstants.PORTFOLIO_UPDATE_TEAMS_FAILURE:
      return {
        ...state,
        loading: false
      }

    default: 
      return state;
      
  }
}