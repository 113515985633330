import axios from 'axios';
import { history } from './helpers';

axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error

    // You can even test for a response code
    // and try a new request before rejecting the promise
    if (error.response.status === 401) {
      localStorage.clear();
      history.push('/');
      //   const requestConfig = error.config;
      //   return axios(requestConfig);
    }
    return Promise.reject(error);
  }
);
