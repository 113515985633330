import { manageUsersConstants } from '../constants';
import { manageUsersService } from "../services"

export const manageUsersActions = { 
  toggleFilter,
  getUsers,
  getBusinessUnit,
  toggleDialogDeleteUser,
  deleteUser,
  getFilteredUsers,
  getFilteredUsersMobile,
}

function toggleFilter(filter) {
  return (dispatch) => {
    dispatch({ 
      type: manageUsersConstants.USER_MANAGE_TOGGLE_FILTER, 
      payload: filter
    })
  };
}

function getUsers(query = '', page = 1, methodology = 1, limit = 10) {
  return (dispatch) => {
    dispatch({
      type: manageUsersConstants.TOGGLE_USER_MANAGE_LOADING
    });

    manageUsersService.getUserType()
      .then((userType) => {
        
        dispatch({
          type: manageUsersConstants.GET_FILTERS_SUCCESS,
          payload: userType,
        }); 

        const statusCode = userType.map(item => item.type)

        manageUsersService.getUsers(query, page, methodology, limit, statusCode)
          .then((users) => {
            dispatch({
              type: manageUsersConstants.GET_USERS_TO_MANAGE_SUCCESS,
              payload: {
                ...users,
                statusCode: statusCode
              }
            })
          })
      })
    .catch((err) => {
        dispatch({
          type: manageUsersConstants.GET_USERS_TO_MANAGE_FAILURE,
        })
    })
  }
}

function getFilteredUsersMobile(search, page, sort, limit, statusCode) {
  return (dispatch) => {
    dispatch({
      type: manageUsersConstants.TOGGLE_USER_MANAGE_LOADING
    });

    manageUsersService.getUsers(search, page, sort, limit, statusCode)
      .then((users) => {
        
        dispatch({
          type: manageUsersConstants.TOGGLE_USER_MANAGE_LOADING
        });

        dispatch({
          type: manageUsersConstants.GET_FILTERED_USERS_TO_MANAGE_MOBILE_SUCCESS,
          payload: {
            users: users.data,
            paging: {
              limit: users.paging?.limit || limit,
              page: users.paging?.page || page,
              skip: users.paging?.skip || 0,
              sort: users.paging?.sort || sort,
              total: users.paging?.total || users.data.length,
              search,
              statusCode,
            }
          }
        })
      })
  }
}

function getFilteredUsers(search, page, sort, limit, statusCode) {
  return (dispatch) => {
    dispatch({
      type: manageUsersConstants.TOGGLE_USER_MANAGE_LOADING
    });

    manageUsersService.getUsers(search, page, sort, limit, statusCode)
      .then((users) => {
        
        dispatch({
          type: manageUsersConstants.TOGGLE_USER_MANAGE_LOADING
        });

        dispatch({
          type: manageUsersConstants.GET_FILTERED_USERS_TO_MANAGE_SUCCESS,
          payload: {
            users: users.data,
            paging: {
              limit: users.paging?.limit || limit,
              page: users.paging?.page || page,
              skip: users.paging?.skip || 0,
              sort: users.paging?.sort || sort,
              total: users.paging?.total || users.data.length,
              search,
              statusCode,
            }
          }
        })
      })
  }
}


function getBusinessUnit() {
  return (dispatch) => {
    manageUsersService.getBusinessUnit()
      .then((response) => {
        dispatch({
          type: manageUsersConstants.GET_BUSINESS_UNIT_SUCCESS,
          payload: response.data
        })
      })
  }
}

function toggleDialogDeleteUser(userId) {
  return (dispatch) => {
    dispatch({
      type: manageUsersConstants.TOGGLE_DIALOG_DELETE_USER,
      payload: userId
    })
  }
}

function deleteUser(userId, search, page, sort, limit, statusCode) {
  return (dispatch) => {
    dispatch({
      type: manageUsersConstants.TOGGLE_USER_MANAGE_LOADING
    });

    manageUsersService.deleteUser(userId)
      .then((response) => {
        
        manageUsersService.getUsers(search, page, sort, limit, statusCode)
          .then((users) => {

            dispatch({
              type: manageUsersConstants.TOGGLE_DIALOG_DELETE_USER
            });
            
            dispatch({
              type: manageUsersConstants.TOGGLE_USER_MANAGE_LOADING
            });
    
            dispatch({
              type: manageUsersConstants.GET_FILTERED_USERS_TO_MANAGE_SUCCESS,
              payload: {
                users: users.data,
                paging: {
                  limit: users.paging?.limit || limit,
                  page: users.paging?.page || page,
                  skip: users.paging?.skip || 0,
                  sort: users.paging?.sort || sort,
                  total: users.paging?.total || users.data.length,
                  search,
                  statusCode,
                }
              }
            })
          })
        })
  }
}