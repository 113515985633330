import React from 'react'
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import makeStyles from '@material-ui/styles/makeStyles';
import { useSelector } from "react-redux";
import { formatBRLFromFloat } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  item: {
    marginTop: 16
  },
}))

export const CostsList = () => {
  const classes = useStyles();
  const { financialPlanning } = useSelector(state => state.portfolioDetails) 

  return (
    <>
     { financialPlanning.details !== undefined && (
        <>
          { financialPlanning.details?.costs?.map(({ order, description, estimatedValue }, key) => (
            <Box className={classes.item} key={key} display="flex" alignItems="center" justifyContent='space-between'>
              <Box>
                <Typography>{description}</Typography>
              </Box>
              <Box >
                <Box style={{ minWidth: '240px' }} >
                  <Typography>{`R$ ${estimatedValue}`}</Typography>
                </Box>
              </Box>
            </Box>
            ))
          }
        </>
     )}
    </>
  )
}
