import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { portfolioDetailsActions } from './../../actions/portfolio/portfolio-details.actions';

const Tab = withStyles({
  wrapper: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  }
})(MuiTab);

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  }
}));

export function PortifolioMenusTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectTasksStatus } = useSelector(state => state.domains);
  const { taskTabChosed } = useSelector(state => state.portfolioDetails);

  function handleChangeTab(code) {
    dispatch(portfolioDetailsActions.changeField("taskTabChosed", code))
  }

  return (
    <>
      { projectTasksStatus && (
        <Tabs
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          className={classes.tabs}
          value={taskTabChosed}
          onChange={(_e, value) => handleChangeTab(value)} 
        >
          {projectTasksStatus.map((item, index) => <Tab key={index} value={item.code} label={item.name} />)}
        </Tabs>
      )}
    </>
  );
}