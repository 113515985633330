import { Box, Button, makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  buttonSelected: {
    borderRadius: 32,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    padding: '0 20px',
    margin: 5
  },
  button: {
    borderRadius: 32,
    backgroundColor: 'transparent',
    border: `.5px solid #CED4DA`,
    padding: '0 24px',
    margin: 5
  }
}));

export function ImagineSwitchNav({ buttons = [{ label: '' }], handleClick, btnSelected="" }) {
  const { t } = useTranslation();
  const classes = useStyles();

  function handleBtnStyle(tag) {
    return tag === btnSelected ? classes.buttonSelected : classes.button;
  }


  return (
    <Box className={classes.flexContainer} marginBottom={5}>
      {buttons.map(({ label },  index) => (<Button
        key={index}
        onClick={() => handleClick(label)}
        className={handleBtnStyle(label)}
      >
        {t(label)}
      </Button>))}
    </Box>
  );
}