export const faqConstants = {
  GET_ALL_QUESTIONS_REQUEST: 'GET_ALL_QUESTIONS_REQUEST',
  GET_ALL_QUESTIONS_SUCCESS: 'GET_ALL_QUESTIONS_SUCCESS',
  GET_ALL_QUESTIONS_FAILURE: 'GET_ALL_QUESTIONS_FAILURE',

  GET_QUESTION_BY_ID_REQUEST: 'GET_QUESTION_BY_ID_REQUEST',
  GET_QUESTION_BY_ID_SUCCESS: 'GET_QUESTION_BY_ID_SUCCESS',
  GET_QUESTION_BY_ID_FAILURE: 'GET_QUESTION_BY_ID_FAILURE',

  DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_FAILURE: 'DELETE_QUESTION_FAILURE',

  SET_PAGE: 'SET_PAGE',
  SET_LIMIT: 'SET_LIMIT',

  ADD_NEW_FIELD: 'ADD_NEW_FIELD',
  SET_QUESTION : 'SET_QUESTION',
  SET_ANSWER: 'SET_ANSWER',
  CREATE_QUESTION_SUCCESS: 'CREATE_QUESTION_SUCCESS',
  CREATE_QUESTION_FAILURE: 'CREATE_QUESTION_FAILURE',
  CLEAR_FAQ_EDIT: 'CLEAR_FAQ_EDIT'
};
