import React, { useState, useEffect } from 'react'
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Delete";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete, createFilterOptions  } from '@material-ui/lab';
import { useSelector, useDispatch } from "react-redux";
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from "react-i18next"
import { DialogConfirmDeleteTask } from "./DialogConfirmDeleteTask"
import { portfolioDialogTaskActions } from "./../../../actions/portfolio/portfolio-dialog-task.actions";

const filterOptions = createFilterOptions({
  limit: 200,
});

const useStyles = makeStyles((theme) => ({
  description: {
    position: 'relative',
  },
  characters: {
    position: 'absolute',
    right: 18,
    fontSize: 12,
    padding: theme.spacing(1, 0),
  },
  btnFloat: {
    float: 'right',
  },
  deleteIcon: {
    cursor: "pointer",
    color: theme.palette.grey.darkest
  }
}));


//foi criado este componente, porque ao remover uma task, sem um controlled component (com estados controlados), podemos excluir corretamente
//usando o redux e alterando o redux pra exibir o estado da subtask, tem uma lentidao absurda
//a resolucao foi criar um estado do component pra controlar o component na hora de deletar uma subtask e nao ficar lento
export const SubtaskRow = ({ index, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [ subtaskState, setSubtaskState ] = useState([])
  const [indexToDelete, setIndexToDelete] = useState('');
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const { subTasks } = useSelector(state => state.portfolioDialogTask);
  const { teams } = useSelector(state => state.portfolioDetails)

  useEffect(() => {
    setSubtaskState(item)
  }, [item])

  function deleteSubtask(index, subtask) {
    return () => {
      setIndexToDelete(index)
      if (subtask._id) {
        handleOpenConfirmDelete()
      } else {
        dispatch(portfolioDialogTaskActions.removeSubtask(index))
      }
    }
  }

  function handleChangeSubtask(name, value) {
    setSubtaskState(state => ({
      ...state,
      [name]: value
    }))
  }

  function handleChangeDateSubtask(date, index) {
    if (date instanceof Date && isFinite(date)) {
      dispatch(portfolioDialogTaskActions.changeFieldSubtask("conclusionDate", date, index))
    }
  }

  function handleChangeResponsibleSubtask(data, index) {
    dispatch(portfolioDialogTaskActions.changeFieldSubtask("responsible", data?.option || { name: "", value: "" }, index));
  }

  function handleOpenConfirmDelete() {
    setOpenDialogConfirmDelete(true)
  }

  function handleCloseConfirmDelete() {
    setOpenDialogConfirmDelete(false)
  }

  function handleChangeSubtaskReducer(name, value, index) {
    dispatch(portfolioDialogTaskActions.changeFieldSubtask(name, value, index))
  }


  return (
    <Grid container spacing={2}>
      { openDialogConfirmDelete && <DialogConfirmDeleteTask open={openDialogConfirmDelete} onClose={handleCloseConfirmDelete} subtaskIndex={indexToDelete} /> }
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between" height="100%">
          <Typography variant="subtitle2">{t('subtarefa')}{' '}{index + 1}</Typography>
          <DeleteIcon className={classes.deleteIcon} onClick={deleteSubtask(index, item)} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <TextField 
          fullWidth
          variant="filled"
          label={t("nome_da_subtarefa")}
          onChange={(e) => handleChangeSubtask("description", e.target.value)}
          onBlur={(e) => handleChangeSubtaskReducer("description", e.target.value, index)}
          value={subtaskState?.description || null}
        />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KeyboardDatePicker
            variant="inline"
            inputVariant="filled"
            format="dd/MM/yyyy"
            fullWidth
            label={t("data")}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            name="startDate"
            onChange={date => handleChangeDateSubtask(date, index)}
            onBlur={(e) => handleChangeSubtaskReducer("startDate", e.target.value, index)}
            value={item?.conclusionDate || null}
            autoOk
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete 
            filterOptions={filterOptions}
            loadingText={t('carregando')}
            noOptionsText={t('sem_opcoes')}
            options={teams.details.map(item => ({ name: item.user.name, value: item.user._id }))}
            loading={teams.details.length === 0}
            value={item?.responsible || null}
            onChange={(e, items, options, details) => handleChangeResponsibleSubtask(details, index)}
            getOptionLabel={(input) => input.name || ""}
            renderInput={(params) => <TextField {...params} label={t('responsavel')} variant="filled" />}
            renderTags={(option, state) => (<span>{`${option[0].name}`}</span>)}
          />
        </Grid>
    </Grid>
  )
}
