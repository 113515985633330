import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const curationService = {
  getIdeas,
  filter,
  postPortfolioLinkedIdea,
  filterAdmin,
  getStepFluxIdeas
};

function getIdeas(page, sort, query, limit, statusCode) {
  return axios
    .get(
      `${
        config.URL_BASE
      }/ideas/manager?&page=${page}&statusCode=${JSON.stringify(
        statusCode
      )}&sort=${JSON.stringify(sort)}&q=${query}&limit=${limit}`,
      requestHeaders()
    )
    .then(handleResponse);
}


function filter(body) {
  return axios.post(`${config.URL_BASE}/curation/ideas`, body, requestHeaders()).then(handleResponse).then(({data}) => data);
}

function filterAdmin(body) {
  return axios.post(`${config.URL_BASE}/curation/ideas-list`, body, requestHeaders()).then(handleResponse).then(({data}) => data);
}

function postPortfolioLinkedIdea(data) {
  return axios.post(`${config.URL_BASE}/curation/ideas/portfolio`, data, requestHeaders())
    .then(handleResponse)
}
function getStepFluxIdeas(body = {
	statusCode: [],
	stepsIdeas: [],
	challenges: [],
  occupationAreas: [],
	page: 1,
	limit: 10,
	sort: {
		createAt: -1
	}
}) {
  return axios.post(`${config.URL_BASE}/curation/ideas-by-steps`, body, requestHeaders())
    .then(handleResponse)
}