import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { Box, Grid, Typography, styled } from '@material-ui/core';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import GetAppIcon from '@material-ui/icons/GetApp';
import { truncate } from '../../../helpers';
import { DialogCustom } from '../../Shared';

const Container = styled(Box)(({ theme }) => ({
  width: 260,
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  gap: 10,
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.neutrals.high.lightest,
  transition: 'all 0.3s ease-in-out',

  '&:hover': {
    opacity: 0.6,
    backgroundColor: theme.palette.neutrals.high.medium,
  }
}));

export function DialogToViewUploads({ open, onClose, attachment }) {
  const { t } = useTranslation();
  const images = attachment.mimeType.container === 'images';
  const video = attachment.mimeType.container === 'videos';
  const [isHovered, setIsHovered] = useState(false);

  function handleUpload() {
    saveAs(attachment.url, attachment.fileName);
  }

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title={`anexo de ${images ? 'imagem' : video ? 'video' : 'arquivo'}`}
      content={<>
        <Grid item xs={12}>
          <Typography variant="body2">
            {images ? t('a_imagem_esta_disponivel_para_ser_visualizada_aqui_caso_queira_baixar_e_so_clicar_no_anexo') :
             video ? t('clique_no_video_para_assistir_caso_queira_baixar_e_so_clicar_no_anexo') : null}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Container
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleUpload}
          >
            { video ? <VideocamOutlinedIcon style={{ fontSize: 25 }} /> 
            : images ? <CropOriginalIcon style={{ fontSize: 25 }} /> : null}
              <Box>
                <Typography variant="subtitle2">{truncate(attachment.fileName, 25)}</Typography>
                <Typography variant="body2">
                  {(attachment.size / (1024 * 1024)).toFixed(2)}mb
                </Typography>
              </Box>
            {isHovered && (
              <Box position="absolute" bottom="5px" right="10px">
                <GetAppIcon />
              </Box>
            )}
          </Container>
        </Grid>
        <Grid item xs={12}>
          {images ? <img src={attachment.url} alt={attachment.fileName} width="100%" /> :
            video ? (
            <video controls width="100%">
              <source src={attachment.url} type={attachment.mimeType.mimeType} />
              <object data={attachment.url}>
                <embed src={attachment.url}/>
              </object>
            </video>
          ) : null}
        </Grid>
      </>}
    />
  );
}