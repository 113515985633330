import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { Box, Typography, styled } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import { truncate } from '../../helpers';
import { DialogToViewUploads } from './Dialogs/DialogToViewUploads';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  gap: 10,
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.neutrals.high.lightest,
  transition: 'all 0.3s ease-in-out',

  '&:hover': {
    opacity: 0.6,
    backgroundColor: theme.palette.neutrals.high.medium,
  }
}));

export function BoxAttachment({ attachment }) {
  const images = attachment.mimeType.container === 'images';
  const video = attachment.mimeType.container === 'videos';
  const files = attachment.mimeType.container === 'dynamic-files';
  const [viewUpload, setViewUpload] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function handleOpenDialog() {
    setViewUpload(!viewUpload);
  }

  function handleDownload() {
    saveAs(attachment.url, attachment.fileName);
  }

  return (
    <>
      <DialogToViewUploads
        open={['images', 'videos'].includes(attachment.mimeType.container) && viewUpload}
        onClose={handleOpenDialog}
        attachment={attachment}
      />

      <Container
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={(images | video) ? handleOpenDialog : handleDownload}
      >
        {video ? <VideocamOutlinedIcon style={{ fontSize: 25 }} /> 
          : images ? <CropOriginalIcon style={{ fontSize: 25 }} /> 
          : files ? <DescriptionOutlinedIcon style={{ fontSize: 25 }} /> : null}
          <Box>
            <Typography variant="subtitle2">{truncate(attachment.fileName, 25)}</Typography>
            <Typography variant="body2">
              {(attachment.size / (1024 * 1024)).toFixed(2)}mb
            </Typography>
          </Box>
        {isHovered && (
          <Box position="absolute" bottom="5px" right="10px">
            {files ? <GetAppIcon  /> : <VisibilityIcon />}
          </Box>
        )}
      </Container>
    </>
  );
}