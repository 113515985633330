import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	makeStyles,
 } from '@material-ui/core';

import { crudTemplateActions } from '../../actions';

const useStyles = makeStyles((theme) => ({

}));

export function TemplateCrudFilter() {
  const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	
  return (
    <>
      Filtro
    </>
  );
}