import React from 'react';
import filesize from 'filesize';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { VideocamOutlined } from '@material-ui/icons';
import Delete from '@material-ui/icons/Delete';
import { ideaUpdateActions } from '../../actions/ideaUpdate.actions';

function VideoPreview({ video }) {
  const dispatch = useDispatch();

  function removeVideo() {
    dispatch(ideaUpdateActions.removeVideo(video.id ?? video._id));
  }

  function getFileSize(rawSize) {
    rawSize = rawSize ? rawSize : 10;
    const size = parseFloat(rawSize);
    return filesize(size)
  }

  return (
    <Box width="100%" key={video?.id}>
      <Box display='flex' alignItems='center' justifyContent="space-between">
        <Box display='flex' alignItems='center'>
          <VideocamOutlined />
          <Box marginLeft={2}>
            <Typography variant='subtitle2'>{video.name ?? video.fileName}</Typography>
            <Typography variant='caption'>{getFileSize(video.readableSize) ?? getFileSize(video.size)}</Typography>
          </Box>
        </Box>
        <IconButton onClick={removeVideo}>
          <Delete />
        </IconButton>
      </Box>

      <video style={{ width: '100%' }} height="255" controls>
        <source
          src={video.preview ?? video.url}
          type={video.type}
        />
      </video>
    </Box>
  );
}

export { VideoPreview };
