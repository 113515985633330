import React from 'react';
import {
  makeStyles,
  Container,
  Box
} from '@material-ui/core';
 import config from '../../config';

// var Iframe = React.createClass({     
//   render: function() {
//     return(         
//       <div>          
//         <iframe src={this.props.src} height={this.props.height} width={this.props.width}/>         
//       </div>
//     )
//   }
// });
 
function InvitePage(props) {  
  
  return (

    <div >
 
      <iframe src="{config.REACT_APP_INVITE_SITE_URL}" style={{ boxSizing: "border-box",width:"calc(100vw)",height:"calc(100vh)",border:0, frameBorder:"0", scrolling:"no", overflow: "hidden" }} />
    </div>
  );
}

export {
  InvitePage
};