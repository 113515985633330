import React from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { optionConstants } from '../../constants';
import { CustomSelect } from '../Idea';
import { Copyright } from '../Shared';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  typography: {
    fontWeight: 600,
  },
}));

const useStylesIcon = makeStyles(() => ({
  root: {
    minWidth: 30,
  },
}));

export function ImagineSwitchSidebar({ sideBarOptions = [{ label: '', path: '', icon: Element }], handleClick, type }) {
  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const { t } = useTranslation();

  return (
    <>
      <Hidden mdDown>
        <Grid item xs={3}>
          <List className={classes.root}>
            {sideBarOptions.map(({ label, path, icon }, index) => (
              <ListItem
                key={index}
                button
                component={Link}
                onClick={() => handleClick(path)}
                className={
                  path === type
                    ? classes.backgroundActived
                    : ''
                }
              >
                <ListItemIcon classes={classesIcon}>
                  {icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      className={classes.typography}
                      color={
                        path === type ? 'primary' : 'inherit'
                      }
                    >
                      {t(label)}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Divider style={{ marginTop: 16 }} />

          <Copyright />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <CustomSelect
            optionsAvaiable={[
              optionConstants.PRIMEIROACESSO,
              optionConstants.TUTORIAIS,
              optionConstants.FAQ,
              optionConstants.ADMINISTRADORES
            ]}
            startOption={optionConstants.PRIMEIROACESSO}
            handlePage={handleClick}
          />
        </Grid>
      </Hidden>
    </>
  );
}
