import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, makeStyles, TableCell, Typography, useTheme, withStyles } from '@material-ui/core';
import { AddCircle, Create, Delete, Edit, ExpandMore, Help, Info, More, PlayCircleFilled, Save } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';
import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, faqActions, uploadsAcitons } from '../../actions';
import { DialogCustomTitle, DialogImagine, DialogVideo, FirstAccess, SkeletonTable, TemplateCrudTable, Upload, UploadFile } from '../../components';
import { ImagineBackNav } from '../../components/ImagineTemplates/ImagineBackNav';
import { ImagineFormTextField } from '../../components/ImagineTemplates/ImagineFormTextField';
import { ImagineSectionTitle } from '../../components/ImagineTemplates/ImagineSectionTitle';
import { ImagineSwitchSidebar } from '../../components/ImagineTemplates/ImagineSwitchSidebar';
import { tableCss } from '../../components/Tables/TableCss';
import { dateFormat, truncate } from '../../helpers';
import { imagineService } from '../../services/crudTemplate.service';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ImagineDialogConfirmation } from '../../components/ImagineTemplates';
import { ImagineSwitchNav } from '../../components/ImagineTemplates/ImagineSwitchNav';
import dialogVideoSvg from '../../assets/imgs/dialog-video.svg';
import Dropzone from 'react-dropzone';
import { uniqueId } from 'lodash';
import fileSize from 'filesize';
import { VideoPreview } from '../../components/Upload/VideoPreview';
import { helpCenterService } from '../../services/help-center.service';

const useStyles = makeStyles(theme => ({
  titleCard: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'left',
  },
  titleIcon: {
    color: theme.palette.primary.main,
    width: 18
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.neutrals.low.medium
  },
  contentDescription: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.neutrals.low.medium
  }
}));

export function FirstAccessEditPage() {
  const [openDialogConfirmation, setDialogConfirmation] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dialogOpen, setDialog] = useState(false);
  const [navButtonSelected, setNavButtonSelected] = useState('upload_de_video');
  const { video } = useSelector((state) => state.uploadsReducer);
  const [YTLink, setTYLink] = useState('');
  const [lastVideoId, setLastVideoId] = useState('');

  useEffect(() => {
    getWelcomeVideo();
  }, [])

function getWelcomeVideo() {
    helpCenterService.getWelcomeVideo()
      .then(response => {
        setLastVideoId(response.data?._id)
        dispatch(uploadsAcitons.setVideo(response.data))
      })
      .catch((error) => {
        console.error(error);
      })
}

  function handleSend() {
    navButtonSelected === 'upload_de_video' ? dispatch(uploadsAcitons.postVideo(video, lastVideoId, t('o_video_foi_salvo'))) : dispatch(uploadsAcitons.postYTVideo(YTLink, lastVideoId, t('o_video_foi_salvo')));
    setDialog(false);
  }

  function handleUpload(archives) {
    const newUploadedFiles = archives.map((file) => ({
      file,
      type: file.type,
      id: uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      url: null,
    }));

    dispatch(uploadsAcitons.setVideo(newUploadedFiles[0]));
  }

  function VideoYTManager() {
    return (
      <>
        <Typography className={classes.titleCard} gutterBottom>{t('video_do_youtube')}</Typography>
        <Typography>{t('inclua_o_link_do_video_compartilhado_no_youTube.')}</Typography>
        <ImagineFormTextField name='link' label={t('link_do_video')} onChange={setTYLink} />
      </>
    );
  }

  function VideoFileManager() {
    return (
      <>
        <Typography className={classes.titleCard} gutterBottom>{t('upload_de_video')}</Typography>
        <Typography>{t('adicione_um_video_de_boas_vindas_a_plataforma')}</Typography>

        {((video && video?.id )|| video?._id) ? <VideoPreview video={video} isEdit /> : <UploadFile
          onUpload={handleUpload}
          name="video"
          message={t('')}
          accept="video/*"
        >
          <img src={dialogVideoSvg} alt="video upload" />
        </UploadFile>}
      </>
    );
  }

  function VideoEditorCard() {
    return (
      <>
        <ImagineSwitchNav buttons={[{ label: 'upload_de_video' }, { label: 'video_do_youtube' }]} handleClick={setNavButtonSelected} btnSelected={navButtonSelected} />
        <Box>
          {navButtonSelected === 'upload_de_video' ? <VideoFileManager /> : <VideoYTManager />}
        </Box>
      </>
    );
  }

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialog(false)}>
          <DialogCustomTitle onClose={() => setDialog(false)}>{t('editar_video')}</DialogCustomTitle>
          <Divider />
          <DialogContent>
            <VideoEditorCard />
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'end', padding: '0 21px 21px 21px' }} >
            <Button startIcon={<Save />} variant="contained" color='primary' onClick={() => handleSend()}>{t('salvar_video')}</Button>
          </DialogActions>
      </Dialog>
      <ImagineDialogConfirmation
        open={openDialogConfirmation}
        onClose={setDialogConfirmation}
        title={t('excluir_duvida')}
        subtitle={t('deseja_excluir_a_duvida')}
        description={t('as_informacoes_sobre_a_duvida_Como_posso_editar_as_informaçoes_do_meu_perfil_serao_excluidas_permanentemnte')}
      >
        <Box>
          <Button
            style={{ marginRight: 16 }}
            variant="outlined"
            color="primary"
            onClick={() => setDialogConfirmation(false)}
          >
            {t('voltar')}
          </Button>
          <Button
            startIcon={<Delete />}
            variant="contained"
            color="primary"
            onClick={() => { }}
          >
            {t('excluir_duvida')}
          </Button>
        </Box>
      </ImagineDialogConfirmation>
      <Grid item xs={12} lg={9}>
        <Container maxWidth="lg">
          <Typography gutterBottom>{t('primeiro_acesso').toLocaleUpperCase()}</Typography>
          <Card style={{ padding: 32 }}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <ImagineSectionTitle icon={<PlayCircleFilled className={classes.titleIcon} />} title={t('video_de_onboarding')} />
              <Button startIcon={<Create />} style={{ height: 48 }} variant='outlined' color='primary' onClick={() => setDialog(true)}>{t('editar_video')}</Button>
            </Box>
            <CardContent>
              <FirstAccess />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </>
  );
}