import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Grid, Typography, IconButton, TextField, Divider, Button, useTheme } from '@material-ui/core';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RateReviewIcon from '@material-ui/icons/RateReview';

import {
  AttachmentsDialog,
  ImageDialog,
  VideoDialog,
  FileDialog
} from '../../Idea/QualificationAttachments';
import { ImageList, VideoPreview, FileList, VideoList } from '../../Upload';
import { DialogForwardIdea } from '../Dialogs';
import { avaliationDialogsActions, fluxOfIdeasActions } from '../../../actions';
import { Card, Slider, QualificationActions } from './styles';
import { AvatarIconWithText, MenuInformation, LoadingButton, ButtonWithTooltip } from '../../Shared';
import { sliderValues } from './sliderValues';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage';

export function QualificationCard() {
  const { palette } = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: ideaId } = useParams();
  const { videoRender, imageRender, fileRender } = useSelector(state => state.avaliationDialogs);
  const { managerObservation, loadingUpdate, evaluationInFluxIdea } = useSelector(state => state.fluxIdeas);
  const [openDialogForwardIdea, setOpenDialogForwardIdea] = useState(false);
  const [evaluationRates, setEvaluationRates] = useState([]);
  const [error, setError] = useState(false);
  const { ideaDetailsFlux } = useIdeaDetailsController();
  const decisions = ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.decisions;
  
  useEffect(() => {
    dispatch(avaliationDialogsActions.clearDialogs());
    setEvaluationRates(evaluationInFluxIdea);
  }, [evaluationInFluxIdea]);

  useEffect(() => {
    if (ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.ideaExecutionIsRequired === true ||
      ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.visualizationConfigs.showInCurationEvaluation === true
    ) {
      history.push(`/idea/${ideaId}/execution`);
    }
  }, [ideaDetailsFlux]);
  
  function handleOpenDialogForwardIdea() {
    setOpenDialogForwardIdea(!openDialogForwardIdea);
  }

  function handleManagerObservation(e) {
    setError(e.target.value.length === 0 && !error);
    dispatch(fluxOfIdeasActions.setManagerObservation(e.target.value));
  }

  function handleOpenAttachmentsDialog() {
    dispatch(avaliationDialogsActions.openAttachmentDialog());
  }

  function handleSetEvaluationRates(index, rate) {
    setEvaluationRates(prevRates => {
      const updatedRates = [...prevRates];
      updatedRates[index].rate = rate;
      return updatedRates;
    });
  }

  function handleEvaluateIdea(decisionStep) {
    if (managerObservation.length === 0) {
      return setError(true);
    }

    let data = {
      decision: decisions.filter(item => item.typeDecision === decisionStep)[0],
      managerObservation: managerObservation?.length !== 0 && managerObservation,
      personalizedEvaluationsRates: ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.usePersonalizedEvaluation && evaluationRates
    }

    dispatch(fluxOfIdeasActions.updateIdeaStageInFlux(
      ideaId,
      data,
      t('sua_avaliacao_foi_feita_com_sucesso'),
      ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.attachmentsRequired,
      [...imageRender, ...videoRender, ...fileRender],
    ));
  }

  const getIcon = (icon, typeDecision) => (
    <span
      className="material-icons"
      style={{
        color: typeDecision === 'NextStepApproved' ? palette.neutrals.high.main : palette.primary.main,
        width: 30,
        textAlign: 'center',
      }}
    >
      {icon}
    </span>
  )


  return (<>
    <DialogForwardIdea
      open={openDialogForwardIdea}
      onClose={handleOpenDialogForwardIdea}
    />
    <AttachmentsDialog />
    <ImageDialog />
    <VideoDialog />
    <FileDialog />

    <Card elevation={0}>
      <Grid container spacing={3} style={{ padding: '32px 32px 20px' }}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <AvatarIconWithText
              icon={<ThumbsUpDownIcon />}
              text={`etapa: ${ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.name}`}
              variant="h3"
            />
            <IconButton onClick={handleOpenDialogForwardIdea}>
              <MoreHorizIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="body2">
            {ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.stepDescription}
          </Typography>
          { ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.stepDetailedDescriptionHTML &&
          <MenuInformation width={300}><Box dangerouslySetInnerHTML={{__html: ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.stepDetailedDescriptionHTML}}></Box></MenuInformation>
          }
        </Box>
        </Grid>
        {ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.evaluationNeedObservation && (
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              minRows={6}
              variant="filled"
              name="managerObservation"
              label={t('feedback_da_ideia_obrigatorio')}
              defaultValue={managerObservation}
              onChange={handleManagerObservation}
              inputRef={{
                maxLength: 1024,
                required: t('o_feedback_da_ideia_e_obrigatorio'),
              }}
              error={error}
              helperText={error && t('o_preenchimento_do_feedback_e_obrigatorio')}
            />
          </Grid>
        )}
        {ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.attachmentsRequired && (
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            startIcon={<AddCircleIcon />}
            onClick={handleOpenAttachmentsDialog}
          >
            {t('adicionar_anexo')}
          </Button>
        </Grid>
        )}
        <Grid item xs={12}>
          {!!imageRender.length &&
            <Box>
              <Typography variant='subtitle2' gutterBottom>{t('anexo')}</Typography>
              <ImageList files={imageRender} onRemove={avaliationDialogsActions.removeImageRender} />
            </Box>
          }
          {!!videoRender.length &&
            <Box marginBottom={5}>
              <Typography variant='subtitle2' gutterBottom>{t('anexo')}</Typography>
              <VideoList videos={videoRender} onRemove={avaliationDialogsActions.removeVideoRender} />
            </Box>
          }
          {!!fileRender.length &&
            <Box>
              <Typography variant='subtitle2' gutterBottom>{t('anexo')}</Typography>
              <FileList files={fileRender} onRemove={avaliationDialogsActions.removeFileRender} />
            </Box>
          }
        </Grid>

        {ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.usePersonalizedEvaluation && (
          <>
            <Grid item xs={12}>
              <AvatarIconWithText
                icon={<RateReviewIcon />}
                text={ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.personalizedEvaluationsGroups[0]?.description}
                variant="h3"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {t('avalie_a_ideia_com_base_nos_criterios_estabelecidos')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {evaluationRates?.map((item, index) => (
                <Box display="flex" alignItems="center" justifyContent="space-between" key={index}>
                  <Typography variant="subtitle1">{item?.personalizedEvaluation?.name}</Typography>
                  <Box width="45%" display="flex" alignItems="center" justifyContent="space-between">
                    <MenuInformation>
                      {item?.personalizedEvaluation?.description}
                    </MenuInformation>
                    <Slider
                      aria-labelledby="rate"
                      valueLabelDisplay="auto"
                      style={{ marginLeft: 16 }}
                      marks={sliderValues}
                      max={5}
                      min={1}
                      value={item?.rate}
                      onChange={(event, value) => handleSetEvaluationRates(index, value)}
                    />
                  </Box>
                </Box>
              ))}
            </Grid>
          </>
        )}
      </Grid>

      {decisions.length !== 0 && (<>
        <Divider />
        <QualificationActions>
          <Box>
            {loadingUpdate ? null : (
              decisions.filter(({ typeDecision }) => typeDecision === 'NextStepPendingReview').map(({ title, typeDecision, icon, tooltip }, index) => (
                <ButtonWithTooltip
                  key={index}
                  tooltip={tooltip}
                  text={title}
                  variant="text"
                  startIcon={getIcon(icon, typeDecision)}
                  onClick={() => handleEvaluateIdea(typeDecision)}
                  disabled={loadingUpdate}
                />
              ))
            )}
          </Box>
          <Box display="flex" style={{ gap: 10 }}>
            {loadingUpdate ? <LoadingButton /> : (
              decisions.filter(({ typeDecision }) => typeDecision !== 'NextStepPendingReview').map(({ title, typeDecision, icon, tooltip }, index) => (
                <ButtonWithTooltip
                  key={index}
                  tooltip={tooltip}
                  text={title}
                  variant={typeDecision === 'NextStepApproved' ? 'contained' : 'outlined'}
                  startIcon={getIcon(icon, typeDecision)}
                  onClick={() => handleEvaluateIdea(typeDecision)}
                />
              )).reverse()
            )}
          </Box>
        </QualificationActions>
      </>)}
    </Card>
  </>);
}