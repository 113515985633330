import React from 'react';
import {
  Card,
  CardContent,
  makeStyles,
  CardActions,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { articleActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: theme.spacing(2, 2),
  },
  chip: {
    margin: theme.spacing(1),
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
  },
}));

function ArticleStatus({ isEdit = false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const { newArticle, articleDetails } = useSelector(
    (state) => state.articlesReducer
  );

  const status = isEdit ? articleDetails.status : newArticle.status;
  const highlight = isEdit ? articleDetails.highlight : newArticle.highlight;


  function handleSetStatus(status) {
    dispatch(articleActions.setStatus(status, isEdit));
  }

  function handleSetHighlight(highlight) {
    dispatch(articleActions.setHighlight(highlight, isEdit));
  }

  return (
    <Card>
      <CardActions className={classes.actions}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.iconDiscription}>
            <Settings color="primary" />
          </div>
          <div className={classes.description}>{t('status')}</div>
        </div>
      </CardActions>
      <CardContent className={classes.content}>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={status}
              onChange={() =>
                handleSetStatus(!status)
              }
            />
          }
          label={status ? t('publicado') : t('nao_publicado')}
        />
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={highlight}
              onChange={() =>
                handleSetHighlight(!highlight)
              }
            />
          }
          label={highlight ? t('destacado') : t('nao_destacado')}
        />
      </CardContent>
    </Card>
  );
}

export { ArticleStatus };
