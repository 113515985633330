import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MuiMenu from '@material-ui/core/Menu';
import Box  from '@material-ui/core/Box';
import Grid  from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControl  from '@material-ui/core/FormControl';
import Select  from '@material-ui/core/Select';
import MenuItem  from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { externalContentActions } from '../../../actions';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 250,
    padding: theme.spacing(3, 2)
  },
  filterButton: {
    backgroundColor: theme.palette.neutrals.high.light,
    borderRadius: "4px",
    padding: "1rem",
  },
  filterButtonIcon: {
    color: theme.palette.neutrals.low.dark,
  },
  filterButtonText: {
    padding: theme.spacing(0, 1),
    color: theme.palette.neutrals.low.dark,
  },
  filterButtonArrow: {
    color: theme.palette.neutrals.low.dark,
  },
  filterMenuHeader: {
    padding: theme.spacing(2, 3),
  },
  filterMenuHeaderTitle: {
    color: theme.palette.neutrals.low.light,
  },
  button: {
    display: 'flex',
    justifyContent: 'end'
  },
}));

const Menu = withStyles({
  list: {
    padding: 0,
    maxWidth: 328
  },
})(MuiMenu);

const MenuProps = {
  PaperProps: {
    style: {
      width: 200,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export function ContentsFilterMenu({ paging, statusCode }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  console.log(paging.statusCode)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChangeFilter(e) {
    dispatch(externalContentActions.toggleFilter(e.target.value));
  }

  function handleFilter() {
    if (paging.statusCode !== 'all') {
      dispatch(externalContentActions.getExternalContentList(1, paging.query, paging.limit, paging.statusCode));
    } else {
      dispatch(externalContentActions.getExternalContentList(1, paging.query, paging.limit));
    }
    handleClose();
  }

  return (
    <>
      <ButtonBase className={classes.filterButton} onClick={handleClick}>
        <FilterListIcon className={classes.filterButtonIcon} />
        <Typography variant="subtitle2" className={classes.filterButtonText}>{t('filtro')}</Typography>
        <ArrowDropDownIcon className={classes.filterButtonArrow} />
      </ButtonBase>
      <Menu
        keepMounted
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box display="flex" flexDirection="column">
          <Box className={classes.filterMenuHeader}>
            <Typography variant="body2" className={classes.filterMenuHeaderTitle}>{t('filtrar_por')}</Typography>
          </Box>
          <Divider />
          <Box className={classes.container}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl variant="filled" fullWidth>
                  <Select
                    autoWidth
                    labelId="select-status-code-content"
                    id="select-status-code-content"
                    MenuProps={MenuProps}
                    value={statusCode}
                    onChange={handleChangeFilter}
                  >
                    <MenuItem value={'active'}>{t('ativo')}</MenuItem>
                    <MenuItem value={'not_active'}>{t('nao_ativo')}</MenuItem>
                    <MenuItem value={'all'}>{t('todos')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.button}>
                <Button 
                  variant="contained" 
                  color="primary"
                  startIcon={<FilterListIcon />}
                  onClick={handleFilter}
                >
                  {t('filtrar')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Menu>
    </>
  );
}