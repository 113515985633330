import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(2)
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
}))(MuiSkeleton);

export const SkeletonHighlightPost = () => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Skeleton width="100%" height="200px" variant="rect" />
      <Box display="flex" flexDirection="column" padding="1rem">
        <Skeleton variant="text" width="200px" className={classes.title} />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="50%" />
      </Box>
    </Card>
  );
}
