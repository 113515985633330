import React from 'react';
import {  makeStyles } from '@material-ui/core';

import {
Search,
WalletFiltersMenu
} from '../../components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  filter: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'space-between'
  },
}));

function WalletFilters(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.filter}>
      <WalletFiltersMenu query={props.search} />
      <Search
        query={props.search}
        placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
      />
    </div>
  );
}

export { WalletFilters };
