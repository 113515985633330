import { Avatar, Card, CardContent, CardHeader, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
  }
}));


export const FeedbackCard = ({ feedback: { user, createdAt, feedbackText } }) => {
    const language = useSelector(state => state.language);
    const classes = useStyles();
    const formattedDate = moment(createdAt).format(language === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY');

    return (
        <Card className={classes.card}>
            <CardHeader
                titleTypographyProps={{ variant: 'subtitle1', sx: { fontSize: 14, fontWeight: 600 } }}
                avatar={<Avatar src={user.attachment?.url} alt={user.name} />}
                title={user.name}
                subheader={<>
                    <Typography>{`${user.businessUnitLocal.occupationArea.name} | ${user.businessUnitLocal.local.name}`}</Typography><Typography>{formattedDate}</Typography>
                </>
                }
            />
            <CardContent>
                <Typography variant="body1">{feedbackText}</Typography>
            </CardContent>
        </Card>
    );
};
