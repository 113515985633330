import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { crudTemplateActions } from '../../actions/crudTemplate.actions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  typography: {
    fontWeight: 600,
  },
}));

const useStylesIcon = makeStyles(() => ({
  root: {
    minWidth: 30,
  },
}));

export function SidebarTemplateCrud({data}) {
  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const location = useLocation();
  const { type } = useSelector(state => state.crudTemplate);
  const dispatch = useDispatch();

  function handleClick ({ type, label }) {
    dispatch(crudTemplateActions.setType(type));
    dispatch(crudTemplateActions.setTitle(label));
  };

  return (
    <List className={classes.root}>
      {data.map(({ label, pathname }, index) => (
        <ListItem
          key={index}
          button
          component={Link}
          onClick={() => handleClick({label, type: pathname})}
          className={
            pathname === type
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <PersonIcon
              color={
                pathname === type ? 'primary' : 'inherit'
              }
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography
                className={classes.typography}
                color={
                  pathname === type ? 'primary' : 'inherit'
                }
              >
                {label}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}