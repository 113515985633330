import { makeStyles } from '@material-ui/core';

export const infraCss = makeStyles((theme) => ({
  // Dialogs
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2, 4),
  },
  description: {
    fontSize: 16,
    color: theme.palette.neutrals.low.dark
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4, 4),
  },
  button: {
    float: 'right'
  },
  // Dialog Delete
  subTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark
  },
  // Dialog Association
  deleteAssociation: {
    height: '100%',
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
  },
  // Dialog Details
  info: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.neutrals.low.dark,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark
  },
  text: {
    fontSize: 14,
    color: theme.palette.neutrals.low.medium
  },
  // Infra Row
  menuText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
  },
  // Dialog Responsive
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
  },
  titleDialog: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.dark
  },
  // Tag
  tag: {
    height: 24,
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 4,
    textTransform: 'uppercase',
    color: theme.palette.neutrals.high.main,
  },
}));