
import React, { useState } from 'react'
import {
  Box,
  Grid,
  makeStyles,
  useTheme,
  Typography
} from '@material-ui/core';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import militaryTech from '../../assets/imgs/military_tech.svg';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { truncate, history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { ChatBubble, ThumbUp } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  line: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 20,
    color: theme.palette.text.secondary,
  },
  chevron: {
    cursor: 'pointer'
  },
  bulTitle: {
    fontSize: 14,
    color: theme.palette.text.dark,
    fontWeight: 600,
  },
  bulSubtitle: {
    fontSize: 14,
  }
}));

export function TopUserDashboard({ type = "colaborador", user, position }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)

  function handleOpenBul() {
    setOpen(!open)
  }

  return (
    <div className={classes.line}>
      <Grid container>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={2}>
              {position < 3 ? (
                <img
                  src={militaryTech}
                  alt="campeao"
                  style={{ width: 20, marginRight: 20 }}
                />
              ) : (
                <Box style={{ marginRight: 40 }} />
              )}
            </Grid>
            <Grid item xs={2}>
              <Box color='#2F2F2F' fontSize={14} fontWeight='bold'>
                {position + 1}º
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box fontSize={14} cursor='pointer' onClick={() => history.push(`/profile/${user.userId}`)}>
                {truncate(user.name, 15)}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid container item xs={6}>
            <Grid item xs={3}>
              {parseInt(user.totalUps) >= 0 ? <ThumbUp className={classes.icon} /> : <EmojiEventsIcon className={classes.icon} />}
            </Grid>
            <Grid item xs={9}>
              <div style={{ fontSize: 14 }}>
                <span className={classes.text}>
                  {parseInt(user.totalUps) >= 0 ? user.totalUps : user.points}
                </span>{' '}
                <span style={{ color: theme.palette.neutrals.low.light }}>{" "}{parseInt(user.totalUps) >= 0 ? 'ups' : 'pts'}</span>
              </div>
            </Grid>
          </Grid>

          <Grid container item xs={6}>
            <Grid item xs={2}>
              {parseInt(user.totalComments) >= 0 ? <ChatBubble className={classes.icon} /> : <EmojiObjectsIcon className={classes.icon} />}
            </Grid>
            <Grid item xs={7}>
              <div style={{ fontSize: 14, marginLeft: 6 }}>
                <span className={classes.text}>
                  {parseInt(user.totalComments) >= 0 ? user.totalComments : user.ideas}
                </span>{' '}
                <span style={{ color: theme.palette.neutrals.low.light }}>{parseInt(user.totalComments) >= 0 ? '' : t('ideias').toLowerCase()}</span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Box cursor="pointer" onClick={handleOpenBul}>
                {open ? (
                  <KeyboardArrowUpIcon className={classes.chevron} />
                ) : (
                  <KeyboardArrowDown className={classes.chevron} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {open && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Box display="flex" flexDirection="column" paddingTop="1rem">
                  <Typography className={classes.bulTitle} component={"b"}>
                    {t("unidade_de_negocio")}
                  </Typography>
                  <Typography className={classes.bulSubtitle} component={"b"}>
                    {user.businessUnitLocal?.businessUnit?.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Box display="flex" flexDirection="column" paddingTop="1rem">
                  <Typography className={classes.bulTitle} component={"b"}>
                    {t("local")}
                  </Typography>
                  <Typography className={classes.bulSubtitle} component={"b"}>
                    {user.businessUnitLocal?.local?.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Box display="flex" flexDirection="column" paddingTop="1rem">
                  <Typography className={classes.bulTitle} component={"b"}>
                    {t("area_de_atuacao")}
                  </Typography>
                  <Typography className={classes.bulSubtitle} component={"b"}>
                    {user.businessUnitLocal?.occupationArea?.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
