import React from 'react';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { HeaderCollapse } from './HeaderCollapse';
import { collapseCss } from './collapseCss';
import { useDispatch, useSelector } from 'react-redux';
import { executionActions, ideaConclusionActions } from '../../actions';
import DescriptionIcon from '@material-ui/icons/Description';
import { TextAreaCustom } from './TextAreaCustom';
import { useTranslation } from 'react-i18next';

function DetailsCollapse({ isConclusion, isResults = false }) {
  const collapseClasses = collapseCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { execution: {executionDetails},  ideaConclusion: {conclusionDetails}} = useSelector(state => state);
  
  const value = isResults ? conclusionDetails : executionDetails;

  function handleChangeDetails(e) {
   if (isConclusion || isResults) {
      dispatch(
        ideaConclusionActions.setConclusionInput(
          e.target.value,
          'conclusionDetails'
        )
      );
    } else {
      dispatch(executionActions.changeDetails(e.target.value));
    }
  }

  return (
    <Card className={collapseClasses.card} elevation={0}>
      <CardActions className={collapseClasses.actions}>
        <HeaderCollapse
          title={t('detalhes')}
          icon={<DescriptionIcon color="primary" />}
        />
      </CardActions>
      <CardContent className={collapseClasses.content}>
        {isResults ? <>
                  <strong>{t('detalhes_de_execucao')}</strong>
                  <Typography>{value}</Typography>
                </> : <TextAreaCustom
          required={true}
          name="executionDetails"
          label={t('detalhes_de_execucao')}
          defaultValue={value}
          onChange={(e) => handleChangeDetails(e)}
          disabled={isResults}
          inputRef={{
            maxLength: 2000,
            required: t('os_detalhes_do_desafio_e_obrigatorio'),
          }}
        />}
      </CardContent>
    </Card>
  );
}

export { DetailsCollapse };
