import {
  styled,
  Box,
  Typography,
  Card as MuiCard,
  LinearProgress as MuiLinearProgress
} from '@material-ui/core';

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.grey.main}`,
}));

export const Header = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey.main}`,
  padding: theme.spacing(2, 3),

  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(2),
  }
}));

export const Title = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  height: 36
});

export const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),

  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(2),
  }
}));

export const ValuesContainer = styled(Box)({
  marginTop: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 15
});

export const LinearProgressBlue = styled(MuiLinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.auxiliary.blue.light,
  },
}));

export const LinearProgressRed = styled(MuiLinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.auxiliary.red.light,
  },
}));

export const LinearProgressGreen = styled(MuiLinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.auxiliary.green.light,
  },
}));

export const LinearProgressOrange = styled(MuiLinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.auxiliary.orange.light,
  },
}));