import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { infraStructureActions } from '../../../actions';
import { Tabs, Tab } from './styles';

export function InfrastructureListingTabs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedTab } = useSelector(state => state.infraStructure);

  function handleChangeTab(newValue) {
    dispatch(infraStructureActions.changeTab(newValue));
  }
  
  return (
      <Tabs
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
        aria-label="infra listing tabs"
        onChange={(_e, newValue) => handleChangeTab(newValue)}
        value={selectedTab}
      >
        <Tab value="businessUnit" label={t('unidade_de_negocio')} />
        <Tab value="local" label={t('local')} />
        <Tab value="occupationArea" label={t('area_de_atuacao')} />
      </Tabs>
  );
}
