export const userFunctionConstants = {
  LOAD_BUSINESS_UNIT_FIELDS: "LOAD_BUSINESS_UNIT_FIELDS",
  GET_USER_TYPE_REQUEST: "GET_USER_TYPE_REQUEST",
  GET_USER_TYPE_SUCCESS: "GET_USER_TYPE_SUCCESS",
  GET_USER_TYPE_FAILURE: "GET_USER_TYPE_FAILURE",
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_MANAGE_USERS_SUCCESS: "GET_MANAGE_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_MANAGE_USER_SUCCESS: "GET_MANAGE_USER_SUCCESS",
  GET_MANAGE_USER_REQUEST: "GET_MANAGE_USER_REQUEST",
  GET_MANAGE_USER_FAILURE: "GET_MANAGE_USER_FAILURE",
  GET_BUSINESS_REQUEST: "GET_BUSINESS_REQUEST",
  GET_BUSINESS_SUCCESS: "GET_BUSINESS_SUCCESS",
  GET_BUSINESS_FAILURE: "GET_BUSINESS_FAILURE",
  SET_USER: "SELECT_USER",
  REMOVE_BUSINESS: "REMOVE_BUSINESS",
  SET_USER_TYPE: "SET_USER_TYPE",
  USER_FUNCTION_CLEAR: "USER_FUNCTION_CLEAR",
  CLEAR_USER_FUNCTION_STATE: "CLEAR_USER_FUNCTION_STATE",
  TOGGLE_DIALOG_CONFIRM_USER: "TOGGLE_DIALOG_CONFIRM_USER",
  TOGGLE_DIALOG_CANCEL_USER: "TOGGLE_DIALOG_CANCEL_USER",
  SUBMIT_USER_REQUEST: "SUBMIT_USER_REQUEST",
  SUBMIT_USER_SUCCESS: "SUBMIT_USER_SUCCESS",
  SUBMIT_USER_FAILURE: "SUBMIT_USER_FAILURE",
  GET_ADMIN_USER_EDIT_SUCCESS: "GET_ADMIN_USER_EDIT_SUCCESS",
  CLEAR_ADMIN_USER_EDIT: "CLEAR_ADMIN_USER_EDIT",
  GET_ADMIN_USER_EDIT_ERROR: "GET_ADMIN_USER_EDIT_ERROR",
  CLEAR_ADMIN_USER_EDIT_ERRORS: "CLEAR_ADMIN_USER_EDIT_ERRORS",
  GET_ADMIN_USER_EDIT_REQUEST: "GET_ADMIN_USER_EDIT_REQUEST",
  UPDATE_ADMIN_USER_EDIT_REQUEST: "UPDATE_ADMIN_USER_EDIT_REQUEST",
  UPDATE_ADMIN_USER_EDIT_SUCCESS: "UPDATE_ADMIN_USER_EDIT_SUCCESS",
  UPDATE_ADMIN_USER_EDIT_FAILURE: "UPDATE_ADMIN_USER_EDIT_FAILURE",
  TOGGLE_DIALOG_CONFIRM_EMAIL_RECOVER_PASSWORD: "TOGGLE_DIALOG_CONFIRM_EMAIL_RECOVER_PASSWORD",
  CLEAR_USER_FUNCTION_SELECTED_USER: "CLEAR_USER_FUNCTION_SELECTED_USER",
  REQUEST_EMAIL_NEW_PASSWORD_SUCCESS: "REQUEST_EMAIL_NEW_PASSWORD_SUCCESS",
  REQUEST_EMAIL_NEW_PASSWORD_REQUEST: "REQUEST_EMAIL_NEW_PASSWORD_REQUEST",
  REQUEST_EMAIL_NEW_PASSWORD_FAILURE: "REQUEST_EMAIL_NEW_PASSWORD_FAILURE",
  CLEAR_EMAIL_NEW_PASSWORD: "CLEAR_EMAIL_NEW_PASSWORD",
  USER_LOADING_REQUEST: "USER_LOADING_REQUEST",
  USER_LOADING_REQUEST_END: "USER_LOADING_REQUEST_END",
  CLEAR_ADMIN_USER_EDIT_SUCCESS: "CLEAR_ADMIN_USER_EDIT_SUCCESS",

  SAVE_NEW_PASSWORD_REQUEST: 'SAVE_NEW_PASSWORD_REQUEST',
  SAVE_NEW_PASSWORD_SUCCESS: 'SAVE_NEW_PASSWORD_SUCCESS',
  SAVE_NEW_PASSWORD_FAILURE: 'SAVE_NEW_PASSWORD_FAILURE',
  GET_COLLABORATOR_BY_ID_REQUEST: 'GET_COLLABORATOR_BY_ID_REQUEST',
  GET_COLLABORATOR_BY_ID_SUCCESS: 'GET_COLLABORATOR_BY_ID_SUCCESS',
  GET_COLLABORATOR_BY_ID_FAILURE: 'GET_COLLABORATOR_BY_ID_FAILURE',
}