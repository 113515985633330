import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  Menu,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  IconButton,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InfoIcon from '@material-ui/icons/Info';
import { CustomTooltip } from './CustomTooltip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 370,
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconAddCircle: {
    marginRight: 8,
    color: theme.palette.auxiliary.green.light
  },
  iconRemoveCircle: {
    marginRight: 8,
    color: theme.palette.auxiliary.red.main
  },
  tableHeader: {
    fontSize: 14,
    border: 'none',
    padding: theme.spacing(2, 1),
    background: theme.palette.neutrals.high.main
  },
  tableRow: {
    background: theme.palette.neutrals.high.main
  },
  tableCell: {
    fontSize: 14,
    border: 'none',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 1),
  }
}));

export function PointsRuleTable() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addedPoints = [
    { label: t('ideia_executada'), points: "500" },
    { label: t('ideia_aprovada'), points: "200" },
    { label: t('publicar_nova_ideia'), points: "100" },
    { label: t('receber_up_na_ideia_publicada'), points: "5" },
    { label: t('receber_comentario_na_ideia_publicada'), points: "20" },
    { label: t('dar_up_em_uma_ideia'), points: "2" },
    { label: t('comentar_em_uma_ideia'), points: "10" },
    { label: t('dar_up_em_um_comentario'), points: "2" },
    { label: t('receber_up_em_um_comentario'), points: "5" },
    { label: t('criar_uma_conta'), points: "50" },
  ];

  const removePoints = [
    { label: t('perder_up_em_uma_ideia_publicada'), points: "-5" },
    { label: t('perder_comentario_em_uma_ideia_publicada'), points: "-20" },
    { label: t('perder_up_em_um_comentario_publicado'), points: "-5" },
    { label: t('remover_up_de_uma_ideia'), points: "-2" },
    { label: t('remover_comentario_de_uma_ideia'), points: "-10" },
    { label: t('remover_up_de_um_comentario'), points: "-2" },
  ]

  return(
    <>
      <CustomTooltip title={t('ver_detalhes')} aria-label={t('ver_detalhes')}>
        <IconButton onClick={handleClick} size="small">
          <InfoIcon
            fontSize="inherit"
            color={anchorEl ? 'primary' : 'inherit'}
          />
        </IconButton>
      </CustomTooltip>
      <Menu
        id="menu-information"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        MenuListProps={{ disablePadding: true }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        className={classes.root}
      >
        <Paper className={classes.paper}>
          <Typography> 
            {t('os_pontos_no_imagine_sao_atribuidos_com_base_nas_seguintes_acoes')}
          </Typography>

          <Box display="flex" align="center" paddingTop="1.2rem">
            <AddCircleIcon className={classes.iconAddCircle} />
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {t('acoes_que_geram_adicao_de_pontos')} 
            </Typography>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="table rule points">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableHeader}>{t('acao')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('pontos').toLowerCase()}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {addedPoints.map((added) => (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {added.label}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {added.points}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" align="center" paddingTop="1.2rem">
            <RemoveCircleIcon className={classes.iconRemoveCircle} />
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {t('acoes_que_geram_remocao_de_pontos')}
            </Typography>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="table rule points">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableHeader}>{t('acao')}</TableCell>
                  <TableCell className={classes.tableHeader}>{t('pontos').toLowerCase()}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {removePoints.map((remove) => (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {remove.label}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {remove.points}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Menu>
    </>
  );
}