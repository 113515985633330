import React from 'react';
import { Button, Dialog, Typography, DialogContent, DialogActions, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { LoadingButton } from '../../components';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 450
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5, 3, 1),
    height: 150
  },
  info: {
    textAlign: 'center'
  },
  actions: {
    padding: theme.spacing(3)
  }
}));

export function FluxOfIdeaConfirmModal({ open, handleClose, handleConfirm, isDelete=false }) {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.ideaStepFlux);

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
      <DialogContent className={classes.content}>
        <Typography variant="h3" className={classes.info}>
          Você tem certeza
        </Typography>
        <Typography variant="h3" className={classes.info}>
          que deseja {isDelete ? 'excluir' : 'alterar'} a etapa?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {loading ? <LoadingButton /> : (
          <>
            <Button variant="outlined" onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleConfirm} color="primary">
              Confirmar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}