export const LineChartMock = [

  {
    month: 1,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
{
    month: 2,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
  {
    month: 3,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
{
    month: 4,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
  {
    month: 5,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
{
    month: 6,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
  {
    month: 7,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
{
    month: 8,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
  {
    month: 9,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
{
    month: 10,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
  {
    month: 11,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
{
    month: 12,
    ideiasCriadas: 0,
    ideiasAprovadas: 0,
    ideiasRejeitadas: 0,
    ideiasImplementadas: 0,
  },
];
