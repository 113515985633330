import axios from 'axios';
import config from '../config';
import { requestHeaders } from '../helpers';

export const ideaConclusionService = {
  sendConclusionIdea,
  cancelConclusionIdea,
};


async function sendConclusionIdea(id, conclusion) {
  const response = await axios.put(`${config.URL_BASE}/ideas/${id}/finishexecution`, conclusion, requestHeaders());

  return response.data;
}

async function cancelConclusionIdea(id, cancel) {
  const response = await axios.put( `${config.URL_BASE}/ideas/${id}/cancelexecution`, cancel, requestHeaders());
  
  return response.data;
}
