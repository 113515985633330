import React from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import { Tab } from './styles';

export function InfrastructureTabs({ handleChangeTab, tabChosed }) {
  const { t } = useTranslation();

  return (
    <Tabs
      centered
      value={tabChosed}
      textColor="primary"
      indicatorColor="primary"
      aria-label="portfolio menu tabs"
      onChange={(e, value) => handleChangeTab(value)}
    >
      <Tab label={t('listagem_de_infraestrutura')} />
      <Tab label={t('vinculos_de_infraestrutura')} />
    </Tabs>
  );
}