import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Avatar, List, ListItem, ListItemIcon, ListItemText, Typography, CardHeader, CardContent } from '@material-ui/core';
import { TableMenu } from '../Tables';
import { Delete, Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { adminsActions } from '../../actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 200,
    width: 300,
    margin: 12,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: 16,
  },

  text: {
    margin: 0,
  },
  body: {
    marginTop: 16,
  },
  phone: {
    margin: 0,
    marginTop: 8,
    color: theme.palette.neutrals.low.medium,

  },
  email: {
    textDecoration: 'none',

  },
  localUnit: {
    marginTop: 16,
    margin: 0,
    color: theme.palette.neutrals.low.medium,

  }
}))


function CardProfile({
  userId,
  name,
  email,
  phone,
  pictureUrl,
  position, isEdit = false }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function setDialogConfirmation(open = false) {
    dispatch(adminsActions.setDialogConfirmation(open));
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Avatar alt={`${name} photo's`} src={pictureUrl} />}
        title={<strong>{name}</strong>}
        subheader={<p className={classes.text}>Inovação</p>}
        action={isEdit && (
          <TableMenu>
            <List>
              <ListItem
                button
                onClick={() => {
                  dispatch(adminsActions.handleDialogAddContact(true, true));
                  dispatch(adminsActions.handleAdminId(userId));
                  dispatch(adminsActions.handleEmail(email));
                  dispatch(adminsActions.handlePhone(phone));
                  dispatch(adminsActions.handleAdminName(name));
                  dispatch(adminsActions.handleImageUrl(pictureUrl));
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary={<Typography>{t('editar')}</Typography>} />
              </ListItem>
              <ListItem onClick={() => { dispatch(adminsActions.handleAdminId(userId)); setDialogConfirmation(true) }} button>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText primary={<Typography>{t('deletar')}</Typography>} />
              </ListItem>
            </List>
          </TableMenu>
        )}
      />
      <CardContent>
        <a href={`mailto:${email}`} className={classes.email}>
          {email}
        </a>
        <p className={classes.phone}>{phone}</p>
        <p className={classes.localUnit}>{position}</p>
      </CardContent>
    </Card>
  );
}
export { CardProfile };
