import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';

import { portfolioHomeActions } from '../../actions/portfolio/portfolio-home.actions';
import { DataCard } from '../../components/Portfolio/DataCard';
import EmptyCard from '../../components/Portfolio/EmptyCard/EmptyCard';
import { SkeletonPortfolioHome } from '../../components/Portfolio';

const TitleColumn = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

function PortfolioHome() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loading, first_status_items, second_status_items, third_status_items } = useSelector(state => state.portfolioHome);
  const { portfolioStatus } = useSelector(state => state.domains)

  function objectCompare( a, b ) {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }

  function getArrOfObjects(order) {
    if (order === 1) return first_status_items
    if (order === 2) return second_status_items
    if (order === 3) return third_status_items
  }

  useEffect(() => {
    if (portfolioStatus) {
      dispatch(portfolioHomeActions.getAllProjects(portfolioStatus))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioStatus]);

  function Column({ type, data }) {
    return (
      <>
        <TitleColumn>
          <Typography variant="subtitle2">{type}</Typography>
          <span style={{ color: theme.palette.neutrals.low.light }}>{data.length}</span>
        </TitleColumn>
        <Grid container spacing={2}>
          {!!data.length ? data.map((item, index) => <DataCard index={index} key={item._id} project={item} />) : <EmptyCard />}
        </Grid>
      </>
    );
  };

  return (
    loading && portfolioStatus ? (
      <SkeletonPortfolioHome /> 
    ) : (
      <Grid container spacing={3}>
        { portfolioStatus?.sort(objectCompare).map(item => (
          <Grid item md={4} xs={12}>
            <Column type={item.name} data={getArrOfObjects(item.order)} key={item._id} />
          </Grid>
        ))}
      </Grid>
    )
  );
}

export { PortfolioHome }