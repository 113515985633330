import React from 'react';
import { CustomTooltip } from '../Shared/CustomTooltip';
import { IconButton, makeStyles } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import { useDispatch } from 'react-redux';
import { ideaCreateDialogsActions } from '../../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  containerButtonsIcons: {
    display: 'flex',
  },
  buttonPhoto: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  buttonVideo: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.info.main,
    },
  },
  buttonDescription: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.auxiliary.green.light,
    },
  },
  buttonAttachment: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.warning.main,
    },
  },
  buttonCanvas: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonValueCanvas: {
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

function DialogAttachment({ from }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={classes.containerButtonsIcons}>
      <div>
        <CustomTooltip title={t('anexar_foto')} aria-label={t('anexar_foto')}>
          <IconButton
            className={classes.buttonPhoto}
            onClick={() => {
              dispatch(ideaCreateDialogsActions.openDialogPhoto(from));
            }}
          >
            <PhotoCameraIcon />
          </IconButton>
        </CustomTooltip>
      </div>
      <div>
        <CustomTooltip title={t('anexar_video')} aria-label={t('anexar_video')}>
          <IconButton
            className={classes.buttonVideo}
            onClick={() =>
              dispatch(ideaCreateDialogsActions.openDialogVideo(from))
            }
          >
            <VideocamIcon />
          </IconButton>
        </CustomTooltip>
      </div>
      <div>
        <CustomTooltip
          title={t('anexar_documento')}
          aria-label={t('anexar_documento')}
        >
          <IconButton
            className={classes.buttonDescription}
            onClick={() =>
              dispatch(ideaCreateDialogsActions.openDialogFile(from))
            }
          >
            <DescriptionIcon />
          </IconButton>
        </CustomTooltip>
      </div>
      {/*       <div>
        <CustomTooltip
          title={t('anexar_canvas')}
          aria-label={t('anexar_canvas')}
        >
          <IconButton
            className={classes.buttonCanvas}
            onClick={() =>
              dispatch(ideaCreateDialogsActions.openDialogCanvas(from))
            }
          >
            <DashboardIcon />
          </IconButton>
        </CustomTooltip>
      </div>
      <div>
        <CustomTooltip title="Anexar Value Proposition canvas" aria-label="anexar value proposition canvas">
          <IconButton
            className={classes.buttonValueCanvas}
            onClick={() =>
              dispatch(ideaCreateDialogsActions.openDialogValueCanvas(from))
            }
          >
            < ViewQuiltIcon / >
          </IconButton>
        </CustomTooltip>
      </div>*/}
      <div>
        <CustomTooltip title={t('anexar_link')} aria-label={t('anexar_link')}>
          <IconButton
            className={classes.buttonAttachment}
            onClick={() =>
              dispatch(ideaCreateDialogsActions.openDialogLink(from))
            }
          >
            <AttachmentIcon />
          </IconButton>
        </CustomTooltip>
      </div>
    </div>
  );
}

export { DialogAttachment };
