import { portfolioConstants } from '../../constants/portfolio.constants';

const initialStateSubtask = {
  description: '',
  conclusionDate: null,
  responsible: ''
}

const initialState = {
  loading: false,
  success: false,
  error: false,
  conclusionDate: null,
  name: "",
  description: "",
  responsible: "",
  subTasks: [],
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,
  taskId: null,
}

export function portfolioDialogTask(state = initialState, action) {
  switch (action.type) {
    case portfolioConstants.CHANGE_PORTFOLIO_DIALOG_TASK: {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    }

    case portfolioConstants.CHANGE_PORTFOLIO_DIALOG_SUBTASK: {
      return {
        ...state,
        subTasks: state.subTasks.map((item, index) => {
          if (index.toString() === action.payload.index.toString()) {
            return {
              ...item,
              [action.payload.name]: action.payload.value
            }
          }
          return item
        })
      }
    }

    case portfolioConstants.ADD_PORTFOLIO_DIALOG_SUBTASK: {
      return {
        ...state,
        subTasks: [
          ...state.subTasks,
          initialStateSubtask,
        ]
      }
    }

    case portfolioConstants.REMOVE_PORTFOLIO_DIALOG_SUBTASK: {
      return {
        ...state,
        subTasks: state.subTasks.filter((item, index) => index.toString() !== action.payload.toString())
      }
    }

    case portfolioConstants.CLEAR_PORTFOLIO_DIALOG_TASK: {
      return initialState;
    }

    case portfolioConstants.PORTFOLIO_UPDATE_TASK_REQUEST:
    case portfolioConstants.PORTFOLIO_UPDATE_SUBTASK_REQUEST:
    case portfolioConstants.PORTFOLIO_POST_CREATE_TASK_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case portfolioConstants.PORTFOLIO_UPDATE_TASK_SUCCESS:
    case portfolioConstants.PORTFOLIO_UPDATE_SUBTASK_SUCCESS:
    case portfolioConstants.PORTFOLIO_POST_CREATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case portfolioConstants.PORTFOLIO_UPDATE_TASK_FAILURE:
    case portfolioConstants.PORTFOLIO_UPDATE_SUBTASK_FAILURE:
    case portfolioConstants.PORTFOLIO_POST_CREATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      }

    case portfolioConstants.LOAD_CREATE_TASK_DIALOG:
      return { 
        ...state,
        taskId: action.payload._id,
        name: action.payload.name,
        description: action.payload.description,
        responsible: action.payload.responsible,
        conclusionDate: action.payload.conclusionDate,
        subTasks: action?.payload?.subTasks?.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              description: action.payload.description,
              responsible: action.payload.responsible,
              conclusionDate: action.payload.conclusionDate
            }
          }
          return item;
        })
      }
    

    

    case portfolioConstants.CLEAR_UPDATE_TASK_FLAGS: {
      return {
        ...state,
        loadingUpdateTask: false,
        successUpdateTask: false,
        errorUpdateTask: false,        
      }
    }

    case portfolioConstants.PORTFOLIO_DELETE_TASK_REQUEST: {
      return {
        ...state,
        loadingDelete: true
      }
    }

    case portfolioConstants.PORTFOLIO_DELETE_TASK_SUCCESS: {
      return {
        ...state,
        loadingDelete: false,
        successDelete: true
      }
    }

    case portfolioConstants.PORTFOLIO_DELETE_TASK_FAILURE: {
      return {
        ...state,
        loadingDelete: false,
        errorDelete: true
      }
    }

    case portfolioConstants.CLEAR_PORTFOLIO_DELETE_FLAGS: {
      return {
        ...state,
        loadingDelete: false,
        successDelete: false,
        errorDelete: false,
      }
    }


    default: 
      return state;
      
  }
}