import config from '../config';
import axios from 'axios';
import { handleResponse, requestHeaders } from '../helpers';

export const walletService = {
  getWallets,
  getPoints,
  getUserPoints
};

function getWallets(id, page, quantityPerPage, filter, sort, query='') {
  return axios
    .get(`${config.URL_BASE}/wallet/${id}/?page=${page}&quantityPerPage=${quantityPerPage}&filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&q=${query}`,requestHeaders())
    .then(handleResponse)
    .then((response) => response);
}

function getWalletsWithScore(id, page, quantityPerPage, filter, sort, query='') {
  return axios
    .get(`${config.URL_BASE}/wallet/${id}/?page=${page}&quantityPerPage=${quantityPerPage}&filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&q=${query}`,requestHeaders())
    .then(handleResponse)
    .then((response) => response);
}

function getPoints() {
  return axios
    .get(`${config.URL_BASE}/wallet`, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}
function getUserPoints(id) {
  return axios
    .get(`${config.URL_BASE}/wallet/points/${id}`, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}