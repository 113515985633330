export const walletConstants = {
  GET_WALLET_REQUEST: 'GET_WALLET_REQUEST',
  GET_WALLET_SUCCESS: 'GET_WALLET_SUCCESS',
  GET_WALLET_FAILURE: 'GET_WALLET_FAILURE',

  GET_WALLET_MOBILE_REQUEST: 'GET_WALLET_MOBILE_REQUEST',
  GET_WALLET_MOBILE_SUCCESS: 'GET_WALLET_MOBILE_SUCCESS',
  GET_WALLET_MOBILE_FAILURE: 'GET_WALLET_MOBILE_FAILURE',

  GET_POINTS_REQUEST: 'GET_POINTS_REQUEST',
  GET_POINTS_SUCCESS: 'GET_POINTS_SUCCESS',
  GET_POINTS_FAILURE: 'GET_POINTS_FAILURE',

  SET_QUANTITY_PER_PAGE: 'SET_QUANTITY_PER_PAGE',
  SET_PAGE: 'SET_PAGE',
  SET_STATUS: 'SET_STATUS',
  SET_PERIOD: 'SET_PERIOD',
  SET_QUERY: 'SET_QUERY',
  CLEAR_WALLET: "CLEAR_WALLET"
};
