import brazilStrings from 'react-timeago/lib/language-strings/pt-br';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import enStrings from 'react-timeago/lib/language-strings/en';
import esStrings from 'react-timeago/lib/language-strings/es';

const selectedLanguage = (language) => {
  switch (language) {
    case 'en':
      return enStrings;
    case 'pt_BR':
      return brazilStrings;
    case 'es':
      return esStrings;
    default:
      return brazilStrings;
  }
};

const formatterTimer = (language) => {
  return buildFormatter(selectedLanguage(language));
};

export { formatterTimer };
