import React from 'react'
import {
  Grid,
  Typography,
  Paper,
  Box,
  makeStyles,
  Button,
} from "@material-ui/core";
import { TagPerfil, AvatarIconWithText } from "./../../components";
import { useSelector } from "react-redux";
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  avatarIcon: {
    marginRight: 16,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  card: {
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
    borderRadius: 8,
  },
  boldText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  listItem: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #E7EAED",
    }
  },
  gridFlex: {
    display: 'flex',
    alignItems: 'center',
  }
}));

export const ListFunction = ({ toggleEdit }) => {
  const classes = useStyles();
  const { collaborator } = useSelector(state => state.userFunction)
  const { t } = useTranslation();
  const hasLocals = collaborator?.businessUnitLocals?.length > 0;

  return (
    <Paper elevation={0} className={classes.card}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <AvatarIconWithText
              variant="h3"
              icon={<DescriptionIcon />} 
              text={t('funcao_do_usuario')}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={toggleEdit}
            >
              Editar
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.boldText}>{t('perfil_do_usuario')}:</Typography>
            &nbsp;&nbsp;
            <TagPerfil perfil={collaborator?.typeProfile?.description} />
          </Box>
        </Grid>
        {hasLocals && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className={classes.boldText}>{t('unidade_de_negocio')}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.boldText}>{t('local')}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.boldText}>{t('area_de_atuacao')}</Typography>
                </Grid>
              </Grid>
            </Grid>
              {collaborator?.businessUnitLocals.map(item => (
                <Grid key={item._id} item xs={12} className={classes.listItem}>
                <Grid container spacing={2}>
                    <Grid item xs={4} className={classes.gridFlex}>
                    <Typography variant="body2">{ item.businessUnit.name }</Typography>
                  </Grid>
                    <Grid item xs={4} className={classes.gridFlex}>
                    <Typography variant="body2">{ item.local.name }</Typography>
                  </Grid>
                    <Grid item xs={4} className={classes.gridFlex}>
                    <Typography variant="body2">{ item.occupationArea.name }</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        )} 
      </Grid>
    </Paper>
  )
}
