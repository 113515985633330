import update from 'react-addons-update';
import { avaliationDialogsConstants } from '../constants';

const initialState = {
  attachmentDialog: false,
  imageDialog: false,
  videoDialog: false,
  documentDialog: false,
  imageRender: [],
  videoRender: [],
  fileRender: [],
  images: [],
  video: [],
  files: []
};

export function avaliationDialogs(state = initialState, action) {
  switch (action.type) {
    case avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_ATTACHMENT_DIALOG:
      return { ...state, attachmentDialog: !state.attachmentDialog };

    case avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_IMAGE_DIALOG:
      return { ...state, imageDialog: !state.imageDialog, attachmentDialog: false };

    case avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_VIDEO_DIALOG:
      return { ...state, videoDialog: !state.videoDialog, attachmentDialog: false };

    case avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_DOCUMENT_DIALOG:
      return { ...state, documentDialog: !state.documentDialog, attachmentDialog: false };

    case avaliationDialogsConstants.SET_STEP_FLUX_IMAGES:
      return update(state, {
        images: { $push: action.payload.images },
      });

    case avaliationDialogsConstants.REMOVE_STEP_FLUX_IMAGE:
      const images = state.images.filter(
        (image) => image.id !== action.payload.id
      );

      return update(state, {
        images: { $set: images },
      });

    case avaliationDialogsConstants.SET_STEP_FLUX_VIDEO:
      return update(state, {
        video: { $push: action.payload.video },
      });

    case avaliationDialogsConstants.REMOVE_STEP_FLUX_VIDEO:
      const videos = state.video.filter(
        (video) => video.id !== action.payload.id
      );
      return update(state, {
        video: { $set: videos },
      });

    case avaliationDialogsConstants.SET_STEP_FLUX_FILES:
      return update(state, {
        files: { $push: action.payload.files },
      });

    case avaliationDialogsConstants.REMOVE_STEP_FLUX_FILE:
      const files = state.files.filter((file) => file.id !== action.payload.id);

      return update(state, {
        files: { $set: files },
      });

    case avaliationDialogsConstants.SAVE_STEP_FLUX_ATTACHMENTS:
      const { renderName, dialogName } = action.payload;
      return update(state, {
        [renderName]: { $set: [...state[renderName], ...state[dialogName]] },
        [dialogName]: { $set: [] }
      });

    case avaliationDialogsConstants.CLEAR_STEP_FLUX_DIALOGS:
      return update(state, {
        attachmentDialog: { $set: false },
        imageDialog: { $set: false },
        videoDialog: { $set: false },
        documentDialog: { $set: false },
        fileRender: { $set: [] },
        imageRender: { $set: [] },
        videoRender: { $set: [] },
        files: { $set: [] },
        images: { $set: [] },
        video: { $set: [] }
      });

    case avaliationDialogsConstants.REMOVE_STEP_FLUX_IMAGE_RENDER:
      const image = state.imageRender.filter(
        (image) => image.id !== action.payload.id
      );

      return update(state, {
        imageRender: { $set: image },
      });


    case avaliationDialogsConstants.REMOVE_STEP_FLUX_VIDEO_RENDER:
      const videoRender = state.videoRender.filter((video) => video.id !== action.payload.id);
      return update(state, {
        videoRender: { $set: videoRender },
      });

    case avaliationDialogsConstants.REMOVE_STEP_FLUX_FILE_RENDER:
      const file = state.fileRender.filter((file) => file.id !== action.payload.id);

      return update(state, {
        fileRender: { $set: file },
      });

    default:
      return state;
  }
}
