import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';
import axios from 'axios';

export const rankingService = {
  getRanking,
};

function getRanking(period, page, sort, limit) {
  const typeOfRanking = period.column === "engagement" ? "engagement" : "standard";
  return axios
    .get(
      `${config.URL_BASE}/ranking/score/?q=${JSON.stringify({
        ...period,
        typeOfRanking: typeOfRanking
      })}&page=${page}&sort=${JSON.stringify(sort)}&quantityPerPage=${limit}`,
      requestHeaders()
    )
    .then(handleResponse)
    .then((response) => response);
}
