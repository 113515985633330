import update from "react-addons-update";
import { tutorialsConstants } from "../constants";

const initialState = {
  tutorialName: '',
  tutorialFile: {},
  tutorialList: [],
  loading: false,
  openDialog: false,
  dialogIsEdit: false,
  fileToDeleteId: "",
  fileUrl: "",
  tutorialId: ""
};

export function tutorialsReducer(state = initialState, action) {
  switch (action.type) {
    case "REQUEST_GET_TUTORIALS":
      return update(state, {
        loading: { $set: true }
      });

    case "FAILURE_GET_TUTORIALS":
      return update(state, {
        loading: { $set: false }
      });

    case "SUCCESS_GET_TUTORIALS":
      return update(state, {
        loading: { $set: false },
        tutorialList: { $set: action.payload }
      });

    case "SET_UPLOAD_FILES":
      return update(state, {
        tutorialFile: { $set: action.payload.files[0] }
      });

    case "SET_FILE_NAME":
      return update(state, {
        tutorialName: { $set: action.payload.title }
      });

    case "HANDLE_DIALOG_TUTORIAL":
      return update(state, {
        openDialog: { $set: action.payload.open },
        dialogIsEdit: { $set: action.payload.isEdit },
        tutorialId: { $set: action.payload.tutorialId }
      });

    case tutorialsConstants.DELETE_TUTORIAL_FILE:
      return update(state, {
        fileToDeleteId: { $set: action.payload.id },
        tutorialFile: { $set: initialState.tutorialFile }
      });

    case tutorialsConstants.SET_TUTORIAL_URL:
      return update(state, {
        fileUrl: { $set: action.payload.url },
      });

    default:
      return initialState;
  }
}