import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const executionService = {
  executeIdea,
  getIdea,
  updateExecution
};

async function executeIdea(execution, id) {
  const response = await axios.post(`${config.URL_BASE}/ideas/${id}/execution`, execution, requestHeaders());
  
  return response.data;
}
function getIdea(id) {
  return axios
    .get(`${config.URL_BASE}/ideas/${id}/execution/`, requestHeaders())
    .then(handleResponse);
}
async function updateExecution(execution, id) {
  const response = await axios.put(`${config.URL_BASE}/ideas/${id}/execution`, execution, requestHeaders());

  return response.data;
}
