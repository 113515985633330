import { ideaUpdateConstants } from '../constants';
import update from 'react-addons-update';

const initialStateParticipants = {
  name: '',
  value: ''
}

const initialState = {
  _id: '',
  name: '',
  description: '',
  problem: '',
  solution: '',
  estimatedExecutionCost: 0,
  estimatedFinancialReturn: 0,
  nameLowerCase: '',
  challenge: '',
  links: [],
  statusIdea: {},
  attachments: [],
  user: {},
  images: [],
  files: [],
  video: {},
  personalizedClassifications: [],
  saveLoading: false,
  canvas: [
    {
      key: 'Q1',
      answer: '',
    },
    {
      key: 'Q2',
      answer: '',
    },
    {
      key: 'Q3',
      answer: '',
    },
    {
      key: 'Q4',
      answer: '',
    },
    {
      key: 'Q5',
      answer: '',
    },
    {
      key: 'Q6',
      answer: '',
    },
    {
      key: 'Q7',
      answer: '',
    },
    {
      key: 'Q8',
      answer: '',
    },
    {
      key: 'Q9',
      answer: '',
    },
  ],
  canvasValueProposition: [
    {
      key: 'Q1',
      answer: '',
    },
    {
      key: 'Q2',
      answer: '',
    },
    {
      key: 'Q3',
      answer: '',
    },
    {
      key: 'Q4',
      answer: '',
    },
    {
      key: 'Q5',
      answer: '',
    },
    {
      key: 'Q6',
      answer: '',
    },
    {
      key: 'Q7',
      answer: '',
    },
    {
      key: 'Q8',
      answer: '',
    },
    {
      key: 'Q9',
      answer: '',
    },
  ],
  businessUnitLocal: [],
  filesToRemove: [],
  challengesList: [],
  classificationsList: [],
  ideaFailed: false,
  contributors: [initialStateParticipants],
  loadingParticipants: false,
  allParticipants: [],
  loadParticipants: []
};

export function ideaUpdate(state = initialState, action) {
  switch (action.type) {

    case ideaUpdateConstants.UPDATE_IDEA_SUCCESS: {
      return {
        ...state,
        saveLoading: false
      }
    }
    
    case ideaUpdateConstants.UPDATE_IDEA_FAILURE: {
      return {
        ...state,
        saveLoading: false
      }
    }

    case ideaUpdateConstants.UPDATE_IDEA_REQUEST: {
      return {
        ...state,
        saveLoading: true
      }
    }

    case ideaUpdateConstants.DELETE_DRAFT_REQUEST: {
      return {
        ...state,
        deleteLoading: true
      }
    }

    case ideaUpdateConstants.SET_PUT_SELECTED_BUSINESS: {
      return update(state, {
        businessUnitLocal: { $set: action.payload }
      });
    }

    case ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_SUCCESS: {
      const contributors = action.payload.contributors.map(({ _id, name }) => ({ value: _id, name: name }));
      const challenges = state.challengesList.filter(item => item.value === action.payload.challenge?._id)[0];
      const classifications = state.classificationsList.map(classification => {
        const matchingClassification = action.payload.personalizedClassifications.find(item => item._id === classification._id);

        if (matchingClassification) {
          return { ...classification, checked: true }
        }
        return classification;
      })

      function formatCurrency(value) {
        return Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }).format(value);
      }

      const estimatedExecutionCost = formatCurrency(action.payload?.estimatedExecutionCost);
      const estimatedFinancialReturn = formatCurrency(action.payload?.estimatedFinancialReturn);
      const videoAttachment = action.payload.attachments.filter((attachment) => attachment.mimeType.container === 'videos')[0];

      if (action.payload.problem) {
        state.problem = action.payload.problem;
      }

      if (action.payload.solution) {
        state.solution = action.payload.solution;
      }

      return update(state, {
        _id: { $set: action.payload._id },
        name: { $set: action.payload.name },
        description: { $set: action.payload.description },
        nameLowerCase: { $set: action.payload.nameLowerCase },
        links: { $set: action.payload.links },
        statusIdea: { $set: action.payload.statusIdea },
        user: { $set: action.payload.user },
        attachments: { $set: action.payload.attachments },
        canvas: { $set: action.payload.canvas },
        canvasValueProposition: { $set: action.payload.canvasValueProposition },
        createdAt: { $set: action.payload.createdAt },
        contributors: { $set: contributors},
        challenge: { $set: challenges },
        personalizedClassifications: { $set: classifications },
        currentStepFlux: { $set: action.payload.currentStepFlux },
        businessUnitLocal: { $set: action.payload.businessUnitLocal },
        estimatedExecutionCost: { $set: estimatedExecutionCost },
        estimatedFinancialReturn: { $set: estimatedFinancialReturn },
        images: [],
        files: [],
        video: { $set: videoAttachment ?? {} },
        loading: { $set: false }
      });
    };

    case ideaUpdateConstants.GET_TO_UPDATE_IDEA_USER_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        ideaFailed: true,
      }
    }

    case ideaUpdateConstants.PUT_TITLE_DESCRIPTION: {
      return update(state, {
          name: { $set: action.payload.name },
          description: { $set: action.payload.description },
          nameLowerCase: { $set: action.payload.name },
      });
    };

    case ideaUpdateConstants.PUT_CANVAS: {
      const canvas = state.canvas.map((c) => {
        return {
          key: c.key,
          answer: action.payload.canvas[c.key],
          _id: c._id
        };
      });

      return update(state, { canvas: { $set: canvas } });
    };

    case ideaUpdateConstants.PUT_CANVAS_VALUE: {
      const canvasValueProposition = state.canvasValueProposition.map((c) => {
        return {
          key: c.key,
          answer: action.payload.canvas[c.key],
          _id: c._id
        };
      });

      return update(state, { canvasValueProposition: { $set: canvasValueProposition } });
    };

    case ideaUpdateConstants.PUT_LINKS: {
      return update(state, { links: { $set: action.links } });
    };

   case ideaUpdateConstants.PUT_SET_IMAGES: {
      return update(state, {
        images: { $push: action.payload.images },
      });
    };

    case ideaUpdateConstants.PUT_SET_VIDEO: {
      return update(state, {
        video: { $set: action.payload.video },
      });
    };

    
    case ideaUpdateConstants.PUT_SET_FILE: {
      return update(state, {
        files: { $push: action.payload.file },
      });
    };

    case ideaUpdateConstants.CLEAR_UPTADE_IDEA_DETAILS: {
      return update(state, { $set: initialState });
    };

    case ideaUpdateConstants.REMOVE_IMAGE:
      return {
        ...state,
        images: state.images.filter(image => image.id !== action.payload.id),
        attachments: state.attachments.map(item => {
          if (item._id === action.payload.id) {
            return {
              ...item,
              status: false
            }
          }
          return item;
        })
      };

    case ideaUpdateConstants.REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload.id),
        attachments: state.attachments.map(item => {
          if (item._id === action.payload.id) {
            return {
              ...item,
              status: false
            }
          }
          return item;
        })
      };

    case ideaUpdateConstants.REMOVE_VIDEO:
      return {
        ...state,
        video: {},
        attachments: state.attachments.map(item => {
          if (item._id === action.payload.id) {
            return {
              ...item,
              status: false
            }
          }
          return item;
        })
      };

    case ideaUpdateConstants.LOAD_EDIT_IDEA_CHALLENGES:
      return {
        ...state,
        challengesList: action.payload,
        saveLoading: false
      }

    case ideaUpdateConstants.LOAD_EDIT_IDEA_CLASSIFICATIONS:
      return {
        ...state,
        classificationsList: action.payload,
      }

    case ideaUpdateConstants.CHANGE_IDEIA_EDIT_FIELD: {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    }

    case ideaUpdateConstants.CHANGE_FIELD_CLASSIFICATION:
      return {
        ...state,
        personalizedClassifications: state.personalizedClassifications.map(classification => {
          if (classification._id === action.payload._id) {
            return {
              ...classification,
              checked: !action.payload.checked
            }
          }
          return classification;
        })
      }

    case ideaUpdateConstants.ADD_IDEIA_EDIT_LINK: {
      //se nao fizer assim, o objeto nao muda e nao atualiza.
      const auxState = state;
      auxState.links.push("");
      return {
        ...auxState
      };
    }

    case ideaUpdateConstants.REMOVE_IDEIA_EDIT_LINK: {
      return {
        ...state,
        links: state.links.filter((item, index) => index !== action.payload)
      }
    }

    case ideaUpdateConstants.CHANGE_IDEA_EDIT_LINK: {
      const auxState = state;
      auxState.links[action.payload.index] = action.payload.value
      
      return {
        ...auxState
      }
    }

    case ideaUpdateConstants.CLEAR_CHALLENGE_FIELD:
      return {
        ...state,
        challenge: action.payload.challenge
      }

    case ideaUpdateConstants.IDEA_UPDATE_CHANGE_PARTICIPANT_FIELD:
      return {
        ...state,
        contributors: state.contributors.map((item, index) => {
          if (index === action.payload.index) {
            return action.payload.data
          }
          return item
        })
      }

    case ideaUpdateConstants.IDEA_UPDATE_CLEAR_PARTICIPANT_FIELD:
      return {
        ...state,
        contributors: state.contributors.map((item, index) => {
          if (index === action.payload.index) {
            return action.payload.data
          }
          return item
        })
      }

    case ideaUpdateConstants.IDEA_UPDATE_ADD_NEW_PARTICIPANT:
      return {
        ...state,
        contributors: [
          ...state.contributors,
          initialStateParticipants,
        ]
      }

    case ideaUpdateConstants.IDEA_UPDATE_REMOVE_PARTICIPANT:
      return {
        ...state,
        contributors: state.contributors.filter((item) => item.value !== action.payload)
      }

    case ideaUpdateConstants.IDEA_UPDATE_LOAD_PARTICIPANTS:
      return {
        ...state,
        loadParticipants: action.payload,
        loadingParticipants: false
      }

    case ideaUpdateConstants.IDEA_UPDATE_GET_PARTICIPANTS_REQUEST:
      return {
        ...state,
        loadingParticipants: true
      }

    case ideaUpdateConstants.IDEA_UPDATE_GET_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        allParticipants: action.payload,
        loadingParticipants: false
      }

    case ideaUpdateConstants.IDEA_UPDATE_GET_PARTICIPANTS_FAILURE:
      return {
        ...state,
        loadingParticipants: false
      }

    default:
      return state;
  };
};