import { rankingConstants } from '../constants';
import update from 'react-addons-update';

const initialState = {
  ranking: [],
  userRanking: {},
  loading: false,
  paging: {
    page: 1,
    skip: 0,
    limit: 10,
    sort: { position: 1 },
    total: 0,
    filter: {
      period: 9000,
      userName: '',
      column: 'points',
    },
  },
};

export function ranking(state = initialState, action) {
  switch (action.type) {

    case rankingConstants.GET_RANKING_REQUEST:
      return { 
        ...state,
        loading: true
      }

    case rankingConstants.GET_RANKING_SUCCESS:
      return update(state, {
        loading: { $set: false },
        ranking: { $set: action.payload.ranking.ranking },
        userRanking: { $set: action.payload.ranking.userRanking[0] },
        paging: {
          filter: { userName: { $set: action.payload.userName } },
          total: { $set: action.payload.paging.total },
        },
      });

    case rankingConstants.GET_RANKING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    
    case rankingConstants.GET_RANKING_MOBILE_REQUEST:
      return update(state, {
        loading: { $set: true }
      });
    
    case rankingConstants.GET_RANKING_MOBILE_SUCCESS:
      return update(state, {
        ranking: { $push: action.payload.ranking.ranking },
        userRanking: { $set: action.payload.ranking.userRanking[0] },
        paging: {
          page: { $set: parseInt(action.payload.paging.page) + 1 },
          filter: { userName: { $set: action.payload.userName } },
          total: { $set: action.payload.paging.total },
        },
        loading: { $set: false },
      });

    case rankingConstants.GET_RANKING_MOBILE_FAILURE:
      return state;

    case rankingConstants.SET_LIMIT:
      return update(state, {
        paging: {
          limit: { $set: action.payload.limit },
        },
      });

    case rankingConstants.SET_PAGE:
      return update(state, {
        paging: {
          page: { $set: action.payload.page },
        },
      });

    case rankingConstants.SET_FILTER: {
      return update(state, {
        paging: {
          filter: {
            period: { $set: action.payload.period },
          },
        },
      });
    }

    case rankingConstants.SET_TYPE_RANKING:
      return {
        ...state,
        paging: {
          ...state.paging,
          filter: {
            ...state.paging.filter,
            column: action.payload
          }
        }
      }

    default:
      return state;
  }
}
