import { businessUnitPropsConstants } from "./../constants"

export const businessUnitPropsAction = {
  loadBusinessUnitProps,
  changeBusinessUnitPropField,
  clearBusinessUnitPropField,
  clearAll,
}

function changeBusinessUnitPropField(businessUnitProp, data, imagineLocals) {
  return dispatch => {
    dispatch({
      type: businessUnitPropsConstants.CHANGE_BUSINESS_UNIT_PROP,
      payload: {
        businessUnitProp,
        data,
        imagineLocals
      }
    })
  }
}

function clearBusinessUnitPropField(businessUnitProp) {
  return dispatch => {
    dispatch({
      type: businessUnitPropsConstants.CLEAR_BUSINESS_UNIT_PROP,
      payload: businessUnitProp
    })
  }
}

function loadBusinessUnitProps(data) {
  return dispatch => {
    dispatch({
      type: businessUnitPropsConstants.LOAD_BUSINESS_UNIT_PROPS,
      payload: data
    })
  }
}


function clearAll() {
  return dispatch => {
    dispatch({
      type: businessUnitPropsConstants.CLEAR_ALL_BUSINESS_UNIT_PROP
    })
  }
}