import React from 'react';

import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  dialogContent: {
    padding: theme.spacing(3, 4, 2),
  },
  dialogActions: {
    padding: theme.spacing(3, 4),
  },
}));

export function DialogCustom({ title, open, onClose, content, action, maxWidth, styles }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} maxWidth={maxWidth} classes={styles}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />

      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          {content}
        </Grid>
      </DialogContent>
          
      <DialogActions className={classes.dialogActions}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {action}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}