import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, Typography, makeStyles } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { history } from '../../../helpers';
import { DialogCreateProject } from '../Dialogs/DialogCreateProject';
import { DialogDeleteProject } from '../Dialogs/DialogDeleteProject';
import { portfolioDialogProjectActions } from '../../../actions/portfolio/portfolio-dialog-project.actions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    menuText: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.neutrals.low.medium,
    },
}));

export default function CardMenu({ project, isExecutionFLow = false }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [projectDialogOpen, seProjectDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const { ideaDetails } = useSelector((state) => state.idea);
    const { t } = useTranslation();

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDeleteDialog = () => {
      seProjectDialogOpen(false);
      setOpenDeleteDialog(false);
    };

    const handleCloseEditDialog = () => {
      seProjectDialogOpen(false);
    }

    const handleOpenDeleteDialog = () => {
      handleClose(); //close do menu (se tirar buga)
      setOpenDeleteDialog(true);
    }

    const handleView = () => {
        history.push(`/portfolio/${project._id}`);
    };

    function handleOpenEditDialogProject() {
      return () => {
        dispatch(portfolioDialogProjectActions.loadProject(project));
        seProjectDialogOpen(true);

        isExecutionFLow && dispatch(portfolioDialogProjectActions.changeField('idea', { label: ideaDetails.name, id: ideaDetails._id }));
      }
    }

    return (<>
        { console.log('projectDialogOpen', projectDialogOpen)}
        { openDeleteDialog && <DialogDeleteProject open={openDeleteDialog} onClose={handleCloseDeleteDialog} project={project} /> }
        { projectDialogOpen && <DialogCreateProject open={projectDialogOpen} onClose={handleCloseEditDialog} project={project} executionFlow={isExecutionFLow} title={t('editar_projeto')} /> }
        <IconButton
            aria-controls="menu"
            aria-haspopup="true"
            style={{ padding: 5 }}
            onClick={handleOpen}
        >
            <MoreHorizIcon />
        </IconButton>
        <Menu
            keepMounted
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <List style={{ padding: 0 }}>
                <ListItem onClick={handleView} button>
                    <ListItemIcon>
                        <Visibility />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography className={classes.menuText}>{t('visualizar')}</Typography>
                    } />
                </ListItem>
                <ListItem onClick={handleOpenEditDialogProject()} button>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography className={classes.menuText}>{t('editar')}</Typography>
                    } />
                </ListItem>
                <ListItem onClick={handleOpenDeleteDialog} button>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography className={classes.menuText}>{t('excluir')}</Typography>
                    } />
                </ListItem>
            </List>
        </Menu>
    </>);
};