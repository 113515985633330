import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonLineChart() {
  return(
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'70%'} height={19} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={250} />
      </Grid>
    </Grid>
  );
} 