import { delegateStepIdeaConstants } from "../constants";
import { delegateStepIdeaService } from "../services";
import { alertActions } from "./alert.actions";

export const delegateStepIdeaActions = {
  setObservation,
  setTypeDialog,
  clearObservation,
  setbusinessUnitLocals,
  postDelegate,
  setEvaluationTeamsIds,
  getAllUsers,
  setUsersIds,
  addUserField,
  removeUserField,
  clearUserFields,
  clearUserIds,
  changeSelectedStep,
  postForwardStepId
};

function setObservation(observation) {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.SET_OBSERVATION, payload: { observation } });
  }
}

function setTypeDialog(type) {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.SET_TYPE_DIALOG, payload: { type } });
  }
}

function clearObservation() {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.CLEAR_OBSERCATION });
  }
}

function setbusinessUnitLocals(businessUnitLocalsId) {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.SET_DELEGATE_STEP_FLUX_BUSINESS_UNIT_LOCAL, payload: { businessUnitLocalsId } });
  }
}

function setEvaluationTeamsIds(evaluationsTeamsIds) {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.SET_DELEGATE_STEP_FLUX_EVALUATION_TEAMS, payload: { evaluationsTeamsIds } });
  }
}

function postDelegate(ideaId, delegateDetails) {
  return dispatch => {
    delegateStepIdeaService.postDelegateStepIdea(ideaId, delegateDetails)
      .then((response) => {
        dispatch(alertActions.success(response.data.message));
    })
      .catch((error) => {
        console.error(error);
        const message = error.response.data.errors.substring(7);
        dispatch(alertActions.error(message));
      });
  }
}

function getAllUsers() {
  return dispatch => {
    delegateStepIdeaService.getAllUsers().then(({data}) => {
    dispatch({ type: delegateStepIdeaConstants.GET_DELEGATE_STEP_FLUX_USERS, payload: { data } });

    }).catch(err => console.error(err));
  }
}

function setUsersIds(option) {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.SET_DELEGATE_STEP_FLUX_USERS, payload: { option } });
  }
}
function clearUserFields() {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.CLEAR_USER_FIELDS });
  }
}
function clearUserIds() {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.CLEAR_USER_IDS });
  }
}
function addUserField(id) {
  return dispatch => {
    dispatch({ type:delegateStepIdeaConstants.DELEGATE_STEP_FLUX_ADD_USER_FIELD, payload: { id } });
  }
}
function removeUserField(id) {
  return dispatch => {
    dispatch({ type:delegateStepIdeaConstants.DELEGATE_STEP_FLUX_REMOVE_USER_FIELD, payload: { id } });
  }
}

function changeSelectedStep(value) {
  return dispatch => {
    dispatch({
      type: delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SELECTED_IDEA,
      payload: { value }
    });
  }
}

function postForwardStepId(ideaId, stepId) {
  return dispatch => {
    dispatch({ type: delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_REQUEST });

    delegateStepIdeaService.forwardingStepFlux(ideaId, stepId)
      .then(() => {
        dispatch(alertActions.success('Idea encaminhada com sucesso!'));
        dispatch({ type: delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_SUCCESS });
        dispatch(changeSelectedStep(null));
      })
      .catch((error) => {
        dispatch({
          type: delegateStepIdeaConstants.DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_FAILURE,
          erro: error.toString()
        });
      })
  }
}
