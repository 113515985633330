import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

export function SelectTypeInfra(props) {
  const { t } = useTranslation();

  function handleChangeStatus(event) {
    props.handle(event.target.value)
  }

  return (
    <Grid item xs={12}>
      <FormControl variant="filled" style={{ width: '100%' }}>
        <InputLabel id="select-status-label">{t('tipo_da_infraestrutura')}</InputLabel>
        <Select
          fullWidth
          id="select-status"
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={handleChangeStatus}
          label={t('tipo_da_infraestrutura')}
        >
          <MenuItem value={'local'}>{t('local')}</MenuItem>
          <MenuItem value={'occupationArea'}>{t('area_de_ocupacao')}</MenuItem>
          <MenuItem value={'businessUnit'}>{t('unidade_de_negocio')}</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}
