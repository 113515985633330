import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Grid,
  Hidden,
  Avatar, 
  makeStyles, 
  Typography,
} from '@material-ui/core';

import { AvatarWithoutPhoto, Copyright } from '../Shared';
import { SkeletonSidebarProfile } from '../Skeletons';
import { ImagineLinkify } from '../TextLinkify';
import { CustomTooltip } from '../Shared/CustomTooltip';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyle = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(3),
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    position: "relative"
  },
  large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  name: {
    color: theme.palette.neutrals.low.dark,
  },
  position: {
    color: theme.palette.neutrals.low.light,
  },
  email: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark,
    wordWrap: 'break-word'
  },
  about: {
    color: theme.palette.neutrals.low.medium,
  },
  titleAbout: {
    fontWeight: 700,
    color: theme.palette.neutrals.low.dark,
  },
  disableTooltip: {
    position: "absolute",
    bottom: "1rem",
    right: "3rem",
    background: 'white'
  },
  disableIcon: {
    position: "absolute",
    zIndex: 2,
    height: 30,
    width: 30,
    color: theme.palette.grey.darkest,
    border: "2px solid white",
    borderRadius: "50%"
  }
}));

export function SidebarProfile({ user }) {
  const classes = useStyle();
  const { t } = useTranslation();
  const loading = useSelector((state) => state.profile.loading);

  return (
    <>
      {loading ? (
        <Card elevation={0} className={classes.card}>
          <SkeletonSidebarProfile />
        </Card>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.avatar}>
                  {user.attachment?.url ? (
                    <Avatar src={user.attachment?.url} className={classes.large} />
                  ) : (
                    <AvatarWithoutPhoto superLarge userName={user.name} />
                  )}
                  { !user.status && (
                    <CustomTooltip
                      className={classes.disableTooltip}
                        title={t('disabled')}
                        aria-label={t('disabled')}
                      >
                      <CancelIcon className={classes.disableIcon} />
                    </CustomTooltip>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3" align="center" className={classes.name}>
                    {user.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" align="center" className={classes.position}>
                    {user.position}{' '}
                    {user.businessUnitLocal?.occupationArea?.name && (
                      <>{t('em')} {user.businessUnitLocal?.occupationArea?.name}</>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {user.email && user.email.includes('@') && 
                    <Typography variant="body1" align="center" className={classes.email}>
                      {user.email}
                    </Typography>
                  }
                </Grid>
              </Grid>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="overline" className={classes.titleAbout}>
                    {t('sobre_mim')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {user.about && 
                    <Typography variant="body2" className={classes.about}>
                      <ImagineLinkify>
                        {user.about}
                      </ImagineLinkify>
                    </Typography>
                  }
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      <Hidden smDown>
        <Copyright />
      </Hidden>   
    </>
  );
}
