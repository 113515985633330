import React from 'react';
import Box from '@material-ui/core/Box';
import { LinearProgress, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 8,
  },
  containerText: {
    marginTop: 12,
    marginBottom: 6,
  },
  text: {
    fontSize: 13,
    fontWeight: 500,
  },
  barColorSecondary: {
    backgroundColor: theme.palette.auxiliary.green.light,
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box alignItems="center">
      <Box minWidth={35} className={classes.containerText}>
        <Typography
          variant="body2"
          style={
            props.value < 100 ? { color: theme.palette.primary.main } : { color: theme.palette.auxiliary.green.light }
          }
          className={classes.text}
        >
          {`${Math.round(props.value)}% ${t('completo')}`}
        </Typography>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress
          classes={{
            barColorSecondary: classes.barColorSecondary,
            root: classes.root,
          }}
          variant="determinate"
          {...props}
          color={props.value < 100 ? 'primary' : 'secondary'}
        />
      </Box>
    </Box>
  );
}

export { LinearProgressWithLabel };
