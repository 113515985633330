import React from 'react';
import { makeStyles, Typography, Box, Icon } from '@material-ui/core';

import { dateFormat } from '../../helpers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.neutrals.high.main,
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  score: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'lowercase',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  scoreComment: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    paddingLeft: theme.spacing(1.75),
  },
  idea: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  boxScore: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2.5),
    paddingLeft: 0,
    paddingBottom: theme.spacing(0.5),
  },
  date: {
    color: theme.palette.neutrals.low.light,
    fontSize: 10,
    fontWeight: 400,
    paddingTop: theme.spacing(1.5),
  },
  icons: {
    color: theme.palette.neutrals.low.light,
  },
}));

function WalletCard(props) {
  const classes = useStyles();
  const language = useSelector((state) => state.language);
  const { t } = useTranslation();

  function getIconsWallet(type) {
    switch (type) {
      case 'COMMENT_UP_GIVEN':
        return 'thumb_up';

      case 'COMMENT_UP_GIVEN_REMOVED':
        return 'thumb_down';

      case 'COMMENT_UP_RECEIVED':
        return 'thumb_up';

      case 'COMMENT_UP_RECEIVED_REMOVED':
        return 'thumb_down';

      case 'IDEA_APPROVED':
        return 'check_circle';

      case 'IDEA_COMMENT':
        return 'chat_bubble';

      case 'IDEA_COMMENT_GIVEN':
        return 'chat_bubble';

      case 'IDEA_COMMENT_GIVEN_COMMENT':
        return 'chat_bubble';

      case 'IDEA_COMMENT_REMOVED':
        return 'chat_bubble';

      case 'IDEA_COMMENT_GIVEN_REMOVED':
        return 'chat_bubble';

      case 'IDEA_EXECUTED':
        return 'check_circle';

      case 'IDEA_UP':
        return 'thumb_up';

      case 'IDEA_UP_GIVEN':
        return 'thumb_up';

      case 'IDEA_UP_GIVEN_REMOVED':
        return 'thumb_down';

      case 'IDEA_UP_REMOVED':
        return 'thumb_down';

      case 'NEW_IDEA':
        return 'emoji_objects';

      case 'NEW_USER':
        return '';

      default:
        return null;
    }
  }

  return (
    <Box className={classes.card}>
      <Box className={classes.header}>
        <Typography
          className={classes.score}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
        >
          {props.wallet.score?.points?.owner} {t('pontos')}
        </Typography>
      </Box>
      <Box className={classes.boxScore}>
        <Icon className={classes.icons}>
          {getIconsWallet(props.wallet.score?.type)}
        </Icon>
        <Typography
          className={classes.scoreComment}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="p"
        >
          {props.wallet.score?.label}
        </Typography>
      </Box>
      <Box>
        <Typography
          className={classes.idea}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="p"
        >
          {props.wallet.idea?.name}
        </Typography>
      </Box>
      <Box>
        <Typography
          className={classes.date}
          variant="subtitle2"
          color="primary"
          style={{ flexGrow: 1 }}
          component="p"
        >
          {dateFormat(props.wallet.createdAt, language)}
        </Typography>
      </Box>
    </Box>
  );
}

export { WalletCard };
