import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  makeStyles,
  Typography
} from '@material-ui/core';
import { tableCss } from '../Tables/TableCss';
import { useDispatch, useSelector } from 'react-redux';
import { rankingActions } from '../../actions';
import clsx from 'clsx';
import { history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../Shared';
import { RankingMedalColors } from '../Ranking';

const useStyles = makeStyles((theme) => ({
  user: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

function RankingTable({ rankings, paging, userId }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const styleProps = { text: t('linhas_por_pagina') };
  const tableClasses = tableCss(styleProps);

  const columns = [
    { id: 'champions', label: '' },
    { id: 'placing', label:  t('colocacao') },
    { id: 'user', label: t('colaborador')},
    { id: 'businessUnit', label: t('unidade')},
    { id: 'local', label: t('local')},
    { id: 'occupationArea', label: t('area')},
    paging.filter.column === 'points' 
      ? {id: 'points', label: t('pontos').toLowerCase()} 
      : {id: 'ideas', label: t('ideias').toLowerCase()}
  ];
  
  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(rankingActions.setPage(newPage));
    dispatch(
      rankingActions.getRanking(
        paging.filter,
        newPage,
        paging.sort,
        paging.limit
      )
    );
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(rankingActions.setLimit(limit));
    dispatch(rankingActions.getRanking(paging.filter, 1, paging.sort, limit));
  }
  function handleView(id) {
    history.push(`/profile/${id}`);
  }

  return (
    <div>
      <Paper className={tableClasses.paperRoot}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="idea table"
            classes={{ root: tableClasses.table }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    classes={{ root: tableClasses.tableCellHeader }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rankings &&
                rankings.map((ranking, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      style={{ cursor: 'pointer' }}
                      classes={{ root: tableClasses.tableRow }}
                      className={
                        ranking.userId === userId ? classes.user : null
                      }
                    >
                      <TableCell
                        classes={{ root: tableClasses.tableCellRadiusLeft }}
                        style={{ textAlign: 'right' }}
                      >
                        {ranking.position <= 5 && (
                          <RankingMedalColors position={ranking.position} />     
                        )}{' '}
                      </TableCell>
                      <TableCell
                        classes={{ root: tableClasses.tableCell }}
                        className={
                          ranking.userId._id === userId ? classes.user : null
                        }
                      >
                        {ranking.position}º
                      </TableCell>
                      <TableCell
                        className={clsx(
                          ranking.userId._id === userId ? classes.user : null,
                          tableClasses.oneLine
                        )}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        <CustomTooltip 
                          title={ranking.userName} 
                          aria-label={ranking.userName} 
                          placement="bottom-start"
                        >
                          <Typography 
                            variant="subtitle2" 
                            className={tableClasses.link} 
                            onClick={() => handleView(ranking.userId._id)}
                      >
                        {ranking.userName}
                          </Typography>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          ranking.userId === userId ? classes.user : null,
                          tableClasses.oneLine
                        )}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        {ranking.businessUnitLocal?.businessUnit?.name}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          ranking.userId === userId ? classes.user : null,
                          tableClasses.oneLine
                        )}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        {ranking.businessUnitLocal?.local?.name}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          ranking.userId === userId ? classes.user : null,
                          tableClasses.oneLine
                        )}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        {ranking.businessUnitLocal?.occupationArea?.name}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          ranking.userId === userId ? classes.user : null,
                          tableClasses.oneLine
                        )}
                        classes={{ root: tableClasses.tableCellRadiusRight }}
                      >
                        {paging.filter.column === 'points' ? parseInt(ranking.score) : ranking.ideas}
                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          classes={{
            input: tableClasses.paginationInput,
            spacer: tableClasses.paginationSpacer,
            caption: tableClasses.paginationCaption,
            toolbar: tableClasses.paginationToolbar,
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          count={paging.total}
          rowsPerPage={paging.limit}
          page={paging.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('exibindo')}
        />
      </Paper>
    </div>
  );
}

export { RankingTable };
