import { portfolioConstants } from '../../constants';
import { portfolioService } from '../../services';
import { alertActions } from './../alert.actions';
import { portfolioDetailsActions } from "./portfolio-details.actions"
import { portfolioHomeActions } from "./portfolio-home.actions"
import { history } from './../../helpers';

export const portfolioDialogFinnacialActions = {
  loadCreateFinancialPlanningDialog,
  changeFieldExpense,
  addNewExpense,
  removeExpense,
  setEstimatedValueReturn,
  clearDialogFinancialPlanning,
  updateFinancialPlanning,
  postCreateFinancialPlanning
}


function loadCreateFinancialPlanningDialog(newFinancialPlanning) {
  return (dispatch) => {
    dispatch({ 
      type: portfolioConstants.LOAD_CREATE_FINANCIAL_PLANNING_DIALOG, 
      payload: newFinancialPlanning
    });
  }
}

function changeFieldExpense(name, value, index) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_CHANGE_FIELD_EXPENSE,
      payload: { name, value, index }
    });
  }
}

function addNewExpense() {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_ADD_NEW_EXPENSE })
  }
}

function removeExpense(index) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_REMOVE_EXPENSE,
      payload: index
    })
  }
}


function setEstimatedValueReturn(name, value) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_SET_ESTIMATED_VALUE_RETURN,
      payload: { name, value }
    })
  }
}

function clearDialogFinancialPlanning() {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CLEAR_PORTFOLIO_DIALOG_FINANCIAL_PLANNING,
    })
  }
}


function updateFinancialPlanning(data, successMessage) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.PORTFOLIO_UPDATE_FINANCIAL_PLANNING_REQUEST,
    })

    portfolioService.updateFinancialPlanning(data)
      .then((response) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_UPDATE_FINANCIAL_PLANNING_SUCCESS,
          payload: response.data
        })
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioDetailsActions.getOneFinancialPlanning(data.projectId))
      })
      .catch((error) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_UPDATE_FINANCIAL_PLANNING_FAILURE,
          error
        })
      })
  }
}

function postCreateFinancialPlanning(data, successMessage) {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_REQUEST })
    portfolioService.postCreateFinancialPlanning(data)
      .then((response) => {
        dispatch({
          type: portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_SUCCESS,
          payload: response.data
        });
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioDetailsActions.getOneFinancialPlanning(data.projectId))
      },
        (error) => {
          dispatch({
            type: portfolioConstants.PORTFOLIO_POST_ADD_FINANCIAL_PLANNING_FAILURE,
            error
          });
        })
  }
}