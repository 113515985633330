export const today = new Date();
export const beforeMonth = new Date().setMonth(new Date().getMonth() - 1);
export const formatDate = (date) => {
  return new Date(date).toISOString().slice(0, 10);
};

export const formatDateMonthYear = (date) => {
  return date.slice(0, -3);
};

export const formatDateMouthsEquals = (startDate, endDate) => {
  const start = startDate.split('-');
  const end = endDate.split('-');
  if (start[1] === end[1] && start[1] === '01') return `${start[0]}-12`;
  if (start[1] === end[1]) return `${start[0]}-${start[1] - 1}`;
  return formatDateMonthYear(startDate);
};
