import React from 'react';
import { Grid, Button, Box, CircularProgress, Typography } from '@material-ui/core';
import {
  DeadlineCollapse,
  DetailsCollapse,
  FinancialFeedbackCollapse,
  CostStructureCollapse,
  DialogJustification,
} from '../../components';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { useTranslation } from 'react-i18next';
import { LoadingButton, DialogExecutionFlow, ButtonWithTooltip } from '../Shared';
import { useIdeaConclusionController } from './hooks';
import { IdeaCss } from './IdeaCss';

function IdeaConclusion() {
  const classes = IdeaCss();
  const { t } = useTranslation();



  const {
      id, 
    loading,
    loadingUpdate,
    openDialog,
    dialogIsOpen,
    loadingConclusion,
    sendConclusion,
    openDialogConclusion,

    closeDialogConclusion,
    openModelJustification,

    onCloseDialogJustification
  } = useIdeaConclusionController();

  return (
    <>
        <DialogExecutionFlow
        open={dialogIsOpen}
        onClose={closeDialogConclusion}
        title={t('concluir_execucao')}
        subtitle={t('deseja_concluir_a_execucao_dessa_ideia')}
        description={t('as_informacoes_dos_resultados_da_execucao_nao_poderao_ser_editadas_posteriormente')}
        >
        {(loadingConclusion || loadingUpdate) ? <LoadingButton /> : (
        <Box>
          <Button variant="outlined" color="primary" onClick={closeDialogConclusion}>
            {t('voltar')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 23 }}
              onClick={sendConclusion}
          >
            {t('concluir_execucao')}
          </Button>
        </Box>
        )}
      </DialogExecutionFlow>
      <DialogJustification
        open={openDialog}
        onClose={onCloseDialogJustification}
        id={id}
      />
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='80vh' width='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.titleUpperCase}>
                {t('execucao')}
              </Typography>
            </Grid>
        <Grid item xs={12}>
          <DeadlineCollapse isConclusion />
        </Grid>
        <Grid item xs={12}> 
          <CostStructureCollapse isConclusion />
        </Grid>
        <Grid item xs={12}>
          <FinancialFeedbackCollapse isConclusion />
        </Grid>
        <Grid item xs={12}>
          <DetailsCollapse isConclusion />
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' width='100%'>
                <ButtonWithTooltip
                  tooltip={t('aprovar_solicitar_investimento_ou_estudo')}
                  text={t('execucao_nao_concluida')}
              variant="outlined"
              color="primary"
              startIcon={<CancelPresentationIcon />}
              onClick={openModelJustification}
              style={{ marginRight: 10 }}
                />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AssignmentTurnedInIcon />}
              onClick={openDialogConclusion}
            >
              {t('concluir_execucao')}
            </Button>
          </Box>
        </Grid>
          </Grid>
        </>
      )
      }
    </>
  );
}

export { IdeaConclusion };
