import config from '../config';
import axios from 'axios';
import { authHeader } from '../helpers/auth-headers';

export const uploadsService = {
  postVideo,
  postYTVideo,
  deleteVideo,
  postTutorialFile,
  putTutorialFile,
  postTutorial,
};

function deleteVideo(id) {
  return axios.delete(`${config.URL_BASE}/help/first-access/video/${id}`, {
    headers: authHeader(),
  });
}

function postTutorialFile(file) {
  const data = new FormData();

  data.append('file', file.file, file.name);

  return axios.post(`${config.URL_BASE}/uploads/tutorial/63bff77145ad305edaa32ce5`, data, {
    headers: authHeader(),
  }).then(({ data }) => data).catch(err => console.error(err))
}

function postTutorial(title, url) {

  return axios.post(`${config.URL_BASE}/help/tutorials`, {
    "title": title,
    "documentUrl": url
  }, {
    headers: authHeader(),
  })
}


function putTutorialFile(title, url, id, fileName="") {
  console.log(fileName)
  const data = {
    "title": title,
    "documentUrl": url,
    "attachments": [
      {
      "fileName": !!fileName.length ? fileName : title ,
        "url": url,
      }
    ]
  };
  return axios.put(`${config.URL_BASE}/help/tutorials/${id}`, data, {
    headers: authHeader(),
  })

}

function postYTVideo(link) {
  const data = {
    "name": "Vídeo primeiro acesso",
    "description": "URL do Vídeo de primeiro acesso ao Imagine",
    "key": "video-intro-url",
    "value": link,
    "configObject": {
      "type": "YOUTUBE",
      "link": link
    },
    "status": true
  };
  return axios.post(`${config.URL_BASE}/help/first-access/video`, data, {
    headers: authHeader(),
  });
}


function postVideo(file) {
  const data = new FormData();

  data.append('file', file.file, file.name);

  return axios.post(`${config.URL_BASE}/uploads/first-access/63e3e7338bd010d526f513e5`, data, {
    headers: authHeader(),
  }).then(({ data: { data } }) => axios.post(`${config.URL_BASE}/help/first-access/video`, {
    "name": "Vídeo primeiro acesso",
    "description": "URL do Vídeo de primeiro acesso ao Imagine",
    "key": "video-intro-url",
    "value": data,
    "configObject": {
      "type": "YOUTUBE",
      "link": data
    },
    "status": true
  }, {
    headers: authHeader(),
  })).catch(err => console.error(err))
}
