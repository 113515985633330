import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { alertActions, userFunctionActions, walletActions } from '../../../actions';

export function useAdminUserController() {
  const dispatch = useDispatch();
  const { id: userId } = useParams();
  const [type, setType] = useState('atribuir-funcao');
  const { paging: { quantityPerPage, filter, sort, query } } = useSelector((state) => state.wallet);
  const {
    sendEmailPasswordSuccess,
    sendEmailPasswordError,
    loadingCollaborator
  } = useSelector(state => state.userFunction);
  
  const title = useMemo(() => {
    switch (type) {
      case 'atribuir-funcao':
        return 'atribuir_funcao_ao_usuario';
      case 'editar-dados':
        return 'editar_dados_do_usuario';
      case 'gerenciar-pontos':
        return 'gerenciar_pontos';
      default:
        return '';
    }
  }, [type]);

  useEffect(() => {
    if (type === 'atribuir-funcao') {
      dispatch(userFunctionActions.getUser(userId));
    }
  }, [userId, type]);

  useEffect(() => {
    if (type === 'editar-dados') {
      dispatch(userFunctionActions.getUser(userId));
      dispatch(userFunctionActions.getAdminUserEdit(userId));
    }
  }, [userId, type]);

  useEffect(() => {
    if (type === 'gerenciar-pontos') {
      dispatch(userFunctionActions.getUser(userId));
      dispatch(walletActions.getUserPoints(userId));
      dispatch(walletActions.getWallets(userId, 1, quantityPerPage, filter, sort, query))
    }
  }, [userId, type, 1, quantityPerPage, filter, sort, query]);

  useEffect(() => {
    if (sendEmailPasswordSuccess) {
      dispatch(alertActions.success("Link de recuperação enviado!"));
    }

    if (sendEmailPasswordError) {
      dispatch(alertActions.error("Erro ao enviar o link de recuperação"));
    }
  }, [sendEmailPasswordSuccess, sendEmailPasswordError]);

  return {
    userId,
    title,
    type,
    setType,
    loadingCollaborator
  }
}