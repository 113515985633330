export const authenticationConstanst = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  REGISTER_OFFICE_REQUEST: 'USERS_REGISTER_OFFICE_REQUEST',
  REGISTER_OFFICE_SUCCESS: 'USERS_REGISTER_OFFICE_SUCCESS',
  REGISTER_OFFICE_FAILURE: 'USERS_REGISTER_OFFICE_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGIN_OFFICE_REQUEST: 'USERS_LOGIN_OFFICE_REQUEST',
  LOGIN_OFFICE_SUCCESS: 'USERS_LOGIN_OFFICE_SUCCESS',
  LOGIN_OFFICE_FAILURE: 'USERS_LOGIN_OFFICE_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
};
