import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  errorSpan: {
    position: 'absolute',
    width: 550,
    right: -300,
  }
}));

export function DatePicker(props) {
  const classes = useStyles();

  return (
    <KeyboardDatePicker
      {...props}
      autoOk
      fullWidth
      variant="inline"
      inputVariant="filled"
      KeyboardButtonProps={{ 'aria-label': 'change date' }}
      invalidDateMessage={
        <span className={classes.errorSpan}>
          {props.invalidDateMessage}
        </span>
      }
      minDateMessage={
        <span className={classes.errorSpan}>
          {props.minDateMessage}
        </span>
      }
    />
  );
}