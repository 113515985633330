import React, { useState } from 'react';
import {
  Card,
  CardActions,
  Button,
  Collapse,
  CardContent,
  makeStyles,
  TextField,
} from '@material-ui/core';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { challengeActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.neutrals.low.medium,
    marginTop: 16,
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 10),
  },
}));

function ChallengeCriteriaCard({ expanded, requirement }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });
  const [requirementChar, setRequeriment] = useState('');
  const { t } = useTranslation();

  function handleRequirement() {
    const requirement = getValues().requirement;
    setRequeriment(requirement);
  }

  function onSubmit({target: {value}}) {
      dispatch(challengeActions.setCriteria(value));
  }

  return (
    <Card>
      <CardActions className={classes.actions}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.iconDiscription}>
            <AssignmentTurnedInIcon color="primary" />
          </div>
          <div className={classes.description}>{t('criterios')}</div>
        </div>
      </CardActions>
        <CardContent className={classes.content}>
            <div className={clsx(classes.about, classes.marginBetweenInputs)}>
              <div className={classes.characters}>
                <span>{requirementChar.length}/10000 caracteres</span>
              </div>
              <TextField
                error={!!errors.requirement}
                id="outlined-multiline-static"
                name="requirement"
                label={t('criterios_e_requisitos')}
                multiline
                fullWidth
                rows={8}
                variant="filled"
                onChange={handleRequirement}
                inputRef={register({
                  maxLength: 10000,
                required: t('criterios_e_requisitos_e_obrigatorio'),
                })}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                defaultValue={requirement}
                onBlur={onSubmit}
              />
              <p className={classes.error}>
                {errors.requirement && errors.requirement.message}
                {errors.requirement &&
                  errors.requirement.type === 'maxLength' &&
                  'Você passou o limite de caracteres'}
              </p>
            </div>
        </CardContent>
    </Card>
  );
}

export { ChallengeCriteriaCard };
