import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { externalContentActions } from '../../actions';
import { BackNavAdmin } from '../../components/Shared';
import { DialogDelete } from '../../components/Dialogs';
import { ExternalContentForm } from '../../components/ExternalContent';
import { Container, makeStyles, Grid, Button, Card, CardContent } from '@material-ui/core';

import {
  ContentImageUploader
} from '../../components';
 
import { Save } from '@material-ui/icons'; 
import { useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';

function ExternalContentsForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { contentFields, loading } = useSelector(state => state.externalContent);
  const { thumbnail, banner } = useSelector(state => state.imageUpload);
  const [showDialog, setShowDialog] = useState(false);
  const [externalContent, setExternalContent] = useState({});

  function deleteContent() {
    dispatch(externalContentActions.deleteContent(externalContent._id, t('o_conteudo_foi_excluido')));
    setExternalContent({});
    setShowDialog(!showDialog);
  }

  function handleOpenDialogDelete() {
    setExternalContent({});
    setShowDialog(!showDialog);
  }
  function handleSend() {
    if (!!id) {
      dispatch(externalContentActions.editContent(contentFields, id, {thumbnail, banner}));
    } else {
      dispatch(externalContentActions.createContent(contentFields, {thumbnail, banner}));
    }
  };

  useEffect(() => {
    if (!!id) {
      dispatch(
        externalContentActions.getExternalContentById(
          id
        )
      );
    }

    return function () {
      dispatch(externalContentActions.resetExternalContent());
    }
  }, [id, dispatch])

  const shouldButtonDisabled = !!contentFields?.title?.length && !!contentFields?.link?.length && !!contentFields?.description?.length;

  return (
    <>
       <DialogDelete
        open={showDialog}
        onClose={handleOpenDialogDelete}
        onAccept={deleteContent}
      />
      <BackNavAdmin title={t('conteudos')} />
      <Container style={{ marginTop: 32 }}>
        <Grid container spacing={4}>
          <Grid item container xs={9} spacing={6}>
            <Grid item>
              <ExternalContentForm contentId={id} 
                setExternalContent={setExternalContent}
                dialog={{ setOpenDialog: setShowDialog, openDialog: showDialog }}/>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              { !loading ? <ContentImageUploader /> :  <Skeleton variant='retangular' style={{ height: 200, marginTop: 30 }} /> }
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Card style={{ display: 'flex', justifyContent: 'center' }}>
              <CardContent>
                {!loading ? <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Save />}
                  onClick={() => handleSend()}
                  disabled={!shouldButtonDisabled}
                >
                  {t('publicar_conteudo')}
                </Button> :  <Skeleton variant='retangular' style={{ height: 50 }} />}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ExternalContentsForm };
