import { multiAreaCascadeConstants } from "./../constants"

export const multiAreaCascadeActions = {
  initialLoad,
  addField,
  removeField,
  changeField,
  clearAll,
  loadFields
}

function initialLoad(locals) {
  return dispatch => {
    dispatch({
      type: multiAreaCascadeConstants.MULTI_AREA_CASCADE_INITIAL,
      payload: { 
        locals,
      }
    })
  }
}

function loadFields(businessUnitLocals, imagineLocals) {
  return dispatch => {
    dispatch({
      type: multiAreaCascadeConstants.LOAD_MULTI_AREA_CASCADE_FIELDS,
      payload: {
        businessUnitLocals,
        locals: imagineLocals,
      }
    })
  }
}

function addField(imagineLocals) {
  return dispatch => {
    dispatch({
      type: multiAreaCascadeConstants.ADD_MULTI_AREA_NEW_FIELD,
      payload: {
        locals: imagineLocals
      }
    })
  }
}

function removeField(fieldId) {
  return dispatch => {
    dispatch({
      type: multiAreaCascadeConstants.REMOVE_MULTI_AREA_FIELD,
      payload: fieldId
    })
  }
}

function changeField(unit, fieldIndex, newValue, imagineLocals, field) {
  return (dispatch) => {
    dispatch({
      type: multiAreaCascadeConstants.CHANGE_MULTI_AREA_FIELD,
      payload: {
        fieldIndex,
        unit,
        value: newValue ? newValue.value : '',
        label: newValue ? newValue.label : '', 
        locals: imagineLocals,
        businessUnit: field.businessUnit,
        local: field.local,
        occupationArea: field.occupationArea
      }
    })
  }
}

function clearAll() {
  return dispatch => {
    dispatch({
      type: multiAreaCascadeConstants.CLEAR_ALL_MULTI_AREA
    })
  }
}