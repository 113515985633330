import React from 'react';
import { Button, Hidden, makeStyles } from '@material-ui/core';
import { uniqueId } from 'lodash';
import fileSize from 'filesize';
import { ideaUpdateActions } from '../../actions';
import { useDispatch } from 'react-redux';
import config from '../../config';
import { useWindowSize } from '../../hooks';

const useStyle = makeStyles((theme) => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
}));

function EditUploadButton({ 
    type, 
    text, 
    icon, 
    variant = 'outlined',
  }) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [width] = useWindowSize();

  function handleInputChange(e) {
    const file = e.target.files[0];
    const newUploadedFiles = {
      file,
      type: file.type,
      id: uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      url: null,
    };

    if (type === 'photo') {
      dispatch(ideaUpdateActions.updateSetImages([newUploadedFiles]));
    }

    // if (type === 'video') {
    //   dispatch(ideaCreateActions.setVideo(newUploadedFiles[0]));
    // }

    if (type === 'file') {
      dispatch(ideaUpdateActions.updateSetFile([newUploadedFiles]));
    }
  }

  function accept(type) {
    if (type === 'photo') {
      return 'image/*';
    }

    if (type === 'video') {
      return 'video/*';
    }

    if (type === 'file') {
      return '.pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,application/msword,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
  }

  return (
    <Button
      variant={variant}
      component="label"
      startIcon={icon}
      color="primary"
      style={{ marginRight: 8 }}
      classes={{
        startIcon: width > config.RESPONSIVE_MOBILE ? '' : classes.startIcon,
      }}
    >
      <Hidden smDown>{text}</Hidden>
      <input
        type="file"
        accept={accept(type)}
        style={{ display: 'none' }}
        onChange={handleInputChange}
      />
    </Button>
  );
}

export { EditUploadButton };
