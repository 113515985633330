export const infraStructureConstants = {
	CHANGE_INFRASTRUCTURE_FIELD_NAME: 'CHANGE_INFRASTRUCTURE_FIELD_NAME',
	CLEAR_INFRASTRUCTURE_DIALOG: 'CLEAR_INFRASTRUCTURE_DIALOG',
	LOAD_INFRASTRUCTURE_DIALOG: 'LOAD_INFRASTRUCTURE_DIALOG',
	LOAD_INFRASTRUCTURE_ASSOCIATIONS: 'LOAD_INFRASTRUCTURE_ASSOCIATIONS',

	CREATE_INFRASTRUCTURE_REQUEST: 'CREATE_INFRASTRUCTURE_REQUEST',
	CREATE_INFRASTRUCTURE_SUCCESS: 'CREATE_INFRASTRUCTURE_SUCCESS',
	CREATE_INFRASTRUCTURE_FAILURE: 'CREATE_INFRASTRUCTURE_FAILURE',

	UPDATE_INFRASTRUCTURE_REQUEST: 'UPDATE_INFRASTRUCTURE_REQUEST',
	UPDATE_INFRASTRUCTURE_SUCCESS: 'UPDATE_INFRASTRUCTURE_SUCCESS',
	UPDATE_INFRASTRUCTURE_FAILURE: 'UPDATE_INFRASTRUCTURE_FAILURE',

	DELETE_INFRASTRUCTURE_REQUEST: 'DELETE_INFRASTRUCTURE_REQUEST',
	DELETE_INFRASTRUCTURE_SUCCESS: 'DELETE_INFRASTRUCTURE_SUCCESS',
	DELETE_INFRASTRUCTURE_FAILURE: 'DELETE_INFRASTRUCTURE_FAILURE',

	GET_INFRASTRUCTURE_REQUEST: 'GET_INFRASTRUCTURE_REQUEST',
	GET_INFRASTRUCTURE_SUCCESS: 'GET_INFRASTRUCTURE_SUCCESS',
	GET_INFRASTRUCTURE_FAILURE: 'GET_INFRASTRUCTURE_FAILURE',

	GET_INFRASTRUCTURE_ASSOCIATION_REQUEST: 'GET_INFRASTRUCTURE_ASSOCIATION_REQUEST',
	GET_INFRASTRUCTURE_ASSOCIATION_SUCCESS: 'GET_INFRASTRUCTURE_ASSOCIATION_SUCCESS',
	GET_INFRASTRUCTURE_ASSOCIATION_FAILURE: 'GET_INFRASTRUCTURE_ASSOCIATION_FAILURE',

	POST_INFRASTRUCTURE_ASSOCIATION_REQUEST: 'POST_INFRASTRUCTURE_ASSOCIATION_REQUEST',
	POST_INFRASTRUCTURE_ASSOCIATION_SUCCESS: 'POST_INFRASTRUCTURE_ASSOCIATION_SUCCESS',
	POST_INFRASTRUCTURE_ASSOCIATION_FAILURE: 'POST_INFRASTRUCTURE_ASSOCIATION_FAILURE',

	CHANGE_INFRASTRUCTURE_FIELD_ASSOCIATION: 'CHANGE_INFRASTRUCTURE_FIELD_ASSOCIATION',
	ADD_NEW_INFRASTRUCTURE_ASSOCIATION: 'ADD_NEW_INFRASTRUCTURE_ASSOCIATION',
	REMOVE_INFRASTRUCTURE_ASSOCIATION: 'REMOVE_INFRASTRUCTURE_ASSOCIATION',
	CLEAR_INFRASTRUCTURE_ASSOCIATIONS: 'CLEAR_INFRASTRUCTURE_ASSOCIATIONS',

	SET_PAGING: 'SET_PAGING',
	CHANGE_SELECTED_TAB: 'CHANGE_SELECTED_TAB',
	CHANGE_INFRA_CLASSIFICATION_FILTER: 'CHANGE_INFRA_CLASSIFICATION_FILTER',
	GET_ALL_ASSOCIATIONS: 'GET_ALL_ASSOCIATIONS',
	CLEAR_IDS_TO_REMOVE: 'CLEAR_IDS_TO_REMOVE',

	PUT_INFRASTRUCTURE_ASSOCIATION_REQUEST: "PUT_INFRASTRUCTURE_ASSOCIATION_REQUEST",
	PUT_INFRASTRUCTURE_ASSOCIATION_SUCCESS: "PUT_INFRASTRUCTURE_ASSOCIATION_SUCCESS",
	PUT_INFRASTRUCTURE_ASSOCIATION_FAILURE: "PUT_INFRASTRUCTURE_ASSOCIATION_FAILURE",

	DELETE_INFRASTRUCTURE_ASSOCIATION_REQUEST: "DELETE_INFRASTRUCTURE_ASSOCIATION_REQUEST",
	DELETE_INFRASTRUCTURE_ASSOCIATION_SUCCESS: "DELETE_INFRASTRUCTURE_ASSOCIATION_SUCCESS",
	DELETE_INFRASTRUCTURE_ASSOCIATION_FAILURE: "DELETE_INFRASTRUCTURE_ASSOCIATION_FAILURE",

	REQUEST_ALL_ASSOCIATIONS: "REQUEST_ALL_ASSOCIATIONS",
	FAILURE_ALL_ASSOCIATIONS: "FAILURE_ALL_ASSOCIATIONS",
  REQUEST_DELETE_BIND: "REQUEST_DELETE_BIND"
};