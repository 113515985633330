import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import teamIcon from '../../../assets/imgs/teamicon.svg';
import { SkeletonTeams } from '../Details/SkeletonGeneralData';
import { DialogCreateTeam } from "./../DialogCreateTeam";
import { TeamList } from "./../Details/TeamList";
import { useSelector } from 'react-redux';
import { PortifolioTitle } from "./../PortifolioTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
}));

export const CardTeams = () => {
  const { t } = useTranslation();
  const [openCreateTeam, setOpenCreateTeam] = useState(false);
  const { teams } = useSelector(state => state.portfolioDetails);

  const classes = useStyles();

  function handleOpenCreateTeam() {
    setOpenCreateTeam(!openCreateTeam);
  };

  return (
    <>
      { openCreateTeam && <DialogCreateTeam open={openCreateTeam} onClose={handleOpenCreateTeam} /> }
      <Card elevation={0} className={classes.card}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <PortifolioTitle
            title='equipes'
            icon={<img src={teamIcon} alt="team icon" style={{ width: 25 }} />}
          />
          {teams.details?.length > 0 ? (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleOpenCreateTeam}
            >
              {t('editar_equipe')}
            </Button>
          ) : (
            <Button
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.button}
              onClick={handleOpenCreateTeam}
            >
              {t('adicionar_equipe')}
            </Button>
          )}
        </Box>

        { teams.loading ? (
          <SkeletonTeams />
        ) : (
          <TeamList   />
        )}
      </Card>
    </>
  )
}
