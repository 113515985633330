import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@material-ui/core';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import FilterListIcon from '@material-ui/icons/FilterList';

const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderRadius: 8, 
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.palette.neutrals.high.main,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
}));

const Tab = styled(MuiTab)({
  textTransform: 'lowercase'
});

export function QualificationTabs({ selectedTab, setSelectedTab }) {
  const { t } = useTranslation();

  return (
    <Tabs
      variant="fullWidth"
      textColor="primary"
      indicatorColor="primary"
      value={selectedTab}
      onChange={(_e, value) => setSelectedTab(value)} 
    >
      <Tab
        value='qualification'
        label={(
          <Box display="flex" alignItems="center" justifyContent="center">
            <ThumbsUpDownIcon style={{ marginRight: 8 }} />
            <Typography variant="subtitle2">{t('avaliacao')}</Typography>
          </Box>
        )}
      />
      <Tab
        value="history"
        label={(
          <Box display="flex" alignItems="center" justifyContent="center">
            <FilterListIcon style={{ marginRight: 8 }} />
            <Typography variant="subtitle2">{t('historico')}</Typography>
          </Box>
        )}
      />
    </Tabs>
  );
}