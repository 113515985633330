export const feedbackConstants = {
  SET_FEEDBACK_TEXT: 'SET_FEEDBACK_TEXT',
  CLEAR_FEEDBACK_TEXT: 'CLEAR_FEEDBACK_TEXT',
  OPEN_FEEDBACK: 'OPEN_FEEDBACK',
  DISCART_FEEDBACK: 'DISCART_FEEDBACK',
  POST_USER_FEEDBACK_REQUEST: 'POST_USER_FEEDBACK_REQUEST',
  POST_USER_FEEDBACK_SUCCESS: 'POST_USER_FEEDBACK_SUCCESS',
  POST_USER_FEEDBACK_FAILURE: 'POST_USER_FEEDBACK_FAILURE',

  REQUEST_GET_FEEDBACKS: 'REQUEST_GET_FEEDBACKS',
  SUCCESS_GET_FEEDBACKS: 'SUCCESS_GET_FEEDBACKS',
  FAILURE_GET_FEEDBACKS: 'FAILURE_GET_FEEDBACKS',

  SUCCESS_GET_FEEDBACKS_SCROLL: 'SUCCESS_GET_FEEDBACKS_SCROLL',
  FAILURE_GET_FEEDBACKS_SCROLL: 'FAILURE_GET_FEEDBACKS_SCROLL',
  REQUEST_GET_FEEDBACKS_SCROLL: 'REQUEST_GET_FEEDBACKS_SCROLL'
};
