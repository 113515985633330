export const delegateStepIdeaConstants = {
  SET_OBSERVATION: 'SET_OBSERVATION',
  CLEAR_OBSERCATION: 'CLEAR_OBSERCATION',
  SET_TYPE_DIALOG: 'SET_TYPE_DIALOG',
  SET_DELEGATE_STEP_FLUX_BUSINESS_UNIT_LOCAL: 'SET_DELEGATE_STEP_FLUX_BUSINESS_UNIT_LOCAL',
  SET_DELEGATE_STEP_FLUX_EVALUATION_TEAMS: 'SET_DELEGATE_STEP_FLUX_EVALUATION_TEAMS',
  SET_DELEGATE_STEP_FLUX_USERS: 'SET_DELEGATE_STEP_FLUX_USERS',
  GET_DELEGATE_STEP_FLUX_USERS: 'GET_DELEGATE_STEP_FLUX_USERS',
  DELEGATE_STEP_FLUX_ADD_USER_FIELD: 'DELEGATE_STEP_FLUX_ADD_USER_FIELD',
  CLEAR_USER_FIELDS: 'CLEAR_USER_FIELDS',
  DELEGATE_STEP_FLUX_REMOVE_USER_FIELD: 'DELEGATE_STEP_FLUX_REMOVE_USER_FIELD',
  CLEAR_USER_IDS: 'CLEAR_USER_IDS',
  DELEGATE_STEP_FLUX_SELECTED_IDEA: 'DELEGATE_STEP_FLUX_SELECTED_IDEA',
  DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_REQUEST: 'DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_REQUEST',
  DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_SUCCESS: 'DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_SUCCESS',
  DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_FAILURE: 'DELEGATE_STEP_FLUX_SEND_FORWARD_STEP_ID_FAILURE'
};
