import { IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import clsx from 'clsx';
import { truncate } from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortfolioCollapsibleRow } from '../../Portfolio';
import { CustomTooltip } from '../../Shared';
import { tableCss } from '../../Tables/TableCss';

const useRowStyles = makeStyles((theme) => ({
  hidden: {
    backgroundColor: "unset",
    border: "unset",
  },
  menuText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
  },
  avatar: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  root: {
    cursor: 'default',
    marginRight: 15,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const TableRowStyled = withStyles({
  root: {
    borderRight: `1px solid #E7EaED`,
    borderLeft: `1px solid #E7EaED`,
  },
})(TableRow);

function ImagineTable({ rows = [] }) {
  const { t } = useTranslation();
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);



  const linhasTable = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(linhasTable);

  const columns = Object.keys(rows[0]).map(key => ({ id: key, label: key }));
  columns.push({ id: 'actions', label: '' })

  return (<>
    <Paper className={tableClasses.paperRoot}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="tabelas de tarefas"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    classes={{ root: tableClasses.tableCellHeader }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody >
            {rows.map((row, index) => (
              <TableRow key={index} className={clsx(classes.root, tableClasses.tableRow)}>
                {columns.map((_, i) => (
                  <TableCell style={{ maxWidth: 540 }} classes={() => {
                    if(i === 0) return ({ root: tableClasses.tableCellRadiusLeft })
                    else if(i === columns.length - 1) return ({ root: tableClasses.tableCellRadiusRight })
                    else return ({ root: tableClasses.tableCellRadiusLeft })
                    }}>
                    <CustomTooltip title={t(row[columns[i].label])} arial-label={t(row[columns[i].label])}>
                      <Typography >
                        {t(row[columns[i].label])}
                      </Typography>
                    </CustomTooltip>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        // count={tasks.items?.paging?.total || 0}
        // rowsPerPage={tasks.items?.paging?.limit || 0}
        // page={tasks.items?.paging?.page - 1 || 0}
        // onPageChange={handleChangePage}
        // onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
      />
    </Paper>
  </>
  );
}

export function InfrastructureLinks() {
  const { t } = useTranslation();
  const rows = [
    {
      data_de_cadastro: Date.now(),
      nome_da_infraestrutura: 'Corpoprativo',
      tipo_da_infraestrutura: 'unidade_de_negocio',
      locais_vinculados: 3,
      areas_vinculadas: 10,
    }
  ];
  return (
    <>
      <ImagineTable rows={rows} />
    </>
  );
}