import React from 'react'
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@material-ui/core"
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { Copyright } from '../Shared';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const useStyles = makeStyles((theme) => ({
  sidebarIcon: {
    minWidth: "auto",
    paddingRight: 8,
  },
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export const SidebarManageAccess = ({ type, setType }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <List style={{ padding: 0 }}>
          <ListItem 
            button 
            onClick={() => setType('atribuir-funcao')}
            className={type === "atribuir-funcao" ? classes.backgroundActived : ''}
          >
            <ListItemIcon className={classes.sidebarIcon}>
              <DescriptionIcon color={type === "atribuir-funcao" ? 'primary' : 'inherit'}/>
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="subtitle2" color={type === "atribuir-funcao" ? 'primary' : 'inherit'} 
              className={classes.sidebarText}
            >
              {t('atribuir_funcao_ao_usuario')}
            </Typography>} />
          </ListItem>
      
          <ListItem 
            button 
            onClick={() => setType('editar-dados')}
            className={type === "editar-dados" ? classes.backgroundActived : ''}
          >
            <ListItemIcon className={classes.sidebarIcon}>
              <EditIcon color={type === "editar-dados" ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="subtitle2" color={type === "editar-dados" ? 'primary' : 'inherit'} 
              className={classes.sidebarText}
            >
              {t('editar_dados_do_usuario')}
            </Typography>} />
          </ListItem>
          <ListItem 
            button 
            onClick={() => setType('gerenciar-pontos')}
            className={type === "gerenciar-pontos" ? classes.backgroundActived : ''}
          >
            <ListItemIcon className={classes.sidebarIcon}>
              <AccountBalanceWalletIcon color={type === "gerenciar-pontos" ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="subtitle2" color={type === "gerenciar-pontos" ? 'primary' : 'inherit'} 
              className={classes.sidebarText}
            >
              {t('gerenciar_pontos')}
            </Typography>} />
          </ListItem>
      </List>
      <Divider style={{ marginTop: 16 }}/>
      <Copyright />
    </>
  )
}
