import { ideaCreateConstans, ideaConstants } from '../constants';
import update from 'react-addons-update';
import fileSize from 'filesize';
import { uniqueId } from 'lodash';

const initialState = {
  name: '',
  description: '',
  problem: '',
  solution: '',
  estimatedExecutionCost: 'R$ 0,00',
  estimatedFinancialReturn: 'R$ 0,00',
  methodology: {},
  businessUnitLocal: [],
  selectedBusiness: [],
  challenge: {
    _id: uniqueId(),
    name: 'Ideia Aberta',
    notChallenge: true,
  },
  personalizedClassifications: [],
  images: [],
  video: {},
  files: [],
  links: [],
  canvas: [
    {
      key: 'Q1',
      answer: '',
    },
    {
      key: 'Q2',
      answer: '',
    },
    {
      key: 'Q3',
      answer: '',
    },
    {
      key: 'Q4',
      answer: '',
    },
    {
      key: 'Q5',
      answer: '',
    },
    {
      key: 'Q6',
      answer: '',
    },
    {
      key: 'Q7',
      answer: '',
    },
    {
      key: 'Q8',
      answer: '',
    },
    {
      key: 'Q9',
      answer: '',
    },
  ],
  canvasValueProposition: [
    {
      key: 'Q1',
      answer: '',
    },
    {
      key: 'Q2',
      answer: '',
    },
    {
      key: 'Q3',
      answer: '',
    },
    {
      key: 'Q4',
      answer: '',
    },
    {
      key: 'Q5',
      answer: '',
    },
    {
      key: 'Q6',
      answer: '',
    },
    {
      key: 'Q7',
      answer: '',
    },
    {
      key: 'Q8',
      answer: '',
    },
    {
      key: 'Q9',
      answer: '',
    },
  ],
  contributors: [],
  locals: [],
  ideaCreateChallenge: false,
  loading: false,
};

export function ideaCreate(state = initialState, action) {
  switch (action.type) {
    case ideaCreateConstans.IDEA_CREATE_SET_TITLE_DESCRIPTION:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }

    case ideaCreateConstans.SET_METHODOLOGY:
      const methodology = action.payload.methodologies.find(
        (methodology) => methodology.code === action.payload.code
      );

      return update(state, {
        methodology: { $set: methodology },
      });

    case ideaCreateConstans.SET_BUSINESS_UNIT_LOCAL:
      return update(state, {
        businessUnitLocal: { $push: [action.payload.businessUnitLocals] },
      });

    case ideaCreateConstans.REMOVE_BUSINESS_UNIT_LOCAL:
      const newState = state.businessUnitLocal.filter(
        (BUL) => BUL._id !== action.payload.businessUnitLocals._id
      );

      return update(state, {
        businessUnitLocal: { $set: newState },
      });

    case ideaCreateConstans.SET_CHALLENGE:
      return update(state, {
        challenge: { $set: action.payload.challenge },
      });

    case ideaCreateConstans.SET_CLASSIFICATION:
      return update(state, {
        personalizedClassifications: { $push: [action.payload.personalizedClassifications] },
      });
    case ideaCreateConstans.DROP_CLASSIFICATION:
      const personalizedClaissificationFilter = state.personalizedClassifications.filter((item) => item._id !== action.payload._id)
      return update(state, {
        personalizedClassifications: { $set: personalizedClaissificationFilter },
      });
    case ideaCreateConstans.CLEAR_CLASSIFICATION:
      return update(state, {
        personalizedClassifications: { $set: initialState.personalizedClassifications },
      });
    case ideaCreateConstans.SET_IMAGES:
      return update(state, {
        images: { $push: action.payload.images },
      });

    case ideaCreateConstans.REMOVE_IMAGE:
      const images = state.images.filter(
        (image) => image.id !== action.payload.id
      );

      return update(state, {
        images: { $set: images },
      });

    case ideaCreateConstans.SET_VIDEO:
      return update(state, {
        video: { $set: action.payload.video },
      });

    case ideaCreateConstans.REMOVE_VIDEO:
      return update(state, {
        video: { $set: {} },
      });

    case ideaCreateConstans.SET_FILES:
      return update(state, {
        files: { $push: action.payload.files },
      });

    case ideaCreateConstans.REMOVE_FILE:
      const files = state.files.filter((file) => file.id !== action.payload.id);

      return update(state, {
        files: { $set: files },
      });

    case ideaCreateConstans.SET_LINKS:
      return update(state, {
        links: { $push: [action.payload.link] },
      });

    case ideaCreateConstans.REMOVE_LINK:
      const links = state.links.filter((link) => link !== action.payload.link);

      return update(state, {
        links: { $set: links },
      });

    case ideaCreateConstans.SET_CONTRIBUTORS:
      return update(state, {
        contributors: { $set: action.payload.contributorsIds },
      });

    case ideaCreateConstans.SET_IDEA_LOCALS:
      return {
        ...state,
        locals: action.payload
      }

    case ideaCreateConstans.SET_LOCALS_CHECKED:
      const newLocals = state.locals.map((local) => {
        if (local._id === action.payload._id) {
          local.checked = true;
          return local;
        }

        return local;
      });
      return update(state, {
        locals: { $set: newLocals },
      });

    case ideaCreateConstans.SET_LOCALS_UNCHECKED: {
      const newLocals = state.locals.map((local) => {
        if (local._id === action.payload._id) {
          delete local.checked;
          return local;
        }

        return local;
      });

      return update(state, {
        locals: { $set: newLocals },
      });
    }

    case ideaCreateConstans.LOAD_IDEA_DETAILS_DRAFT:
      return {
        ...state,
        _id: action.payload._id,
        name: action.payload.name,
        links: action.payload.links,
        canvas: action.payload.canvas,
        problem: action.payload.problem,
        solution: action.payload.solution,
        challenge: action.payload.challenge || {},
        description: action.payload.description,
        contributors: action.payload.contributors,
        businessUnitLocal: action?.payload?.businessUnitLocal,
        canvasValueProposition: action.payload.canvasValueProposition,
        video: action?.payload?.attachments.filter(item => item.mimeType.container === 'videos'),
        images: action?.payload?.attachments.filter(item => item.mimeType.container === 'images'),
        files: action?.payload?.attachments.filter(item => item.mimeType.container === 'dynamic-files'),
        locals: action.payload.businessUnitLocal.map(item => item)[0] || [],
        loading: false,
      }

    case ideaCreateConstans.GET_IDEA_USER_DRAFT_SUCCESS: {
      let images = [];
      let videos = [];
      let files = [];

      const locals = state.locals.map((local) => {
        action.payload.idea.businessUnitLocal.forEach((BUL) => {
          if (BUL._id === local._id) local.checked = true;
        });
        return local;
      });

      if (action.payload.idea?.attachments?.length > 0) {
        images = action.payload.idea.attachments.filter(
          (attachment) => attachment.mimeType.container === 'images'
        );

        images = images.map((image) => ({
          type: image.mimeType.mimeType,
          name: image.fileName,
          readableSize: fileSize(image.size),
          id: image._id,
          url: image.url,
        }));

        videos = action.payload.idea.attachments.filter(
          (attachment) => attachment.mimeType.container === 'videos'
        );

        videos = videos.map((video) => ({
          type: video.mimeType.mimeType,
          name: video.fileName,
          readableSize: fileSize(video.size),
          id: video._id,
          url: video.url,
        }));

        files = action.payload.idea.attachments.filter(
          (attachment) => attachment.mimeType.container === 'dynamic-files'
        );

        files = files.map((file) => ({
          type: file.mimeType.mimeType,
          name: file.fileName,
          readableSize: fileSize(file.size),
          id: file._id,
          url: file.url,
        }));
      }

      return update(state, {
        files: { $set: files },
        locals: { $set: locals },
        images: { $set: images },
        video: { $set: videos[0] },
        _id: { $set: action.payload.idea._id },
        name: { $set: action.payload.idea.name },
        links: { $set: action.payload.idea.links },
        canvas: { $set: action.payload.idea.canvas },
        problem: { $set: action.payload.idea.problem },
        solution: { $set: action.payload.idea.solution },
        description: { $set: action.payload.idea.description },
        challenge: { $set: action.payload.idea.challenge || {} },
        contributors: { $set: action.payload.idea.contributors ?? [] },
        businessUnitLocal: { $set: action.payload.idea.businessUnitLocal },
        canvasValueProposition: { $set: action.payload.idea.canvasValueProposition },
        personalizedClassifications: { $set: action.payload.personalizedClassifications ?? [] }
      });
    }

    case ideaConstants.GET_IDEA_USER_DETAILS_FAILURE:
      return state;

    case ideaCreateConstans.SET_CANVAS: {
      const canvas = state.canvas.map((c) => {
        return {
          key: c.key,
          answer: action.payload.canvas[c.key],
        };
      });

      return update(state, {
        canvas: { $set: canvas },
      });
    }
    case ideaCreateConstans.SET_VALUE_CANVAS: {
      const canvasValueProposition = state.canvasValueProposition.map((c) => {
        return {
          key: c.key,
          answer: action.payload.canvasValueProposition[c.key],
        };
      });

      return update(state, {
        canvasValueProposition: { $set: canvasValueProposition },
      });
    }

    case ideaCreateConstans.CLEAR_CREATE_IDEA: {
      return initialState;
    }

    case ideaCreateConstans.CLEAR: {
      const newLocals = state.locals.map((local) => {
        delete local.checked;
        return local;
      });
      const canvas = state.canvas.map((c) => ({ key: c.key, answer: '' }));
      const canvasValueProposition = state.canvasValueProposition.map((c) => ({ key: c.key, answer: '' }));

      if (state._id) {
        delete state._id;
      }

      return update(state, {
        name: { $set: '' },
        description: { $set: '' },
        businessUnitLocal: { $set: [] },
        challenge: { $set: {
          _id: uniqueId(),
          name: 'Ideia Aberta',
          notChallenge: true,
        } },
        images: { $set: [] },
        video: { $set: {} },
        files: { $set: [] },
        links: { $set: [] },
        contributors: { $set: [] },
        locals: { $set: newLocals },
        canvas: { $set: canvas },
        canvasValueProposition: { $set: canvasValueProposition },
        ideaCreateChallenge: { $set: false },
      });
    }

    case ideaCreateConstans.SET_IDEA_CREATE_CHALLENGE:
      return update(state, {
        ideaCreateChallenge: { $set: true },
      });

    case ideaCreateConstans.SET_SELECTED_BUSINESS: {
      return {
        ...state,
        selectedBusiness: action.payload
      }
    }

    case ideaCreateConstans.CLEAR_SELECTED_BUSIINESS: {
      return {
        ...state,
        selectedBusiness: []
      }
    }

    case ideaCreateConstans.POST_IDEA_IMAGINE_REQUEST:
      return update(state, {
        loading: { $set: true }
      });

    case ideaCreateConstans.POST_IDEA_IMAGINE_SUCCESS: 
      return update(state, {
        loading: { $set: false }
      });

    case ideaCreateConstans.POST_IDEA_IMAGINE_FAILURE:
      return update(state, {
        loading: { $set: false }
      });

    case ideaConstants.POST_IDEA_DRAFT_IMAGINE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ideaConstants.POST_IDEA_DRAFT_IMAGINE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ideaConstants.POST_IDEA_DRAFT_IMAGINE_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}
