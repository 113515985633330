import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { portfolioDialogTaskActions } from "./../../actions/portfolio/portfolio-dialog-task.actions"
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 4,
    padding: "4px 8px",
    boxShadow: "none",
  },
  menuItem: {
    fontSize: 12,
    display: 'flex',
    alignItems: "center"
  },
  status: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginRight: 8,
  }
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export function StatusMenu({ selectedStatus, allStatus, type, id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const { id: projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tasks, taskTabChosed } = useSelector(state => state.portfolioDetails)
  const { loading } = useSelector(state => state.portfolioDialogTask)
  const { projectTasksStatus } = useSelector(state => state.domains);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChangeStatus(data, id) {
    return () => {
      const taskPayload = {
        page: tasks.items?.paging?.page,
        limit: tasks.items?.paging?.limit,
        taskStatusCode: taskTabChosed,
        projectId
      }

      dispatch(portfolioDialogTaskActions.updateTask(id, { taskStatus: { ...data } }, t('status_atualizado'), taskPayload, projectTasksStatus));
      handleClose();
    }
  }

  return (
    <div>
      <Button
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        style={{ backgroundColor: !loading && `${selectedStatus?.color}` }}
        disabled={loading}
        className={classes.button}
      >
        <Box display="flex" alignItems="center">
          { selectedStatus?.name } &nbsp;&nbsp;|&nbsp;&nbsp;<ExpandMoreIcon fontSize='inherit' />
        </Box>
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { allStatus.filter(item => item.code !== selectedStatus?.code).map(item => (
          <MenuItem className={classes.menuItem} key={item._id} onClick={handleChangeStatus(item, id)}>
            <Box className={classes.status} style={{ backgroundColor: item.color}} />
            <Typography variant="body2">{ item.name }</Typography>
          </MenuItem>
        ))} 
      </StyledMenu>
    </div>
  );
}