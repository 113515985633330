import { feedbackConstants } from '../constants';

const initialState = {
  feedbackText: '',
  loading: false,
  openFeedback: false,
  discartFeedback: false,
  feedbacks: [],
  paging: {},
};

export function feedback(state = initialState, action) {
  switch (action.type) {

    case feedbackConstants.SET_FEEDBACK_TEXT: {
      return {
        ...state,
        feedbackText: action.payload
      }
    }

    case feedbackConstants.CLEAR_FEEDBACK_TEXT: {
      return initialState;
    }

    case feedbackConstants.OPEN_FEEDBACK: {
      return {
        ...state,
        openFeedback: action.payload
      }
    }

    case feedbackConstants.DISCART_FEEDBACK: {
      return {
        ...state,
        discartFeedback: action.payload
      }
    }

    case feedbackConstants.POST_USER_FEEDBACK_REQUEST:
      return {
        loading: true
      }

    case feedbackConstants.POST_USER_FEEDBACK_SUCCESS:
      return {
        loading: false
      }

    case feedbackConstants.POST_USER_FEEDBACK_FAILURE:
      return {
        loading: false
      }
    case feedbackConstants.REQUEST_GET_FEEDBACKS:
      return {
        loading: true
      }

    case feedbackConstants.SUCCESS_GET_FEEDBACKS:
      return {
        loading: false,
        feedbacks: action.payload.data,
        paging: action.payload.paging
      }

    case feedbackConstants.FAILURE_GET_FEEDBACKS:
      return {
        loading: false
      }

    case feedbackConstants.SUCCESS_GET_FEEDBACKS_SCROLL:
      const newFeedbacks = action.payload.data.filter((newFeedback) => {
        return !state.feedbacks.some((existingFeedback) => existingFeedback._id === newFeedback._id);
      });
      return {
        loadingScroll: false,
        feedbacks: [...state.feedbacks, ...newFeedbacks],
        paging: action.payload.paging
      };

    default:
      return state;
  }
}
