import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 40,
    color: theme.palette.auxiliary.green.light,
  },
  pointsText: {
    color: theme.palette.neutrals.low.light,
  },
  pointsNumber: {
    color: theme.palette.neutrals.low.main,
  },
}));

function WalletPoints({ points }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.gridItem}>
        <AccountBalanceWalletIcon className={classes.icon} />
        <Box paddingLeft="3rem">
          <Typography variant="subtitle2" className={classes.pointsText}>
            {t('pontos').toLowerCase()}
          </Typography>
          <Typography variant="h1" className={classes.pointsNumber}>
            {points?.points?.amount?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export { WalletPoints };
