import { ideaConclusionService } from '../services';
import { ideaConclusionConstanst } from '../constants';
import { alertActions } from './alert.actions';
import { history } from '../helpers';

export const ideaConclusionActions = {
  setConclusionInput,
  clear,
  setCostsFields,
  setInputDeadline,
  sendConclusionIdea,
  cancelConclusionIdea,
};

function setConclusionInput(value, name) {
  return dispatch => {
    dispatch({

      type: ideaConclusionConstanst.SET_CONCLUSION_INPUT,

      payload: {
        value,
        name
  }
    })
  }
}

function setCostsFields(fields) {
  return dispatch => {
    dispatch({
      type: ideaConclusionConstanst.SET_CONCLUSION_COSTS,
      payload: fields,
    })

}

}

function clear() {

  return dispatch => { 
    dispatch({

      type: ideaConclusionConstanst.IDEA_CONCLUSION_CLEAR 

    });

}

}

function sendConclusionIdea(id, conclusion, successMessage) {
  return async (dispatch) => {
    dispatch({ type: ideaConclusionConstanst.PUT_CONCLUSION_IDEA_REQUEST });

    try {
      await ideaConclusionService.sendConclusionIdea(id, conclusion);
      dispatch({ type: ideaConclusionConstanst.PUT_CONCLUSION_IDEA_SUCCESS });
        dispatch(alertActions.success(successMessage));
    } catch (error) {
      dispatch({ type: ideaConclusionConstanst.PUT_CONCLUSION_IDEA_FAILURE });
    }
  }



}

function cancelConclusionIdea(id, cancel, successMessage) {
  return async (dispatch) => {
    dispatch({ type: ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_REQUEST });

    try {
      await ideaConclusionService.cancelConclusionIdea(id, cancel)
      dispatch({ type: ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_SUCCESS });
        dispatch(alertActions.success(successMessage));
    } catch (error) {
        dispatch({
        type: ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_FAILURE,
        error
        });
    }
  };

  }

function setInputDeadline(value, name) {
  return dispatch => {
    dispatch({
      type: ideaConclusionConstanst.CHANGE_DEADLINE_START,
      payload: {
        value,
        name
  }
    })
  }
}
