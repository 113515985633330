import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { Card, GridFlex, AvatarIcon, PositionOfEvaluators } from './styles';
import { ReactComponent as FeedbackIcon } from '../../../assets/imgs/idea-feedback-icon.svg';

export function IdeaAvaliatorsAndComments() {
  const { t } = useTranslation();
  const { followUp: { comments } } = useSelector(state => state.ideaGeneralEvaluation);

  return (
    <Card elevation={0}>
      <Grid container spacing={3}>
        <GridFlex item xs={12}>
          <AvatarIcon>
            <FeedbackIcon />
          </AvatarIcon>
          <Typography variant="h3">{t('avaliadores_e_comentarios')}</Typography>
        </GridFlex>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1">{t('total_de_avaliadores')}</Typography>
            <Typography variant="h3" color="primary">{comments.length}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {comments?.map(({ _id, user, comment }, index) => (
          <Grid item xs={12} key={_id}>
            <Box display="flex" marginBottom={index !==(comments.length - 1) && '16px'}>
              <Avatar src={user?.attachment?.url} alt={user.name} />
              <Box flex="1" display="flex" flexDirection="column" marginLeft="14px">
                <Typography variant="subtitle2">{user.name}</Typography>
                <PositionOfEvaluators variant="caption">{user.position}</PositionOfEvaluators>
                <Typography variant="body2">{comment}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}