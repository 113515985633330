import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { ChatBubble, ThumbUp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 40,
    marginRight: theme.spacing(3),
  },
  info: {
    color: theme.palette.neutrals.low.light,
  },
  points: {
    color: theme.palette.neutrals.low.main,
  },
}));

function RankingInfo({ points, placing, ideas, ups, comments }) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { paging, ranking, userRanking } = useSelector((state) => state.ranking);

  return (
    <Grid container spacing={4}>
      <Grid item sm={6} md={3} className={classes.gridItem}>
        <EmojiEventsIcon style={{ color: theme.palette.auxiliary.yellow.main }} className={classes.icon} />
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.info}>{t('colocacao')}</Typography>
            <Typography variant="h2" className={classes.points}>
              {parseInt(placing) || 0}º {t('lugar')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} md={3} className={classes.gridItem}>
        {paging.filter.column === 'points' && !!ranking.length && userRanking.typeOfRanking === "standard" && (
          <>
            <AccountBalanceWalletIcon color="secondary" className={classes.icon} />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.info}>{t('pontos').toLowerCase()}</Typography>
                <Typography variant="h2" className={classes.points}>
                  {parseInt(points) || 0}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {paging.filter.column === 'ideas' && !!ranking.length && userRanking.typeOfRanking === "standard" && (
          <>
            <EmojiObjectsIcon color="primary" className={classes.icon} />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.info}>{t('ideias').toLowerCase()}</Typography>
                <Typography variant="h2" className={classes.points}>
                  {ideas || 0}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {paging.filter.column === 'engagement' && !!ranking.length && userRanking.typeOfRanking === "engagement" &&(
          <>
            <ThumbUp color="primary" className={classes.icon}
              style={{ color: theme.palette.auxiliary.pink.main }}
            />
            <Grid container style={{ marginRight: 20 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.info}>{t('ups')}</Typography>
                <Typography variant="h2" className={classes.points}>
                  {ups || 0}
                </Typography>
              </Grid>
            </Grid>
            <ChatBubble color="primary" className={classes.icon}
              style={{ color: theme.palette.auxiliary.orange.main }}

            />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.info}>{t('comentarios').toLowerCase()}</Typography>
                <Typography variant="h2" className={classes.points}>
                  {comments || 0}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

    </Grid>
  );
}

export { RankingInfo };
