import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Box, Grid, Typography, IconButton, Divider, Collapse, useTheme } from '@material-ui/core';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventIcon from '@material-ui/icons/Event';

import { ChipEvaluation } from '../Qualification/ChipEvaluation';
import { BoxAttachment } from '../BoxAttachment';
import { AvatarWithoutPhoto } from '../../Shared';
import { Card, DateAndTimeContainer, LinearProgress, Indicators } from './styles';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage';

export function HistoryCard() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { ideaDetailsFlux } = useIdeaDetailsController();
  const [expandedItems, setExpandedItems] = useState(Array(ideaDetailsFlux?.evaluationStepsHistory.length).fill(false));
  
  function handleChangeCollapse(index) {
    setExpandedItems((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  }

  function roundValue(value) {
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  function calculateAverage(rate) {
    return rate.reduce((t, n) => n + t, 0)/rate.length;
  }

  return (
    <Grid container spacing={3}>
      {ideaDetailsFlux?.evaluationStepsHistory.filter((item, index) => index !== 0).map((item, index) => (
        <Grid item xs={12} key={item._id}>
          <Card elevation={0}>
            <Grid container spacing={3} style={{ padding: '32px 32px 20px' }}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <SubdirectoryArrowRightIcon color="primary" />
                  <Typography variant="subtitle1" style={{ margin: '0px 10px' }}>
                    {t('etapa')}:
                  </Typography>
                  <Typography variant="subtitle1">{item?.stepEvaluated?.name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" style={{ marginRight: 10 }}>
                    {t('pergunta_norteadora')}:
                  </Typography>
                  <Typography variant="subtitle2">{item?.stepEvaluated?.stepDescription}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">{t('avaliacao')}:</Typography>                
                    <ChipEvaluation label={item?.decision?.title} bg={theme.palette.primary.main} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('analises_e_comentarios').toLocaleUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap="1rem">
                  <IconButton onClick={() => handleChangeCollapse(index)}>
                    {expandedItems[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  <Typography variant="subtitle2">
                    {t('total_de_avaliadores')} 1
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={expandedItems[index]} timeout="auto">
                  <Grid container spacing={3}>
                    {item.personalizedEvaluationsResponse.length > 0 && (
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="1rem">
                          <Typography variant="body1">{t('nota_media')}:</Typography>
                          <Typography variant="subtitle2" color="primary">
                            {calculateAverage(item.personalizedEvaluationsResponse.map(({ rate }) => rate)).toFixed(2)}
                          </Typography>
                        </Box>
                        <Divider style={{ marginBottom: 16 }} />
                        <Typography variant="subtitle2">
                          {t('media_por_criterios')}
                        </Typography>
                        {item.personalizedEvaluationsResponse.map((evaluation) => (
                          <Indicators key={evaluation._id}>
                            <Typography variant="body2">{evaluation.personalizedEvaluation.name}</Typography>
                            <Box width="50%" display="flex" alignItems="center">
                              <LinearProgress variant="determinate" value={20 * evaluation.rate} />
                              <Typography variant="subtitle2" color="primary">
                                {roundValue(evaluation.rate)}
                              </Typography>
                            </Box>
                          </Indicators>
                        ))}
                        <Divider style={{ marginTop: 16 }} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box display="flex">
                        <AvatarWithoutPhoto userName={item?.evaluator?.name} src={item?.evaluator?.attachment?.url} />
                        <Box paddingLeft="1rem">
                          <Typography variant="subtitle2">{item?.evaluator?.name}</Typography>
                          <Typography variant="caption" style={{ color: theme.palette.neutrals.low.light }}>
                            {item?.evaluator?.position}
                          </Typography>
                          <Box display="flex" alignItems="center" padding="0.5rem 0rem">
                            <DateAndTimeContainer paddingRight="1rem">
                              <EventIcon fontSize="small" />
                              <Typography variant="caption">
                                {moment(item?.evaluatedAt).format("DD/MM/YYYY")}
                              </Typography>
                            </DateAndTimeContainer>
                            <DateAndTimeContainer>
                              <AccessTimeIcon fontSize="small" />
                              <Typography variant="caption">
                                {moment(item?.evaluatedAt).format('hh:mm')}hs
                              </Typography>
                            </DateAndTimeContainer>
                          </Box>
                          <Typography variant="body2">{item?.observation}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {item.evaluationAttachments.length > 0 && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">{t('anexos')}</Typography>
                        <Grid container spacing={4} style={{ marginTop: 5 }}>
                          {item.evaluationAttachments.map((attachment) => (
                            <Grid item xs={4} key={attachment._id}>
                              <BoxAttachment attachment={attachment} />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}