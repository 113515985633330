import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { BackNav, Contents } from '../../components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function ContentsPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg" className={classes.root}>
      <BackNav title={t('conteudos')} pathname="/" />
      <Contents />
    </Container>
  );
}

export { ContentsPage };
