import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';
import axios from 'axios';

export const notificationService = {
  loadNotifications,
  updateVisualized,
  markAllRead,
  updateRead,
};

function loadNotifications(page, sort) {
  const url = `${
    config.URL_BASE
  }/notification?page=${page}&sort=${JSON.stringify(sort)}`;

  return axios.get(url, requestHeaders()).then(handleResponse);
}

function updateVisualized() {
  const url = `${config.URL_BASE}/notification/mark-as-visualized`;

  return axios.put(url, {}, requestHeaders());
}

function markAllRead() {
  return axios.put(
    `${config.URL_BASE}/notification/mark-all-read`,
    {},
    requestHeaders()
  );
}

function updateRead(id) {
  return axios.put(
    `${config.URL_BASE}/notification/mark-as-read/${id}`,
    {},
    requestHeaders()
  );
}
