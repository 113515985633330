import { externalContentsConstants } from '../constants'
import update from 'react-addons-update';
import { imageUploaderConstants } from '../constants/imageUploader.constants';

const initialState = {
  contentFields: {
    title: '',
    link: '',
    description: '',
    status: true,
    highlight: false,
    fixed: false,
  },
  externalContents: [],
  externalContent: {},
  paging: {
    page: 1,
    limit: 10,
    skip: 0,
    sort: { created: -1 },
    total: 0,
    search: '',
    statusCode: 'active' 
  },
  loading: false
}

export const externalContent = (state = initialState, action) => {
  switch (action.type) {
    case externalContentsConstants.GET_EXTERNAL_CONTENT_LIST_REQUEST:
      return {
          ...state,
          loading: true
      }
    case externalContentsConstants.GET_EXTERNAL_CONTENT_LIST_SUCCESS:
      return update(state, {
        externalContents: { $set: action.payload.data },
        paging: {
          // TODO: Change total to paging data
          total: { $set: action.payload.data.length },
          page: { $set: parseInt(action.payload.paging.page) },
          search: { $set: action.payload.query },
          limit: { $set: action.payload.paging.limit },
        },
        loading: {
          $set: false,
        },
      });

    case externalContentsConstants.TOGGLE_FILTER:
      return update(state, {
        paging: {
          statusCode: { $set: action.payload },
        },
      });

    case externalContentsConstants.SET_EXTERNAL_CONTENT_LOADING:
      return update(state, {
        loading: {
          $set: action.payload
        }
      })

    case externalContentsConstants.SET_EXTERNAL_CONTENT_DATA:
      const { _id, title, link, description, status, highlight, fixed, createdAt } = action.payload;
      const fields =  { _id, title, link, description, status, highlight, fixed, createdAt };
      return update(state, {
        contentFields: { $set: action.payload },
      })

    case externalContentsConstants.SET_NEW_CONTENT:
      return update(state, {
        contentFields: {
          [action.payload.name]: { $set: action.payload.value }
        }
      });

    case externalContentsConstants.SET_NEW_CONTENT_SWITCH:
      return update(state, {
        contentFields: {
          [action.payload.name]: { $set: action.payload.checked }
        }
      });

    case imageUploaderConstants.IMAGEUPLOAD_REMOVE_IMAGE:
      return update(state, {
        contentFields: {
          attachment: { $set: {} }
        }
      });

    case externalContentsConstants.CLEAR_CONTENT_FIELDS:
      return update(state, { contentFields: { $set: initialState.contentFields } });

    default:
      return state;
  }
}