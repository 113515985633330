import React, { useState, useEffect } from 'react'
import { makeStyles, Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  tag: {
    border: "1px solid",
    borderRadius: "4px",
    fontWeight: 600,
    fontSize: 11,
    padding: "4px 8px",
    width: "fit-content",
  },
}))

export const TagPerfil = ({ perfil }) => {
  const [color, setColor] = useState('')
  const classes = useStyles();

  useEffect(() => {
    switch(perfil) {
      case "Administrador": 
        setColor('info.main')
        break;

      case "Colaborador":
        setColor('warning.main')
        break;

      case "Gestor":
        setColor('primary.main')
        break;

      default: 
        setColor('primary.main')
        break
    }
  }, [perfil])

  return (
    <Box
      color={color}
      className={classes.tag}
    >
      {perfil}
    </Box>
  )
}
