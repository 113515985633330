import { notificationConstants } from '../constants';
import { notificationService } from '../services';
import { history } from '../helpers';

export const notificationActions = {
  getNotification,
  markAllRead,
  loadMoreNotification,
  updateVisualized,
  checkAction,
};

function getNotification(
  page,
  sort = {
    createdAt: -1,
  }
) {
  return (dispatch) => {
    dispatch(request());
    notificationService.loadNotifications(page, sort).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: notificationConstants.GET_NOTIFICATION_REQUEST };
  }

  function success(response) {
    const payload = {
      notifications: response.data,
      paging: response.paging,
    };
    return { type: notificationConstants.GET_NOTIFICATION_SUCCESS, payload };
  }

  function failure(error) {
    return { type: notificationConstants.GET_NOTIFICATION_FAILURE, error };
  }
}

function loadMoreNotification(page, sort) {
  return (dispatch) => {
    dispatch(request());
    notificationService.loadNotifications(page, sort).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: notificationConstants.LOAD_MORE_NOTIFICATION_REQUEST };
  }

  function success(response) {
    const payload = {
      notifications: response.data,
      paging: response.paging,
    };
    return {
      type: notificationConstants.LOAD_MORE_NOTIFICATION_SUCCESS,
      payload,
    };
  }

  function failure(error) {
    return {
      type: notificationConstants.LOAD_MORE_NOTIFICATION_FAILURE,
      error,
    };
  }
}

function updateVisualized() {
  return (dispatch) => {
    notificationService
      .updateVisualized()
      .then(() => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success() {
    return {
      type: notificationConstants.UPDATE_VISUALIZED_NOTIFICATION_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: notificationConstants.UPDATE_VISUALIZED_NOTIFICATION_FAILURE,
      error,
    };
  }
}

function markAllRead() {
  return (dispatch) => {
    notificationService.markAllRead().then(
      () => {
        dispatch(success());
        dispatch(
          getNotification(1, {
            createdAt: -1,
          })
        );
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success() {
    return { type: notificationConstants.MARK_ALL_NOTIFICATION_READ_SUCCESS };
  }

  function failure(error) {
    return {
      type: notificationConstants.MARK_ALL_NOTIFICATION_READ_FAILURE,
      error,
    };
  }
}

function checkAction(notification, indexNotification) {
  return (dispatch) => {
    notificationService.updateRead(notification._id).then(
      () => {
        dispatch(success(notification, indexNotification));

        const ideaId = notification?.payload?.split('/')[1];
        if (notification.type === "challenge"){
          history.push(notification.payload)
        }
        else
        {
         history.push(`/idea/${ideaId}/general`);
        }
        
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(notification, indexNotification) {
    const payload = {
      notification,
      indexNotification,
    };

    return { type: notificationConstants.CHECK_ACTION_SUCCESS, payload };
  }

  function failure(error) {
    return { type: notificationConstants.CHECK_ACTION_FAILURE, error };
  }
}
