import { faqConstants } from '../constants';
import update from 'react-addons-update';
const questionInitalState = {
  _id: '',
  title: '',
  description: '',
  toCreate: true
};

const initalState = {
  questions: [questionInitalState],
  paging: {
    page: 1,
    sort: { createdAt: -1 },
    limit: 10,
    skip: 0,
    total: 0,
  },
  loading: false,
};

export function faq(state = initalState, action) {
  switch (action.type) {
    case faqConstants.GET_ALL_QUESTIONS_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case faqConstants.GET_ALL_QUESTIONS_SUCCESS:
      return update(state, {
        questions: { $set: action.payload.questions.data },
        paging: {
          page: { $set: action.payload.page + 1 },
        },
        loading: { $set: false },
      });

    case faqConstants.GET_ALL_QUESTIONS_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case faqConstants.GET_QUESTION_BY_ID_SUCCESS:
      return update(state, {
        questions: { $set: action.payload.questions._id },
      });

    case faqConstants.GET_QUESTION_BY_ID_FAILURE:
      return state;

    case faqConstants.SET_PAGE:
      return update(state, {
        paging: {
          page: { $set: action.payload.page },
        },
      });

    case faqConstants.SET_LIMIT:
      return update(state, {
        paging: {
          limit: { $set: action.payload.limit },
        },
      });

    case faqConstants.ADD_NEW_FIELD:
      return update(state, {
        questions: {
          $set: [...state.questions, { ...questionInitalState, _id: state.questions.length + 1 }]
        }
      });

    case faqConstants.SET_QUESTION:
      state.questions[action.payload.index].title = action.payload.value;
      return state;

    case faqConstants.SET_ANSWER:
      state.questions[action.payload.index].description = action.payload.value;
      return state;


    case faqConstants.CLEAR_FAQ_EDIT:
      return initalState
        ;
    default:
      return state;
  }
}
