import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonPieChart() {
  const classes = useStyles();

  return(
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'70%'} height={19} />
      </Grid>
      <Grid item xs={12} className={classes.flex}>
        <Skeleton variant="circle" width={250} height={250} />
      </Grid>
    </Grid>
  );
} 