import { styled } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiTypography from '@material-ui/core/Typography';
import MuiCard from '@material-ui/core/Card';
import MuiChip from '@material-ui/core/Chip';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiGrid from '@material-ui/core/Grid';

// Dialog Styles 👇
export const DialogTitle = styled(MuiDialogTitle)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const HeaderTitle = styled(MuiTypography)(() => ({
  fontSize: 20,
  fontWeight: 700
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 3, 2),
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

export const Card = styled(MuiCard)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  borderRadius: 8,
  padding: theme.spacing(4),
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  borderRadius: 4,
  fontsize: 12,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.neutrals.high.main
}));

export const UserPhoto = styled(MuiAvatar)(({ theme }) => ({
  background: theme.palette.primary.lightest,
  color: theme.palette.primary.main,
  width: 20,
  height: 20,
  marginRight: 8
}));

export const Gridflex = styled(MuiGrid)({
  display: 'flex',
  alignItems: 'center',
});

export const GridParticipants = styled(MuiGrid)({
  display: 'flex',
  alignItems: 'center'
});

