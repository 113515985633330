import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiCard from '@material-ui/core/Card';
import { styled, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { Divider } from '@material-ui/core';

const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonGeneralEvaluation() {
  const arrItems = Array.from(Array(5));

  return (
    <Card elevation={0}>
      <Box display="flex" alignItems="center" marginBottom="3rem">
        <Skeleton variant="circle" width={45} height={45} style={{ marginRight: 16 }} />
        <Skeleton variant="rect" width={250} height={45} />
      </Box>
      <Grid container spacing={3}>
        {arrItems.map((item, index) => (
          <Fragment key={index}>
            <Grid item>
              <Skeleton variant="circle" width={25} height={25} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height={25} />
            </Grid>
            <Grid item xs={7}>
              <Skeleton variant="rect" width="100%" height={25} />
            </Grid>
            {index !== (arrItems.length - 1) && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Card>
  );
}