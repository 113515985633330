import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Tab as MuiTab,
  Tabs,
  Typography,
  useTheme,
  withStyles
} from '@material-ui/core';

import {
  BackNavAdmin,
  DashboardIdeaTable,
  FiltersDashboard,
  IdeaForChallengeDashboard,
  InfoDashboard,
  InfoUniqueDashboard,
  MonthlyEvolutionDashboard,
  TopAreas,
  TopAreasGenerateIdeas,
  TopColaboradores,
  TopGestores
} from '../../components';
import {
  beforeMonth,
  formatDate,
  today,
  formatDateMonthYear,
  formatDateMouthsEquals
} from '../../utils/date';
import { dashboardService } from '../../services/dashboard.service';
import { NumberFormatCustom } from '../../helpers/number-format-custom';
import { useTranslation } from 'react-i18next';
import { dashboardActions } from '../../actions';
import config from '../../config';
import { TopLocals } from '../../components/TopLocals';
import { TopLocalsGenerateIdeas } from '../../components/TopLocalsGenerateIdea';
import { IdeaEvolutionBarChart } from '../../components/IdeaEvolutionBarChart';
import { IdeasByClassificationCard, FinancialReturnCard } from '../../components/Dashboard';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  media: {
    height: 246,
    backgroundColor: theme.palette.neutrals.low.light,
  },
  tabs: {
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

const Tab = withStyles((theme) => ({
  wrapper: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  }
}))(MuiTab);
const DashboardPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ideasStepsFlux } = useSelector(state => state.domains);

  const {
          csvBase64,
          topUsers,
          loaders: { loadingTopUsers },
    sortUsers,
    sortPeriod,
    periodType
        } = useSelector(state => state.dashboard);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    statusIdeaCode: [],
    statusChallengeCode: [],
    businessUnits: [],
    locals: [],
    occupationArea: [],
    endDate: formatDate(today),
    startDate: formatDate(beforeMonth),
    stepsIdeas: ideasStepsFlux?.map(({ _id }) => _id) || []
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  const [indicators, setIndicators] = useState([]);
  const [ideasStatus, setIdeasStatus] = useState([]);
  const [ideasMonth, setIdeasMonth] = useState([]);
  const [topAreas, setTopAreas] = useState([]);
  const [topLocals, setTopLocals] = useState([]);
  const [topManagers, setTopManagers] = useState([]);
  const [topAreasGenerateIdeas, setTopAreasGenerateIdeas] = useState([]);
  const [topLocalsGenerateIdeas, setTopLocalsGenerateIdeas] = useState([]);
  const [ideasList, setIdeasList] = useState([]);
  const [ideasByPeriod, setIdeasByPeriod] = useState([]);
  const [ideasTotalByClassification, setIdeasTotalByClassification] = useState([]);
  const [ideasFinancialReturnTotal, setIdeasFinancialReturnTotal] = useState([]);

  // loadings
  const [indicatorsLoading, setIndicatorsLoading] = useState(false);
  const [loadingIdeasStatus, setLoadingIdeasStatus] = useState(false);
  const [loadingIdeasMonth, setLoadingIdeasMonth] = useState(false);
  const [loadingTopAreas, setLoadingTopAreas] = useState(false);
  const [loadingTopLocals, setLoadingTopLocals] = useState(false);
  const [loadingTopManagers, setLoadingTopManagers] = useState(false);
  const [loadingTopAreasGenerateIdeas, setLoadingTopAreasGenerateIdeas] = useState(false);
  const [loadingTopLocalsGenerateIdeas, setLoadingTopLocalsGenerateIdeas] = useState(false);
  const [loadingIdeasByPeriod, setLoadingIdeaByPeriod] = useState(false);
  const [loadingIdeasTotalByClassification, setLoadingIdeasTotalByClassification] = useState(false);
  const [loadingFinancialReturnTotal, setLoadingFinancialReturnTotal] = useState(false);
  const [tabValue, setTabValue] = useState('indicadores_das_ideias');


  const getServices = (filter) => {
    setIndicatorsLoading(true);
    setLoadingIdeasStatus(true);
    setLoadingIdeasMonth(true);
    setLoadingTopAreas(true);
    setLoadingTopLocals(true);
    setLoadingTopManagers(true);
    setLoadingTopAreasGenerateIdeas(true);
    setLoadingTopLocalsGenerateIdeas(true);
    setLoadingIdeaByPeriod(true)
    setLoadingIdeasTotalByClassification(true);
    setLoadingFinancialReturnTotal(true);
    dashboardService.setFilters(filter);

if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      dashboardService
        .getIdeasTotalByClassification(filter)
        .then((response) => {
          setIdeasTotalByClassification(response.data);
          setLoadingIdeasTotalByClassification(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingIdeasTotalByClassification(false);
        });
      dashboardService
        .getIdeasFinancialReturnTotal(filter)
        .then((response) => {
          setIdeasFinancialReturnTotal(response.data);
          setLoadingIdeasFinancialReturnTotal(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingIdeasFinancialReturnTotal(false);
        });
    dashboardService
      .getIndicatorsbySteps(filter)
      .then((response) => {
        setIndicators(response.data);
        setIndicatorsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIndicatorsLoading(false);
      });

  dashboardService
      .getIdeasbySteps(filter)
      .then((response) => {
        setIdeasStatus(response.data.status);
        setLoadingIdeasStatus(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingIdeasStatus(false);
      });

  dashboardService
      .getIdeasByPeriod(filter)
      .then((response) => {
        setIdeasByPeriod(response.data);
        setLoadingIdeaByPeriod(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingIdeaByPeriod(false);
      });

  } else {
    dashboardService
      .getInicators(filter)
      .then((response) => {
        setIndicators(response.data);
        setIndicatorsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIndicatorsLoading(false);
      });

    dashboardService
      .getIdeasStatus(filter)
      .then((response) => {
        setIdeasStatus(response.data.status);
        setLoadingIdeasStatus(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingIdeasStatus(false);
      });
}

    dashboardService
      .getTopAreas(filter)
      .then((response) => {
        setTopAreas(response.data.areas);
        setLoadingTopAreas(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopAreas(false);
      });

    dashboardService
      .getTopLocals(filter)
      .then((response) => {
        setTopLocals(response.data.locals.map((item, index) => ({...item, index: index + 1})));
        setLoadingTopLocals(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopLocals(false);
      });
    dashboardService
      .getTopLocalsGenerateIdeas(filter)
      .then((response) => {
        setTopLocalsGenerateIdeas(response.data.locals);
        setLoadingTopLocalsGenerateIdeas(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopLocalsGenerateIdeas(false);
      });
    dashboardService
      .getTopAreasGenerateIdeas(filter)
      .then((response) => {
        setTopAreasGenerateIdeas(response.data.areas);
        setLoadingTopAreasGenerateIdeas(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopAreasGenerateIdeas(false);
      });

    dashboardService
      .getTopManagers(filter)
      .then((response) => {
        setTopManagers(response.data.managers);
        setLoadingTopManagers(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTopManagers(false);
      });

    dashboardService
      .getIdeasMonth({
        ...filter,
        endDate: formatDateMonthYear(filter.endDate),
        startDate: formatDateMouthsEquals(filter.startDate, filter.endDate)
      })
      .then((response) => {
        setIdeasMonth(response);
        setLoadingIdeasMonth(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingIdeasMonth(false);
      });
  };

  const getIdeasList = (filter) => {
    setLoading(true)
    filter.sort ='{ "createdAt": -1 }';
    dashboardService
      .getIdeasList({ ...filter, page, limit })
      .then((response) => {
        setLoading(false)
        setIdeasList(response);
      })
      .catch((error) => {
        setLoading(true)
        console.log(error)
      });
  };

  useEffect(() => {
    getServices(filters);
    dispatch(dashboardActions.getUsers(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
      dispatch(dashboardActions.getUsers({ ...filters, sort: sortUsers}));
  }, [sortUsers]);
 useEffect(() => {
    dashboardService
      .getIdeasByPeriod(periodType,{...filters, period: sortPeriod + 1 })
      .then((response) => {
        setIdeasByPeriod(response.data);
        setLoadingIdeaByPeriod(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingIdeaByPeriod(false);
      });
  }, [sortPeriod, periodType]);

  useEffect(() => {
    getIdeasList({ ...filters, page, limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filters]);

  useEffect(() => {
    if (csvBase64) {
      const linkSource = `data:text/csv;base64,${csvBase64.substring(csvBase64.indexOf(";") + 1)}`;
      const downloadLink = document.createElement("a");
      const fileName = "Reports.xlsx";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [csvBase64]);
  const theme = useTheme();

  const handleChangePage = (value) => {
    setPage(value);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
  };

  const handleFilterChange = (filter) => {
    setFilters({ ...filter });
    setPage(1);
    setLimit(10);
    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
        dashboardService.getIndicatorsbySteps({ ...filter });
    } else {
        dashboardService.setFilters({ ...filter });
    }
  };

  return (
    <>
      <BackNavAdmin title="dashboard" />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FiltersDashboard onFilterChange={handleFilterChange} />
          </Grid>
          <Grid item xs={12}>
              <Tabs
      variant="fullWidth"
      textColor="primary"
      indicatorColor="primary"
      className={classes.tabs}
        value={tabValue}
      onChange={(_e, value) => setTabValue(value)}
    >
      <Tab
                value={'indicadores_das_ideias'}
                label={t('indicadores_das_ideias')}
      />
      <Tab
        value={'ranking_de_interacoes'}
                label={t('ranking_de_interacoes')}
      />
      <Tab
        value={'lista_de_ideias'}
                label={t('lista_de_ideias')}
      />
    </Tabs>
          </Grid>
          {tabValue === 'indicadores_das_ideias' && <>
            <Grid item xs={12} sm={8}>
            <InfoDashboard
              {...indicators}
              indicatorsLoading={indicatorsLoading}
            />
          </Grid>
            <Grid item xs={12} sm={4}>
              <FinancialReturnCard loading={loadingFinancialReturnTotal} financialReturnTotal={ideasFinancialReturnTotal} />
            </Grid>


            <Grid item xs={12}>
              <IdeasByClassificationCard loading={loadingIdeasTotalByClassification} ideasTotalByClassification={ideasTotalByClassification} />
            </Grid>

            <Grid item xs={12}>
              <IdeaEvolutionBarChart dataIdeaEvolutionBarChart={ideasByPeriod} loading={loadingIdeasByPeriod} />
            </Grid>

            <Grid item xs={12}>
            <IdeaForChallengeDashboard
              ideasStatus={ideasStatus}
              loadingIdeasStatus={loadingIdeasStatus}
            />
          </Grid>
            <Grid item xs={12}>
            <MonthlyEvolutionDashboard
              ideasMonth={ideasMonth}
              loadingIdeasMonth={loadingIdeasMonth}
            />
          </Grid>
        </>
        }

        {
        tabValue === 'ranking_de_interacoes'
        &&
        <>
          <Grid item sm={6} xs={12}>
            <TopColaboradores
              topUsers={topUsers}
              loadingTopUsers={loadingTopUsers}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TopGestores
              topManagers={topManagers}
              loadingTopManagers={loadingTopManagers}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TopLocals
              topLocals={topLocals}
              loadingTopLocals={loadingTopLocals}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TopAreas
              topAreas={topAreas}
              loadingTopAreas={loadingTopAreas}
            />
          </Grid>


              <Grid item sm={6} xs={12}>
                <TopAreasGenerateIdeas
                  topAreasGenerateIdeas={topAreasGenerateIdeas}
                  loadingTopAreasGenerateIdeas={loadingTopAreasGenerateIdeas}
                />
              </Grid>
    

          <Grid item sm={6} xs={12}>
            <TopLocalsGenerateIdeas
              topLocalsGenerateIdeas={topLocalsGenerateIdeas}
              loadingTopLocalsGenerateIdeas={loadingTopLocalsGenerateIdeas}
            />
              </Grid>

            
      </>
      }
    {
    tabValue === 'lista_de_ideias'
    &&
    <>
          <Grid item xs={12}>
            {ideasList && (
              <DashboardIdeaTable
                ideas={ideasList.data}
                paging={ideasList.paging}
                onChangePage={handleChangePage}
                onChangeLimit={handleChangeLimit}
                loading={loading}
              />
            )}
          </Grid>
      </>}
        </Grid>
      </Container>
    </>
  );
};

export { DashboardPage };
