import React from 'react';
import { Avatar, Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Beenhere } from "@material-ui/icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  item: {
    marginTop: 16
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  iconAuthor: {
    marginLeft: 8,
    fontSize: 15,
    color: theme.palette.auxiliary.yellow.main
  }
}));

export default function TeamMember({ member }) {
  const classes = useStyles();
  const { project: { author }, teams: { totalTasks} } = useSelector(state => state.portfolioDetails);
  const owner = member.user._id === author

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Box display="flex" alignItems="center">
          <Avatar
            alt={member.user.name}
            title={member.user.name}
            src={member.user.attachment?.url}
            className={classes.avatar}
          />
          <Typography variant="body2">{member.user.name}</Typography>
          {owner && <Beenhere className={classes.iconAuthor} />}
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2">{`${member.performedTasks}/${totalTasks} tarefas feitas`}</Typography>
      </Grid>
    </Grid>
  )
};