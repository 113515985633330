import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2, 4),
  },
  content: {
    padding: theme.spacing(3, 4, 4),
  },
  text: {
    color: theme.palette.neutrals.low.medium
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer', 
    fontWeight: 600,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    paddingLeft: 5,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
}));

export function DialogSensitiveData({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.title}>
        <Typography variant="h3" style={{ fontWeight: 700 }}>
          {t('o_que_sao_dados_pessoais_sensiveis')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.text}>
              "{t('os_dados_sensiveis_sao_os_que_revelam_origem_racial_ou_etnica')}
               {t('conviccoes_religiosas_ou_filosoficas_opinioes_politicas_filiacao_sindical')}
               {t('questoes_geneticas_biometricas_e_sobre_a_saude_ou_a_vida_sexual_de_uma_pessoa')}"
               {' '}
               ({t('lei')} n.º 13.709/2018)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.text} style={{ display: 'flex' }}>
              {t('para_mais_informacoes_acesse')}
              {' '}
              <a className={classes.link} href="https://www.gov.br/cidadania/pt-br/acesso-a-informacao/lgpd/classificacao-dos-dados" target="_blank">
                {t('classificacao_dos_dados')}
                <OpenInNewIcon color="primary" fontSize="small" style={{ marginLeft: 6 }} />
              </a>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
