export const ideaConclusionConstanst = {

  GET_IDEA_CONCLUSION_REQUEST: 'GET_IDEA_CONCLUSION_REQUEST',
  GET_IDEA_CONCLUSION_SUCCESS: 'GET_IDEA_CONCLUSION_SUCCESS',
  GET_IDEA_CONCLUSION_FAILURE: 'GET_IDEA_CONCLUSION_FAILURE',

  SET_CONCLUSION_INPUT: 'SET_CONCLUSION_INPUT',
  SET_CONCLUSION_COSTS: 'SET_CONCLUSION_COSTS',
  IDEA_CONCLUSION_CLEAR: 'IDEA_CONCLUSION_CLEAR',

  PUT_CONCLUSION_IDEA_REQUEST: 'PUT_CONCLUSION_IDEA_REQUEST',
  PUT_CONCLUSION_IDEA_SUCCESS: 'PUT_CONCLUSION_IDEA_SUCCESS',
  PUT_CONCLUSION_IDEA_FAILURE: 'PUT_CONCLUSION_IDEA_FAILURE',

  PUT_CANCEL_CONCLUSION_IDEA_REQUEST: 'PUT_CANCEL_CONCLUSION_IDEA_REQUEST',
  PUT_CANCEL_CONCLUSION_IDEA_SUCCESS: 'PUT_CANCEL_CONCLUSION_IDEA_SUCCESS',
  PUT_CANCEL_CONCLUSION_IDEA_FAILURE: 'PUT_CANCEL_CONCLUSION_IDEA_FAILURE',
};
