import { personalizedClassificationConstants } from '../constants';
import update from 'react-addons-update';

const INITIAL_STATE = {
  personalizedClassification: [
    {
      "name": '',
      "description": '',
      "order": 0,
    }
  ],
  loading: true,
  classificationsToDelete: []
};

export function personalizedClassification(state = INITIAL_STATE, action) {
  switch (action.type) {


    case personalizedClassificationConstants.SET_LOADING: {
      return update(state, {
        loading: { $set: action.payload }
      });
    }

    case personalizedClassificationConstants.GET_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: {
      return update(state, {
        loading: { $set: true }
      });
    }

    case personalizedClassificationConstants.GET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: {
      const { data } = action.payload.classificationPersonalized;
      return update(state, {
        personalizedClassification: { $set: !!data.length ? data.sort((a, b) => a.order - b.order) : INITIAL_STATE.personalizedClassification },
        loading: { $set: false },
      });
    }


    case personalizedClassificationConstants.GET_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: {
      return update(state, {
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.POST_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case personalizedClassificationConstants.POST_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: {
      return update(state, {

        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.POST_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case personalizedClassificationConstants.POST_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: {
      return update(state, {
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.SET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: {
      return update(state, {
        personalizedClassification: { $push: [action.payload] },
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.SET_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: {
      return update(state, {
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case personalizedClassificationConstants.DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: {
      const { id, index } = action.payload;
      const indexToDelete = index;
      const newStateClassification = state.personalizedClassification.filter((item) => item._id !== id);
      const classificationWithOutId = state.personalizedClassification.filter((item, index) => index !== indexToDelete);

      return update(state, {
        personalizedClassification: { $set: id !== null ? newStateClassification : classificationWithOutId },
        classificationsToDelete: { $push: [id] },
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: {
      return update(state, {
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: {
      return update(state, {
        loading: { $set: true },
      });
    }

    case personalizedClassificationConstants.DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: {
      return update(state, {
        personalizedClassification: { $set: INITIAL_STATE.personalizedClassification },
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: {
      return update(state, {
        loading: { $set: false },
      });
    }

    case personalizedClassificationConstants.SET_CLASSIFICATION_PERSONALIZED_CONTENT:
      const { personalizedClassification } = state;

      const penultimateOrder = Number(personalizedClassification[personalizedClassification.length - 2]?.order);

      const { index, field, value } = action.payload;
      if (personalizedClassification[index]._id) {
        personalizedClassification[index].isEdit = true;
      };

      personalizedClassification[index][field] = value;

      personalizedClassification[index].order = penultimateOrder + 1 || index;

      return state;

    case personalizedClassificationConstants.CLEAR:
      return update(state, { $set: INITIAL_STATE });

    default: {
      return state;
    }
  }
}
