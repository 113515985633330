import React, { useEffect } from 'react'
import { Box, Container, makeStyles, Grid, FormControl, Button, InputLabel, Select, MenuItem, Checkbox, Typography, TextField } from '@material-ui/core';
import { BackNavAdmin } from '../../components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ideaStepFluxActions, ideaStepFluxUpdateActions } from '../../actions';
import { Save } from '@material-ui/icons';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    labelRoot: {
        fontWeight: 600,
        lineHeight: 1,
    },
    input: {
        padding: '27px 12px 10px'
    },
    error: {
        color: theme.palette.auxiliary.red.main,
        marginLeft: 14,
        marginRight: 14,
        marginTop: 3,
        fontSize: '0.6428571428571428rem',
        fontWeight: 400,
        lineHeight: 1.66,
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -8,
        marginTop: 8,
    },
    checkboxText: {
        color: theme.palette.neutrals.low.dark,
        fontSize: 18,
        fontWeight: 600,
    },
    stateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: theme.spacing(5, 0, 2)
    },
    stateTitle: {
        color: theme.palette.neutrals.low.dark,
        fontSize: 18,
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginRight: 16,
    },
    state: {
        fontSize: 14.22,
        color: theme.palette.neutrals.low.main,
        marginLeft: 8,
    },
    content: {
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 4)
        }
    },
    marginTopHigh: {
        marginTop: 20
    }
}));

function IdeaStepFluxEditAssociationsPage() {
  const dispatch = useDispatch();
  const ideaStepFluxUpdate = useSelector((state) => state.ideaStepFluxUpdate);
  const { ideasStepsFlux } = useSelector((state) => state.domains);
  const ideaStepFlux = useSelector((state) => state.ideaStepFlux);
  const classes = useStyles();
  const { t } = useTranslation();
  const { id: _id } = useParams();

  const SelectProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  useEffect(() => {
    if (ideasStepsFlux) {
      dispatch(ideaStepFluxActions.loadStepDetails(ideasStepsFlux.filter(item => item._id === _id)[0]));
    }
  }, [ideasStepsFlux]);

  const stepGeneric = ideaStepFlux?.decisions?.filter(item => item.typeDecision === 'NextStepGeneric')[0];
  const disabledBtn = 
    ideaStepFluxUpdate?.loading || 
    (ideaStepFluxUpdate.isGeneric && !stepGeneric.nextStepWhenGeneric) || 
    (ideaStepFluxUpdate.isGeneric && !stepGeneric?.title);

  useEffect(() => {
    if (stepGeneric) {
      dispatch(ideaStepFluxUpdateActions.toggleGenericBtn({ name: 'nextStepGeneric', checked: true }));
      dispatch(ideaStepFluxUpdateActions.setNewContent({ name: 'nextStepGeneric', value: stepGeneric?.nextStepWhenGeneric }));
    }
  }, [dispatch, ideaStepFlux]);

  useEffect(() => {
    return () => {
      dispatch(ideaStepFluxActions.clearIdeaStepFluxData());
      dispatch(ideaStepFluxUpdateActions.clearAllEditIdeaStepFlux());
    }
  }, []);

  useEffect(() => {
    dispatch(ideaStepFluxUpdateActions.setNewContent({ name: '_id', value: _id }));
  }, [dispatch]);


  function handleSend() {
    let data = {
      _id: ideaStepFluxUpdate._id,
      nextStepWhenApproved: ideaStepFluxUpdate.nextStepWhenApproved,
      nextStepWhenRejected: ideaStepFluxUpdate.nextStepWhenRejected,
      nextStepWhenPendingReview: ideaStepFluxUpdate.nextStepWhenPendingReview,
      decisions: ideaStepFlux.decisions
    };

    if (!ideaStepFluxUpdate.nextStepWhenPendingReview) {
      delete data.nextStepWhenPendingReview;
    }

    if (!ideaStepFluxUpdate.nextStepGeneric) {
      delete data.nextStepGeneric;
      delete data.decisions;
    }

    dispatch(ideaStepFluxUpdateActions.editIdeaStepFlux(data));
  }

  function onChangeSelected(event, itemName) {
    

    if (itemName === 'nextStepGeneric') {
      dispatch(ideaStepFluxUpdateActions.setNewContent({ name: itemName, value: event.target.value }));
      dispatch(ideaStepFluxActions.updateGenericDecision(event.target.value));
    } else if (itemName === 'nextStepGenericTitle') {
      dispatch(ideaStepFluxActions.updateGenericTitle(event.target.value));
    } else {
      dispatch(ideaStepFluxUpdateActions.setNewContent({ name: itemName, value: event.target.value }));
    }
  }

  function toggleGenericBtn({ target: { checked, name } }) {
    dispatch(ideaStepFluxUpdateActions.toggleGenericBtn({ name, checked }));

    let newDecision = {
      title: stepGeneric?.title,
      typeDecision: "NextStepGeneric",
      _id: "6466343cb80ab5a4353c319d",
      nextStepWhenGeneric: stepGeneric?.nextStepWhenGeneric
    }

    dispatch(ideaStepFluxActions.pushNewDecision({ checked, newDecision }));
  }

  const CheckboxCustom = ({ name, checked, onChange, label }) => (
    <Box className={classes.checkbox}>
      <Checkbox
        name={name}
        onChange={onChange}
        checked={checked}
        color="primary"
      />
      <Typography className={classes.checkboxText}>{label}</Typography>
    </Box>
  );

  return (
    <>
      <BackNavAdmin title={t('editar_fluxo_associacao')} pathname="/preferences/flux-of-ideas" />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled">
              <InputLabel>Selecione a próxima etapa se aprovada</InputLabel>
              <Select
                MenuProps={SelectProps}
                defaultValue={ideaStepFluxUpdate?.nextStepWhenApproved}
                onChange={(e) => onChangeSelected(e, 'nextStepWhenApproved')}
              >
                {ideasStepsFlux.map((value, index) => (
                  <MenuItem value={value._id} key={index}>{value.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled">
              <InputLabel>Selecione a próxima etapa se reprovada</InputLabel>
              <Select
                MenuProps={SelectProps}
                defaultValue={ideaStepFluxUpdate?.nextStepWhenRejected}
                onChange={(e) => onChangeSelected(e, 'nextStepWhenRejected')}
              >
                {ideasStepsFlux.map((value, index) => (
                  <MenuItem value={value._id} key={index}>{value.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {ideaStepFlux?.decisions.filter(item => item.typeDecision === 'NextStepPendingReview')[0] && (
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel>Selecione a próxima etapa se tomada decisão de voltar</InputLabel>
                <Select
                  MenuProps={SelectProps}
                  defaultValue={ideaStepFluxUpdate?.nextStepWhenPendingReview}
                  onChange={(e) => onChangeSelected(e, 'nextStepWhenPendingReview')}
                >
                  {ideasStepsFlux.map((value, index) => (
                    <MenuItem value={value._id} key={index}>{value.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {ideaStepFluxUpdate.isGeneric && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>Selecione a próxima etapa para decisão genérica</InputLabel>
                  <Select
                    MenuProps={SelectProps}
                    defaultValue={stepGeneric?.nextStepWhenGeneric || ''}
                    onChange={(e) => onChangeSelected(e, 'nextStepGeneric')}
                  >
                    {ideasStepsFlux.map((value, index) => (
                      <MenuItem value={value._id} key={index}>{value.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t('digite_um_nome_para_o_botao_de_acao _generico')}
                  type="text"
                  fullWidth
                  variant="filled"
                  InputLabelProps={{ classes: { root: classes.labelRoot } }}
                  value={ideaStepFlux?.decisions.filter(item => item.typeDecision === 'NextStepGeneric')[0]?.title || ''}
                  onChange={(e) => onChangeSelected(e, 'nextStepGenericTitle')}
                />
              </Grid>
            </>
          )}
          
          <Grid item xs={12}>
            <CheckboxCustom
              name="isGeneric"
              checked={ideaStepFluxUpdate.isGeneric}
              onChange={toggleGenericBtn}
              label="Incluir botão de ação genérico?"
            />
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Save />}
              onClick={handleSend}
              disabled={disabledBtn}
            >
              {t('salvar_associacoes_fluxo')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { IdeaStepFluxEditAssociationsPage };