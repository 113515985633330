import * as randomcolor from 'randomcolor';

import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import React from 'react';
import { useTheme } from '@material-ui/core';

export function PieChartNumeric({ data, colors }) {
  const theme = useTheme();

 const calculatedHeight = data.length * 30 + 300;


    

  return (
    <ResponsiveContainer height={calculatedHeight}>
      <PieChart width="100%">
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0.8} />
            <stop offset="95%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Pie
          cx="50%"
          cy="50%"
          data={data}
          dataKey="count"
          legendType="circle"
          innerRadius={20}
          outerRadius={120}
          paddingAngle={3}
          cornerRadius={8}
          labelLine={false}
          label={({ count }) => count}
          fontWeight="600"
        >
          {data?.map((_entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index] || randomcolor()}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
