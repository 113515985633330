import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Card, makeStyles } from '@material-ui/core';
import { Search, UsersPointsTable, UserPointsFilterMenu, EmptyState } from '../../components';
import { SkeletonTable } from '../../components/Skeletons';
import { walletIcon } from '../../assets/imgs/emptystate';
import { walletActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
    borderRadius: 8,
  }
}));

export const UserPoints = ({ userId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    wallets,
    loading,
    paging: { quantityPerPage, filter, sort },
  } = useSelector((state) => state.wallet);
  

  const hasWallets = wallets?.length > 0;

  function searchWallet(q) {
    dispatch(walletActions.setQuery(q));
    dispatch(walletActions.getWallets(userId, 1, quantityPerPage, filter, sort, q));
  }

  return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
                  <UserPointsFilterMenu />
                  <Search
                    disabled={loading}
                    placeholder={t('pesquisar_por_ideia_ou_acao')}
                    query={searchWallet}
                  />
        </Box>
              </Grid>
            <Grid item xs={12}>
        {loading && <SkeletonTable cols={4} />}
        {(!loading && hasWallets) && <UsersPointsTable />}
        {(!loading && !hasWallets) && (
          <Card elevation={0} className={classes.card}>
            <EmptyState
              icon={walletIcon}
              description={t('usuario_nao_possui_pontos_para_gerenciar')}
            />
          </Card>
        )}
      </Grid>
    </Grid>
  );
}