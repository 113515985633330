import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from "@material-ui/core"
import { ideaEditStyles } from './IdeaEditStyles'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import { CanvasEdit } from '../EditIdeaDialogs';
import { useSelector } from 'react-redux';

export const IdeaEditGeneralBusinessCanvas = () => {
  const classes = ideaEditStyles();
  const { t } = useTranslation();
  const { ideaUpdate } = useSelector((state) => state);
  const [ isOpenCanvas, setIsOpenCanvas ] = useState(false)

  function handleOpenCanvas() {
    setIsOpenCanvas(!isOpenCanvas)
  }

  return (
    <>
      <CanvasEdit
        idea={ideaUpdate}
        isOpen={isOpenCanvas}
        onClose={handleOpenCanvas}
      />
      <Grid item xs={12}>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12} className={classes.header}>
            <Box display='flex' alignItems='center'>
              <Avatar className={classes.avatarIcon}>
                <ViewQuiltIcon color="primary" />
              </Avatar>
              <Typography variant="h3">business model canvas</Typography>
            </Box>
            <Button 
              variant="text"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={handleOpenCanvas}
            >
              {t('adicionar_canvas')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
