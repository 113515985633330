import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardProfile } from '../CardProfile/CardProfile';
import { helpCenterService } from '../../services/help-center.service'
import { Box } from '@material-ui/core';
import { SkeletonAdministrators } from '../Skeletons';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
})

function Administrators({ isEdit=false }) {
  const classes = useStyles();
  const [administrators, setAdministrators] = useState([])

  useEffect(() => {
    helpCenterService.getAdministrators()
      .then(response => {
        setAdministrators(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])


  return (
    <Box className={classes.container}>
      {!!administrators.length ? administrators.map((item, index) => (
        <CardProfile
          key={index}
          userId={item?._id}
          name={item?.userId?.name}
          email={item?.email}
          phone={item?.phone}
          isEdit={isEdit}
          pictureUrl={item?.userId?.attachment?.url}
        />
      )) : (
        <SkeletonAdministrators />
      )}
    </Box>
  );
}

export { Administrators };