import {
  styled,
  Box,
  Card as MuiCard,
  Typography
} from '@material-ui/core';

export const Card = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 8,
  border: `1px solid ${theme.palette.grey.main}`,
  padding: theme.spacing(3)
}));

export const MiniCardsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: 16,
  marginBottom: 16,

  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  }
}));

export const RowContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.low.light
}));

export const Value = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 28,
});