import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { BackNavAdmin, Search } from '../../components';
import { ExternalContentsTable } from '../../components/Tables';
import { ContentsFilterMenu } from '../../components/Shared/FilterMenu';
import { DialogDelete } from '../../components/Dialogs';
import { externalContentActions } from '../../actions';
import { SkeletonTable } from '../../components/Skeletons';
import { EmptyState } from '../../components/Shared';
import iconEmptyStateContent from '../../assets/imgs/emptystate/illustration_empty-state-contents.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0),
  },
  buttonBackNav: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

function ExternalContentsPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [externalContent, setExternalContent] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const {
    externalContents,
    loading,
    paging,
    paging: { statusCode },
  } = useSelector((state) => state.externalContent);

  const getList = () => {
    dispatch(
      externalContentActions.getExternalContentList(
        1,
        paging.search,
        paging.limit,
      )
    );
  }

  // Run list getter on component mount
  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const searchExternalContent = () => {}

  const deleteContent = () => {
    dispatch(externalContentActions.deleteContent(externalContent._id, t('o_conteudo_foi_excluido')));

    setExternalContent({});
    setShowDialog(false);
  }

  const closeDialog = () => {
    setExternalContent({});
    setShowDialog(false);
  }

  function handleAddNewContent() {
    history.push('/external-content/new');
  }

  function renderTable() {
    if (loading) {
      return <SkeletonTable cols={4} />
    } else if (externalContents.length > 0) {
      return (
        <ExternalContentsTable
          externalContent={externalContent}
          setExternalContent={setExternalContent}
          externalContents={externalContents}
          paging={paging}
          history={props.history}
          dialog={{ setOpenDialog: setShowDialog, openDialog: showDialog }}
        />
      )
    } else if (externalContents.length == 0) {
      return (
        <EmptyState
          icon={iconEmptyStateContent}
          description={t('ainda_nao_ha_conteudos_publicados')}
        />
      )
    }
  }
  return (
    <>
      <DialogDelete
        open={showDialog}
        onClose={closeDialog}
        onAccept={deleteContent}
      />
      <BackNavAdmin title={t('conteudos')} pathname="/">
        <Button
          className={classes.buttonBackNav}
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<AddCircleIcon />}
          onClick={handleAddNewContent}
        >
          {t('novo_conteudo')} 
        </Button>
      </BackNavAdmin>

      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.filter}>
            <ContentsFilterMenu paging={paging} statusCode={statusCode} />
            <Search
              placeholder={t('pesquise_pelo_nome_do_conteudo')}
              query={searchExternalContent}
            />
          </Grid>
          <Grid item xs={12}>
            {renderTable()}
            
          </Grid>
        </Grid>
      </Container>
    </>  
  );
}

export { ExternalContentsPage };
