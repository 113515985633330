import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const manageUsersService = {
  getUsers,
  getDomainProfiles,
  getBusinessUnit,
  getUserType,
  deleteUser
};

function getUsers(query, page, methodology, limit, statusCode, searchType = 'name') {
  return axios.get(`
    ${config.URL_BASE}/user/profile/?q=${query}&search=${searchType}&page=${page}&methodology=${JSON.stringify(methodology)}&quantityPerPage=${limit}&types=${JSON.stringify(statusCode)}
  `,requestHeaders())
    .then(handleResponse) 
}

function getDomainProfiles() {
  return axios.get(`${config.URL_BASE}/domains/type-profiles`, requestHeaders())
    .then(handleResponse)
  
}

function getBusinessUnit() {
  return axios.get(`${config.URL_BASE}/domains/business-unit-locals`, requestHeaders())
    .then(handleResponse)
}

function getUserType() {
  return axios.get(`${config.URL_BASE}/domains/type-profiles`, requestHeaders())
    .then(handleResponse)
}

function deleteUser(userId) {
  return axios.put(`${config.URL_BASE}/user/${userId}/change-status`,{ status: false } ,requestHeaders())
}