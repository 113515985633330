import React from 'react'
import { makeStyles } from "@material-ui/core"
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from "react-redux";
import { manageUsersActions } from "./../../actions"
import { UserCard } from "./UserCard"
import { Loading, ImagineGrid } from "./../../components"

const useStyles = makeStyles(theme => ({
  containerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const UserCards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { users, paging, loading } = useSelector(state => state.manageUsers);

  function loadMoreIdeas() {
    if (!loading) {
      var newPage = paging.page === 1 ? 2 : paging.page;
      dispatch(
        manageUsersActions.getFilteredUsersMobile(
          newPage,
          paging.sort,
          paging.search,
          paging.limit,
          paging.statusCode
        )
      );
    }
  }

  return (
    <InfiniteScroll
      dataLength={users.length}
      next={loadMoreIdeas}
      hasMore={true}
      scrollThreshold={'100%'}
      loader={
        loading && (
          <div className={classes.containerLoading}>
            <Loading />
          </div>
        )
      }
    >
      <ImagineGrid container spacing={4}>
        {users.map((user, index) => (
          <UserCard key={index} user={user} />
        ))}
      </ImagineGrid>
    
    </InfiniteScroll>
  )
}
