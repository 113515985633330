import { portfolioConstants } from "./../../constants/portfolio.constants"

const initialState = {
  loading: false,
  first_status_items: [],
  second_status_items: [],
  third_status_items: [],
}

export function portfolioHome(state = initialState, action) {
  switch(action.type) {
    case portfolioConstants.GET_ALL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true
      };
  
    case portfolioConstants.GET_ALL_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false
      };
  
    case portfolioConstants.GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    default: 
      return state;

  }
}