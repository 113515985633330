import {
  styled,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions
} from '@material-ui/core';

export const Modal = styled(MuiDialog)({
  '& .MuiDialog-paper': {
    overflowY: 'visible',
    minWidth: 600,
    borderRadius: 8
  },
  '& .MuiBackdrop-root': {
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(5px)',
  }
});

export const ModalTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 4)
}));

export const ModalContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 4, 2)
}));

export const ModalActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(3, 4)
}));