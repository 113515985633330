import update from "react-addons-update";
import { adminsConstants } from "../constants";

const initialState = {
  dialogAddContactOpen: false,
  openDialogConfirmation: false,
  admins: [],
  email: '',
  phone: '',
  adminId: '',
  adminName: '',
  isEditDialog: false,
  imageUrl: ''
};

export function adminsReducer(state = initialState, action) {
  switch (action.type) {

    case adminsConstants.HANDLE_DIALOG_ADMIN:
      return update(state, {
        dialogAddContactOpen: { $set: action.payload.open },
        isEditDialog: { $set: action.payload.isEdit }
      });

    case adminsConstants.HANDLE_DIALOG_CONFIRMATION:
      return update(state, {
        openDialogConfirmation: { $set: action.payload.open }
      });
    case adminsConstants.HANDLE_ADMIN_NAME:
      return update(state, {
        adminName: { $set: action.payload.name }
      });
    case adminsConstants.HANDLE_IMAGE_URL:
      return update(state, {
        imageUrl: { $set: action.payload.url }
      });

    case adminsConstants.SUCCESS_GET_ADMINS:
      const admins = action.payload.data.map(({ _id, name }) => ({ value: _id, label: name }))
      return update(state, {
        admins: { $set: admins }
      });

    case adminsConstants.HANDLE_EMAIL:
      return update(state, {
        email: { $set: action.payload.email }
      });

    case adminsConstants.HANDLE_PHONE:
      return update(state, {
        phone: { $set: action.payload.phone }
      });

    case adminsConstants.HANDLE_ADMINID:
      return update(state, {
        adminId: { $set: action.payload.adminId }
      });

    default:
      return initialState;
  }
}