import { portfolioConstants } from '../../constants';
import { portfolioService } from '../../services';
import { alertActions } from './../alert.actions';
import { portfolioDetailsActions } from "./../portfolio/portfolio-details.actions"

export const portfolioDialogTaskActions = {
  changeFieldTask,
  addSubtask,
  changeFieldSubtask,
  removeSubtask,
  clearDialogTask,
  postCreateTask,
  updateTask,
  updateSubTask,
  loadCreateTaskDialog,
  deleteTask,
  clearFlags
}


function changeFieldTask(name, value) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CHANGE_PORTFOLIO_DIALOG_TASK,
      payload: { name, value }
    })
  }
}

function changeFieldSubtask(name, value, index) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CHANGE_PORTFOLIO_DIALOG_SUBTASK,
      payload: { name, value, index }
    })
  }
}


function addSubtask() {
  return dispatch => {
    dispatch({
      type: portfolioConstants.ADD_PORTFOLIO_DIALOG_SUBTASK,
    })
  }
}

function removeSubtask(index) {
  return dispatch => {
    dispatch({
      type: portfolioConstants.REMOVE_PORTFOLIO_DIALOG_SUBTASK,
      payload: index
    })
  }
}

function clearDialogTask() {
  return dispatch => {
    dispatch({
      type: portfolioConstants.CLEAR_PORTFOLIO_DIALOG_TASK,
    })
  }
}

function postCreateTask(data, successMessage, taskPayload, projectTasksStatus) {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_POST_CREATE_TASK_REQUEST });

    portfolioService.postCreateTask(data)
      .then(({ data }) => {
        dispatch({ type: portfolioConstants.PORTFOLIO_POST_CREATE_TASK_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioDetailsActions.getAllTask(taskPayload, projectTasksStatus));  
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: portfolioConstants.PORTFOLIO_POST_CREATE_TASK_FAILURE, error });
    })}
};


function updateTask(id, data, successMessage, taskPayload, projectTasksStatus) {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_UPDATE_TASK_REQUEST });

    portfolioService.updateTask(id, data)
      .then(({ data }) => {
        dispatch({ type: portfolioConstants.PORTFOLIO_UPDATE_TASK_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioDetailsActions.getAllTask(taskPayload, projectTasksStatus));  
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: portfolioConstants.PORTFOLIO_UPDATE_TASK_FAILURE, error });
      })
  }
};

function updateSubTask(arrIds, data, taskPayload, projectTasksStatus, loadTasks=false) {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_UPDATE_SUBTASK_REQUEST });

    portfolioService.updateSubTask(arrIds, data)
      .then(({ data }) => {
        dispatch({ 
          type: portfolioConstants.PORTFOLIO_UPDATE_SUBTASK_SUCCESS,
          payload: data
        });

        if (loadTasks) {
          dispatch(portfolioDetailsActions.getAllTask(taskPayload, projectTasksStatus));  
        }
      })
    .catch(error => {
      console.log(error)
      dispatch({ type: portfolioConstants.PORTFOLIO_UPDATE_SUBTASK_FAILURE, error });
    })}
};

function loadCreateTaskDialog(newTask) {
  return (dispatch) => {
    dispatch({ 
      type: portfolioConstants.LOAD_CREATE_TASK_DIALOG, 
      payload: newTask 
    });
  }
}


function deleteTask(id, successMessage, taskPayload, projectTasksStatus) {
  return dispatch => {
    dispatch({ type: portfolioConstants.PORTFOLIO_DELETE_TASK_REQUEST });
    portfolioService.deleteTask(id)
      .then(() => {
        dispatch({ type: portfolioConstants.PORTFOLIO_DELETE_TASK_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(portfolioDetailsActions.getAllTask(taskPayload, projectTasksStatus));  
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: portfolioConstants.PORTFOLIO_DELETE_TASK_FAILURE, error });
      })
  }
}

function clearFlags() {
  return dispatch => {
    dispatch({ type: portfolioConstants.CLEAR_PORTFOLIO_DELETE_FLAGS})
  }
}