import config from '../config';
import axios from 'axios';
import { handleResponse, requestHeaders } from '../helpers';

export const domainsService = {
  getDomains,
};

function getDomains() {
  return axios
    .get(`${config.URL_BASE}/domains`, requestHeaders())
    .then(handleResponse);
}
