import React, { useState } from 'react';
import TimeAgo from 'react-timeago';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { formatterTimer } from '../../../helpers';
import { CustomTooltip } from '../CustomTooltip';
import { AvatarWithoutPhoto } from '../AvatarWithoutPhoto';
import {
  CustomAvatar, 
  FirstName,
  And,
  SecondName,
  Date, 
  Participants,
  ParticipantName,
  Modal,
} from './styles';

export function AvatarGroupIdea({ user, idea }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [viewParticipants, setViewParticipants] = useState(null);
  const language = useSelector((state) => state.language);

  function handleViewParticipants(e) {
    setViewParticipants(e.currentTarget);
  };

  function handleCloseMenu() {
    setViewParticipants(null);
  };

  function getFirstName(firstName) {
    const arrName = firstName.split(' ');
    if (arrName.length > 1) {
      return arrName[0];
    }
    return arrName;
  }

  function handleDirectToUserProfile(id) {
    history.push(`/profile/${id}`)
  }

  return (
    <>
      <Modal
        keepMounted
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={viewParticipants}
        open={Boolean(viewParticipants)}
        onClose={handleCloseMenu}
      >
        <Grid container spacing={2} style={{ width: 'auto', display: 'block' }}>
          {idea.contributors.filter((item, index) => index !== 0).map((item, index) => (
            <Grid item xs={12} key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <AvatarWithoutPhoto small userName={item.name} src={item.attachment?.url} alt={item.name} />
              <ParticipantName
                variant="subtitle2" 
                style={{ marginLeft: 8 }} 
                onClick={() => handleDirectToUserProfile(item._id)}
              >
                {item.name}
              </ParticipantName>
            </Grid>
          ))}
        </Grid>
      </Modal>

      <Box display="flex" alignItems="center" position="relative" margin="1rem">
        <AvatarWithoutPhoto userName={user.name} src={user.attachment?.url} alt={user.name} />
        <CustomAvatar>
          <AvatarWithoutPhoto 
            userName={idea.contributors[0].name} 
            src={idea.contributors[0].attachment?.url} 
            alt={idea.contributors[0].name} 
          />
        </CustomAvatar>
        <Box>
          <Box display="flex" alignItems="center">
            <FirstName variant="subtitle2" onClick={() => handleDirectToUserProfile(user._id)}>
              {getFirstName(user.name)}
            </FirstName>
            <And variant="subtitle2">{t('e')}</And>
            <SecondName variant="subtitle2" onClick={() => handleDirectToUserProfile(idea.contributors[0]._id)}>
              {getFirstName(idea.contributors[0].name)}
            </SecondName>
          </Box>
          <Date variant="caption">
            <TimeAgo date={idea.timelineDate} formatter={formatterTimer(language)} />
          </Date>
        </Box>
        {idea.contributors.length > 1 && (
          <CustomTooltip title={t('ver_mais')} aria-label={t('ver_mais')}>
            <Participants onClick={handleViewParticipants}>
              +{idea.contributors.filter((item, index) => index !== 0).length}
            </Participants>
          </CustomTooltip>
        )}
      </Box>
    </>
  );
}