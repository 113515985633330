import { makeStyles } from '@material-ui/core';

export const dialogCss = makeStyles((theme) => ({
  subtitle: {
    fontSize: 14,
    padding: theme.spacing(2, 0, 3, 0),
    lineHeight: 1.5,
  },
  containerAction: {
    padding: theme.spacing(3, 3, 3, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  label: {
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(1, 0),
  },
  button: {
    fontSize: 14,
    textTransform: 'lowercase',
  },
  dividerBottom: {
    borderBottom: 'none',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 600,
  },
  content: {
    padding: theme.spacing(2),
  },
  dividerBottom: {
    borderBottom: 'none',
  },
  authorHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  btnMargin: {
    marginRight: 6,
    marginBottom: theme.spacing(1),
  },
  mr: {
    marginRight: theme.spacing(2),
  }
}));
