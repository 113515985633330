import React from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';
import { BackNav } from '../../components';
import { SidebarIdeaPoint } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
}));

function IdeaPointPage() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <BackNav title="ponto eletrônico x folha de ponto" pathname="/" />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <SidebarIdeaPoint />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Container>
  );
}

export { IdeaPointPage };
