export const ideaGeneralEvaluationConstants = {
  GET_EVALUATIONS_BY_GROUP_REQUEST: 'GET_EVALUATIONS_BY_GROUP_REQUEST',
  GET_EVALUATIONS_BY_GROUP_SUCCESS: 'GET_EVALUATIONS_BY_GROUP_SUCCESS',
  GET_EVALUATIONS_BY_GROUP_FAILURE: 'GET_EVALUATIONS_BY_GROUP_FAILURE',

  GET_INDICATORS_BY_IDEA_REQUEST: 'GET_INDICATORS_BY_IDEA_REQUEST',
  GET_INDICATORS_BY_IDEA_SUCCESS: 'GET_INDICATORS_BY_IDEA_SUCCESS',
  GET_INDICATORS_BY_IDEA_FAILURE: 'GET_INDICATORS_BY_IDEA_FAILURE',

  GET_COMMENTS_BY_IDEA_REQUEST: 'GET_COMMENTS_BY_IDEA_REQUEST',
  GET_COMMENTS_BY_IDEA_SUCCESS: 'GET_COMMENTS_BY_IDEA_SUCCESS',
  GET_COMMENTS_BY_IDEA_FAILURE: 'GET_COMMENTS_BY_IDEA_FAILURE',

  GET_EVALUATION_BY_IDEAID_AND_USER_REQUEST: 'GET_EVALUATION_BY_IDEAID_AND_USER_REQUEST',
  GET_EVALUATION_BY_IDEAID_AND_USER_SUCCESS: 'GET_EVALUATION_BY_IDEAID_AND_USER_SUCCESS',
  GET_EVALUATION_BY_IDEAID_AND_USER_FAILURE: 'GET_EVALUATION_BY_IDEAID_AND_USER_FAILURE',

  CREATE_RATES_AND_COMMENT_IN_IDEA_REQUEST: 'CREATE_RATES_AND_COMMENT_IN_IDEA_REQUEST',
  CREATE_RATES_AND_COMMENT_IN_IDEA_SUCCESS: 'CREATE_RATES_AND_COMMENT_IN_IDEA_SUCCESS',
  CREATE_RATES_AND_COMMENT_IN_IDEA_FAILURE: 'CREATE_RATES_AND_COMMENT_IN_IDEA_FAILURE',

  SET_EVALUATION_COMMENT: 'SET_EVALUATION_COMMENT',
  SET_EVALUATION_RATES: 'SET_EVALUATION_RATES',
  CLEAR_GENERAL_EVALUATIONS_COMMENT_FORM: 'CLEAR_GENERAL_EVALUATIONS_COMMENT_FORM'
}