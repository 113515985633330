import { Container, Grid } from '@material-ui/core';
import { AccountBox, Assistant, Help, PlayArrow, PlayArrowRounded, PlayArrowSharp, PlayCircleFilled } from '@material-ui/icons';
import React from 'react';
import { useState } from 'react';
import { ImagineBackNav } from '../../components/ImagineTemplates/ImagineBackNav';
import { ImagineSwitchSidebar } from '../../components/ImagineTemplates/ImagineSwitchSidebar';
import { optionConstants } from '../../constants';
import { AdminsPage } from './AdminsPage';
import { FaqEditPage } from './FaqEditPage';
import { FirstAccessEditPage } from './FirstAccess';
import { TutorialsPage } from './TutorialsPage';



export function HelpCenterEdit() {
  const [typePage, setTypePage] = useState(optionConstants.PRIMEIROACESSO);

  const sideBarOptions = [
    {
      label: 'primeiro_acesso',
      path: optionConstants.PRIMEIROACESSO,
      icon: <PlayCircleFilled color={
        optionConstants.PRIMEIROACESSO === typePage ? 'primary' : 'inherit'
      } />
    },
    {
      label: 'tutoriais',
      path: optionConstants.TUTORIAIS,
      icon: <Assistant color={
        optionConstants.TUTORIAIS === typePage ? 'primary' : 'inherit'
      } />
    },
    {
      label: 'duvidas_frequentes',
      path: optionConstants.FAQ,
      icon: <Help color={
        optionConstants.FAQ === typePage ? 'primary' : 'inherit'
      } />
    },
    {
      label: 'administradores',
      path: optionConstants.ADMINISTRADORES,
      icon: <AccountBox color={
        optionConstants.ADMINISTRADORES === typePage ? 'primary' : 'inherit'
      } />
    }
  ];

  const RenderPage = () => {
    switch (typePage) {
      case optionConstants.PRIMEIROACESSO:
        return <FirstAccessEditPage />
      case optionConstants.TUTORIAIS:
        return <TutorialsPage />
      case optionConstants.FAQ:
        return <FaqEditPage />
      case optionConstants.ADMINISTRADORES:
        return <AdminsPage />
      default:
        return null;
    }
  };

  console.log(typePage)

  return (<>
    <ImagineBackNav title={'central_de_ajuda'} handleRedirectBackPage={() => { }} />
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <ImagineSwitchSidebar sideBarOptions={sideBarOptions} handleClick={setTypePage} type={typePage} />
        <RenderPage />
      </Grid>
    </Container>
  </>
  );
}
