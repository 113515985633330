import React, { useState } from 'react';
import {
  Tab,
  Box,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IdeaQualificationAdmin, IdeaDelegateAdmin } from '../Idea';
 
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(3)
  },
  tabs: {
    border: '1px solid',
    borderRadius: '8px 8px 0px 0px',
    borderColor: theme.palette.neutrals.high.medium
  },
  tab: {
    width: "50%",
    maxWidth: "unset",
    backgroundColor: theme.palette.neutrals.high.main,
  },
  itemTab: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase',
    marginLeft: theme.spacing(0.8)
  },
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.neutrals.high.medium
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 20,
    paddingBottom: theme.spacing(5)
  },
  qualificationIcon: {
    width: 22,
    marginRight: theme.spacing(2)
  },
}));

function IdeaQualificationTabs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const { imagineLocals } = useSelector((state) => state.domains);
  const { ideaDetails, approved, delegate } = useSelector((state) => state.idea);

  function handleChangeTab(tabIndex) {
    setTabIndex(tabIndex);
  }

  return (
    <>
      <Typography component="h1" className={classes.title}>
        {t('qualificacao')}
      </Typography>

      <Tabs
        value={tabIndex}
        className={classes.tabs}
        indicatorColor="primary"
        aria-label="Tabs"
        onChange={(e, value) => handleChangeTab(value)}
      >
        <Tab 
          className={classes.tab}
          aria-controls="qualification"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <ThumbsUpDownIcon color={tabIndex === 0 ? 'primary' : 'default'} />
              <Typography
                className={classes.itemTab}
                color={tabIndex === 0 ? 'primary' : 'default'}
              >
                {t('qualificar_idea')}
              </Typography>
            </Box>
          )}
        />
        <Tab 
          className={classes.tab}
          aria-controls="delegate"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <AssignmentTurnedInIcon color={tabIndex === 1 ? 'primary' : 'default'} />
              <Typography
                className={classes.itemTab}
                color={tabIndex === 1 ? 'primary' : 'default'}
              >
                {t('encaminhar_ideia')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      { tabIndex === 0 && (
        <>
          <IdeaQualificationAdmin id={ideaDetails._id} ideaApproved={approved} ideaDetails={ideaDetails} />
        </>
      )}

      { tabIndex === 1 && (
        <>           
          <IdeaDelegateAdmin id={ideaDetails._id} delegate={delegate} locals={imagineLocals} />
        </>
      )}
    </>
  );
}

export { IdeaQualificationTabs };
