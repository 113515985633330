import React from 'react'
import { Grid, Box, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    height: 32,
    width: 32,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.lightest,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    fontSize: 16,
    marginLeft: 16,
    fontWeight: 600,
  }
}))

export const DialogTitleWithIcon = ({ icon, text }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Box className={classes.iconWrapper}>
            { icon }
          </Box>
          <Typography className={classes.title}>{ text }</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
