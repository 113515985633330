import { Button, Dialog, DialogContent, Slider, makeStyles } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useState } from 'react';
import { DialogCustomTitle } from '../CreateIdeaDialogs';
import Cropper from 'react-easy-crop';

const useStyles = makeStyles((theme) => ({
    dialogTitle: { zIndex: 2, background: 'theme.palette.neutrals.high.main', borderBottom: `.3px solid ${theme.palette.neutrals.high.medium}`, marginBottom: '24px' },
    dialogContent: { display: 'flex', justifyContent: 'center', overflow: 'hidden' },
    cropperContainer: { position: 'relative', width:  393, height: 256 },
    slider: { zIndex: 2, background: 'theme.palette.neutrals.high.main', padding: '24px', overflow: 'hidden' }
}));

function ImageCrop({ src, dispatch, open, close, aspect, shape }) {
    const classes = useStyles();
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [area, setArea] = useState(null);

    function createNewImage() {
        const img = new Image;
        img.src = src;
        return img;
    };

    const image = createNewImage();

    function getCroppedImg() {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const pixelRatio = window.devicePixelRatio;
        canvas.width = area.width * pixelRatio;
        canvas.height = area.height * pixelRatio;
        ctx.imageSmoothingQuality = "high";
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.drawImage(
            image,
            area.x * scaleX,
            area.y * scaleY,
            area.width * scaleX,
            area.height * scaleY,
            0,
            0,
            area.width,
            area.height
        );
        canvas.toBlob(blob => dispatch(blob));
        close();
    }

    return (
        <Dialog open={open} style={{textAlign: 'center'}}>
            <DialogCustomTitle className={classes.dialogTitle} onClose={() => close()}>
                editar foto
            </DialogCustomTitle>
            <DialogContent className={classes.dialogContent}>
                {src && <div className={classes.cropperContainer}>
                    <Cropper
                        cropShape={shape ? shape : ''}
                        image={src}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={(_croppedArea, croppedAreaPixels) => setArea(croppedAreaPixels)}
                        onZoomChange={setZoom}
                        onChange={setCrop}
                    />
                </div>}
            </DialogContent>
            <div className={classes.slider}>
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(_e, zoom) => setZoom(zoom)}
                />
                <Button
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="primary"
                    onClick={getCroppedImg}
                >
                    salvar foto
                </Button>
            </div>
        </Dialog>
    );
}

export { ImageCrop };
