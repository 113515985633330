import { dashboardService } from '../services';
import { dashboardConstants } from '../constants';

export const dashboardActions = {
  getTopFiveAreas,
  getUsers,
  setSortUsers,
  clearDownload,
  setSortPeriod,
  setPeriodType
}

function getTopFiveAreas(data) {
  return (dispatch) => {
    dispatch({ 
      type: dashboardConstants.GET_DASHBOARD_CSV_REQUEST 
    });
    dashboardService.getDashboardReportsCsv(data) 
      .then(response => {
        dispatch({ 
          type: dashboardConstants.GET_DASHBOARD_CSV_SUCCESS,
          payload: response.data.data.csv
        });
        dispatch(clearDownload());
      })
      .catch((err) => {
        dispatch({ 
          type: dashboardConstants.GET_DASHBOARD_CSV_FAILURE,
         })
      })
  }
}

function clearDownload() {
  return (dispatch) => {
    dispatch({ 
      type: dashboardConstants.CLEAR_DASHBOARD_CSV_DOWNLOAD, 
    });
  }
}

function getUsers(filters) {
  return dispatch => {
    dispatch({ type: dashboardConstants.REQUEST_GET_USERS });
    dashboardService.getTopUsers(filters).then(({ data }) => {
      data?.users && dispatch({ type: dashboardConstants.SUCCESS_GET_USERS, payload: data.users });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: dashboardConstants.FAILURE_GET_USERS });
    });
  };
};

function setSortUsers(payload) {
  return dispatch => {
    dispatch({ type: dashboardConstants.SET_SORT_USERS, payload });
  };
};
function setSortPeriod(payload) {
  return dispatch => {
    dispatch({ type: dashboardConstants.SET_SORT_PERIOD, payload });
  };
};
function setPeriodType(payload) {
  return dispatch => {
    dispatch({ type: dashboardConstants.SET_PERIOD_TYPE, payload });
  };
};
