import { styled } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiInfoIcon from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 4),
  fontWeight: 700
}));

export const Title = styled(Typography)({
  fontWeight: 700
});

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  borderBottom: 'none'
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(2, 4),
}));

export const CustomTextField = styled(TextField)({
  '& .MuiFilledInput-input': {
    padding: '19px 8px 18px'
  }
});

export const InfoIcon = styled(MuiInfoIcon)(({ theme }) => ({
  color: theme.palette.neutrals.low.light,
  marginRight: 8
}));

export const InfoDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.low.medium,
}));
