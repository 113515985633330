import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonSidebarProfile() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Skeleton variant="circle" width={128} height={128} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={26} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={20} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={17} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={17} />
      </Grid>
    </Grid>
  );
}