import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import {
  Card,
  Header,
  Title,
  Content,
  ValuesContainer,
  LinearProgressBlue,
  LinearProgressRed,
  LinearProgressGreen,
  LinearProgressOrange
} from './styles';

export function IdeasByClassificationCard({loading=false, ideasTotalByClassification=[] }) {
  const { t } = useTranslation();

  return (
    <Card elevation={0}>
      <Header>
        <Title variant="subtitle1">{t('ideias_por_classificacao')}</Title>
      </Header>
      <Content>
        <Grid container spacing={3}>
       { ideasTotalByClassification.map((item, index) => 
          <Grid key={index} item xs={12} sm={4}>
            <Typography variant="subtitle2">{item.name}</Typography>
            <ValuesContainer>
              <Box flexGrow={1}>
                <LinearProgress color='primary' variant="determinate" value={Number(item.percentage)} />
              </Box>
              <Typography variant="body2">{item.total} {t('ideias').toLowerCase()}</Typography>
            </ValuesContainer>
          </Grid>)}
        </Grid>
      </Content>
    </Card>
  );
}