import React, { useEffect } from 'react';
import { Container, makeStyles, Grid, Card } from '@material-ui/core';
import { BackNavAdmin, ArticleCreateCard, ArticleImageCard, ArticleProgressCard, ArticleAuthors, ArticleStatus } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import { articleActions } from '../../actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  card: {
    marginBottom: 20,
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    top: '76px',
  },
}));

function ArticleEditPage() {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    articleDetails: { title, description, thumbnail, banner },
    articleDetails
  } = useSelector((state) => state.articlesReducer);

  useEffect(() => {
    dispatch(articleActions.getArticleById(id));
  }, [dispatch, id]);

  const shouldDisable = (
    !!title?.length &&
    description?.length > 100
  );

  const RenderImageCard = ({ type, file }) => {
    return (<ArticleImageCard
      isEdit
      type={type}
      image={file?.file ? file : { readableSize: file?.size || '', name: file?.fileName || '', preview: file?.url || '' }}
    />)
  }

  return (
    <div>
      <BackNavAdmin title={t('editar_artigo')}></BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <div className={classes.card}>
              <ArticleCreateCard
                isEdit
                title={title}
                description={description}
              ></ArticleCreateCard>
            </div>

            <Card className={classes.card}>
              <RenderImageCard type='thumbnail' file={thumbnail} />
              <RenderImageCard type='banner' file={banner} />
            </Card>

            <Card className={classes.card}>
              <ArticleAuthors isEdit />
            </Card>

            <Card className={classes.card}>
              <ArticleStatus isEdit />
            </Card>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fixed}>
              <ArticleProgressCard
                isEdit
                article={articleDetails}
                shouldDisable={!shouldDisable}
              ></ArticleProgressCard>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export { ArticleEditPage };
