import React from 'react';
import { MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import { useState } from 'react';
import { useMemo } from 'react';

export function ImagineFormSelect({ label = '', name = '', isRequired = false, options = [{ label: '', value: '' }], onChange = () => { } }) {


  return (
    <FormControl fullWidth variant="filled" sx={{ width: '100%' }}>
      <InputLabel shrink={true} id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        name={name}
        required={isRequired}
        style={{ marginBottom: 30 }}
        onChange={({ target: value }) => {onChange(value)}}
      >
        {options.map(({ label, value }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
