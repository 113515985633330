import React from 'react';
import { useWindowSize } from '../../hooks';
import { Dialog, withStyles } from '@material-ui/core';
import config from '../../config';
import { TransitionUp, Transition } from './DialogTransition';

const DialogIdea = withStyles({
  paper: {
    overflowY: 'visible',
    maxWidth: 686
  },
})(Dialog);

function DialogCreateIdea(props) {
  const [width] = useWindowSize();

  return (
    <DialogIdea
      {...props}
      maxWidth={props.maxWidth ? props.maxWidth : 'sm'}
      fullWidth={true}
      fullScreen={width < config.RESPONSIVE_MOBILE ? true : false}
      PaperProps={{
        style: {
          minHeight: width < config.RESPONSIVE_MOBILE ? 'initial' : '80vh',
          maxHeight: width < config.RESPONSIVE_MOBILE ? 'initial' : '80vh',
        },
      }}
      TransitionComponent={
        width < config.RESPONSIVE_MOBILE ? TransitionUp : Transition
      }
      classes={{ paper: 'paper' }}
    >
      {props.children}
    </DialogIdea>
  );
}

export { DialogCreateIdea };
