import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  container: {
    padding: theme.spacing(2)
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  },
}))(MuiSkeleton);


export function SkeletonChallengeCard() {
  const classes = useStyles();
  const columns = new Array(4).fill(0);

  return (
    <Grid container spacing={2}>
      {columns.map((item, index) => (
        <Grid item xs={12} md>
          <Card elevation={0} className={classes.card} key={index}>
            <Skeleton variant="rect" width="100%" height={145} />
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12}>
                <Skeleton variant="rect" width="100%" height={27} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="rect" width="100%" height={55} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={30} />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rect" width="100%" height={30} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="rect" width="50%" height={30} />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                <Skeleton variant="rect" width="50%" height={30} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}