import React, { useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { BackNav } from '../../components';
import { SidebarIdeaPoint } from '../../components';
import { ideaActions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
}));

function IdeaCanvasPage() {
  const classes = useStyles();
  const { ideaDetails } = useSelector((state) => state.idea);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { t } = useTranslation();
  useEffect(() => {
    if (!ideaDetails._id) {
      dispatch(ideaActions.getIdeaDetails(id, 'canvas'));
    }
  });

  return (
    <Container maxWidth="lg" className={classes.root}>
      <BackNav title="" pathname="/" />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <SidebarIdeaPoint />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Container>
  );
}

export { IdeaCanvasPage };
