import { userService, ideaService } from '../services';
import { profileConstants } from '../constants';

export const profileActions = {
  getUser,
  getIdeasByUserId,
  setLimit,
  setPage,
  getIdeasByUserIdMobile
};

function getUser(id) {
  return (dispatch) => {
    userService.getUserById(id).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  
  function success(user) {
    const payload = {
      user,
    };
    return { type: profileConstants.GET_USER_PROFILE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: profileConstants.GET_USER_PROFILE_FAILURE, error };
  }
}

function getIdeasByUserId(id, page, limit) {
  return (dispatch) => {
    dispatch(request());
    ideaService.getIdeasByUserId(id, page, limit).then(
      (data) => {
        dispatch(success(data.data, data.paging));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(){
    return { type: profileConstants.GET_IDEAS_PROFILE_REQUEST }
  }

  function success(ideas, paging) {
    const payload = {
      ideas,
      paging,
    };

    return { type: profileConstants.GET_IDEAS_PROFILE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: profileConstants.GET_IDEAS_PROFILE_FAILURE, error };
  }
}

function setLimit(limit) {
  const payload = {
    limit,
  };

  return { type: profileConstants.SET_LIMIT, payload };
}

function setPage(page) {
  const payload = {
    page,
  };

  return { type: profileConstants.SET_PAGE, payload };
}

function getIdeasByUserIdMobile(id, page, limit) {
  return (dispatch) => {
    dispatch(request())
    ideaService.getIdeasByUserId(id, page, limit).then(
      (data) => {
        dispatch(success(data.data, data.paging));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(){
    return { type: profileConstants.GET_IDEAS_PROFILE_MOBILE_REQUEST }
  }

  function success(ideas, paging) {
    const payload = {
      ideas,
      paging,
    };

    return { type: profileConstants.GET_IDEAS_PROFILE_MOBILE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: profileConstants.GET_IDEAS_PROFILE_MOBILE_FAILURE, error };
  }
}