import React from 'react'
import { useTranslation } from 'react-i18next';
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import makeStyles from "@material-ui/styles/makeStyles";
import { TagIcon } from "../TagIcon";
import { useSelector } from "react-redux"
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  titleBox: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 20,
    fontWeight: 600,
  },
  infoBox: {
    fontSize: 14,
    color: theme.palette.neutrals.low.medium,
  },
  cronogramaBox: {
    display: 'flex',
  },
  cronogramaTitle: {
    color: theme.palette.neutrals.low.medium,
    fontSize: 12,
    fontWeight: 600,
  },
  tableTitle: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 14,
    fontWeight: 600,
  },
  spacingTop: {
    paddingTop: theme.spacing(4)
  },
  divider: {
    marginTop: theme.spacing(4)
  }
}));

export const CardDetails = () => {
  const classes = useStyles();
  const { project } = useSelector(state => state.portfolioDetails);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card elevation={0} className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.titleBox}>{project?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TagIcon
            title={t('ideia')}
            icon={<EmojiObjectsIcon fontSize="small" style={{ color: theme.palette.primary.main }} />}
          />
          <Typography className={classes.infoBox}>{project?.idea?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TagIcon
            title={t('descricao')}
            icon={<DescriptionIcon fontSize="small" style={{ color: theme.palette.secondary.main }} />}
          />
          <Typography className={classes.infoBox}>{project?.description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TagIcon
            title={t('cronograma')}
            icon={<EventIcon fontSize="small" style={{ color: theme.palette.auxiliary.blue.main }} /> }
          />
          <Box className={classes.cronogramaBox}>
            <Box style={{ marginRight: 25 }}>
              <Typography className={classes.cronogramaTitle}>{t('inicio')}</Typography>
              <Typography className={classes.infoBox}>
                {project?.dateInit && moment(project?.dateInit).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.cronogramaTitle}>{t('termino')}</Typography>
              <Typography className={classes.infoBox}>
                {project?.dateEnd && moment(project?.dateEnd).format('DD/MM/YYYY')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}
