import { contentsService } from '../services';
import { contentsConstants } from "../constants"

export const contentsActions = {
  getFixedContent,
  getContents
};

function getFixedContent() {
  return (dispatch) => {
    dispatch({
      type: contentsConstants.GET_FIXED_CONTENT_REQUEST
    })

    contentsService.getFixedContent()
      .then((response) => {
        dispatch({
          type: contentsConstants.GET_FIXED_CONTENT_SUCCESS,
          payload: response.data
        });
      })
      .catch((response) => {
        dispatch({
          type: contentsConstants.GET_FIXED_CONTENT_FAILURE
        })
      });
  }
}

function getContents(page = 1, sort = -1, limit = 10) {
  return (dispatch) => {
    dispatch({
      type: contentsConstants.GET_ALL_CONTENT_REQUEST,
    })

    contentsService.getCotents(page, sort, limit)
      .then((response) => {
        dispatch({
          type: contentsConstants.GET_ALL_CONTENT_SUCCESS,
          payload: response.data
        })
      })
      .catch((err) => {
        dispatch({
          type: contentsConstants.GET_ALL_CONTENT_FAILURE
        })
      })
  }
}