import { executionConstants } from '../constants';

const initialState = {
  estimatedStartDate: null,
  estimatedConclusionDate: null,
  executionDetails: '',
  estimatedFinancialReturn: "",
  costsFields: [],
  error: false,
  errorMessage: "",
  loading: false
};

/** 
 * Objeto dentro de costsFields: 
 * { 
    estimatedCost: "", 
    realCost: "",
    costDescription: "", 
  }
*/

export function execution(state = initialState, action) {
  switch (action.type) {
    case executionConstants.UPDATE_EXECUTION_IDEA_REQUEST:
    case executionConstants.POST_EXECUTION_IDEA_REQUEST:
      return {
        loading: true
      }
    case executionConstants.UPDATE_EXECUTION_IDEA_SUCCESS:
    case executionConstants.POST_EXECUTION_IDEA_SUCCESS:
      return {
        loading: false
      }
    case executionConstants.UPDATE_EXECUTION_IDEA_FAILURE:
    case executionConstants.POST_EXECUTION_IDEA_FAILURE:
      return {
        loading: false
      }

    case executionConstants.CREATE_IDEA_ARRAY_COSTS: {
      return {
        ...state,
        costsFields: [{
          estimatedCost: "", 
          costDescription: "", 
        }]
      }
    }

    case executionConstants.GET_EXECUTED_IDEA_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case executionConstants.GET_EXECUTED_IDEA_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    }

    case executionConstants.GET_EXECUTED_IDEA_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case executionConstants.SET_INPUT_EXECUTION: {
      return {
        ...state,
        [action.payload.input]: action.payload.value
      }
    }

    case executionConstants.CHANGE_EXECUTION_COST: {
      return {
        ...state,
        costsFields: state.costsFields.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              [action.payload.name]: action.payload.value
            }
          }
          return item;
        })
      }
    }

    case executionConstants.ADD_EXECUTION_COST_FIELD: {
      return {
        ...state,
        costsFields: [
          ...state.costsFields,
          {
            estimatedCost: "", 
            realCost: "",
            costDescription: "", 
          }
        ]
      }
    }

    case executionConstants.CHANGE_EXECUTION_FINANCIAL_RETURN: {
      return {
        ...state,
        estimatedFinancialReturn: action.payload
      }
    }

    case executionConstants.DELETE_EXECUTION_COST_FIELD: {
      return {
        ...state,
        costsFields: state.costsFields.filter((item, index) => index !== action.payload)
      }
    }

    case executionConstants.CHANGE_EXECUTION_DETAILS: {
      return {
        ...state,
        executionDetails: action.payload
      }
    }

    case executionConstants.IDEA_EXECUTION_CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}
