import { styled } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MuiMenu from '@material-ui/core/Menu';

export const CustomAvatar = styled(MuiAvatar)({
  borderRadius: '50%',
  border: '2px solid #fff',
  position: 'absolute',
  top: 20,
  left: 20,
  width: 42,
  height: 42
});

export const FirstName = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: 700,
  margin: theme.spacing(1.5, 0, 0, 4),
}));

export const And = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  margin: theme.spacing(1.5, 0.5, 0),
}));

export const SecondName = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: 700,
  margin: theme.spacing(1.5, 0, 0),
}));

export const Date = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  color: theme.palette.neutrals.low.light,
  paddingLeft: theme.spacing(4),
}));

export const Participants = styled(MuiAvatar)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.light,
  borderRadius: 6,
  width: 30,
  height: 25,
  fontSize: 11,
  fontWeight: 600,
  marginLeft: theme.spacing(1.5),
  cursor: 'pointer',
}));

export const ParticipantName = styled(Typography)({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  }
});

export const Modal = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    padding: theme.spacing(2),
  }
}));