import React from 'react';
import { TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function ImagineFormTextField({ disabled = false, type = 'text', name = '', label = '', rows = 0, defaultValue = '', isRequired = false, onChange = () => { } }) {
  const { errors, register } = useForm({ mode: 'onBlur' });
  const { t } = useTranslation();

  return (
    <TextField
      type={type}
      inputRef={register({
        required: `${label} ${t('e_obrigatorio')}`,
      })}
      disabled={disabled}
      error={!!errors[name]}
      helperText={errors[name] && errors[name].message}
      name={name}
      label={label}
      variant="filled"
      multiline
      fullWidth
      required={isRequired}
      defaultValue={defaultValue}
      rows={rows && rows}
      style={{ marginBottom: 30 }}
      onBlur={({ target: { value } }) => onChange(value)}
    />
  );
}
