import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fluxOfIdeasActions, ideaActions } from '../../../actions';
import config from '../../../config';
import { unMasker } from '../../../helpers';

export function useIdeaDetailsController() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, page } = useParams();
  const { t } = useTranslation();
  const ideaCreate = useSelector((state) => state.ideaCreate);
  const { ideaDetails, loading } = useSelector((state) => state.idea);
  const { ideaDetailsFlux, loadingFlux } = useSelector((state) => state.fluxIdeas);
  const stepflux = config.SHOW_IDEAS_BY_STEP_FLUX;
  const hasIdeaFlux = stepflux ? ideaDetailsFlux : ideaDetails;
  const hasLoadingFlux = stepflux ? loadingFlux : loading;

  useEffect(() => {
    if (['general', 'qualification', 'general-evaluation', 'execution', 'conclusion', 'results', 'engagement', 'history', 'evidence-implementation'].includes(page)) {
      if (!hasIdeaFlux?._id || hasIdeaFlux?._id !== id) {
        if (stepflux) {
          dispatch(fluxOfIdeasActions.getIdeaInFlux(id));
        } else {
          dispatch(ideaActions.getIdeaDetails(id));
        }
      }
    } else {
      history.goBack();
    }
  }, [page, id, hasIdeaFlux, dispatch]);

  function handleEditIdea() {
    history.push(`/idea-edit/${hasIdeaFlux._id}`);
  }
  
  async function handleSendIdea() {
    const isDetails = true;

    await dispatch(fluxOfIdeasActions.createIdeaInFlux({
      ...ideaCreate,
      estimatedFinancialReturn: unMasker(ideaCreate?.estimatedFinancialReturn),
      estimatedExecutionCost: unMasker(ideaCreate?.estimatedExecutionCost)
    }, t('sua_ideia_foi_enviada'), isDetails));
  }

  return {
    id,
    page,
    ideaDetails,
    ideaDetailsFlux,
    loading,
    loadingFlux,
    hasIdeaFlux,
    hasLoadingFlux,
    stepflux,
    handleEditIdea,
    handleSendIdea
  }
}