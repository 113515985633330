import * as randomcolor from 'randomcolor';

import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import React from 'react';
import { useTheme } from '@material-ui/core';

export function PieChartPercentage({ data, colors }) {
  const theme = useTheme();

 // Numero de legendas multiplicado pela altura de cada uma mais a altura do chart
 const calculatedHeight = data.length * 30 + 300;
  const percent = data.map((item) => ({ name: item.name, percent: parseFloat(item.percentage) }));

  return (
    <ResponsiveContainer height={calculatedHeight}>
      <PieChart width="100%">
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0.8} />
            <stop offset="95%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Pie
          cx="50%"
          cy="50%"
          data={percent}
          dataKey="percent"
          legendType="circle"
          innerRadius={20}
          outerRadius={120}
          paddingAngle={3}
          cornerRadius={8}
          labelLine={false}
          label={({ percent }) => `${percent}%`}
          fontWeight="600"
        >
          {data?.map((_entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index] || randomcolor()}
            />
          ))} 
        </Pie>
        <Tooltip formatter={(percent) => `${percent}%`} />
      </PieChart>
    </ResponsiveContainer>
  );
}
