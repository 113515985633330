import enLocale from 'date-fns/locale/en-US';
import ptBRLocale from 'date-fns/locale/pt-BR';
import esLocale from 'date-fns/locale/es';

const selectedLocale = (language) => {
  switch (language) {
    case 'en':
      return enLocale;
    case 'pt_BR':
      return ptBRLocale;
    case 'es':
      return esLocale;
    default:
      return ptBRLocale;
  }
};

export { selectedLocale }