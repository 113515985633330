import React from 'react';
import {
  makeStyles,
  IconButton,
  Typography,
  Container,
  Box,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0),
    },

    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  container: {
    display: 'flex',
    justifyContent: "space-between",
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  oneLine: {
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
  childrenDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export function ImagineBackNav({ title, children, handleRedirectBackPage }) {
  const classes = useStyles();
  const { t } = useTranslation();  

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.title}>
          <IconButton onClick={() => handleRedirectBackPage()}>
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography
            variant="h5"
            component="h5"
            style={{ fontSize: 25, fontWeight: 'bold' }}
            className={classes.oneLine}
          >
            {t(title)}
          </Typography>
        </Box>
        {<Box className={classes.childrenDiv}>{children}</Box>}
      </Container>
    </Box>
  );
}
