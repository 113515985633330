import React from 'react';
import {
  makeStyles,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Hidden,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateActions, ideaCreateDialogsActions, multiAreaCascadeActions } from '../../actions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useForm } from 'react-hook-form';
import { dialogCss } from './dialogCss';
import { MenuInformation } from '../Shared';
import clsx from 'clsx';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { DialogCreateIdea } from './DialogCreateIdea';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dividerBottom: {
    borderBottom: 'none',
  },
  title: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  button: {
    textTransform: 'lowercase',
  },
  grid: {
    position: 'relative',
  },
  buttonInformation: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 9999,
  },
  buttonBottom: {
    top: 236,
  },
}));

function DialogValueCanvas() {
  const dialogClasses = dialogCss();
  const idea = useSelector((state) => state.ideaCreate);
  const { dialogValueCanvas, fromHistory } = useSelector(
    (state) => state.ideaCreateDialogs
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const { getValues, handleSubmit, register } = useForm();
  const [width] = useWindowSize();
  const { t } = useTranslation();

  function createDraft() {
    dispatch(ideaCreateActions.createDraft(idea));
  }

  function setValueCanvas() {
    const values = getValues();
    dispatch(ideaCreateActions.setValueCanvas(values));
  }

  function onSubmit(data) {
    dispatch(ideaCreateActions.setValueCanvas(data));

    if (fromHistory.length > 0) {
      dispatch(ideaCreateDialogsActions.previousDialog())
      return;
    }
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog())
  }
  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
  }

  return (
    <DialogCreateIdea maxWidth="lg" open={dialogValueCanvas}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('preencher_canvas')}: {' '} {t('proposta_de_valor')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <div className={classes.title}>
          {t('preencha_os_campos_abaixo_para_que_sua_ideia_fique_ainda_mais_completa')}!
        </div>
        <Grid container justify="center" spacing={2} padding={10}>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t('neste_ponto')}{' '}
                {t('descreva_de_forma_sucinta_as_melhores_solucoes_do_seu_produto_e_aposte_tambem_em_elaborar_um_mvp_produto_minimo_viavel')}, {' '}
                {t('que_consiste_em_um_produto_com_o_minimo_de_recursos_possivel')}, {' '}
                {t('mas_que_serve_para_conhecer_a_reacao_do_mercado_sobre_a_sua_viabilidade')}.
              </MenuInformation>
            </div>
            <TextField
              name="Q1"
              id="Q1"
              variant="filled"
              label={t('produtos_e_servicos')}
              multiline
              fullWidth
              placeholder={t('qual_produto_ou_servico_sera_oferecido_ao_cliente')}
              style={{ marginBottom: 16 }}
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              inputRef={register}
              onBlur={setValueCanvas}
              defaultValue={idea.canvasValueProposition[0]?.answer}
            />

            <div
              className={clsx(classes.buttonInformation, classes.buttonBottom)}
            >
              <MenuInformation>
                {t('defina_as_principais_acoes_e_pontos_que_darao_suporte_e_aliviarao_as_principais_dores_dos_clientes')}.
              </MenuInformation>
            </div>
            <TextField
              name="Q2"
              id="Q2"
              variant="filled"
              label={t('analgesicos')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              placeholder={t('como_sua_ideia_de_produto_alivia_as_principais_dores_dos_clientes')}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              onBlur={setValueCanvas}
              inputRef={register}
              defaultValue={idea.canvasValueProposition[1]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t('faca_uma_lista_dos_tres_principais_beneficios_aos_clientes')}.{' '}
                {t('esse_e_o_momento_de_avaliar_o_que_ajudaria_os_seus_clientes_em_potencial_e_quais_beneficios_que_a_sua_empresa_se_propoe_a_fazer')}.
              </MenuInformation>
            </div>

            <TextField
              name="Q3"
              id="Q3"
              variant="filled"
              label={t('criadores_de_ganhos')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              placeholder={t('quais_elementos_da_sua_ideia_que_trarao_beneficios_aos_clientes')}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                },
              }}
              inputRef={register}
              onBlur={setValueCanvas}
              defaultValue={idea.canvasValueProposition[2]?.answer}
            />
          </Grid>
          
           
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t('e_aqui_que_voce_precisa_concertar_sua_energia_para_pensar_no_grande_diferencial_da_sua_ideia')}, {' '}
                {t('quais_sao_seus_ganhos_necessarios')}, {' '}
                {t('esperados')}, {' '}
                {t('desejados_e_inesperados_que_ira_trazer_inovacao_para_o_mercado_que_esta_inserido')}.
              </MenuInformation>
            </div>
            <TextField
              name="Q4"
              id="Q4"
              variant="filled"
              label={t('ganhos')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              placeholder={t('quais_sao_os_maiores_ganhos_da_sua_ideia_para_seu_cliente')}
              style={{ marginBottom: 16 }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              inputRef={register}
              onBlur={setValueCanvas}

              defaultValue={idea.canvasValueProposition[3]?.answer}
            />

            <div
              className={clsx(classes.buttonInformation, classes.buttonBottom)}
            >
              <MenuInformation>
                {t('e_hora_de_identificar_quais_sao_as_principais_dificuldades_desse_cliente_pensar_em_como_o_seu_produto_ou_servico_poderao_solucionar_esses_problemas')}.
              </MenuInformation>
            </div>
            <TextField
              name="Q5"
              id="Q5"
              variant="filled"
              label={t('dores')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              placeholder={t('quais_sao_as_maiores_dores_do_seus_cliente')}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              inputRef={register}
              onBlur={setValueCanvas}
              defaultValue={idea.canvasValueProposition[4]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t('organize_em_uma_lista_tudo_o_que_os_clientes_precisam_realizar')}. {' '}
                {t('todas_as_tarefas_que_precisam_executar_no_dia_a_dia')}. {' '}
                {t('caso_identifique_que_existe_mais_de_um_tipo_de_cliente')}, {' '}
                {t('o_ideal_e_executar_um_quadro_para_cada_um_deles')}.
              </MenuInformation>
            </div>
            <TextField
              name="Q6"
              id="Q6"
              variant="filled"
              label={t('tarefa_dos_clientes')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              placeholder={t('quais_sao_as_tarefas_funcionais_que_os_clientes_precisam_realizar')}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                },
              }}
              inputRef={register}
              onBlur={setValueCanvas}
              defaultValue={idea.canvasValueProposition[5]?.answer}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={6} className={classes.grid}  >
            <Grid container justify="center" spacing={2}>

              <Grid item xs={12} md={6} className={classes.grid}>
                <div className={classes.buttonInformation}>
                  <MenuInformation>
                    {t('escolha_um_nome_que_passe_o_objetivo_de_maneira_simples_e_acessivel')}.
                  </MenuInformation>
                </div>
                <TextField
                  name="Q7"
                  id="Q7"
                  variant="filled"
                  label={t('nome_do_produto')}
                  multiline 
                  fullWidth

                  rows={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  rowsMax={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  placeholder={t('qual_e_o_nome_da_solucao_que_a_sua_ideia_traz')}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 16, fontWeight: 'bold' },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: '34px 12px 10px',
                    },
                  }}
                  inputRef={register}
                  onBlur={setValueCanvas}
                  defaultValue={idea.canvasValueProposition[6]?.answer}
                />

              </Grid>
              <Grid item xs={12} md={6} className={classes.grid}  >
                <div className={classes.buttonInformation}>
                  <MenuInformation>
                    {t('descreva_como_seria_seu_cliente_ideal')}.
                  </MenuInformation>
                </div>
                <TextField
                  name="Q8"
                  id="Q8"
                  variant="filled"
                  label={t('cliente_ideal')}
                  multiline 
                  fullWidth

                  rows={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  rowsMax={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  placeholder={t('qual_seria_o_cliente_ideal_para_sua_ideia')}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 16, fontWeight: 'bold' },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: '34px 12px 10px',
                    },
                  }}
                  inputRef={register}
                  onBlur={setValueCanvas}
                  defaultValue={idea.canvasValueProposition[7]?.answer}
                />

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                {t('faca_uma_pesquisa_com_os_principais_adversarios_no_mercado_e_veja_como_eles_abordam_o_problema_que_sua_ideia_pretende_resolver')}.
              </MenuInformation>
            </div>
            <TextField
              name="Q9"
              id="Q9"
              variant="filled"
              label={t('concorrentes_ou_substitutos')}
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 4 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 4 : 8}
              placeholder={t('quais_sao_os_principais_concorrentes_da_sua_ideia_atuando_no_mercado')}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                },
              }}
              inputRef={register}
              onBlur={setValueCanvas}
              defaultValue={idea.canvasValueProposition[8]?.answer}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {fromHistory.length > 0 && (
              <Button
                color="primary"
                onClick={previous}
                startIcon={<ArrowBackIcon />}
                className={dialogClasses.button}
              >
                {t('voltar')}
              </Button>
            )}
          </div>
          <div>
            <Hidden smDown>
              <Button
                className={dialogClasses.button}
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={createDraft}
              >
                {t('salvar_rascunho')}
              </Button>
            </Hidden>
            <Button
              className={dialogClasses.button}
              variant="contained"
              type="submit"
              color="primary"
              startIcon={<ArrowForwardIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('avancar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogValueCanvas };
