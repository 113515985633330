import { styled, Box } from '@material-ui/core';

export const Video = styled('video')({
  width: '100%',
  borderRadius: 4
});

export const Image = styled('img')({
  width: '100%',
  borderRadius: 4
});

export const Link = styled('a')(({ theme }) => ({
  color: theme.palette.auxiliary.indigo.main,
  fontSize: 12,
  fontWeight: 600
}));

export const CardItem = styled(Box)({
  padding: 16,
  gap: 16,
  display: 'flex',
  alignItems: 'center',
});
