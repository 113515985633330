import React, { useState } from 'react';
import randomColor from 'randomcolor';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button } from '@material-ui/core';

import config from '../../config';
import { SkeletonPieChart } from '../Skeletons';
import { usePieChartColors } from './usePieChartColors';
import { PieChartNumeric, PieChartPercentage } from './PieCharts';
import { Card, HeaderContainer, Title, Legend, Circle, ButtonContainer, useStyles } from './styles';

export function IdeaForChallengeDashboard({ ideasStatus, loadingIdeasStatus }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [typePieChart, setTypePieChart] = useState('numeric');
  const hasIdeasStatus = ideasStatus.length > 0;
  const { colors } = usePieChartColors();

  function handleBtnStyle(value) {
    return value === typePieChart ? classes.buttonSelected : classes.button;
  }

  function handleClick(value) {
    setTypePieChart(value);
  }

  return (
    <Card elevation={0}>
      {loadingIdeasStatus && <SkeletonPieChart />}
      
      {(!loadingIdeasStatus && hasIdeasStatus) && (
        <Box>
          <HeaderContainer display="flex" alignItems="center" justifyContent="space-between">
            <Title>
              {config.SHOW_IDEAS_BY_STEP_FLUX 
                ? t('ideias_por_etapas') 
                : t('ideias_por_status')
              }
            </Title>
            <ButtonContainer>
              <Button
                onClick={() => handleClick('numeric')}
                className={handleBtnStyle('numeric')}
              >
                {t('numerico')}
              </Button>
              <Button
                onClick={() => handleClick('percentage')}
                className={handleBtnStyle('percentage')}
              >
                {t('percentual')}
              </Button>
            </ButtonContainer>
          </HeaderContainer>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {typePieChart === 'numeric'
                ? <PieChartNumeric data={ideasStatus} colors={colors} />
                : <PieChartPercentage data={ideasStatus} colors={colors} />
              }
            </Grid>
            <Grid
              item 
              xs={12}
              sm={6}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Grid container spacing={2}>
                {ideasStatus.map(({ name }, index) => (
                  <Grid item key={index}>
                    <Box display="flex" alignItems="center">
                      <Circle
                        style={{
                          backgroundColor: colors[index] || randomColor()
                        }}
                      />
                      <Legend>{name}</Legend>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Card>
  );
}