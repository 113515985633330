import { Box, Button, DialogActions, Grid, makeStyles, TextField } from "@material-ui/core";
import { ReactComponent as OutBox } from '../../../assets/imgs/outbox.svg';
import { useTranslation } from "react-i18next";
import { AvatarIconWithText, MultiAreaCascade } from '../../Shared';
import { useDispatch, useSelector } from "react-redux";
import { delegateStepIdeaActions, evaluationTeamsActions } from "../../../actions";
import { useEffect } from "react";
import { ArrowBack, FiberManualRecord } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";


const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 20,
    paddingBottom: theme.spacing(5)
  },
  avatarIcon: {
    backgroundColor: theme.palette.primary.light,
    marginRight: 10,
  },
  about: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  buttons: {
    display: 'flex',
    alingItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2)
  },
}));

export function EvaluationTeamForm({ handleBack, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { observation, evaluationsTeamsIds } = useSelector(state => state.delegateStepIdea);
  const { teams } = useSelector(state => state.evaluationTeams);
  const { id } = useParams();
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  useEffect(() => {
    dispatch(delegateStepIdeaActions.setEvaluationTeamsIds([selectedTeamId || '']))
  }, [selectedTeamId]);

  useEffect(() => {
    dispatch(delegateStepIdeaActions.clearObservation());
    dispatch(evaluationTeamsActions.getAllEvaluationTeams());
  }, []);

  function handleChangeObservation({ target: { value } }) {
    dispatch(delegateStepIdeaActions.setObservation(value));
  }

  function handleSend() {
    dispatch(delegateStepIdeaActions.postDelegate(id, { observation, evaluationsTeamsIds }));
    onClose();
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AvatarIconWithText
            style={{ width: 32, height: 32 }}
            icon={<OutBox />}
            variant="subtitle1"
            text={t('encaminhar_ideia')}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={teams}
            loadingText={t('carregando...')}
            noOptionsText={t('sem_opcoes')}
            loading={teams.length === 0}
            getOptionLabel={(option) => option.name}
            value={teams.find((team) => team._id === selectedTeamId)}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedTeamId(newValue._id);
              } else {
                setSelectedTeamId(null);
              }
            }}
            renderInput={(params) => <TextField {...params} variant="filled" label={t("equipe_de_avaliacao")} />}
          />
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.about}>
            <Box className={classes.characters}>
              <span>
                {observation.length}/1024 {t('caracteres')}
              </span>
            </Box>
            <TextField
              name="observation"
              label={t('observacoes')}
              multiline
              fullWidth
              minRows={8}
              variant="filled"
              onChange={handleChangeObservation}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent='center' J>
          <FiberManualRecord color='primary' />
          <FiberManualRecord color='primary' />
        </Box>
      </Grid>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent={'space-between'}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleBack('index')}
            startIcon={<ArrowBack />}
          >
            {t('voltar')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
          >
            {t('encaminhar')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}