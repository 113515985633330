import React from 'react';

import {
  Box,
  Typography,
  makeStyles,
  CardHeader,
} from '@material-ui/core';
import { Component } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark
  },
  icon: {
    backgroundColor: theme.palette.primary.light,
    width: 36,
    height: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function ImagineSectionTitle({ icon = Component, title = "" }) {
  const classes = useStyles();

  return (
    <CardHeader
      avatar={
        <Box className={classes.icon}>
          {icon}
        </Box>
      }
      title={<Typography className={classes.title}>
        {title}
      </Typography>}
    />
  );
}