import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';

import { Copyright } from '../Shared';

const useStyles = makeStyles((theme) => ({
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const useStylesIcon = makeStyles(() => ({
  root: {
    minWidth: 30,
  },
}));

export function SidebarUserEdit() {
  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <List style={{ padding: 0 }}>
        <ListItem
          button
          component={Link}
          to="/user/edit"
          className={
            location.pathname === '/user/edit'
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <PersonIcon
              color={
                location.pathname === '/user/edit' ? 'primary' : 'inherit'
              }
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="subtitle2"
                color={
                  location.pathname === '/user/edit' ? 'primary' : 'inherit'
                }
              >
                {t('editar_perfil')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/user/settings"
          className={
            location.pathname === '/user/settings'
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <SettingsIcon
              color={
                location.pathname === '/user/settings'
                  ? 'primary'
                  : 'inherit'
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={
                  location.pathname === '/user/settings'
                    ? 'primary'
                    : 'inherit'
                }
              >
                {t('configuracoes_da_conta')}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider style={{ marginTop: 16 }} />
      <Copyright />
    </>
  );
}