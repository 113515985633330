import {
  Box,
  Button,
  ButtonBase,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  buttonSelected: {
    borderRadius: 32,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    padding: '0 20px',
    margin: 5,
  },
  button: {
    borderRadius: 32,
    backgroundColor: 'transparent',
    border: `.5px solid #CED4DA`,
    padding: '0 24px',
    margin: 5,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

function SelectIdeasByPeriod() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sortPeriod, periodType } = useSelector((state) => state.dashboard);
  const [btnSelected, setBtnSelected] = useState(periodType);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(sortPeriod);
  const dispatch = useDispatch();

  function handleBtnStyle(tag) {
    return tag === btnSelected ? classes.buttonSelected : classes.button;
  }

  function handleButtonClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuItemClick(tag) {
    if (typeof tag === 'number') {
      setSelectedPeriod(tag);
      setBtnSelected('period');
      setAnchorEl(null); 
      dispatch(dashboardActions.setSortPeriod(tag));
    } else {
      setBtnSelected(tag);
      dispatch(dashboardActions.setPeriodType(tag));
    }
  }

  function handleClose() {
    setAnchorEl(null); 
  }

  return (
    <Box className={classes.flexContainer} marginBottom={5}>
      <Button
        onClick={() => handleMenuItemClick('daily')}
        className={handleBtnStyle('daily')}
      >
        {t('diario')}
      </Button>
      <Button
        onClick={() => handleMenuItemClick('acumulated')}
        className={handleBtnStyle('acumulated')}
      >
        {t('acumulado')}
      </Button>
      <ButtonBase
        onClick={handleButtonClick}
        className={handleBtnStyle('period')}
      >
        {selectedPeriod === null
          ? t('periodo')
          : `${selectedPeriod} ${t('dias')}`}
        {anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick(30)}>
          {`30 ${t('dias')}`}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(60)}>
          {`60 ${t('dias')}`}
        </MenuItem>
      </Menu>
    </Box>
  );
}

export { SelectIdeasByPeriod };
