import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { BackNavAdmin } from "./../../components";
import { DialogCreateProject } from '../../components/Portfolio/Dialogs/DialogCreateProject';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { PortfolioHome } from '../PortfolioHome';
import { curationActions } from '../../actions';
import { portfolioDialogProjectActions } from "./../../actions/portfolio/portfolio-dialog-project.actions"

export const PortfolioPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ openNewProject, setOpenNewProject ] = useState(false);
  const { ideas, filters } = useSelector((state) => state.curation);
  const { portfolioStatus } = useSelector(state => state.domains)

  useEffect(() => {
    if (portfolioStatus) {
      const arrStatusCode = portfolioStatus.filter(item => item.order === 1).map(item => item.code);
      dispatch(curationActions.postPortfolioLinkedIdea({ ...filters, page: -1, statusCode: arrStatusCode }));
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioStatus]);

  useEffect(() => {
    if (ideas) {
      dispatch(portfolioDialogProjectActions.getLinkedIdeas(ideas.map(item => ({
        id: item._id,
        label: item.name
      }))))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideas])


  function openProjectDialog() {
    return () => {
      setOpenNewProject(true)
    }
  }

  function closeCreateProject() {
    dispatch(portfolioDialogProjectActions.clearCreateProject());
    setOpenNewProject(false)
  }

  return (
    <>
      <BackNavAdmin title={t('portfolio')}>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
            onClick={openProjectDialog()}
          >
            {t('novo_projeto')}
          </Button>
          { console.log('openNewProject', openNewProject)}
          {openNewProject && <DialogCreateProject open={openNewProject} onClose={closeCreateProject} title={t('novo_projeto')} /> }
        </Box>
      </BackNavAdmin>
      <Container maxWidth="lg" style={{ marginTop: 16 }}>
        <PortfolioHome />
      </Container>
    </>
  )
}
