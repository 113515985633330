import React from 'react';
import { Grid, Card, Divider, makeStyles, withStyles } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { ImagineGrid } from '../../../components';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
})) (MuiSkeleton);

function SkeletonBoxProject() {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <ImagineGrid container spacing={1} cols={1}>
        <ImagineGrid item xs={12}>
          <Skeleton variant="text" width={'100%'} height={60} />
          <Skeleton variant="text" width={'40%'} />
          <Skeleton variant="text" width={'100%'} height={40} />
          <Skeleton variant="text" width={'40%'} />
          <Skeleton variant="text" width={'100%'} height={100} />
          <Skeleton variant="text" width={'40%'} />
          <Skeleton variant="text" width={'100%'} height={60} />
        </ImagineGrid>
      </ImagineGrid>
    </Card>
  );
}

function SkeletonTeamsAndFinancialPlanning() {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Grid container spacing={1} display="flex" alignItems="center">
        <Grid item xs={1}>
          <Skeleton variant="circle" width={40} height={40} />
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant="text" width={'70%'} height={60} style={{ marginLeft: 10 }} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" width={'60%'} height={60} style={{ float: 'right' }} />
        </Grid>
      </Grid>
    </Card>
    
  );
}

function SkeletonTeams() {
  const arrayCells = Array.from(Array(2));

  return (
    <>
      <Grid container spacing={1} display="flex" alignItems="center" style={{ paddingTop: 25 }}>
        <Grid item xs={6}>
          <Skeleton variant="text" width={'50%'} height={40} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="text" width={'50%'} height={40} />
        </Grid>
      </Grid>
      {arrayCells.map((item, key) => (
        <Grid container spacing={1} display="flex" alignItems="center" key={key}>
          <Grid item xs={1}>
            <Skeleton variant="circle" width={25} height={25} />
          </Grid>
          <Grid item xs={5}>
            <Skeleton variant="text" width={'50%'} height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width={'70%'} height={40} />
          </Grid>
        </Grid>
      ))}
    </>
  );
}

function SkeletonFinancialPlanning() {
  const arrayCells = Array.from(Array(3));

  return (
    <>
      <Grid container spacing={1} display="flex" alignItems="center" style={{ paddingTop: 25 }}>
        <Grid item xs={12}>
          <Skeleton variant="text" width={'30%'} height={40} />
        </Grid>
      </Grid>
      {arrayCells.map((item, key) => (
        <Grid container spacing={1} display="flex" alignItems="center" key={key}>
          <Grid item xs={6}>
            <Skeleton variant="text" width={'80%'} height={30} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width={'70%'} height={30} />
          </Grid>
        </Grid>
      ))}
      <Divider style={{ margin: '20px 0' }} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant="text" width={'50%'} height={40} />
          <Skeleton variant="text" width={'50%'} height={30} />
        </Grid>
      </Grid>
    </>
  );
}

export { SkeletonBoxProject, SkeletonTeamsAndFinancialPlanning, SkeletonTeams, SkeletonFinancialPlanning };