import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Chip from '@material-ui/core/Chip';
import { infraCss } from './infraCss';

export const TagInfrastructure = ({ type }) => {
  const classes = infraCss();
  const { t } = useTranslation();
  const [label, setLabel] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    switch (type) {
      case "businessUnit":
        setLabel(t('unidade_de_negocio'))
        setBackgroundColor('#97359E')
        break;

      case "local":
        setLabel(t('local'))
        setBackgroundColor('#448AFF')
        break;

      case "occupationArea":
        setLabel(t('area_de_ocupacao'))
        setBackgroundColor('#0097BA')
        break;

      default:
        setBackgroundColor('primary.main')
        break
    }
  }, [type])

  return (
    <Chip
      style={{ backgroundColor: `${backgroundColor}`}}
      className={classes.tag}
      label={label}
    />
  )
}
