import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { MenuInformation } from '../Shared';

const useStyles = makeStyles((theme) => ({
  boxIcon: {
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing(2)
  },
  title: {
    color: theme.palette.neutrals.low.dark
  },
}));

export function PortifolioTitle({ title, icon, info }) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Avatar className={classes.boxIcon}>{icon}</Avatar>
      <Typography variant="h3" className={classes.title}>{title}</Typography>
      {info && (
        <MenuInformation>
          {info}
        </MenuInformation>
      )}
    </Box>
  );
}