import { domainsConstants } from '../constants';
import update from 'react-addons-update';

export function domains(state = {}, action) {
  switch (action.type) {
    case domainsConstants.DOMAINS_SUCCESS:
      return action.domains;

    case domainsConstants.DOMAINS_FAILURE:
      return {};

    case domainsConstants.SET_LOCALS_IMAGINE:
      const imagineLocals = action.payload.businessUnitLocals.filter(
        (unitLocal) => unitLocal.methodology.code === action.payload.code && unitLocal.businessUnit && unitLocal.local && unitLocal.occupationArea
      );

      imagineLocals.sort((a, b) => a.local?.name?.localeCompare(b.local?.name));

      return update(state, {
        imagineLocals: { $set: imagineLocals },
      });
    default:
      return state;
  }
}
