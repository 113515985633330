import { adminsConstants } from "../constants";
import { adminsService } from "../services/admins.service";
import { alertActions } from "./alert.actions";

export const adminsActions = {
  handleDialogAddContact,
  getAdmins,
  postAdminContact,
  putAdminContact,
  handleEmail,
  handlePhone,
  handleAdminId,
  handleDelete,
  setDialogConfirmation,
  handleAdminName,
  handleImageUrl
};


function getAdmins() {
  return dispatch => {
    dispatch({ type: adminsConstants.REQUEST_GET_ADMINS })
    adminsService.getAdmins().then(({ data }) => {
      dispatch({ type: adminsConstants.SUCCESS_GET_ADMINS, payload: { data } })
    }).catch(err => {
      console.error(err);
      dispatch({ type: adminsConstants.FAILURE_GET_ADMINS })
    })
  }
}

function handleDialogAddContact(open, isEdit) {
  return dispatch => {
    dispatch({ type: adminsConstants.HANDLE_DIALOG_ADMIN, payload: { open, isEdit } })
  }
}


function setDialogConfirmation(open) {
  return dispatch => {
    dispatch({ type: adminsConstants.HANDLE_DIALOG_CONFIRMATION, payload: { open } })
  }
}

function postAdminContact(phone, email, id, successMessage = "", errorMessage = "") {
  return dispatch => {
    adminsService.postAdminContact(phone, email, id).then(() => {
      dispatch({ type: adminsConstants.SUCCESS_POST_ADMIN });
      dispatch(alertActions.success(successMessage));
    }).catch(err => {
      dispatch(alertActions.error(errorMessage));
      console.error(err)
    })
  }
}

function putAdminContact(phone, email, id, successMessage = "", errorMessage = "") {
  return dispatch => {
    adminsService.putAdminContact(phone, email, id).then(() => {
      dispatch({ type: adminsConstants.SUCCESS_PUT_ADMIN });
      dispatch(alertActions.success(successMessage));
    }).catch(err => {
      dispatch(alertActions.error(errorMessage));
      console.error(err)
    })
  }
}

function handleEmail(email) {
  return dispatch => {
    dispatch({ type: adminsConstants.HANDLE_EMAIL, payload: { email } })
  }
}

function handlePhone(phone) {
  return dispatch => {
    dispatch({ type: adminsConstants.HANDLE_PHONE, payload: { phone } })
  }
}

function handleAdminName(name) {
  return dispatch => {
    dispatch({ type: adminsConstants.HANDLE_ADMIN_NAME, payload: { name } })
  }
}

function handleImageUrl(url) {
  return dispatch => {
    dispatch({ type: adminsConstants.HANDLE_IMAGE_URL, payload: { url } })
  }
}


function handleAdminId(adminId) {
  return dispatch => {
    dispatch({ type: adminsConstants.HANDLE_ADMINID, payload: { adminId } })
  }
}

function handleDelete(id, successMessage = "", errorMessage = "") {
  return dispatch => {
    adminsService.deleteAdminContact(id).then(() => {
      dispatch({ type: adminsConstants.HANDLE_DIALOG_CONFIRMATION, payload: { open: false } })
      dispatch(alertActions.success(successMessage));
    }).catch(err => {
      dispatch({ type: adminsConstants.FAILURE_HANDLE_DELETE });
      dispatch(alertActions.error(errorMessage));
    })
  }
}