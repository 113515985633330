import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SaveIcon from '@material-ui/icons/Save';

import { infraCss } from './infraCss';
import { SelectTypeInfra } from './SelectTypeInfra';
import { DialogResponsive } from './DialogResponsive';
import { infraStructureActions } from './../../actions';

export const DialogAddInfra = ({ open, onClose, id, isEdit= false }) => {
  const classes = infraCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { newInfraStructure, selectedTab, filters: { dateSort } } = useSelector((state) => state.infraStructure);
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  function handleChangeType(value) {
    dispatch(infraStructureActions.changeFieldName('type', value));
    setTypeError(false);
  }

  function handleChangeName(e) {
    dispatch(infraStructureActions.changeFieldName('name', e.target.value));
    setNameError(false);
  }

  function handleCloseInfra() {
    dispatch(infraStructureActions.clearInfrastructureDialog());
    onClose();
  }

  function handleSubmit() {
    if (!newInfraStructure.name) {
      setNameError(true);
      return;
    }
    if (!newInfraStructure.type) {
      setTypeError(true);
      return;
    }
    const data = { name: newInfraStructure.name };

    if (isEdit) {
      dispatch(infraStructureActions.updateInfraStructure({ id: id, type: newInfraStructure.type, data }, selectedTab, dateSort, t('a_edicao_dos_dados_foi_salva')));
    } else {
      dispatch(infraStructureActions.createInfraStructure({ type: newInfraStructure.type, data }, selectedTab, dateSort, t('cadastro_de_infraestrutura_realizado')));
    }

    handleCloseInfra();
  }

  return (
    <DialogResponsive open={open} onClose={handleCloseInfra} title={isEdit ? t('edicao_de_infraestrutura') : t('nova_infraestrutura')}>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {isEdit 
                ? t('realize_a_edicao_dos_dados')
                : t('selecione_o_tipo_infraestrutuar_para_add')
              }
            </Typography>
          </Grid>
          <SelectTypeInfra 
            handle={handleChangeType} 
            value={newInfraStructure.type} 
            defaultValue={newInfraStructure.type} 
            error={typeError}
            helperText={typeError && t('tipo_infraestrutura_obrigatorio')}
          />
          <Grid item xs={12}>
            <TextField
              variant='filled'
              style={{ width: '100%' }}
              defaultValue={newInfraStructure.name}
              label={t('nome_da_infraestrutura')}
              placeholder={t('adicione_o_nome_da_infraestrutura')}
              onBlur={handleChangeName}
              error={nameError}
              helperText={nameError && t('nome_da_infraestrutura_obrigatorio')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
              className={classes.button}
            >
              {isEdit ? t('salvar_edicao') : t('salvar_cadastro')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </DialogResponsive>
  )
}