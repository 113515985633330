import { manageUsersConstants } from '../constants'
const initialState = { 
  users: [],
  filters: [],
  loading: false,
  dialogDeleteUser: false,
  businessUnit: [],
  userToEdit: false,
  userToDelete: false,
  paging: {
    page: 1,
    limit: 10,
    skip: 0,
    sort: { created: -1 },
    total: 0,
    search: '',
    statusCode: [],
  },
}

export function manageUsers(state = initialState, action) {
  switch(action.type) {
    
    case manageUsersConstants.GET_USERS_TO_MANAGE_REQUEST:
      return {
        ...state,
        loading: true
      }
    
    case manageUsersConstants.GET_USERS_TO_MANAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        paging: {
          ...state.paging,
          page: action.payload.paging.page,
          skip: action.payload.paging.skip,
          limit: action.payload.paging.limit,
          sort: action.payload.paging.sort,
          total: action.payload.paging.total,
          statusCode: action.payload.statusCode
        }
      }


    case manageUsersConstants.GET_FILTERED_USERS_TO_MANAGE_SUCCESS: 
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        paging: {
          page: action.payload.paging.page,
          limit: action.payload.paging.limit,
          skip: 0,
          sort: { created: -1 },
          total: action.payload.paging.total,
          search: action.payload.paging.search,
          statusCode: action.payload.paging.statusCode,
        }
      }

    case manageUsersConstants.GET_FILTERED_USERS_TO_MANAGE_MOBILE_SUCCESS: 
      return {
        ...state,
        loading: false,
        users: [
          ...state.users,
          ...action.payload.users,
        ],
        paging: {
          page: action.payload.paging.page,
          limit: action.payload.paging.limit,
          skip: 0,
          sort: { created: -1 },
          total: action.payload.paging.total,
          search: action.payload.paging.search,
          statusCode: action.payload.paging.statusCode,
        }
      }

    case manageUsersConstants.GET_USERS_TO_MANAGE_FAILURE:
      return {
        ...state,
        loading: false
      }

    case manageUsersConstants.GET_FILTERS_SUCCESS: 
      return {
        ...state,
        filters: action.payload
      }

    case manageUsersConstants.USER_MANAGE_TOGGLE_FILTER: 
      return {
        ...state,
        paging: {
          ...state.paging,
          statusCode: action.payload
        }
      }

    case manageUsersConstants.GET_BUSINESS_UNIT_SUCCESS: 
      return {
        ...state,
        businessUnit: action.payload,
      }

    case manageUsersConstants.TOGGLE_DIALOG_DELETE_USER: 
      return {
        ...state,
        dialogDeleteUser: !state.dialogDeleteUser,
        userToDelete: action.payload
      }

    case manageUsersConstants.TOGGLE_USER_MANAGE_LOADING: 
      return {
        ...state,
        loading: true
      }
      
    default: 
      return state;
  }
}