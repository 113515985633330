import React, { useState } from 'react'
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  makeStyles
} from "@material-ui/core"
import { MultiAreaCascade, AvatarIconWithText } from "./../Shared";
import { useDispatch, useSelector } from "react-redux";
import DescriptionIcon from '@material-ui/icons/Description';
import { userFunctionActions, multiAreaCascadeActions } from "./../../actions"
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
    borderRadius: 8,
  }
}));

export const FormEditFunction = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    userType,
    selectedUser,
    selectedProfileType,
    selectedBusiness
  } = useSelector(state => state.userFunction)
  const { imagineLocals } = useSelector(state => state.domains)
  const { fields } = useSelector(state => state.multiAreaCascade)
  const [userId, setUserId] = useState(false);
  const { t } = useTranslation();

  function handleChangeProfileType(e) {
    dispatch(userFunctionActions.setUserType(e.target.value))
  }

  function handleAddMoreBusiness(imagineLocals) {
    dispatch(multiAreaCascadeActions.addField(imagineLocals))
  }

  function handleToggleDialogConfirmUser() {
    dispatch(userFunctionActions.toggleDialogConfirmUser())
  }

  function handleToggleDialogCancelUser() {
    dispatch(userFunctionActions.toggleDialogCancelUser())
  }

  return (
    <Paper elevation={0} className={classes.card}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AvatarIconWithText
            variant="h3"
            icon={<DescriptionIcon />} 
            text={t('funcao_do_usuario')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="filled" disabled={selectedUser === false}>
            <InputLabel id="userFunction-label">{t('perfil_do_usuario')}</InputLabel>
            <Select
              labelId="userFunction-label"
              id="userFunction"
              value={selectedProfileType || ""}
              onChange={ handleChangeProfileType }
            >
              { userType.map((item, key) => (
                <MenuItem value={item.type} key={key}>{item.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        { selectedProfileType && selectedProfileType !== 'colaborator' && (
          <Grid item xs={12}>
            <MultiAreaCascade hasDeleteButton={true} loadBusinessUnitLocals={selectedBusiness} />
          </Grid>
        )}
        
        {fields.filter(item => item.selectedBusinessUnit).length > 0 && (
          <Grid item xs={12}>
            <Button
              variant="text"
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={() => handleAddMoreBusiness(imagineLocals)}
            >
              {t('incluir_area')}
            </Button>
          </Grid>
        )}

        { selectedUser &&
          selectedProfileType &&
          (["colaborator", "admin"].includes(selectedProfileType) || (selectedProfileType === "manager" && fields.filter(item => item.selectedBusinessUnit).length > 0)) && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 16 }}
                  onClick={handleToggleDialogCancelUser}
                >
                  {t('cancelar')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleToggleDialogConfirmUser}
                >
                  { userId ? t('salvar') : t('atribuir_funcao') }
                </Button>
              </Box>
            </Grid>
          )}
      </Grid>
    </Paper>
  )
}
