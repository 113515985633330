import React from 'react';
import { Grid, styled } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import iconEmptyStatePortfolio from '../../../assets/imgs/emptystate/illustration_empty-state-portfolio.svg';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '../../Shared';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: 8,
    padding: theme.spacing(2),
    height: 200
}));

export default function EmptyCard() {
    const {t} = useTranslation();
    return (
        <Grid item xs={12}>
            <Card elevation={0}>
                <EmptyState
                    icon={iconEmptyStatePortfolio}
                    description={t('ainda_nao_ha_projetos_nessa_etapa')}
                />
            </Card>
        </Grid>
    );
}