import React, { useEffect, useState } from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

const ImagineSlider = ({ children, settings }) => {
  const [ sliderSettings, setSliderSettings ] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 7000,
  })

  useEffect(() => {
    if (settings) {
      setSliderSettings(settings)
    }
  }, [settings])

  return (
    <Slider { ...sliderSettings }>
      { children }
    </Slider>
  )
}

export default ImagineSlider