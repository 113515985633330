import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const infraStructureService = {
    getAssociation,
    getInfraStructure,
    postNewAssociation,
    searchByName,
    postInfraStructure,
    deleteInfraStructure,
    getById,
    editInfraStructures,
    deleteAssociation,
    updateAssociation,
    getAllAssociations
};

function getAllAssociations(page, limit, dateSort, searchText="") {
    return axios.get(`${config.URL_BASE}/infrastructures/links?page=${page}&limit=${limit}&dateSort=${dateSort}&searchText=${searchText}`, requestHeaders()).then(handleResponse);
};

function getAssociation(id, page, sort={}, limit) {
    return axios.get(`${config.URL_BASE}/business-unit-local/infra/${id}?page=${page}&limit=${limit}&sort=${JSON.stringify(sort)}`, requestHeaders());
};

function getInfraStructure(page, limit, types, dateSort) {
    return axios
        .get(`${config.URL_BASE}/infrastructures?page=${page}&limit=${limit}&types=${JSON.stringify(types)}&dateSort=${dateSort}`, requestHeaders())
        .then(handleResponse);
};

function postNewAssociation(local, occupationArea, businessUnit) {
    return axios.post(`${config.URL_BASE}/business-unit-local`, { occupationArea, local, businessUnit }, requestHeaders()).then(handleResponse);
}


function searchByName(name) {
    return axios.get(`${config.URL_BASE}/infrastructures?searchTerm=${name}`, requestHeaders());
};

function postInfraStructure(type, name) {
    switch (type) {
        case 'local':
            return axios.post(`${config.URL_BASE}/infrastructures/local`, name, requestHeaders());
        case 'occupationArea':
            return axios.post(`${config.URL_BASE}/infrastructures/occupation-area`, name, requestHeaders());
        case 'businessUnit':
            return axios.post(`${config.URL_BASE}/infrastructures/business-unit`, name, requestHeaders());
        default:
            return null
    };
};

function deleteInfraStructure(id, type) {
    switch (type) {
        case 'local':
            return axios.delete(`${config.URL_BASE}/infrastructures/local/${id}`, requestHeaders()).then(handleResponse);
        case 'occupationArea':
            return axios.delete(`${config.URL_BASE}/infrastructures/occupation-area/${id}`, requestHeaders()).then(handleResponse);
        case 'businessUnit':
            return axios.delete(`${config.URL_BASE}/infrastructures/business-unit/${id}`, requestHeaders()).then(handleResponse);
        default:
            return null
    };
};

function getById(type, id) {
    switch (type) {
        case 'local':
            return axios.get(`${config.URL_BASE}/local/${id}`, requestHeaders()).then(handleResponse).then(({ data }) => data);
        case 'occupationArea':
            return axios.get(`${config.URL_BASE}/occupation-area/${id}`, requestHeaders()).then(handleResponse).then(({ data }) => data);
        case 'businessUnit':
            return axios.get(`${config.URL_BASE}/business-unit/${id}`, requestHeaders()).then(handleResponse).then(({ data }) => data);
        default:
            return null
    };
};

function editInfraStructures(id, type, name) {
    switch (type) {
        case 'local':
            return axios.put(`${config.URL_BASE}/infrastructures/local/${id}`, name, requestHeaders());
        case 'occupationArea':
            return axios.put(`${config.URL_BASE}/infrastructures/occupation-area/${id}`, name, requestHeaders());
        case 'businessUnit':
            return axios.put(`${config.URL_BASE}/infrastructures/business-unit/${id}`, name, requestHeaders());
        default:
            return null
    };
};

async function deleteAssociation(idsToRemove) {
  if (idsToRemove.length) {
    const deletePromises = idsToRemove.map(async (id) => {
      await axios.delete(`${config.URL_BASE}/business-unit-local/${id}`, requestHeaders()).then(handleResponse);
    });
    await Promise.all(deletePromises);
  }
  return null;
}

async function updateAssociation(associations) {
  const editAssociations = associations.filter(({ isToEdit, _id }) => isToEdit === true && _id !== "");
  if (editAssociations.length) {
    const updatePromises = editAssociations.map(async ({ businessUnit, occupationArea, local, _id }) => {
      await axios.put(`${config.URL_BASE}/business-unit-local/${_id}`, { businessUnit, occupationArea, local }, requestHeaders()).then(handleResponse);
    });
    await Promise.all(updatePromises);
  }
  return null;
}
