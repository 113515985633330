import React from 'react';
import { Typography, IconButton, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alingItems: 'center',
    padding: theme.spacing(1.5),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
    paddingTop: theme.spacing(.7),
    paddingLeft: theme.spacing(2)
  },
  grow: {
    flexGrow: 1
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>ups</Typography>
      <div className={classes.grow}></div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function IdeaUpTitle(props) {
  return <DialogTitle {...props} />;
}

export { IdeaUpTitle };