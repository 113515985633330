import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  }
}))(MuiSkeleton);

export function SkeletonInfoUniqueDashboard() {
  return(
    <>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%" height="50%">
        <Skeleton variant="text" width={'70%'} height={20} />
        <Skeleton variant="text" width={'70%'} height={58} />
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" width="100%" height="50%">
        <Skeleton variant="text" width={'70%'} height={20} />
        <Skeleton variant="text" width={'70%'} height={58} />
      </Box>
    </>
  );
} 