import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from '@material-ui/styles/makeStyles';
import { SkeletonTeams } from './SkeletonGeneralData';
import TeamMember from './TeamMember';

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    color: theme.palette.neutrals.low.dark,
  },
  spacingTop: {
    paddingTop: theme.spacing(4)
  }
}))

export const TeamList = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const { teams } = useSelector(state => state.portfolioDetails);

  return (
    <Grid item xs={12} className={classes.spacingTop}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="subtitle2" className={classes.tableTitle}>{t('participantes')}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2" className={classes.tableTitle}>{t('tarefas')}</Typography>
        </Grid>
        { teams.loading ? (
          <Grid item xs={12}><SkeletonTeams /></Grid>
        ) : (
          <Grid item xs={12}>
            {teams.details.map((member, index) => <TeamMember key={index} member={member} projectId={id} />)}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
