import { styled } from '@material-ui/core/styles';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

export const ButtonBase = styled(MuiButtonBase)(({ theme }) => ({
  height: 51,
  borderRadius: 4,
  color: theme.palette.neutrals.low.dark,
  backgroundColor: theme.palette.neutrals.high.light,
  transition: 'all 0.5s ease-in-out', 
  '&:hover': {
    backgroundColor: theme.palette.neutrals.high.dark
  },
}));

export const FilterTitle = styled(Typography)(({ theme }) => ({
	padding: '16px 24px', 
	color: theme.palette.neutrals.low.light 
}));