import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux"
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { DialogPortifolio } from './../DialogPortifolio';
import { portfolioDialogTaskActions } from "./../../../actions/portfolio/portfolio-dialog-task.actions"

const useStyles = makeStyles((theme) => ({
  titleDelete: {
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: 15,
    color: theme.palette.neutrals.low.dark
  },
  descriptionDelete: {
    fontSize: 16,
    color: theme.palette.neutrals.low.medium
  },
  btnFloat: {
    float: 'right',
  },
  dialogMaxWidth: {
    maxWidth: 576
  }
}));

export function DialogDeleteTask({ open, onClose, task }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loadingDelete, successDelete } = useSelector(state => state.portfolioDialogTask);
  const { taskTabChosed, tasks } = useSelector(state => state.portfolioDetails);
  const { projectTasksStatus } = useSelector(state => state.domains)
  const { id: projectId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (successDelete) {
      handleClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDelete]) 

  function handleClose() {
    dispatch(portfolioDialogTaskActions.clearFlags())
    onClose()
  }

  function handleDeleteTask() {
    const taskPayload = {
      page: tasks.items?.paging?.page,
      limit: tasks.items?.paging?.limit,
      taskStatusCode: taskTabChosed,
      projectId
    }

    dispatch(portfolioDialogTaskActions.deleteTask(task._id, t('tarefa_excluida'), taskPayload, projectTasksStatus));
  }

  return (
    <DialogPortifolio
      title={t('excluir_tarefa')}
      open={open}
      onClose={onClose}
      styles={{ paperWidthSm: classes.dialogMaxWidth }}
      content={
        <Grid item xs={12}>
          <Typography className={classes.titleDelete}>
            {t('deseja_excluir_a_tarefa')}{' '}{task.name}{' '}{t('do_projeto')}?
          </Typography>
          <Typography className={classes.descriptionDelete}>
            {t('as_informacoes_sobre_a_tarefa_e_subtarefas_vinculadas_serao_excluidas_permanentemente')}
          </Typography>
        </Grid>
      }
      action={
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={handleClose}
              disabled={loadingDelete}
            >
              {t('voltar')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btnFloat}
              startIcon={<DeleteIcon />}
              onClick={handleDeleteTask}
              disabled={loadingDelete}
            >
              {t('excluir_tarefa')}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}