import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { GeneralEvaluationRow } from './GeneralEvaluationRow';
import { tableCss } from '../Tables/TableCss';

import { generalEvaluationFunctionsActions } from './../../actions';

export function GeneralEvaluationTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const linhasTable = { text: t('linhas_por_pagina') };
  const tableClasses = tableCss(linhasTable);
  const { group, paging } = useSelector(state => state.generalEvaluation);

  const columns = [
    { id: 'groupName', label: t('nome') },
    { id: 'groupStatus', label: t('status') },
  ];

  function handleChangePage(_event, page) {
    let newPage = page + 1;
    dispatch(generalEvaluationFunctionsActions.setTableFilter("page", newPage));
  }

  async function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(generalEvaluationFunctionsActions.setTableFilter("limit", limit));
  }

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer>
        <Table stickyHeader  classes={{ root: tableClasses.table }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  classes={{ root: tableClasses.tableCellHeader }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {group.map((item) => (
              <GeneralEvaluationRow key={item._id} row={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={paging?.total || 0}
        rowsPerPage={paging.limit || 0}
        page={paging.page - 1 || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
      />
    </Paper>
  );
}