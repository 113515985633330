import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import { DialogTitle, DialogContent, DialogActions, ButtonWithFill } from './styles';
import { ReactComponent as InventoryIcon } from '../../../assets/imgs/inventory-icon.svg';

export function DialogConcludeEvaluation({ open, onClose, handleSubmit }) {
  const { t } = useTranslation();
  
  function handleSubmitAndCloseDialog() {
    handleSubmit();
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {t('avaliar_ideia').toLowerCase()}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom variant="h3">
          {t('deseja_concluir_a_avaliacao_dessa_ideia')}
        </Typography>
        <Typography variant="body1">
          {t('as_informacoes_de_avaliacao_nao_poderao_ser_editadas_posteriormente')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          {t('voltar')}
        </Button>
        <ButtonWithFill
          color="primary"
          variant="contained"
          startIcon={<InventoryIcon />}
          onClick={handleSubmitAndCloseDialog}
        >
          {t('avaliar_ideia')}
        </ButtonWithFill>
      </DialogActions>
    </Dialog>
  );
}