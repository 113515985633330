import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import SaveIcon from '@material-ui/icons/Save';

import { Card, Header, AvatarIcon, Actions, Button, Form, ErrorMessage, VisibilityIcon, VisibilityOffIcon } from './userStyles';
import { DialogForgotPassword } from './';
import { userActions } from '../../actions';
import { validation } from '../../helpers';
import { LoadingButton } from '../Shared';

export function UserSecurity() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [openDialogForgotPassword, setOpenDialogForgotPassword] = useState(false);
  const { handleSubmit, register, control, reset, errors } = useForm({ 
    mode: 'onBlur',
    defaultValues: {
      password: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  function handleOpenDialogForgotPassword() {
    setOpenDialogForgotPassword(!openDialogForgotPassword);
  }
  
  function handleNewPasswordSubmit(data) {
    if (data.password) {
      user.password = data.password;
      user.newPassword = data.newPassword;
      user.confirmPassword = data.confirmPassword;
    }

    dispatch(userActions.updateUser(user, t('alteracao_de_senha_salva')));
    reset();
  }

  return (<>
    <DialogForgotPassword
      open={openDialogForgotPassword}
      onClose={handleOpenDialogForgotPassword}
    />

    <Card elevation={0}>
      <Form onSubmit={handleSubmit(handleNewPasswordSubmit)}>
        <Grid container spacing={2}>
          <Header item xs={12}>
            <AvatarIcon>
              <LockIcon />
            </AvatarIcon>
            <Typography variant="h3">{t('alterar_senha')}</Typography>
          </Header>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('crie_uma_senha_nova_com_pelo_menos_8_caracteres_e_1_caractere_especial')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type={viewPassword ? "text" : "password"}
              variant="filled"
              name="password"
              control={control}
              label={t('senha_atual')}
              inputRef={register({
                validate: {
                  goodPassword: (value) =>
                    validation.goodPassword(value) ||
                    t('a_senha_deve_conter_no_minimo_8_caracteres_sendo_ao_menos_1_caractere_especial'),
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setViewPassword(!viewPassword)} edge="end">
                      {viewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage>{errors.password && errors.password.message}</ErrorMessage>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type={viewNewPassword ? "text" : "password"}
              variant="filled"
              name="newPassword"
              control={control}
              label={t('nova_senha')}
              inputRef={register({
                validate: {
                  goodPassword: (value) =>
                    validation.goodPassword(value) ||
                    t('a_senha_deve_conter_no_minimo_8_caracteres_sendo_ao_menos_1_caractere_especial'),
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setViewNewPassword(!viewNewPassword)} edge="end">
                      {viewNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage>{errors.newPassword && errors.newPassword.message}</ErrorMessage>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type={viewConfirmPassword ? "text" : "password"}
              variant="filled"
              name="confirmPassword"
              control={control}
              label={t('confirmar_nova_senha')}
              inputRef={register({
                validate: {
                  goodPassword: (value) =>
                    validation.goodPassword(value) ||
                    t('a_senha_deve_conter_no_minimo_8_caracteres_sendo_ao_menos_1_caractere_especial'),
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setViewConfirmPassword(!viewConfirmPassword)} edge="end">
                      {viewConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</ErrorMessage>
          </Grid>
          <Actions item xs={12}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleOpenDialogForgotPassword}
            >
              {t('esqueci_a_senha')}
            </Button>
            {user.loading ? (
              <LoadingButton />
            ) : (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {t('salvar_nova_senha')}
              </Button>
            )}
          </Actions>
        </Grid>
      </Form>
    </Card>
  </>);
}