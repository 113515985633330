import React from 'react';
import { makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';
import { Search } from '../Shared';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  filter: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ChallengeFilters({ queryFilter, classification, changeStatus }) {
  const classes = useStyles();
  const { t } = useTranslation();

  function query(q) {
    queryFilter(q);
  }

  function handleChangeStatus(event) {
    changeStatus(event.target.value);
  }

  return (
    <div className={classes.filter}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: 300,
        }}
      >
        <div>{t('visualizar')}: </div>
        <div style={{ marginLeft: 10, width: '100%' }}>
          <FormControl
            variant="filled"
            style={{ width: '100%', maxWidth: 220 }}
          >
            <Select
              labelId="select-status"
              id="select-status"
              fullWidth
              value={classification}
              onChange={handleChangeStatus}
              disableUnderline
            >
              <MenuItem value={0}>{t('todos')}</MenuItem>
              {/* <MenuItem value={1}>Submetido</MenuItem> */}
              {/* <MenuItem value={2}>Reprovado</MenuItem> */}
              <MenuItem value={3}>{t('programado')}</MenuItem>
              <MenuItem value={4}>{t('em_andamento')}</MenuItem>
              <MenuItem value={5}>{t('encerrados')}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <Search
        query={query}
        placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
      />
    </div>
  );
}

export { ChallengeFilters };
