import React from 'react';

import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import { StatusIdea } from '../../components';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import { tableCss } from '../Tables/TableCss';
import { dateFormat, truncate, history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { curationActions } from '../../actions';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});


function PortifolioRowTableColapse() {
  const classes = useRowStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const linhasTable = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(linhasTable);
  return (
    <>

      <TableRow
        hover
        // key={idea._id}
        classes={{ root: tableClasses.tableRow }}
      >
        <TableCell
          classes={{ root: tableClasses.tableCellRadiusLeft }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>

        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>
          Desenvolver plano de ação do projeto
        </TableCell>
        <TableCell
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          Kleber Santos
        </TableCell>
        <TableCell
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          01/06/2022
        </TableCell>
        <TableCell
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          a fazer
        </TableCell>


      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit  >
            <Table size="small" aria-label="purchases">
              <TableBody>

                <TableRow className={classes.root} classes={{ root: tableClasses.tableRow }}  >
                  <TableCell>


                  </TableCell>
                  <TableCell component="th" scope="row">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                    >
                      <SubdirectoryArrowRightIcon />
                    </IconButton>
                    Realizar reunião de kick-off
                  </TableCell>
                  <TableCell align="right">Kleber Santos</TableCell>
                  <TableCell align="right">01/06/2022</TableCell>
                  <TableCell align="right">a fazer</TableCell>
                </TableRow>
              </TableBody>

            </Table>
          </Collapse>
        </TableCell>
      </TableRow></>
  );
}

export { PortifolioRowTableColapse };