import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { userFunctionActions } from '../../../actions';
import { DialogTitle, Title, DialogContent, DialogActions, InfoIcon, InfoDescription } from './dialogStyles';
import { Form, VisibilityIcon, VisibilityOffIcon } from '../userStyles';

export function DialogResetPassword({ open, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const { email } = useSelector(state => state.user);
  const { handleSubmit, register, control, reset } = useForm({ 
    mode: 'onBlur',
    defaultValues: {
      code: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  function handleResendEmail() {
    dispatch(userFunctionActions.sendRequestNewPassword(email, t('email_enviado'), t('email_invalido')));
    onClose();
  }
  
  function handleSavePassword({ code, password, confirmPassword }) {
    if (code, password, confirmPassword) {
      dispatch(userFunctionActions.confirmPasswordRecover(code, password, confirmPassword, t('alteracao_de_senha_salva')));
    }
    onClose();
    reset();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Title variant="h3">{t('redefina_a_senha')}</Title>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Form onSubmit={handleSubmit(handleSavePassword)}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('insira_o_codigo_de_acesso_enviado_por_email...')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                id="code"
                name="code"
                label={t('codigo')}
                control={control}
                inputRef={register()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                id="password"
                name="password"
                label={t('nova_senha')}
                control={control}
                type={viewNewPassword ? "text" : "password"}
                inputRef={register()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setViewNewPassword(!viewNewPassword)} edge="end">
                        {viewNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                id="confirmPassword"
                name="confirmPassword"
                label={t('confirmar_nova_senha')}
                control={control}
                type={viewConfirmPassword ? "text" : "password"}
                inputRef={register()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setViewConfirmPassword(!viewConfirmPassword)} edge="end">
                        {viewConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <InfoIcon fontSize="small" />
                <InfoDescription variant="caption">
                  {t('caso_nao_encontre_o_email_na_caixa_de_entrada_verifique_sua_pasta_de_spam')}
                </InfoDescription>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <Button
              color="primary"
              variant="outlined"
              onClick={handleResendEmail}
            >
              {t('reenviar_email')}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => {}}
            >
              {t('salvar_senha')}
            </Button>
          </Box>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
