import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const helpCenterService = {
  getWelcomeVideo,
  getAdministrators,
  getTutorials,
  deleteTutorial
};

function getWelcomeVideo() {
  return axios
    .get(
      `${config.URL_BASE
      }/help/first-access/video`,
      requestHeaders()
    )
    .then(handleResponse);
}

function getAdministrators(page, sort) {
  if (!page) {
    page = 0
  }
  if (!sort) {
    sort = 0
  }
  return axios
    .get(
      `${config.URL_BASE
      }/help/administrators/?page=${page}&sort=${0}&limit=1000`,
      requestHeaders()
    )
    .then(handleResponse);
}

function getTutorials(page, sort) {
  if (!page) {
    page = 0
  }
  if (!sort) {
    sort = 0
  }
  return axios
    .get(
      `${config.URL_BASE
      }/help/tutorials/?page=${page}&sort=${0}&limit=1000`,
      requestHeaders()
    )
    .then(handleResponse);
}

function deleteTutorial(id) {
  return axios
      .delete(
        `${config.URL_BASE
        }/help/tutorials/${id}`,
        requestHeaders()
      )
      .then(handleResponse);
}
