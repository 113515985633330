import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, alpha } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import DeleteIcon from '@material-ui/icons/Delete';
import { ideaUpdateActions } from '../../actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    width: '100%',
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '80%',
  },
  overlay: {
    position: 'absolute',
    backgroundColor: alpha('#000', 0.4),
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: 99999,
    '&:hover': {
      opacity: 1,
    },
  },
  delete: {
    position: 'absolute',
    right: 5,
    top: 5,
    padding: 0,
    zIndex: 99999,
  },
  buttonCarousel: {
    width: 48,
    height: 48,
    backgroundColor: '#e0e0e0',
    position: 'absolute',
    top: 'calc(50% - 24px)',
    background: 'none',
    border: 'none',

    color: alpha('#000', 0.87),
    outline: 0,
    borderRadius: '50%',
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)`,
    '&:hover': {
      backgroundColor: '#d5d5d5',
    },
    '&:disabled': {
      display: 'none',
    },
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

function EditImageList({ files }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function removePhoto(id) {
    dispatch(ideaUpdateActions.removeImage(id));
  }

  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={50}
        totalSlides={files.length}
        style={{ position: 'relative' }}
      >
        <Slider>
          {files.map((file, index) => (
            <Slide key={file.id} index={index} style={{ position: 'relative' }}>
              <IconButton
                className={classes.deleteButton}
                onClick={() => removePhoto(file.id ? file.id : file._id)}
              >
                <DeleteIcon />
              </IconButton>
              <div className={classes.imageContainer}>
                <img
                  src={file.preview || file.url}
                  alt={file.name}
                  className={classes.image}
                />
              </div>
            </Slide>
          ))}
        </Slider>
        {files.length > 1 && (
          <div>
            <ButtonBack className={classes.buttonCarousel} style={{ left: 20 }}>
              <ArrowBackIcon />
            </ButtonBack>
            <ButtonNext
              className={classes.buttonCarousel}
              style={{ right: 20 }}
            >
              <ArrowForwardIcon />
            </ButtonNext>
          </div>
        )}
      </CarouselProvider>
    </div>
  );
}

export { EditImageList };
