export const manageUsersConstants = {
  USER_MANAGE_TOGGLE_FILTER: "USER_MANAGE_TOGGLE_FILTER",
  GET_USERS_TO_MANAGE_REQUEST: "GET_USERS_TO_MANAGE_REQUEST",
  GET_USERS_TO_MANAGE_SUCCESS: "GET_USERS_TO_MANAGE_SUCCESS",
  GET_USERS_TO_MANAGE_FAILURE: "GET_USERS_TO_MANAGE_FAILURE",
  GET_FILTERED_USERS_TO_MANAGE_REQUEST: "GET_FILTERED_USERS_TO_MANAGE_REQUEST",
  GET_FILTERED_USERS_TO_MANAGE_SUCCESS: "GET_FILTERED_USERS_TO_MANAGE_SUCCESS",
  GET_FILTERED_USERS_TO_MANAGE_FAILURE: "GET_FILTERED_USERS_TO_MANAGE_FAILURE",
  GET_FILTERS_SUCCESS: "GET_FILTERS_SUCCESS",
  GET_BUSINESS_UNIT_SUCCESS: "GET_BUSINESS_UNIT_SUCCESS",
  TOGGLE_DIALOG_DELETE_USER: "TOGGLE_DIALOG_DELETE_USER",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  TOGGLE_USER_MANAGE_LOADING: "TOGGLE_USER_MANAGE_LOADING",
  GET_FILTERED_USERS_TO_MANAGE_MOBILE_SUCCESS: "GET_FILTERED_USERS_TO_MANAGE_MOBILE_SUCCESS"
}