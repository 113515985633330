import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { infraCss } from '../../components/Infrastructure/infraCss';
import { DialogResponsive } from '../../components/Infrastructure/DialogResponsive';
import { infraStructureActions } from './../../actions';
import { SkeletonInfraDialogAssociation } from '../../components/Skeletons/SkeletonInfraDialogAssociation';
import { useEffect } from 'react';
import { infraStructureService } from '../../services';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { BackNavAdmin, SkeletonTable } from '../../components';
import { Save } from '@material-ui/icons';
import { tableCss } from '../../components/Tables/TableCss';
import { useParams } from 'react-router-dom';
import { DialogAssociationInfra } from '../../components/Infrastructure/DialogAssociationInfra';
import { useState } from 'react';
import { DialogConfirmDeleteTask } from '../../components/Portfolio/Dialogs/DialogConfirmDeleteTask';
import { DialogConfirmDeleteAssociation } from '../../components/Portfolio/Dialogs/DialogConfirmDeleteAssociation';

const filterOptions = createFilterOptions({ limit: 100 });

export const InfraAssociationsPage = () => {
  const { id } = useParams();
  const classes = infraCss();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { newAssociations, loadingAssociations, paging } = useSelector(state => state.infraStructure);
  const { locals, occupationAreas } = useSelector(state => state.domains);
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDialogDelete, setOpenedDialogDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const styleProps = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(styleProps);


useEffect(() => {
    dispatch(infraStructureActions.getAssociation(id, 
        paging.page,
        paging.sort,
        paging.limit,));
}, []);

  function handleChangeAssociation(data, index) {
    const { name, _id } = data.option;
    dispatch(infraStructureActions.changeFieldAssociation('local', { name: name, _id: _id }, index));
  }

  function handleChangeAreaAssociation(data, index) {
    const { name, _id } = data.option;
    dispatch(infraStructureActions.changeFieldAssociation('occupationArea', { name: name, _id: _id }, index));
  }

  function handleRemoveAssociation(associationId) {
    dispatch(infraStructureActions.deleteAssociation([associationId], id, t('infraestrutura_excluida')));

    setOpenedDialogDelete(false);
  }

function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(infraStructureActions.getAssociation(id, 
      // ideaActions.getIdeasUser(
        newPage,
        paging.sort,
      //   paging.search,
        paging.limit,));
      //   paging.statusCode
      // )
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(infraStructureActions.getAssociation(id, 
        paging.page,
      //   1,
        paging.sort,
      //   paging.search,
      //   limit,
      //   paging.statusCode
      // )
        limit));
  }

  return (
<>
<DialogAssociationInfra open={openedDialog} onClose={() => setOpenedDialog(false)} />
      <DialogConfirmDeleteAssociation open={openedDialogDelete} onClose={() => setOpenedDialogDelete(false)} onDelete={() => handleRemoveAssociation(idToDelete)} />
      <BackNavAdmin title={t('editar_vinculos')}><Button startIcon={<Save />} color="primary" variant="contained" onClick={() => dispatch(infraStructureActions.putAssociation(newAssociations, id, t('vinculos_foram_salvos')))}>{t('salvar_vinculos')}</Button></BackNavAdmin>
<Container>
{ loadingAssociations ? <SkeletonTable /> : <>
<TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('local')}</TableCell>
                    <TableCell>{t('area_de_atuacao')}</TableCell>
                    <TableCell style={{ display: 'flex', justifyContent: 'center' }}><Button onClick={() => setOpenedDialog(true)} startIcon={<AddCircleIcon />} color="primary" variant="text">{t('vincular_nova_infraestrutura')}</Button></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newAssociations?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Autocomplete
                          loadingText={t('carregando...')}
                          noOptionsText={t('sem_opcoes')}
                          filterOptions={filterOptions}
                          options={locals}
                          loading={locals?.length === 0}
                          getOptionLabel={(input) => input?.name}
                          getOptionSelected={(option, value) => option?.name === value?.name}
                          defaultValue={{ name: item?.local?.name }}
                          onChange={(_e, _items, _options, details) => handleChangeAssociation(details, index)}
                          renderInput={(params) => <TextField {...params} variant="filled" />}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          loadingText={t('carregando...')}
                          noOptionsText={t('sem_opcoes')}
                          filterOptions={filterOptions}
                          options={occupationAreas}
                          loading={occupationAreas?.length === 0}
                          getOptionLabel={(input) => input?.name}
                          getOptionSelected={(option, value) => option?.name === value?.name}
                          defaultValue={{ name: item?.occupationArea?.name }}
                          onChange={(_e, _items, _options, details) => handleChangeAreaAssociation(details, index)}
                          renderInput={(params) => <TextField {...params} variant="filled" />}
                        />
                      </TableCell>
                      <TableCell className={classes.deleteAssociation}>
                      <IconButton onClick={() => {
                        setOpenedDialogDelete(true);
                        setIdToDelete(item._id);
                      }}>
                          <Tooltip title={t('excluir')} aria-label="excluir">
                            <DeleteIcon />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
</TableContainer>

        <TablePagination
          component="div"
          classes={{
            input: tableClasses.paginationInput,
            spacer: tableClasses.paginationSpacer,
            caption: tableClasses.paginationCaption,
            toolbar: tableClasses.paginationToolbar,
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          count={paging.total}
          rowsPerPage={paging.limit}
          page={paging.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('exibindo')}
          style={{zIndex: 10, position: 'relative'}}
        />
</>}
</Container>
</>
           
  );
};
