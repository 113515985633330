import React from 'react';
import {
  Card,
  CardContent,
  Switch,
  Typography,
  makeStyles,
  TextField,
  Checkbox,
  Box,
} from '@material-ui/core';

import { externalContentActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  input: {
    padding: '27px 12px 10px'
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4)
    }
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(3)
    }
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(5, 0, 2)
  },
  stateTitle: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginRight: 16,
  },
  state: {
    fontSize: 14.22,
    color: theme.palette.neutrals.low.main,
    marginLeft: 8,
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -8,
    marginTop: 8,
  },
  checkboxText: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 18,
    fontWeight: 600,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  smallDescription: {
    color: theme.palette.neutrals.low.medium,
    fontSize: 13,
    marginLeft: 8,
  }
}));

function ExternalContentForm() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { contentFields, loading } = useSelector((state) => state.externalContent);

  function onChangeText({ target: { value, name } }) {
    dispatch(externalContentActions.setNewContent({ name, value }));
  }

  function onChangeSwitch({ target: { checked, name } }) {
    dispatch(externalContentActions.setNewContentSwitch({ name, checked }));
  }

  return (
    <Card>
      <CardContent className={classes.content}>
        <Box>
          {!loading ? <TextField
            name="title"
            id="title"
            label={t('titulo')}
            type="text"
            variant="filled"
            margin="normal"
            fullWidth
            // inputRef={register({
            //   required: t('titulo_do_conteudo_e_obrigatorio'),
            // })}
            // error={!!errors.title}
            // helperText={errors.title && errors.title.message}
            className={classes.marginBetweenInputs}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            InputProps={{
              classes: {
                input: classes.input,
              }
            }}
            defaultValue={contentFields.title}
            onBlur={onChangeText}
          /> : <Skeleton variant='retangular' style={{ height: 50, marginTop: 20 }} />}

          {!loading ? <TextField
            name="link"
            id="link"
            label={t('link_de_acesso')}
            type="text"
            variant="filled"
            margin="normal"
            fullWidth
            // inputRef={register({
            //   // required: t('o_link_de_acesso_do_conteudo_e_obrigatorio'),
            //   pattern: {
            //     value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
            //     message: 'O link digitado é inválido'
            //   }
            // })}
            // error={!!errors.link}
            // helperText={errors.link && errors.link.message}
            className={classes.marginBetweenInputs}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            InputProps={{
              classes: {
                input: classes.input,
              }
            }}
            defaultValue={contentFields.link}
            onBlur={onChangeText}
          /> : <Skeleton variant='retangular' style={{ height: 50, marginTop: 20 }} />
          }
          {!loading ? <Box className={clsx(classes.about, classes.marginBetweenInputs)}>
            <TextField
              // error={!!errors.description}
              id="outlined-multiline-static"
              name="description"
              label={t('area_da_materia')}
              multiline
              fullWidth
              rows={8}
              variant="filled"
              // inputRef={register({
              //   required: t('area_da_materia_e_obrigatorio'),
              // })}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
              defaultValue={contentFields.description}
              onBlur={onChangeText}
            />
            {/* <p className={classes.error}>
              {errors.description && errors.description.message}
              {errors.description &&
                errors.description.type === 'minLength' &&
                t('a_quantidade_minima_de_caracteres_da_descricao_e_100')}
            </p> */}
          </Box> : <Skeleton variant='retangular' style={{ height: 170, marginTop: 20 }} />}

          {!loading ? <Box className={classes.stateContainer}>
            <Typography className={classes.stateTitle}>
              {t('estado')}
            </Typography>

            <Switch
              checked={contentFields.status}
              onChange={onChangeSwitch}
              name="status"
              color="primary"
              size="small"
            />

            <Typography className={classes.state}>
              {contentFields.status ? t('ativo') : t('inativo')}
            </Typography>
          </Box> : <Skeleton variant='retangular' style={{ height: 50, marginTop: 20, width: '40%' }} />}

          {!loading ? <Box className={classes.checkbox}>
            <Checkbox
              name="highlight"
              onChange={onChangeSwitch}
              checked={contentFields.highlight}
              color="primary"
            />

            <Typography className={classes.checkboxText}>
              {t('exibir_conteudo_no_feed')}
            </Typography>
          </Box> : <Skeleton variant='retangular' style={{ height: 50, marginTop: 20, width: '50%' }} />
          }
          {!loading ? <Box className={classes.checkbox}>
            <Checkbox
              onChange={onChangeSwitch}
              checked={contentFields.fixed}
              name="fixed"
              color="primary"
            />
            <Typography className={classes.checkboxText}>
              {t('destacar_conteudo_no_feed')}
            </Typography>
          </Box> : <Skeleton variant='retangular' style={{ height: 50, marginTop: 20, width: '50%' }} />}
          <Box className={classes.info}>
            <InfoIcon size={14} color="primary" />
            <Typography className={classes.smallDescription}>
              {t('so_e_possivel_destacar_um_conteudo_principal')} {' '}
              {t('se_ja_existir_outro_conteudo_em_destaque_ele_sera_substituido')}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export { ExternalContentForm };
