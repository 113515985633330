import { faqConstants } from '../constants';
import { faqService } from '../services';
import { alertActions } from './alert.actions';

export const faqActions = {
  getAllQuestions,
  getQuestionById,
  deleteQuestion,
  setPage,
  setLimit,
  createQuestion,
  addNewQuestion,
  setQuestion,
  setAnswer,
  clearFaqEdit
};

function setQuestion(index, value) {
  return (dispatch) => {
    dispatch({ type: faqConstants.SET_QUESTION, payload: { index, value } });
  };
}

function setAnswer(index, value) {
  return (dispatch) => {
    dispatch({ type: faqConstants.SET_ANSWER, payload: { index, value } });
  };
}

function getAllQuestions(page, sort, limit) {
  return (dispatch) => {
    dispatch({ type: faqConstants.CLEAR_FAQ_EDIT });
    dispatch({ type: faqConstants.GET_ALL_QUESTIONS_REQUEST });
    faqService.getAllQuestions(page, sort, limit).then((questions) => {
      dispatch({
        type: faqConstants.GET_ALL_QUESTIONS_SUCCESS,
        payload: {
          questions,
          page,
        }
      })
    }).catch((error) => {
      dispatch({ type: faqConstants.GET_ALL_QUESTIONS_FAILURE });
    })
  };
}

function getQuestionById(id) {
  return (dispatch) => {
    dispatch({ type: faqConstants.GET_QUESTION_BY_ID_REQUEST });
    faqService.getQuestionById(id).then((questions) => {
      dispatch({
        type: faqConstants.GET_QUESTION_BY_ID_SUCCESS,
        payload: questions.data
      });
    }).catch((error) => {
      dispatch({ type: faqConstants.GET_QUESTION_BY_ID_FAILURE });
    })
  };
}

function deleteQuestion(id) {
  return (dispatch) => {
    faqService.deleteQuestion(id).then(() => {
      dispatch({ type: faqConstants.DELETE_QUESTION_SUCCESS });
    })
      .catch((error) => {
        dispatch({ type: faqConstants.DELETE_QUESTION_FAILURE });
      });
  };
}

function createQuestion(questions, message = '') {
  return (dispatch) => {
    faqService.createQuestion(questions).then(() => {
      dispatch({ type: faqConstants.CREATE_QUESTION_SUCCESS });
      dispatch(alertActions.success(message));
    })
      .catch((error) => {
        console.error(error);
        dispatch({ type: faqConstants.CREATE_QUESTION_FAILURE });
        dispatch(alertActions.error('tente novamente'));
      });
  };
}

function setPage(page) {
  const payload = { page };

  return { type: faqConstants.SET_PAGE, payload };
}

function setLimit(limit) {
  const payload = { limit };

  return { type: faqConstants.SET_LIMIT, payload };
}

function addNewQuestion() {
  return dispatch => {
    dispatch({ type: faqConstants.ADD_NEW_FIELD });
  };
}

function clearFaqEdit() {
  return dispatch => {
    dispatch({ type: faqConstants.CLEAR_FAQ_EDIT });
  };
}