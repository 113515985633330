import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  IconButton,
  Typography,
  Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(2, 4),
  },
  title: {
    color: theme.palette.neutrals.low.main,
  },
  content: {
    color: theme.palette.neutrals.low.medium,
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'end',
    padding: theme.spacing(1, 4, 3),
  },
}));

export function DialogDelete({ onAccept, onClose, open }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.dialogTitle}>
        <Typography variant="h3" style={{ fontWeight: 'bold' }}>{t('excluir_conteudo')}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              {t('deseja_excluir_o_conteudo')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.content}>
              {t('as_informacoes_sobre_o_conteudo_serao_excluidas_permanentemente')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 16 }}
          onClick={() => onClose()}
        >
          {t('voltar')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={() => onAccept()}
        >
          {t('excluir_conteudo')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}