import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Card, Grid } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4)
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
}))(MuiSkeleton);

export function SkeletonUserFunction() {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Skeleton width={40} height={40} variant="circle" />
              <Skeleton width={200} height={25} variant="text" style={{ marginLeft: 20 }} />
            </Box>
            <Skeleton width={107} height={56} variant="text" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Skeleton width={200} height={25} variant="text" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {[1,2,3,4,5,6].map((item, index) => (
              <Grid item xs={4} key={index}>
                <Skeleton width={'50%'} height={22} variant="text" />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}