import { styled } from '@material-ui/core/styles';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import MuiCard from '@material-ui/core/Card';
import MuiGrid from '@material-ui/core/Grid';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiSlider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiButton from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

export const Tab = styled(MuiTab)({
  '& .MuiTab-wrapper': {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  }
});

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderRadius: 8, 
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.palette.neutrals.high.main,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,

  '& .Mui-selected': {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  }
}));

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
}));

export const GridFlex = styled(MuiGrid)({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

export const GridIndicators = styled(MuiGrid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const AvatarIcon = styled(MuiAvatar)(({ theme }) => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fill: theme.palette.primary.main,
}));

export const Slider = styled(MuiSlider)({
  width: '90%',
  height: 6,

  '& .MuiSlider-track': {
    height: 6,
  },
  '& .MuiSlider-rail': {
    height: 6,
  },
});

export const PositionOfEvaluators = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.low.light,
  marginBottom: 12,
}));

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 3, 1),
  borderBottom: 'none'
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(1, 3, 3),
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8
}));

export const ButtonWithFill = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.neutrals.high.main,
  fill: theme.palette.neutrals.high.main,
}));

export const EvaluationIcon = styled(MuiAvatar)(({ theme }) => ({
  width: 24,
  height: 24,
  background: theme.palette.neutrals.high.dark,
  color: theme.palette.neutrals.low.light,
  fill: theme.palette.neutrals.low.light,
  marginRight: theme.spacing(1),
}));

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: 10,
  borderRadius: 5,
  margin: theme.spacing(0, 1),
  backgroundColor: theme.palette.neutrals.high.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const CardEmptyState = styled(MuiGrid)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  background: theme.palette.neutrals.high.main,
  padding: 32
}));