import { languageConstanst } from '../constants';

export const languageActions = {
  changeLanguage,
};

function changeLanguage(language) {
  const payload = {
    language,
  };

  return { type: languageConstanst.CHANGE_LANGUAGE, payload };
}
