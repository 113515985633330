import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  makeStyles,
  CardActions,
  TextField,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import { Person, Delete, AddCircle } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { articleActions, userFunctionActions } from '../../actions';
import { uniqueId } from 'lodash';

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: theme.spacing(2, 2),
  },
  chip: {
    margin: theme.spacing(1),
  },
  deleteIcon: {
    marginLeft: theme.spacing(1),
  },
}));

function ArticleAuthors({ isEdit=false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { users } = useSelector((state) => state.userFunction);
  const { articleDetails: { authors } } = useSelector((state) => state.articlesReducer);

  const [authorFields, setAuthorFields] = useState([
    { id: uniqueId(), selectedAuthor: null },
  ]);

  useEffect(() => {
    if(isEdit) {
      setAuthorFields(authors.map((author) => ({ id: uniqueId(), selectedAuthor: {name: author.name, id: author._id} })));
    }
  }, []);

  useEffect(() => {
    dispatch(userFunctionActions.getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    const authorsIds = authorFields.filter(({ selectedAuthor }) => selectedAuthor !== null).map(({ selectedAuthor: { id } }) => id);
    dispatch(articleActions.setAuthors(authorsIds, isEdit));
  }, [authorFields]);

  const getAvailableOptions = (fieldId) => {
    const selectedAuthorsIds = authorFields
      .filter((field) => field.id !== fieldId && field.selectedAuthor)
      .map((field) => field.selectedAuthor.id);

    return users.filter((user) => !selectedAuthorsIds.includes(user.id));
  };

  const handleAddAuthorField = () => {
    setAuthorFields((prevFields) => [
      ...prevFields,
      { id: uniqueId(), selectedAuthor: null },
    ]);
  };

  const handleAddAuthor = (author, fieldId) => {
    setAuthorFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId ? { ...field, selectedAuthor: author } : field
      )
    );
  };

  const handleRemoveAuthor = (fieldId) => {
    setAuthorFields((prevFields) => prevFields.filter((field) => field.id !== fieldId));

  };

  const handleInputChange = (reason, fieldId) => {
    if (reason === 'clear') {
      handleAddAuthor(null, fieldId);
    }
  };

  return (
    <Card>
      <CardActions className={classes.actions}>
        <div style={{ display: 'flex ', alignItems: 'center' }}>
          <div className={classes.iconDiscription}>
            <Person color="primary" />
          </div>
          <div className={classes.description}>{t('autores')}</div>
        </div>
      </CardActions>
      <CardContent className={classes.content}>
        {authorFields.map((field, index) => (
          <Grid container spacing={2} key={field.id}>
            <Grid item xs={10}>
              <Autocomplete
                id={`users-autocomplete-${field.id}`}
                onInputChange={(e, v, r) => handleInputChange(e, v, r, field.id)}
                options={getAvailableOptions(field.id)}
                getOptionLabel={(user) => user.name}
                value={field.selectedAuthor}
                onChange={(event, value) => {
                  if (value) {
                    handleAddAuthor(value, field.id);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t('participante')} variant="filled" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              {
                index !== 0 && <IconButton
                  style={{ marginLeft: 10 }}
                  onClick={() => handleRemoveAuthor(field.id)}
                >
                  <Delete />
                </IconButton>
              }
            </Grid>
          </Grid>
        ))}
        {authorFields[0]?.selectedAuthor !== null && <Button
          startIcon={<AddCircle />}
          variant="text"
          color="primary"
          style={{ marginTop: 10 }}
          onClick={handleAddAuthorField}
        >
          {t('adicionar_autor')}
        </Button>}
      </CardContent>
    </Card>
  );
}

export { ArticleAuthors };
