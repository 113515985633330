import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import { curationActions } from "./../../actions"

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const Tab = withStyles({
  wrapper: {
    textTransform: 'lowercase',
  }
})(MuiTab);

function CurationMenusTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters, esteiraTab } = useSelector(state => state.curation)
  const { occupationArea } = useSelector(state => state.businessUnitProps);
  const { t } = useTranslation();

  function handleChangeTab(tabView, label) {
    dispatch(curationActions.loadAllStatus([{
      label,
      value: tabView,
      checked: true
    }])); 
    dispatch(curationActions.changeCurationProp("esteiraTab", tabView));
    dispatch(curationActions.setStatusFixed({
        value: tabView,
        checked: true
      }));
    dispatch(curationActions.loadIdeas({
      ...filters,
      statusCode: [{
        value: tabView,
        checked: true
      }]
    }, {
      page: 1,
      query: '',
      total: 0,
      limit: 10,
      sort: { createDate: -1 },
    }, occupationArea))
  }

  return (
    <Tabs
      variant="fullWidth"
      textColor="primary"
      indicatorColor="primary"
      className={classes.tabs}
      value={esteiraTab}
      onChange={(_e, value) => handleChangeTab(value)}
    >
      <Tab
        value={'AWAITING_QUALIFICATION'}
        label={
          <Box display="flex" alignItems="center">
            <LooksOneIcon fontSize="small" className={classes.icon} />
            <Typography variant="subtitle2">{t('qualificacao')}</Typography>
          </Box>
        }
      />
      <Tab
        value={'EXECUTION_QUEUE'}
        label={
          <Box display="flex" alignItems="center">
            <LooksTwoIcon fontSize="small" className={classes.icon} />
            <Typography variant="subtitle2">{t('planejar_execucao')}</Typography>
          </Box>
        }
      />
      <Tab
        value={'EXECUTING'}
        label={
          <Box display="flex" alignItems="center">
            <Looks3Icon fontSize="small" className={classes.icon} />
            <Typography variant="subtitle2">{t('concluir_execucao')}</Typography>
          </Box>
        }
      />
    </Tabs>
  );
}

export { CurationMenusTable };
