export const profileConstants = {
  GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

  GET_IDEAS_PROFILE_REQUEST: 'GET_IDEAS_PROFILE_REQUEST',
  GET_IDEAS_PROFILE_SUCCESS: 'GET_IDEAS_PROFILE_SUCCESS',
  GET_IDEAS_PROFILE_FAILURE: 'GET_IDEAS_PROFILE_FAILURE',

  GET_IDEAS_PROFILE_MOBILE_REQUEST: 'GET_IDEAS_PROFILE_MOBILE_REQUEST',
  GET_IDEAS_PROFILE_MOBILE_SUCCESS: 'GET_IDEAS_PROFILE_MOBILE_SUCCESS',
  GET_IDEAS_PROFILE_MOBILE_FAILURE: 'GET_IDEAS_PROFILE_MOBILE_FAILURE',

  SET_LIMIT: 'SET_LIMIT',
  SET_PAGE: 'SET_PAGE',
};
