import React from 'react';

import { 
  Box,
  Typography, 
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.dark
  },
  icon: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function TemplateNewTitle({ icon, title }) {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box className={classes.icon}>
        {icon}
      </Box>
      <Typography className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}