import React from 'react';
import { useSelector } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import { CardDetails } from "./CardDetails";
import { CardTeams } from "./CardTeams";
import { CardFinacialPlanning } from "./CardFinacialPlanning";
import { SkeletonBoxProject, SkeletonTeamsAndFinancialPlanning} from "../Details/SkeletonGeneralData"

export const GeneralData = () => {
  const { loading } = useSelector(state => state.portfolioDetails);

  return (
    <>
      <Grid item xs={4}>
        {loading ? (
          <SkeletonBoxProject />
        ) : (
          <CardDetails />
        )}
      </Grid>

      <Grid item xs={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {loading ? (
              <SkeletonTeamsAndFinancialPlanning />
            ) : (
              <CardTeams />
            )}
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <SkeletonTeamsAndFinancialPlanning />
            ) : (
              <CardFinacialPlanning />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
