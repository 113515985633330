import React from 'react';
import {
  makeStyles,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import { DialogCustomTitle } from './DialogCustomTitle';
import { dialogCss } from './dialogCss';
import { MenuInformation } from '../Shared';
import clsx from 'clsx';
import { DialogCreateIdea } from './DialogCreateIdea';
import { useWindowSize } from '../../hooks';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  dividerBottom: {
    borderBottom: 'none',
  },
  title: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  button: {
    textTransform: 'lowercase',
  },
  grid: {
    position: 'relative',
  },
  buttonInformation: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 9999,
  },
  buttonBottom: {
    top: 242,
  },
}));

function ValueCanvasRead(props) {
  const dialogClasses = dialogCss();
  const classes = useStyles();
  const [width] = useWindowSize();

  return (
    <DialogCreateIdea maxWidth="lg" open={props.isOpen}>
      <DialogCustomTitle id="customized-dialog-title" onClose={props.onClose}>
        canvas
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
              <Grid container justify="center" spacing={2} padding={10}>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                Nesse ponto descreva de forma sucinta as melhores soluções do seu produto e aposte também emm elaborar um MVP(Produto Mínimo Viável), que consistem em um produto com o mínimo de recursos possível, mas que serve para conhecer a reação do mercado sobre a sua viabilidade. 

              </MenuInformation>
            </div>
            <TextField
              name="Q1"
              id="Q1"
              variant="filled"
              label="produtos e serviços"
              multiline
              fullWidth
              placeholder="Qual produto ou serviço será oferecido ao cliente?"
              style={{ marginBottom: 16 }}
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              disabled
              defaultValue={props.idea.canvasValueProposition[0]?.answer}
            />

            <div
              className={clsx(classes.buttonInformation, classes.buttonBottom)}
            >
              <MenuInformation>
                 Defina as principais ações e pontos que darão suporte e aliviarão as principais dores dos clientes.
                
              </MenuInformation>
            </div>
            <TextField
              name="Q2"
              id="Q2"
              variant="filled"
              label="analgésicos"
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              placeholder="Como sua ideia de produto alivia as principais dores dos clientes?"
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              disabled
              defaultValue={props.idea.canvasValueProposition[1]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                Faça uma lista dos três principais benefícios aos clientes.Esse é o momento de avaliar o que ajudaria os seus clientes em potencial e quais benefícios que a sua empresa se propõe a fazer.

              </MenuInformation>
            </div>

            <TextField
              name="Q3"
              id="Q3"
              variant="filled"
              label="criadores de ganhos"
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              placeholder="Quais elementos da sua ideia que trarão benefícios aos clientes?"
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                },
              }}
              disabled
              defaultValue={props.idea.canvasValueProposition[2]?.answer}
            />
          </Grid>
          
           
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                É aqui que você precisa concertar sua energia para pensar no grande diferencial da sua ideia, quais são seus ganhos necessários, esperados, desejados e inesperados que irá trazer inovação para o mercado que está inserido.

              </MenuInformation>
            </div>
            <TextField
              name="Q4"
              id="Q4"
              variant="filled"
              label="ganhos"
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              placeholder="Quais são os maiores ganhos da sua ideia para seu cliente?"
              style={{ marginBottom: 16 }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              disabled
              defaultValue={props.idea.canvasValueProposition[3]?.answer}
            />

            <div
              className={clsx(classes.buttonInformation, classes.buttonBottom)}
            >
              <MenuInformation>
                   É hora de identificar quais são as principais dificuldades desse cliente pensar em como o seu produto ou serviço poderão solucionar esses problemas.

              </MenuInformation>
            </div>
            <TextField
              name="Q5"
              id="Q5"
              variant="filled"
              label="dores"
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 8 : 8}
              placeholder="Quais são as maiores dores do seus cliente?"
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 5px',
                },
              }}
              disabled
              defaultValue={props.idea.canvasValueProposition[4]?.answer}
            />
          </Grid>
          <Grid item xs={12} md className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
                    Organize em uma lista tudo o que os clientes precisam realizar.Todas as tarefas que precisam executar no dia a dia. Caso Identifique que existe mais de um tipo de cliente, o ideal é executar um quadro para cada um deles.

              </MenuInformation>
            </div>
            <TextField
              name="Q6"
              id="Q6"
              variant="filled"
              label="tarefa dos clientes"
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 19 : 8}
              placeholder="Quais são as tarefas funcionais que os clientes precisam realizar?"
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                },
              }}
              disabled
              defaultValue={props.idea.canvasValueProposition[5]?.answer}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={6} className={classes.grid}  >
            <Grid container justify="center" spacing={2}>

              <Grid item xs={12} md={6} className={classes.grid}>
                <div className={classes.buttonInformation}>
                  <MenuInformation>
                    Escolha um nome que passe o objetivo de maneira simples o acessível.

                  </MenuInformation>
                </div>
                <TextField
                  name="Q7"
                  id="Q7"
                  variant="filled"
                  label="Nome do Produto"
                  multiline 
                  fullWidth

                  rows={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  rowsMax={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  placeholder="Qual é o nome da solução que a sua ideia traz ?"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 16, fontWeight: 'bold' },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: '34px 12px 10px',
                    },
                  }}
                  disabled
                  defaultValue={props.idea.canvasValueProposition[6]?.answer}
                />

              </Grid>
              <Grid item xs={12} md={6} className={classes.grid}  >
                <div className={classes.buttonInformation}>
                  <MenuInformation>
                Descreva como seria seu cliente ideal.

                  </MenuInformation>
                </div>
                <TextField
                  name="Q8"
                  id="Q8"
                  variant="filled"
                  label="cliente ideal"
                  multiline 
                  fullWidth

                  rows={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  rowsMax={width > config.RESPONSIVE_MOBILE ? 4 : 8}
                  placeholder="Qual seria o cliente ideal para sua ideia?"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 16, fontWeight: 'bold' },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: '34px 12px 10px',
                    },
                  }}
                  disabled
                  defaultValue={props.idea.canvasValueProposition[7]?.answer}
                />

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid}>
            <div className={classes.buttonInformation}>
              <MenuInformation>
Faça uma pesquisa com os principais adversários no mercado e veja como eles abordam o problema que sua ideia pretende resolver.

              </MenuInformation>
            </div>
            <TextField
              name="Q9"
              id="Q9"
              variant="filled"
              label="concorrentes ou substitutos"
              multiline
              fullWidth
              rows={width > config.RESPONSIVE_MOBILE ? 4 : 8}
              rowsMax={width > config.RESPONSIVE_MOBILE ? 4 : 8}
              placeholder="Quais são os principais concorrentes da sua ideia atuando no mercado?"
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 16, fontWeight: 'bold' },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '34px 12px 10px',
                },
              }}
              disabled
              defaultValue={props.idea.canvasValueProposition[8]?.answer}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <Button
              className={dialogClasses.button}
              variant="contained"
              type="submit"
              color="primary"
              onClick={props.onClose}
            >
              fechar
            </Button>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { ValueCanvasRead };
