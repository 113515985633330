import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export const useStylesIcon = makeStyles(() => ({
  root: {
    minWidth: 30,
  },
}));