import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  image: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  text: {
    transform: 'none',
  },
}))(MuiSkeleton);

export const SkeletonChallengeBanner = () => {
  const classes = useStyles();

  return (
    <Box>
      <Card elevation={0} className={classes.card} style={{ display: 'flex', alignItems: 'center', padding: 16 }}>
        <Skeleton variant="circle" width={22} height={22} />
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" marginLeft="7px">
          <Skeleton variant="text" width={60} />
          <Skeleton variant="text" width={60} />
        </Box>
      </Card>
      
      <Card elevation={0} className={classes.card} style={{ margin: '16px 0' }}>
        <Skeleton variant="text" width="100%" height={46} className={classes.image} />
        <Box padding="20px 16px 20px">
          <Skeleton variant="text" width="100%" height={25} style={{ marginBottom: 8 }} />
          <Skeleton variant="text" width="100%" height={12} style={{ marginBottom: 8 }} />
          <Skeleton variant="text" width="100%" height={12} style={{ marginBottom: 8 }} />
          <Skeleton variant="text" width="80%" height={12} />
        </Box>
      </Card>
    </Box>
  );
}