import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

import { truncate, history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  headerIcon: {
    marginRight: 6,
    color: theme.palette.auxiliary.yellow.main,
  },
  cardTitle: {
    color: theme.palette.neutrals.low.main,
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  position: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.neutrals.low.light,
    paddingRight: theme.spacing(1),
  },
  userName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  contentUserPoints: {
    fontSize: 13,
    color: theme.palette.neutrals.low.light,
  },
  contentPoints: {
    fontSize: 13,
    color: theme.palette.neutrals.low.light,
    marginLeft: theme.spacing(1),
  },
}));

export function Ranking({ ranking, userId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  function getFirstAndLastName(fullName) {
    const arrName = fullName.split(' ');
    if (arrName.length > 1) {
      return arrName[0] + ' ' + arrName[arrName.length - 1];
    }
    return arrName;
  }

  function handleSeeAllRanking() {
    history.push('/ranking');
  }

  function handleGoToProfile(id) {
    history.push(`/profile/${id}`);
  }

  return (
    <Card className={classes.root} elevation={0}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <EmojiEventsIcon className={classes.headerIcon} />
          <Typography variant="subtitle2" className={classes.cardTitle}>ranking</Typography>
        </Box>
        <Typography className={classes.textSeeAll} onClick={handleSeeAllRanking}>
          {t('ver_todos')}
        </Typography>
      </Box>
      <Box marginTop="16px">
        {ranking &&
          ranking.slice(0, 5).map((user, index) => (
            <div className={classes.contentBox} key={index}>
              <Box display="flex" alignItems="center">
                <Typography className={classes.position}>
                  {user.position}º
                </Typography>
                <Typography className={classes.userName} onClick={() => handleGoToProfile(user.userId)}>
                  {truncate(getFirstAndLastName(user.userName), 15)}
                </Typography>
              </Box>
              <Box>
                <span className={classes.contentUserPoints}>
                  {parseInt(user.score)}
                </span>
                <span className={classes.contentPoints}>pts</span>
              </Box>
            </div>
          ))}
      </Box>
    </Card>
  );
}