import { portfolioConstants } from '../../constants';
import { portfolioService } from '../../services';
import { alertActions } from './../alert.actions';

export const portfolioHomeActions = {
  getAllProjects,
  getAllProjectsTasks
}

function getAllProjects(portfolioStatus) {
  return dispatch => {
    dispatch({ 
      type: portfolioConstants.GET_ALL_PROJECTS_REQUEST 
    });
    portfolioService.getAllProjects().then(({ data }) => {
      const orderOneCode = portfolioStatus?.filter(item => item.order === 1)[0]?.code;
      const orderSecondCode = portfolioStatus?.filter(item => item.order === 2)[0]?.code;
      const orderThirdCode = portfolioStatus?.filter(item => item.order === 3)[0]?.code;

      const first_status_items = data.filter(item => item.status.code === orderOneCode);
      const second_status_items = data.filter(item => item.status.code === orderSecondCode);
      const third_status_items = data.filter(item => item.status.code === orderThirdCode);

      dispatch({ 
        type: portfolioConstants.GET_ALL_PROJECTS_SUCCESS, 
        payload: { 
          first_status_items,
          second_status_items,
          third_status_items
        }
      });
    }).catch(error => {
      console.error(error);
      dispatch({ 
        type: portfolioConstants.GET_ALL_PROJECTS_FAILURE 
      });
    });
  };
};

function getAllProjectsTasks(projectId) {
  return dispatch => {
    dispatch({ type: portfolioConstants.REQUEST_ALL_TASK_TO_BORDERLINE_PROGRESS });
    portfolioService.getAllProjectsTasks().then(({ data }) => {
      const tasksByProjectId = data.filter(data => data.projectId === projectId);
      const taskListBorderLineProgress = [
        tasksByProjectId.filter(({ taskStatus: { code } }) => code === "CONCLUDED").length || 0,
        tasksByProjectId.length || 0,
      ]
      dispatch({ type: portfolioConstants.SUCCESS_ALL_TASK_TO_BORDERLINE_PROGRESS, payload: { projectId, list: taskListBorderLineProgress } });
    }).catch(error => {
      console.log(error);
      dispatch({ type: portfolioConstants.FAILURE_ALL_TASK_TO_BORDERLINE_PROGRESS });
    });
  }
}
