import React, { useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import { AccountBox, Create, Delete} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { adminsActions } from '../../actions';
import { Administrators, DialogCustomTitle } from '../../components';
import { ImagineFormTextField } from '../../components/ImagineTemplates/ImagineFormTextField';
import { ImagineSectionTitle } from '../../components/ImagineTemplates/ImagineSectionTitle';
import { ImagineDialogConfirmation } from '../../components/ImagineTemplates';
import { ImagineFormSelect } from '../../components/ImagineTemplates/ImagineFormSelect';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 686,
    height: 529
  },
  titleIcon: {
    color: theme.palette.primary.main,
    width: 18
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.neutrals.low.medium
  },
  contentDescription: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.neutrals.low.medium
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark,
    marginLeft: 16,
    marginBottom: 24
  },
  icon: {
    backgroundColor: theme.palette.primary.light,
    width: 36,
    height: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    margin: 0,
  },
}));

export function AdminsPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialogAddContactOpen, openDialogConfirmation, admins, email, phone, adminId, adminName, isEditDialog, imageUrl } = useSelector(state => state.adminsReducer);

  useState(() => dispatch(adminsActions.getAdmins()), []);


  function handleEmail(email) {
    dispatch(adminsActions.handleEmail(email));
  }

  function handlePhone(phone) {
    dispatch(adminsActions.handlePhone(phone));
  }

  function handleAdminId(value) {
    dispatch(adminsActions.handleAdminId(value.value));
  }

  function handleDelete() {
    dispatch(adminsActions.handleDelete(adminId));
  }

  function handleSend() {
    if (isEditDialog) {
      dispatch(adminsActions.putAdminContact(phone, email, adminId, t('o_contado_foi_salvo')))
    } else {
      dispatch(adminsActions.postAdminContact(phone, email, adminId, t('o_contado_foi_adicionado')))
    }
  }

  function setDialogConfirmation(open = false) {
    dispatch(adminsActions.setDialogConfirmation(open));
  }

  return (
    <>
      <Dialog open={dialogAddContactOpen} onClose={() => dispatch(adminsActions.handleDialogAddContact(false, false))}>
        <DialogCustomTitle onClose={() => dispatch(adminsActions.handleDialogAddContact(false, false))}>{isEditDialog ? t('editar_contato') : t('novo_contato')}</DialogCustomTitle>
        <Divider />
        <DialogContent>
          <Typography style={{ fontSize: 14, fontWeight: 400, color: '#495057', marginBottom: 21 }}>{t('adicione_o_contato_dos_administradores_da_plataforma')}</Typography>
          {!isEditDialog && <ImagineFormSelect label={t('administrador')} name='admin' onChange={handleAdminId} options={admins} />}
          <Box display="flex">
            <Box className={classes.icon}>
              <AccountBox className={classes.titleIcon} />
            </Box>
            <Typography className={classes.title}>
              {t('perfil_de_administrador')}
            </Typography>
          </Box>
          <Box className={classes.header}>
            <Avatar alt={`${adminName} photo's`} src={imageUrl} />
            <Box className={classes.headerSide} >
              <strong>{adminName}</strong>
              <Typography className={classes.text}>Inovação</Typography>
            </Box>
          </Box>
          <Box marginBottom={16}>
            <ImagineFormTextField defaultValue={email} label={t('email')} name='email' onChange={handleEmail} />
            <ImagineFormTextField defaultValue={phone} label={t('telefone')} name='phone' onChange={handlePhone} />
          </Box>
        </DialogContent>
        <DialogActions style={{ width: '100%', display: 'flex', justifyContent: 'end', padding: '0 21px 21px 21px' }} >
          <Button variant="contained" color='primary' onClick={() => handleSend()}>{isEditDialog ? t('salvar_contato') : t('adicionar_contato')}</Button>
        </DialogActions>

      </Dialog>
      <ImagineDialogConfirmation
        open={openDialogConfirmation}
        onClose={setDialogConfirmation}
        title={t('excluir_contato')}
        subtitle={t('deseja_excluir_o_contato')}
        description={t('as_informacoes_de_contato_do_administrador_nao_serao_mais_exibidas_dessa_secao')}
      >
        <Box>
          <Button
            style={{ marginRight: 16 }}
            variant="outlined"
            color="primary"
            onClick={() => setDialogConfirmation(false)}
          >
            {t('voltar')}
          </Button>
          <Button
            startIcon={<Delete />}
            variant="contained"
            color="primary"
            onClick={handleDelete}
          >
            {t('excluir_contato')}
          </Button>
        </Box>
      </ImagineDialogConfirmation>
      <Grid item xs={12} lg={9}>
        <Container maxWidth="lg">
          <Typography gutterBottom>{t('administradores').toLocaleUpperCase()}</Typography>
          <Card style={{ padding: 32 }}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <ImagineSectionTitle icon={<AccountBox className={classes.titleIcon} />} title={t('administradores')} />
              <Button startIcon={<Create />} style={{ height: 48 }} variant='outlined' color='primary' onClick={() => dispatch(adminsActions.handleDialogAddContact(true, false))} >{t('adicionar_contato')}</Button>
            </Box>
            <CardContent>
              <Administrators isEdit />
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </>
  );
}